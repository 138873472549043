import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { NavLink, Link } from 'react-router-dom'
import intl from "react-intl-universal"
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

export default function SideMenu(props) {
  const [open, setOpen] = React.useState({ clients: false, finance: false, settings: false });
  const [activeTab, setActiveTab] = React.useState('');

  React.useEffect(() => {
    const pathname = window.location.pathname;
    setActiveTab(pathname);
  }, []);

  console.log('Active Tab:', activeTab);

  const handleClick = (menu) => {
    const updatedOpenState = Object.keys(open).reduce((acc, key) => {
      acc[key] = key === menu ? !open[key] : false;
      return acc;
    }, {});
    setOpen(updatedOpenState);
  };

  const DrawerList = (
    <Box  sx={{ width: '13vw', paddingTop: 2 }}>
      <Link to="/dashboard" onClick={() => { setActiveTab('/dashboard') }}>
        <ListItem style={{ backgroundColor: activeTab == '/dashboard' ? 'white' : 'transparent' }} >
          <ListItemButton>
            <div style={{ color: activeTab == '/dashboard' ? '#3e87c6' : 'white' }}>{intl.get('DASHBOARD') || 'Dashboard'}</div>
          </ListItemButton>
        </ListItem>
      </Link>
      <Link to="/appointments" onClick={() => { setActiveTab('/appointments') }}>
        <ListItem style={{ backgroundColor: activeTab == '/appointments' ? 'white' : 'transparent' }}>
          <ListItemButton>
            <div style={{ color: activeTab == '/appointments' ? '#3e87c6' : 'white' }}>{intl.get('APPOINTMENTS') || 'Appointments'}</div>
          </ListItemButton>
        </ListItem>
      </Link>

      <NavLink to="/users/view" onClick={() => { setActiveTab('/users/view') }}>
        <ListItem style={{ backgroundColor: activeTab == '/users/view' ? 'white' : 'transparent' }} >
          <ListItemButton>
            <div style={{ color: activeTab == '/users/view' ? '#3e87c6' : 'white' }}>{intl.get('STAFF') || 'Staff'}</div>
          </ListItemButton>
        </ListItem>
      </NavLink>
      <NavLink to="/pendingAppointments" onClick={() => { setActiveTab('/pendingAppointments') }}>
        <ListItem style={{ backgroundColor: activeTab == '/pendingAppointments' ? 'white' : 'transparent' }}>
          <ListItemButton>
            <div style={{ color: activeTab == '/pendingAppointments' ? '#3e87c6' : 'white' }}>{intl.get('WAITING_LIST') || 'Waiting list'}</div>
          </ListItemButton>
        </ListItem>
      </NavLink>

      <ListItemButton onClick={() => handleClick('clients')}>
        <ListItemText sx={{ color: 'white' }}
          primary={intl.get('CLIENTS') || 'Clients'} />
        {open.clients ? <ExpandLess sx={{ color: 'white' }} /> : <ExpandMore sx={{ color: 'white' }} />}
      </ListItemButton>
      <Collapse in={open.clients} timeout="auto" unmountOnExit>
        <List component="div" >
          <Link to="/clients/view" color='white' onClick={() => { setActiveTab('/clients/view') }}>
            <ListItem style={{ backgroundColor: activeTab == '/clients/view' ? 'white' : 'transparent' }}>
              <ListItemButton>
                <div style={{ color: activeTab == '/clients/view' ? '#3e87c6' : 'white' }}>{intl.get('CLIENTS') || 'Clients'}</div>
              </ListItemButton>
            </ListItem>
          </Link>
          <Link to="/metadata/view" onClick={() => { setActiveTab('/metadata/view') }}>
            <ListItem style={{ backgroundColor: activeTab == '/metadata/view' ? 'white' : 'transparent' }}>
              <ListItemButton>
                <div style={{ color: activeTab == '/metadata/view' ? '#3e87c6' : 'white' }}>{intl.get('CLIENTS_INFORMATIONS') || 'Clients Information'}</div>
              </ListItemButton>
            </ListItem>
          </Link>
        </List>
      </Collapse>
      <ListItemButton onClick={() => handleClick('finance')}>
        <ListItemText sx={{ color: 'white' }}
          primary={intl.get('FINANCE') || 'Finance'} />
        {open.finance ? <ExpandLess sx={{ color: 'white' }} /> : <ExpandMore sx={{ color: 'white' }} />}
      </ListItemButton>
      <Collapse in={open.finance} timeout="auto" unmountOnExit>
        <List component="div" >
          <Link to='/sales/all' onClick={() => { setActiveTab('/sales/all') }}>
            <ListItem style={{ backgroundColor: activeTab == '/sales/all' ? 'white' : 'transparent' }}>
              <ListItemButton>
                <div style={{ color: activeTab == '/sales/all' ? '#3e87c6' : 'white' }}>{intl.get('INVOICES') || 'Specialities'}</div>
              </ListItemButton>
            </ListItem>
          </Link>
          <Link to='/quotations/all' onClick={() => { setActiveTab('/quotations/all') }}>
            <ListItem style={{ backgroundColor: activeTab == '/quotations/all' ? 'white' : 'transparent' }}>
              <ListItemButton>
                <div style={{ color: activeTab == '/quotations/all' ? '#3e87c6' : 'white' }}>{intl.get('QUOTATIONS') || 'Quotations'}</div>
              </ListItemButton>
            </ListItem>
          </Link>
          <Link to='/expenses/view' onClick={() => { setActiveTab('/expenses/view') }}>
            <ListItem style={{ backgroundColor: activeTab == '/expenses/view' ? 'white' : 'transparent' }}>
              <ListItemButton>
                <div style={{ color: activeTab == '/expenses/view' ? '#3e87c6' : 'white' }}>{intl.get('EXPENSES') || 'Expenses'}</div>
              </ListItemButton>
            </ListItem>
          </Link>
          <Link to='/finance/report' onClick={() => { setActiveTab('/finance/report') }}>
            <ListItem style={{ backgroundColor: activeTab == '/finance/report' ? 'white' : 'transparent' }}>
              <ListItemButton>
                <div style={{ color: activeTab == '/finance/report' ? '#3e87c6' : 'white' }}>{intl.get('REPORTS') || 'Reports'}</div>
              </ListItemButton>
            </ListItem>
          </Link>

        </List>
      </Collapse>


      <ListItemButton onClick={() => handleClick('settings')}>
        <ListItemText sx={{ color: 'white' }}
          primary={intl.get('SETTINGS') || 'Settings'} />
        {open.settings ? <ExpandLess sx={{ color: 'white' }} /> : <ExpandMore sx={{ color: 'white' }} />}

      </ListItemButton>
      <Collapse in={open.settings} timeout="auto" unmountOnExit>
        <List component="div" >
          <Link to='/specialities/view' onClick={() => { setActiveTab('/specialities/view') }}>
            <ListItem style={{ backgroundColor: activeTab == '/specialities/view' ? 'white' : 'transparent' }}>
              <ListItemButton>
                <div style={{ color: activeTab == '/specialities/view' ? '#3e87c6' : 'white' }}>{intl.get('SPECIALITIES') || 'Specialities'}</div>
              </ListItemButton>
            </ListItem>
          </Link>
          <Link to='/services/view' onClick={() => { setActiveTab('/services/view') }}>
            <ListItem style={{ backgroundColor: activeTab == '/services/view' ? 'white' : 'transparent' }}>
              <ListItemButton>
                <div style={{ color: activeTab == '/services/view' ? '#3e87c6' : 'white' }}>{intl.get('SERVICES') || 'Services'}</div>
              </ListItemButton>
            </ListItem>
          </Link>
          <Link to='/currencies/view' onClick={() => { setActiveTab('/currencies/view') }}>
            <ListItem style={{ backgroundColor: activeTab == '/currencies/view' ? 'white' : 'transparent' }}>
              <ListItemButton>
                <div style={{ color: activeTab == '/currencies/view' ? '#3e87c6' : 'white' }}>{intl.get('CURRENCIES') || 'Currencies'}</div>
              </ListItemButton>
            </ListItem>
          </Link>
          <Link to='/units/view' onClick={() => { setActiveTab('/units/view') }}>
            <ListItem style={{ backgroundColor: activeTab == '/units/view' ? 'white' : 'transparent' }}>
              <ListItemButton>
                <div style={{ color: activeTab == '/units/view' ? '#3e87c6' : 'white' }}>{intl.get('UNITS') || 'Units'}</div>
              </ListItemButton>
            </ListItem>
          </Link>
          <Link to='/settings/general' onClick={() => { setActiveTab('/settings/general') }}>
            <ListItem style={{ backgroundColor: activeTab == '/settings/general' ? 'white' : 'transparent' }}>
              <ListItemButton>
                <div style={{ color: activeTab == '/settings/general' ? '#3e87c6' : 'white' }}>{intl.get('ADVANCED') || 'Advanced'}</div>
              </ListItemButton>
            </ListItem>
          </Link>
        </List>
      </Collapse>


      {/* </ListItem> */}
      {/* </List> */}
      <Divider />
      {/* <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem key={text} >
            <ListItemButton>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List> */}
    </Box>
  );

  return (
    <div>
      {DrawerList}
    </div>
  );
}
