import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { PUBLIC_URL, API_PREFIX } from "../../../commons/config";
import { CardText, Card, Dialog, Tabs, Tab } from "material-ui";
import checkResponse from "../../../commons/CheckResponse";
import AppointmentCard from "../../../components/commons/table/AppointmentCard";
import intl from 'react-intl-universal';

export default
@inject("store")
@observer
class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appointments: [],
      loading: true,
      dialog: false
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    const { get_business_id } = this.props.store;
    const { client_id } = this.props.match.params;
    const URL =
      PUBLIC_URL +
      API_PREFIX +
      "appointments/get/" +
      client_id +
      "/" +
      get_business_id();
    checkResponse(URL, "get", 1).then(result => {
      if (result.data) {
        this.insertData(result);
      }
      if (result.response) {
        this.setState({ err: result.response.data.error_description });
      }
    });
  }

  insertData(data) {
    const { appointments } = data.data;
    this.setState({ appointments, loading: false });
  }

  card() {
    if (!this.state.loading) {
      return this.state.appointments.map(appointment => (
        <a key={appointment.id} onClick={this.handleOpen.bind(this)} href="#">
          <Card className="md20">
            <CardText>
              <h3>{appointment.start_date}</h3>
            </CardText>
          </Card>
        </a>
      ));
    }
  }

  handleOpen = () => {
    this.setState({ dialog: true });
  };

  handleClose = () => {
    this.setState({ dialog: false });
  };

  dialog() {
    return (
      <div>
        <Dialog
          style={{ padding: 0 }}
          paperClassName="nopad"
          modal={false}
          open={this.state.dialog}
          onRequestClose={this.handleClose}
        >
          {this.tabs()}
        </Dialog>
      </div>
    );
  }

  tabs() {
    return (
      <Tabs>
        <Tab label="Item One">
          <div className="pad15">
            <h2>Tab One</h2>
            <p>This is an example tab.</p>
            <p>
              You can put any sort of HTML or react component in here. It even
              keeps the component state!
            </p>
          </div>
        </Tab>
        <Tab label="Item One">
          <div>
            <h2>Tab two</h2>
            <p>This is an example tab.</p>
            <p>
              You can put any sort of HTML or react component in here. It even
              keeps the component state!
            </p>
          </div>
        </Tab>
      </Tabs>
    );
  }

  render() {
    if (this.state.appointments.length > 1) {
      return (
        <header className="header">
          <div className="text-center mt-4">
          <h2 className="primary-heading"> {intl.get('CLIENT_APPOINTMENTS') || 'Client Appointments'}</h2>
          </div>
          <div className="action-container">
          {this.dialog()}

{this.state.appointments.map(appointment => {
  if (appointment.notes != "fakeClientAppointment") {
    return (
      <div className='mb-3'>
       <AppointmentCard
        id={appointment.id}
        appointment={appointment}
        doctor={appointment.doctor.name}
        patient={appointment.patient}
        user={appointment.patient}
        date={appointment.created_at}
        time={appointment.time_start}
        status={appointment.status}
        flag={appointment.flag}
        history={this.props.history}
        get_data={this.props.get_data}
      />
      </div>
     
    )
  }
})}
          </div>
        </header>
       
         
      
      );
    } else {
      return (
        <header className='header'>
           <div className="text-center mt-4">
           <h2 className="primary-heading"> {intl.get('CLIENT_APPOINTMENTS') || 'Client Appointments'}</h2>
          </div>
          <div className="action-container">
            <div className="form-container" style={{padding:'3rem'}}>
            <h1>there is no appointments for this patient yet</h1>
          <button
            className="btn btn-primary btn-sm"
            onClick={_ => this.props.history.push("/appointments")}
          >
            Create the first appointment
          </button>
            </div>
          </div>
          
        </header>
      );
    }
  }
}
