import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Swal from "sweetalert2";

import { PUBLIC_URL, API_PREFIX } from "../../../../commons/config";
import { inject, observer } from "mobx-react";
import checkResponse from "../../../../commons/CheckResponse";
import moment from "moment";
import $ from "jquery";
import SelectSearch from "react-select-search";
import Modal from "react-responsive-modal";
import uuid from "uuid";

import fuzzySearch from "./FuzzySearch";
import CountryCodes from "../../../CountryCodes";
import intl from "react-intl-universal";
import showIcon from "../../../../assets/svgs/showIcon.svg";
import showIconForStuff from "../../../../assets/svgs/showIconForStuff.svg";
import "./ShowCardCreate.css";
import { formatArrays } from "./formateForSelectSearch";

var showIconStyle = {
  backgroundImage: `url(${showIcon})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "right 12px center",
};

var showIconStyleForStuffStyle = {
  backgroundImage: `url(${showIconForStuff})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "right 12px center",
};

const priorities = [
  {
    id: 1,
    name: "normal",
    flag: "normal",
  },
  {
    id: 2,
    name: "medium",
    flag: "medium",
  },
  {
    id: 3,
    name: "high",
    flag: "high",
  },
  {
    id: 4,
    name: "vip",
    flag: "vip",
  },
  {
    id: 5,
    name: "other",
    flag: "other",
  },
];

export default
@inject("store")
@observer
class ShowCardCreate extends Component {
  state = {
    services: [],
    clients: [],
    client_id: null,
    flag: "normal",
    start_date: new Date(),
    user_id: this.props.store.get_id(),
    notes: "",
    status: "Pending",
    quickAdd: false,
    userError: "",
    waiting: false,
    number: 1,
    showClientsSelect: true,
  };

  componentDidMount() {
    this.setState({ clients: this.props.clients });
  }
  _createClient(e) {
    e.preventDefault();
    this.setState({ userError: "" });
    let country_code = this.state.country_code;

    let { pname, pmobile } = this.state;
    pmobile = pmobile.replace(/\s/g, "");
    if (!pmobile.match(/^[0-9]+$/) || pmobile.length > 12) {
      return this.setState({ userError: "invalid phone number" });
    }
    const { get_business_id } = this.props.store;
    const URL = PUBLIC_URL + API_PREFIX + "clients/create";
    const active = 1;
    checkResponse(URL, "post", {
      name: pname,
      country_code,
      mobile: pmobile,
      active,
      email: uuid() + "@clinic.com",
      speciality_id: "client",
      business_id: get_business_id(),
    }).then((result) => {
      if (result.data) {
        this.setState({
          client_name: result.data.name,
          client_id: result.data.id,
          quickAdd: !this.state.quickAdd,
          clients: [
            ...this.state.clients,
            {
              label: `${result.data.name} - ${result.data.mobile}`,
              value: result.data.id,
              active: 1,
            },
          ],
          showClientsSelect: false,
        });
        this.formatted_clients = formatArrays(
          [
            ...this.state.clients,
            {
              label: `${result.data.name} - ${result.data.mobile}`,
              value: result.data.id,
              active: 1,
            },
          ],
          "label",
          "value"
        );

        setTimeout(() => {
          this.setState({
            showClientsSelect: true,
          });
        }, 100);
        this.forceUpdate();
        return;
        //  toast("new client has been added!", {
        //   position: toast.POSITION.TOP_CENTER
        // });
      }

      if (result.response)
        return this.setState({ err: result.response.data.error_description });
    });
  }

  _quickAdd() {
    return (
      <Modal
        open={this.state.quickAdd}
        onClose={(_) => this.setState({ quickAdd: false })}
        center
      >
        {this.userError()}

        <form className="addNewClient" onSubmit={this._createClient.bind(this)}>
          <input
            required
            onChange={(e) => this.setState({ pname: e.target.value })}
            placeholder={intl.get("CLIENT_NAME") || "Client Name"}
            className="clientName"
          />

          <div className="clientPhoneNumberHolder">
            <CountryCodes
              cssClass="country-code-2"
              countryCode={(country_code) => this.setState({ country_code })}
              style={{ padding: "6px 10px" }}
            />
            <input
              required
              onChange={(e) => this.setState({ pmobile: e.target.value })}
              placeholder={intl.get("MOBILE_NUMBER") || "Mobile number"}
              className="mobileNumberInput"
              style={{ marginTop: ".8rem" }}
            />
          </div>
          <div
            className="addNewClientFormActions"
            style={{ justifyContent: "space-around" }}
          >
            <input
              className="btn-ghost "
              value={intl.get("SUBMIT") || "SUBMIT"}
              type="submit"
            />
            <button
              className="btn-ghost"
              type="button"
              onClick={(_) => this.setState({ quickAdd: false })}
            >
              {intl.get("CANCEL") || "cancel"}
            </button>
          </div>
        </form>
      </Modal>
    );
  }

  _validate() {
    const { client_id } = this.state;
    if (client_id == null) {
      this.setState({ err: " patient should not be empty " });
      return false;
    }
    let data = [{ name: "patient", value: client_id }];
    let errors = [];

    for (var i = 0; i <= data.length - 1; i++) {
      if (!data[i].value || !data[i].value.length) {
        errors.push(data[i].name);
      }
    }
    if (errors.length > 0) {
      this.setState({ err: errors[0] + " should not be empty." });
      this.setState({ show: true });
      return false;
    } else {
      return true;
    }

    // return status && user_id && client_id && start_date &&
    // services && time_end && time_start && flag;
  }

  _createAppointment() {
    if (this._validate()) {
      const { get_business_id, get_id } = this.props.store;
      const URL = PUBLIC_URL + API_PREFIX + "appointments/create";
      const { services, client_id, flag, user_id, notes, number } = this.state;
      let status = this.state.status;
      const start_date = this.props.start_date;
      const time_start = this.state.time_start || this.props.time_start;
      const time_end = this.state.time_end || this.props.time_end;
      // if (moment(time_start, 'HH:mm a').format("HH:mm a") < moment().format("HH:mm a")){
      //   return alert("can't make an appointment in the past time")
      // }
      status = "Pending";
      if (this.state.waiting) {
        status = "Waiting";
      }

      let data = {
        time_start,
        services,
        time_end,
        client_id,
        start_date: moment(start_date).format("YYYY/MM/DD"),
        user_id: user_id.value || get_id(),
        notes,
        status,
        number,
        flag,
        business_id: get_business_id(),
      };
      let timeformat = new RegExp(
        "((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))"
      );
      if (!timeformat.test(time_start)) {
        return this.setState({ err: "invalid start time format" });
      }
      if (!timeformat.test(time_end)) {
        return this.setState({ err: "invalid end time format" });
      }
      if (
        time_end >= time_start ||
        (time_start.split(":")[0] >= "10" && time_end.charAt(0) == "0")
      ) {
        checkResponse(URL, "post", data)
          .then((result) => {
            if (result.data) {
              if (result.data.appointment.smsStatus === "Fail") {
                Swal.fire({
                  type: "warning",
                  icon: "warning",
                  title: "Insufficient Balance",
                  text:
                    "Your balance is not sufficient enough to notify the client via SMS. Please re-charge your account.",
                });
              }
              this.props.selectedUser(user_id);
              let clients = [...this.state.clients];
              let filterClient = clients.filter((c) => c.value === client_id);

              var render = {
                title: filterClient[0].label.split(" - ")[0],
                start:
                  moment(start_date).format("YYYY/MM/DD") + " " + time_start,
                end: moment(start_date).format("YYYY/MM/DD") + " " + time_end,
                time_start,
                time_end,
                notes,
                flag,
                number,
                id: result.data.appointment.id,
                status,
                client_id,
                patient_name: filterClient[0].label,
                allDay: false,
              };
              let appointment_time = render.start;
              let date = appointment_time.split(" ")[0];
              let time = appointment_time.split(" ")[1];
              if (
                moment(date).format("LL") === moment(new Date()).format("LL")
              ) {
                date = "today";
              }
              if (
                moment(date).format("LL") ===
                moment(new Date())
                  .add(1, "days")
                  .format("LL")
              ) {
                date = "tomorrow";
              }
              let dateshow = `on ${date} at ${time}`;
              if (!date.includes("/")) {
                dateshow = `${date} at ${time}`;
              }
              let message = `Appointment for ${render.title} has been set ${dateshow}`;
              // toast(message, {
              //   position: toast.POSITION.TOP_CENTER
              // });
              $("#calendar").fullCalendar("renderEvent", render, true);
              // this.setState({ dialog: !this.state.dialog });
              this.props._save();
              this.props.getStats();
            }
          })
          .catch((e) => {
            console.log(e);
          });
        const token = localStorage.getItem("googleTokens");
        if (token) {
          data = {
            token,
            event: data,
            client_name: this.state.client_id.label,
          };
          const URL2 = PUBLIC_URL + API_PREFIX + "calendarEvents";
          checkResponse(URL2, "post", data)
            .then((r) => {
              if (r.response) {
                return;
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      } else {
        this.setState({
          err: "Time end should be greater than Time start",
          show: true,
        });
      }
    }
  }

  validationError() {
    if (this.state.err)
      return (
        <h4 style={{ textAlign: "center", marginTop: "5px", color: "red" }}>
          {this.state.err}
        </h4>
      );
  }

  userError() {
    if (this.state.userError)
      return (
        <h4 style={{ textAlign: "center", marginTop: "5px", color: "red" }}>
          {this.state.userError}
        </h4>
      );
  }

  addToWaiting = (e) => {
    this.setState({ waiting: !this.state.waiting });
  };

  getFlagValue = (flag) => {
    if (flag == "normal") {
      flag = intl.get("NORMAL");
    }
    if (flag == "medium") {
      flag = intl.get("MEDIUM");
    }
    if (flag == "high") {
      flag = intl.get("HIGH");
    }
    if (flag == "vip") {
      flag = intl.get("VIP");
    }
    if (flag == "other") {
      flag = intl.get("OTHER");
    }
    return flag;
  };

  handleNumberChange = (event) => {
    let re = /^[0-9]*$/;
    if (re.test(event.target.value)) {
      this.setState({ number: event.target.value });
    }
  };

  onClientChange = (e) => {
    this.setState({ client_id: e });
  };

  render() {
    const cal = this.props.cal;
    const { services, status, user_id, flag } = this.state;
    let clients = [...this.state.clients];

    let users = [...this.props.users];
    clients = clients.filter((cli) => cli.active == 1);

    // console.log('time_start', this.props.time_start)
    // console.log('time_end', this.props.time_end)
    let formatted_clients = formatArrays(clients, "label", "value");

    return (
      <div className="addNewAppointmentModal">
        <Paper id="addNewAppointment" elevation={20}>
          {this.validationError()}

          {/* <select className="selectClient"
                        style={ showIconStyle }
                        value={this.state.lang}
                        onChange={event => this.setState({ client_id: JSON.parse(event.target.value) }) }
                > 
                  <option value="" disabled selected>&#160;&#160;&#160;{this.state.client_name ? this.state.client_name : intl.get("SELECT_CLIENT") || "Full Name"}</option>
                      {clients.map((client, index) => (
                        <option key={index} value={JSON.stringify(client)}>
                          &#160;&#160;&#160;{client.label}
                        </option>
                      ))}
                </select> */}
          {this.state.showClientsSelect ? (
            <SelectSearch
              autoComplete="off"
              className="selectClient"
              search={true}
              filterOptions={fuzzySearch}
              options={formatted_clients}
              value={this.state.client_id}
              placeholder={
                this.state.client_name
                  ? this.state.client_name
                  : intl.get("SELECT_CLIENT") || "Full Name"
              }
              onChange={this.onClientChange}
            />
          ) : null}

          <div className="numberOfClients">
            <input
              id="clientsNumber"
              value={this.state.number}
              pattern="[0-9]*"
              type="text"
              maxlength="4"
              onChange={this.handleNumberChange}
            />
            <label htmlFor="clientsNumber">
              {intl.get("NUMBER") || "Number"}
            </label>
          </div>
          <button
            className="addNewClientBtn"
            onClick={(_) => this.setState({ quickAdd: !this.state.quickAdd })}
          >
            {intl.get("ADD_NEW") || "Add New"}
          </button>

          <div className="waitingList" onClick={this.addToWaiting}>
            <input
              type="checkbox"
              value="Waiting"
              id="waitingListCheck"
              name="Waiting"
              checked={this.state.waiting}
            />
            <label htmlFor="waitingListCheck">
              {intl.get("WAITING_LIST") || "Waiting list"}
            </label>
          </div>
          <div className="appointmentTiming">
            <i
              className="fa fa-clock-o"
              style={{ color: "#707070", fontSize: "1.8rem" }}
            ></i>
            <input
              defaultValue={this.props.time_start}
              className="time-picker"
              disabled
              type="text"
              style={{ fontSize: 14 }}
              onChange={(e) => this.setState({ time_start: e.target.value })}
            />
            <input
              defaultValue={this.props.time_end}
              className="time-picker"
              disabled
              type="text"
              style={{ fontSize: 14 }}
              onChange={(e) => this.setState({ time_end: e.target.value })}
            />
          </div>

          <div className="addnewAppointmentStaff">
            <span>{intl.get("SELECT_STAFF") || "Select staff"}</span>

            <select
              className="selectStuff"
              style={showIconStyleForStuffStyle}
              defaultValue={users.filter(
                (option) => option.value === this.props.store.get_id()
              )}
              onChange={(val) =>
                this.setState({ user_id: JSON.parse(val.target.value) }, () =>
                  console.log("user_id", user_id)
                )
              }
            >
              <option value="" disabled selected>
                &#160;&#160;&#160;{intl.get("STAFF_NAME") || "Staff Name"}
              </option>
              {users.map((user, index) => (
                <option key={index} value={JSON.stringify(user)}>
                  &#160;&#160;&#160;{user.label}
                </option>
              ))}
            </select>
          </div>

          <div className="addNewAppointmentPriority">
            <img src={require("../../../../assets/svgs/flag.svg")} alt="flag" />
            <select
              className="selectPriority"
              value={this.state.flag}
              onChange={(event) =>
                this.setState({ flag: event.target.value }, () =>
                  console.log("flag", this.state.flag)
                )
              }
            >
              {priorities.map((priority) => (
                <option key={priority.id} value={priority.flag}>
                  {priority.name}
                </option>
              ))}
            </select>
          </div>

          <div className="addNewAppointmentNotes">
            <img src={require("../../../../assets/svgs/note.svg")} alt="note" />
            <TextField
              label={intl.get("NOTES") || "Notes"}
              margin="normal"
              onChange={(event) => this.setState({ notes: event.target.value })}
            />
          </div>

          {this._quickAdd()}

          <div
            className="d-flex justify-content-center align-items-center"
            style={{ marginLeft: "6rem" }}
          >
            <button
              className="btn-ghost mr-2"
              type="button"
              onClick={(_) => this._createAppointment()}
            >
              {intl.get("SAVE") || "save"}
            </button>
            <button
              className="btn-ghost"
              type="button"
              onClick={(_) => this.props._cancel()}
            >
              {intl.get("CANCEL") || "cancel"}
            </button>
          </div>
          <div className="formActions"></div>
        </Paper>
      </div>
    );
  }
}
