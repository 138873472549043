import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import intl from "react-intl-universal";
import logo from "../assets/images/logo.png";
import logo_booking_white from "../assets/images/bookingwhitebg.png";
import smallLogo from "../assets/images/logo.png";
import searchIcon from "../assets/svgs/searchIcon.svg";
import SelectLanguage from "../commons/SelectLanguage";
import RightMenu from "./rightMenu";
import fourDotsWhite from "../assets/svgs/fourDotsWhite.svg";

// import { BurgerIcon } from './'
import styled from "styled-components";

// let CSSTransitionGroup = React.addons.CSSTransitionGroup
var CSSTransitionGroup = require("react-addons-css-transition-group");

const Nav = styled.header`
  width: 100%;
  z-index: 1;
  display: grid;
  grid-template-columns: 20% 60% 20%;
  align-items: center;
  padding: 0px 25px 0 33px;
  height: 73px;
  background: #ffffff;
  position: relative;
  z-index: 1;

  .name {
    font-size: 18px;
    font-weight: 600;
    color: #3e87c6;
    // padding-left: 52px;
    justify-self: center;
  }

  .rightSideNavigation {
    width: 100%;
    height: 100%;
    display: inline-grid;
    /* grid-template-columns: 1fr 1fr 1fr; */
    grid-template-columns: 45% 37% 18%;
    align-items: center;
    justify-items: end;
  }

  .startSearch {
    width: 17px;
    max-width: max-content;
    cursor: pointer;
  }

  .selectLanguageWrapper {
    height: max-content;

    /* margin: 0 31px 0 12px !important; */
  }

  .rightSideNavigation .languageBar {
    width: 100px;
    height: 27px;
    border-radius: 20px;
    padding: 0 0.5rem;
    border: solid 1px #858585;
    background-color: #ffffff;
    font-size: 10px;
    font-weight: 300;
    text-align: center;
    color: #707070;
    text-align-last: center;
    text-align: center;
    -ms-text-align-last: center;
    -moz-text-align-last: center;
  }

  .rightSideNavigation .selectLanguageWrapper .languageBar .d-flex .name {
    color: #707070 !important;
    font-size: 10px;
  }

  .rightMenuWrapper,
  .rightMenuWrapper > div {
    margin: 0 !important;
    padding: 0;
    height: 100%;
  }

  .selectLanguageWrapper select {
    -webkit-appearance: none;
    appearance: none;
  }

  .languageBar {
    font-size: 10px;
    font-weight: 300;
    background-color: #020202;
    border: 0;
    color: #ffffff;
    outline: 0;
    cursor: pointer;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .slide-enter {
    transform: translateY(-100%);
    transition: 0.3s cubic-bezier(0, 1, 0.5, 1);
  }

  .slide-enter.slide-enter-active {
    transform: translateY(0%);
  }

  .slide-leave {
    transform: translateY(0%);
    transition: 0.3s ease-in-out;
  }

  .slide-leave.slide-leave-active {
    transform: translateY(-100%);
  }

  .nav__submenu {
    border: 1px solid #707070;
    display: grid;
    grid-template-columns: 1fr;
  }

  .nav__submenu-item {
    height: 42px;
    line-height: 42px;

    a {
      font-size: 12px;
      font-weight: normal;
      color: #707070;
      line-height: 42px;
      padding: 0 8px;
    }

    a:hover {
      color: #222;
    }
  }

  .nav__submenu-item:not(:last-child) {
    border-bottom: 1px solid #707070;
  }

  // .nav__submenu {
  //   font-weight: 300;
  //   text-transform: none;
  //   display: none;
  //   position: absolute;
  //   width: 180px;
  //   background-color: #00aeef;
  // }

  // .nav__submenu-item:hover {
  //   background: rgba(0, 0, 0, 0.1);
  // }

  .submenu-container {
    height: max-content;
    min-width: 180px;
    position: absolute;
    overflow: hidden;
    z-index: -1;
  }

  .logo {
    max-width: 370px;
    height: max-content;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    justify-items: left;
    align-items: center;
    line-height: 40px;
  }

  .logo a {
    // display: flex;
    // flex-direction: column;
    // clear: both;
    // text-decoration: none;

    p {
      width: 500px;
      display: block;
    }
    img {
      // display: inline-block;
      // margin-top: 5px;
      // width: 15px;
      // float: left;
      display: block;
    }
  }
  .gray {
    color: #ccc;
  }
  a {
    // color: #222;
    // opacity: 0.55;
    transition: all 0.6s;
    // display: grid;
    // grid-template-columns: auto auto;
    // grid-template-rows: 1fr;
    // color: #222;
    // font-size: 1em;
  }
  a:hover {
    opacity: 1;
  }
  .fa-bars {
    display: none;
  }
  nav {
    // width: 100%;
    // padding-left: 27px;
    padding-left: 21px;
    height: auto;

    span {
      line-height: 73px;
      cursor: pointer;
    }

    ul {
      display: flex;
      justify-content: space-between;
      margin: 0;
      padding: 0;
      position: relative;
      z-index: 10;
      background: #ffffff;
    }
    li {
      font-size: 14px;
      font-weight: normal;
      color: #707070;
      // margin: 0 38px 0 12px;
      justify-content: space-between;
      list-style: none;
      position: relative;
      padding: 0 10px;
      .caret {
        // position: absolute;
        // bottom: 0;
        // top: 0;
        // margin: auto;
        // right: -11px;
        vertical-align: middle;
        border: solid #707070;
        border-width: 0 1px 1px 0;
        display: inline-block;
        margin: 0 8px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        width: 5px;
        height: 5px;
        -webkit-transition: -webkit-transform 0.2s ease-in-out;
        -ms-transition: -ms-transform 0.2s ease-in-out;
        transition: transform 0.2s ease-in-out;
      }
    }
    li:hover {
      color: #404040;

      .caret {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
      }
    }
    a {
      font-size: 1em;
      text-decoration: none;
      line-height: 73px;
      .active {
        color: tomato;
      }
    }
    a.active {
      color: #222;

      li {
        color: #222;
      }
    }
  }

  @media only screen and (max-width: 800px) {
    padding: 0px;
    .logo {
      padding-left: 15px;
      padding-top: 0px !important;
    }
  }
  @media only screen and (max-width: 1140px) {
    height: 67px;
    min-height: 50px;
    display: block;
    position: relative;

    .rightSideNavigation {
      display: none;
    }
    .submenu-container {
      position: absolute;
      overflow: hidden;
      z-index: 1;
      top: 32px;
      left: 54px;
    }

    .name {
      padding-left: 0;
      line-height: 67px;
    }

    .logo {
      grid-template-columns: 47px 1fr;
    }
    .nav {
      a,
      span {
        line-height: normal;
        position: relative;
      }
    }
    .logo {
      height: 67px;

      a {
        // padding: 20px 0px;
        img {
          width: 37px;
        }
      }
    }
    .fa-bars {
      max-width: 26px;
      display: inline-block;
      position: absolute;
      top: 0;
      right: 20px;
      cursor: pointer;
      bottom: 0;
      margin: auto;
    }
    ul.collapsed {
      display: none;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-wrap: wrap;

      overflow: hidden;
      max-height: 0;
      min-height: 0px;
      -moz-transition-duration: 0.8s;
      -webkit-transition-duration: 0.8s;
      -o-transition-duration: 0.8s;
      transition-duration: 0.8s;
      -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
      -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
      -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
      transition-timing-function: cubic-bezier(0, 1, 0.5, 1);

      transition-property: all;
      transition-duration: 0.8s;
      transition-timing-function: cubic-bezier(0, 1, 0.5, 1);

      width: 0;
      right: 0;
      position: absolute;

      &.is-expanded {
        overflow: hidden;
        max-height: 309px;
        display: grid;
        justify-content: left;
        align-items: center;
        grid-template-rows: 44px 44px 44px 44px 44px 44px 44px;
        background-color: #ffffff;
        -moz-transition-duration: 0.8s;
        -webkit-transition-duration: 0.8s;
        -o-transition-duration: 0.8s;
        transition-duration: 0.8s;
        -moz-transition-timing-function: ease-in;
        -webkit-transition-timing-function: ease-in;
        -o-transition-timing-function: ease-in;
        transition-timing-function: ease-in;

        min-height: 800px;
        transition-property: all;
        transition-duration: 0.8s;
        transition-timing-function: cubic-bezier(0, 1, 0.5, 1);

        width: 100%;
      }
      li {
        padding: 15px 10px;
        margin: 0px 0px;
        width: 100%;
        height: auto;
        line-height: unset;
        font-size: 14px;
      }
    }
  }

  @media only screen and (max-width: 1420px) {
    grid-template-columns: 15% 75% 10%;
  }
  @media only screen and (max-width: 1220px) {
    grid-template-columns: 10% 80% 10%;
  }

  @media only screen and (max-width: 1227px) {
    .rightSideNavigation {
      grid-template-columns: 58% 24% 18%;
    }
  }
`;

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
      showClientSubmenus: false,
      showFinanceSubmenus: false,
      showSettingsSubmenus: false,
      screenSize: "full",
    };
    this.handleLeave = this.handleLeave.bind(this);
    this.handleHover = this.handleHover.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize);
    this.resize();
    // if (window.innerWidth <= 1000){
    //   this.setState({
    //     screenSize: 'responsive'
    //   })
    // }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  resize = () => {
    console.log(window.innerWidth);
    if (window.innerWidth <= 854) {
      this.setState({
        screenSize: "responsive",
      });
    } else {
      this.setState({
        screenSize: "full",
      });
    }
  };

  handleToggle = (e) => {
    e.preventDefault();
    this.setState({
      isExpanded: !this.state.isExpanded,
    });
  };

  handleCloseNav = () => {
    this.setState({
      isExpanded: false,
    });
  };

  handleHover = (event) => {
    // this.setState({ showAboutMenu: true });
    switch (event) {
      case "clients":
        this.setState({ showClientSubmenus: true });
        break;
      case "finance":
        this.setState({ showFinanceSubmenus: true });
        break;
      case "settings":
        this.setState({ showSettingsSubmenus: true });
      default:
      // code block
    }
  };

  handleLeave = (event) => {
    // this.setState({ showAboutMenu: false });
    switch (event) {
      case "clients":
        this.setState({ showClientSubmenus: false });
        break;
      case "finance":
        this.setState({ showFinanceSubmenus: false });
        break;
      case "settings":
        this.setState({ showSettingsSubmenus: false });
      default:
      // code block
    }
  };

  render() {
    const { isExpanded } = this.state;

    return (
      <div
        style={{
          display: "flex",
          paddingTop: 5,
          paddingBottom: 3,
          paddingLeft: 30,
          paddingRight: 0,
          backgroundColor: "white",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <div className="logo"> */}
        <Link to="/">
          <img
            src={logo_booking_white}
            style={{
              height: 55,
              minWidth: 130,
              marginRight: 25,
              marginLeft: -5,
            }}
            alt="Booking Hub logo"
          />
          {/* <img src={smallLogo} alt='Booking Hub small logo' /> */}
        </Link>
        {/* </div> */}
        <div style={{ color: "#3e87c6", fontSize: 18 }}>
          {localStorage.getItem("BUSINESS_NAME")}
        </div>
        {this.state.screenSize == "responsive" ? (
          <Nav>
            <i
              className="fa fa-bars"
              aria-hidden="true"
              style={{
                fontSize: 20,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={(e) => this.handleToggle(e)}
            />
            <nav className="nav" style={{ position: "relative" }}>
              {/* <img className="fa fa-bars" src={fourDotsWhite} onClick={e => this.handleToggle(e)} alt='four dots menu' /> */}
              <ul
                className={`collapsed ${isExpanded ? "is-expanded" : ""}`}
                style={{
                  width: "50vw",
                  position: "absolute",
                  top: 60,
                  right: 0,
                }}
              >
                <NavLink activeClassName="active" to="/dashboard">
                  <li>{intl.get("DASHBOARD") || "Dashboard"} </li>
                </NavLink>
                <NavLink
                  activeClassName="active"
                  to="/appointments"
                  onClick={this.handleCloseNav}
                >
                  <li>{intl.get("APPOINTMENTS") || "Appointments"}</li>
                </NavLink>

                <span
                  activeClassName="active"
                  onMouseLeave={(e) => this.handleLeave("clients")}
                >
                  <li onMouseEnter={(e) => this.handleHover("clients")}>
                    {intl.get("CLIENTS") || "Clients"}
                    <span className="caret"></span>
                  </li>
                  <div className="submenu-container">
                    <CSSTransitionGroup
                      transitionName="slide"
                      transitionEnterTimeout={300}
                      transitionLeaveTimeout={300}
                    >
                      {this.state.showClientSubmenus ? (
                        <ul className="nav__submenu">
                          <li className="nav__submenu-item ">
                            <Link
                              to="/clients/view"
                              onClick={this.handleCloseNav}
                            >
                              {intl.get("CLIENTS") || "Clients"}
                            </Link>
                          </li>
                          <li className="nav__submenu-item ">
                            <Link
                              to="/metadata/view"
                              onClick={this.handleCloseNav}
                            >
                              {intl.get("CLIENTS_INFORMATIONS") ||
                                "Clients Information"}
                            </Link>
                          </li>
                        </ul>
                      ) : null}
                    </CSSTransitionGroup>
                  </div>
                </span>
                <span
                  activeClassName="active"
                  onMouseLeave={(e) => this.handleLeave("finance")}
                >
                  <li onMouseEnter={(e) => this.handleHover("finance")}>
                    {intl.get("FINANCE") || "Finance"}
                    <span className="caret"></span>
                  </li>
                  <div className="submenu-container">
                    <CSSTransitionGroup
                      transitionName="slide"
                      transitionEnterTimeout={300}
                      transitionLeaveTimeout={300}
                    >
                      {this.state.showFinanceSubmenus ? (
                        <ul className="nav__submenu">
                          <li className="nav__submenu-item ">
                            <Link to="/sales/all" onClick={this.handleCloseNav}>
                              {intl.get("INVOICES") || "Invoices"}
                            </Link>
                          </li>
                          <li className="nav__submenu-item ">
                            <Link
                              to="/quotations/all"
                              onClick={this.handleCloseNav}
                            >
                              {intl.get("QUOTATIONS") || "Quotations"}
                            </Link>
                          </li>
                          <li className="nav__submenu-item ">
                            <Link
                              to="/expenses/view"
                              onClick={this.handleCloseNav}
                            >
                              {intl.get("EXPENSES") || "Expenses"}
                            </Link>
                          </li>
                          <li className="nav__submenu-item ">
                            <Link
                              to="/finance/report"
                              onClick={this.handleCloseNav}
                            >
                              {intl.get("REPORTS") || "Reports"}
                            </Link>
                          </li>
                        </ul>
                      ) : null}
                    </CSSTransitionGroup>
                  </div>
                </span>
                <NavLink activeClassName="active" to="/users/view">
                  <li onClick={this.handleCloseNav}>
                    {intl.get("STAFF") || "Staff"}
                  </li>
                </NavLink>
                <NavLink activeClassName="active" to="/pendingAppointments">
                  <li onClick={this.handleCloseNav}>
                    {intl.get("WAITING_LIST") || "Waiting list"}
                  </li>
                </NavLink>
                <span
                  activeClassName="active"
                  onMouseLeave={(e) => this.handleLeave("settings")}
                >
                  <li onMouseEnter={(e) => this.handleHover("settings")}>
                    {intl.get("SETTINGS") || "Settings"}
                    <span className="caret"></span>
                  </li>
                  <div className="submenu-container">
                    <CSSTransitionGroup
                      transitionName="slide"
                      transitionEnterTimeout={300}
                      transitionLeaveTimeout={300}
                    >
                      {this.state.showSettingsSubmenus ? (
                        <ul className="nav__submenu">
                          <li className="nav__submenu-item ">
                            <Link
                              to="/specialities/view"
                              onClick={this.handleCloseNav}
                            >
                              {intl.get("SPECIALITIES") || "Specialities"}
                            </Link>
                          </li>
                          <li className="nav__submenu-item ">
                            <Link
                              to="/services/view"
                              onClick={this.handleCloseNav}
                            >
                              {intl.get("SERVICES") || "Services"}
                            </Link>
                          </li>
                          <li className="nav__submenu-item ">
                            <Link
                              to="/currencies/view"
                              onClick={this.handleCloseNav}
                            >
                              {intl.get("CURRENCIES") || "Currencies"}
                            </Link>
                          </li>
                          <li className="nav__submenu-item ">
                            <Link
                              to="/units/view"
                              onClick={this.handleCloseNav}
                            >
                              {intl.get("UNITS") || "Units"}
                            </Link>
                          </li>
                          <li className="nav__submenu-item ">
                            <Link
                              to="/settings/general"
                              onClick={this.handleCloseNav}
                            >
                              {intl.get("ADVANCED") || "Advanced"}
                            </Link>
                          </li>
                        </ul>
                      ) : null}
                    </CSSTransitionGroup>
                  </div>
                </span>
              </ul>
            </nav>
          </Nav>
        ) : null}
        <div style={{ flex: 1 }}></div>
        {this.state.screenSize == "full" ? (
          <div style={{ display: "flex" }}>
            {/* <div className='startSearchWrapper'> */}
            {/* <img className='startSearch' src={searchIcon} alt='Search' /> */}
            {/* </div> */}

            <div className="selectLanguageWrapper">
              <SelectLanguage />
            </div>
            <div className="rightMenuWrapper" style={{ paddingRight: 30 }}>
              <RightMenu />
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Navigation;
