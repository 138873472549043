import React, { Component } from "react";

import {  RaisedButton } from "material-ui";
import checkResponse from "../../../commons/CheckResponse";
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { inject, observer } from "mobx-react";
import { PUBLIC_URL, API_PREFIX } from "../../../commons/config";

import { Confirm } from "semantic-ui-react";
import moment from "moment";
import CountryCodes from "../../CountryCodes";
import intl from 'react-intl-universal';



export default
@inject("store")
@observer
class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      loading: true,
      schema: [],
      err: "",
      specialities: ["a", "b"],
      user: [{ createdat: "" }]
    };
  }

  componentDidMount() {
    this.getSpecialities();
    const { Http } = this.props.store;
    const { user_id } = this.props.match.params;
    const URL = PUBLIC_URL + API_PREFIX + "users/view/" + user_id;
    Http()
      .get(URL)
      .then(r => this.setState({ user: {
        name:r.data.user.name,
        email:r.data.user.email,
        mobile:r.data.user.mobile,
        speciality_id:r.data.user.speciality_id,
        active:r.data.user.active
      },country_code:r.data.user.country_code ,loading: false }));
  }

  getSpecialities = () => {
    const URL =
      PUBLIC_URL +
      API_PREFIX +
      "specialities/all/" +
      this.props.store.get_business_id();
    checkResponse(URL, "get").then(r => {
      if (r.data.length) this.setState({ specialities: r.data });
    });
  };

  _folderHeader(title) {
    return (
      <div className="header-folder">
        <h4>{title}</h4>
      </div>
    );
  }

  _form() {
    return (
      <div>
         

         <header className='header'>
          <div className="text-center mt-4">
            <h2 className="primary-heading"> Edit Staff</h2></div>
            
            <div className="action-container width-60" >
            <div className="table-container padding-2">
           
           
        <div style={{marginBottom:'1rem', 
        color:'black', 
        textAlign:'left', 
        fontWeight:'bold',
        marginRight:'.5rem',
        fontSize:'1.2rem'
        }}>Edit Staff Details</div>
        {this.state.err && <h4 style={{ textAlign: "center", color:'red' }}>
            {this.state.err}
          </h4>} 
            
          <form >
          <div className="row">
            <div className="col-md-6">
              <div className="form-group-container">
              <label htmlFor="name" className="label-control">
                {intl.get('FULL_NAME') || 'Full Name'}
              </label>
              <input
                onChange={ev => this._setInput(ev)}
                className="form-control text-center"
                name="name"
                value={this.state.user.name}
                placeholder="Full Name"
                
              />
              </div>
            </div>
            
            <div className="col-md-6">
            <div className="form-group-container">
            <label htmlFor="email" className="label-control">
              {intl.get('EMAIL') || 'EMAIL'}
              </label>
              <input
                onChange={ev => this._setInput(ev)}
                className="form-control text-center"
                name="email"
                value={this.state.user.email}
                placeholder="E-mail address"
              />
               </div>
             
            </div>
            <div className="col-md-6">
              <div className="form-group-container">
              <label htmlFor="password" className="label-control">
              {intl.get('PASSWORD') || 'Password'}
                
              </label>
              <input
                onChange={ev => this._setInput(ev)}
                className="form-control text-center"
                name="password"
                type="password"
                placeholder="******"
              />
              </div>
             
            </div>
           
            <div className="col-md-6">
            <label htmlFor="mobile" className="custom-label-control">{intl.get("CODE_NUMBER") || 'Code Number'}
          </label>
            <CountryCodes
               cssClass='country-code-1'
                value={this.state.country_code}
                onChange={country_code=>this.setState({country_code})}
             
              />
             
            </div>
            <div className="col-md-6 col-xs-12">
              <div className="form-group-container">
              <label htmlFor="mobile" className="label-control">
              {intl.get('MOBILE_NUMBER') || 'Phone number'}
                
              </label>
              
             
              <input
                onChange={ev => this._setInput(ev)}
                className="form-control text-center"
                name="mobile"
               
                value={this.state.user.mobile}
                placeholder="Mobile number"
              />
              </div>
            
            </div>
            <div className="col-md-6 col-xs-12">
              <div className="form-group-container">
              <label htmlFor="mobile" className="label-control">
              {intl.get('SPECIALITY') || 'Speciality'}

              </label>
              <select
                style={{width: '100%', border:'none'}}
                onChange={ev => this._setInput(ev)}
                className="form-control"
                name='speciality_id'
                value={this.state.user.speciality_id}
              >
                {this.state.specialities.map(speciality => (
                  <option value={speciality.id}>{speciality.name}</option>
                ))}
              </select>
              </div>
            </div>
             
            <div className="col-md-6">
              <div className="form-group-container">
              <label htmlFor="active" className="label-control">
              {intl.get('ACTIVITY') || 'Activity'}

              </label>
              <select
                style={{width: '100%', border:'none'}}
                onChange={ev => this._setInput(ev)}
                id="active"
                name='active'
                value={this.state.user.active}
                className="form-control"
              >
                <option value={1}>{intl.get('ACTIVE') || 'Active'}</option>
                <option value={0}>{intl.get('INACTIVE') || 'Inactive'}</option>
              </select>
              </div>
             
            </div>
          </div>
         
        </form>
        
            <div className="row">
              <div className="col-md-12 d-flex justify-content-center mt-3">
            <button className="btn-red" onClick={this._basicSubmit}>
            {intl.get('SAVE') || 'Save'}
            </button>
            <button className="btn-ghost ml-2" onClick={_ => this.props.history.goBack()}>
            {intl.get('CANCEL') || 'Cancel'}
            </button>

              </div>
            </div>
           
            </div>
           

       
            </div>
            

      </header>
      <ToastContainer/>
      </div>
    );
    // }
  }

  _setInput = ev => {
    const user = { ...this.state.user };
    user[ev.target.name] = ev.target.value;
    this.setState({ user });
  };

  _basicSubmit = (e) => {
    e.preventDefault()

    const country_code = this.state.country_code
    const { Http } = this.props.store;
    const { user_id } = this.props.match.params;
    const URL = PUBLIC_URL + API_PREFIX + "users/edit/" + user_id;
    Http()
      .put(URL, {...this.state.user,country_code})
      .then(r => {
        if (r.data) {
            toast.success('User updated successfully!', {
            position: "top-center",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            });
          this.props.history.push('/users/view')
        }
      })
      .catch(e => {
        return this.setState({ err: e.response.data.error_description });
      });
  };

  _basic() {
    const createdat = moment(this.state.user.created_at).format(
      "YYYY/MM/DD hh:mm:ss a"
    );
    return (

      // <div className="md20">
       
      //   <div style={{ margin: 0 }}>
      //     <Card>
      //       <CardText>
      //         <div className="bootstrap-wrapper md20">
      //           <div className="folder-content">
      //             <h4 style={{ color: "#00c292" }}>{this.state.success}</h4>
      //             <h4 style={{ color: "red" }}>{this.state.err}</h4>
                    <>
                  {this._form()}
                  </>
      //           </div>
      //         </div>
      //         <p>{intl.get('CREATED_ON') || 'Created on'} {createdat}</p>
      //       </CardText>
      //     </Card>
      //   </div>
      // </div>
    );
  }

  _meta() {
    const { user_id } = this.props.match.params;
    return (
      <div className="md20">
        {this._folderHeader("ADD NEW DETAILS")}
        <div className="folder-content">
          <div style={{ paddingLeft: 15, paddingRight: 15 }}>
            <RaisedButton
              primary
              variant="contained"
              className="mt10"
              label="Add new"
              onClick={_ =>
                this.props.history.push("/users/view/other/" + user_id)
              }
            />
          </div>
        </div>
      </div>
    );
  }

  _appointments() {
    return (
      <div className="md20">
        {this._folderHeader("DOCTOR HISTORY")}
        <div className="folder-content">{this._basicHistory()}</div>
      </div>
    );
  }

  _basicHistory() {
    return (
      <div style={{ paddingLeft: 15, paddingRight: 15 }}>
        <RaisedButton
          primary
          variant="contained"
          className="mt10"
          label="APPOINTMENTS( 10 )"
        />
        <RaisedButton
          secondary
          variant="contained"
          className="mt10"
          label="CREATE APPOINTMENT"
        />
      </div>
    );
  }

  render() {
    return (
      <div style={{ margin: 15 }}>
        <div className="bootstrap-wrapper">
          <div className="row">
            <div className="col-12">{this._basic()}</div>
            {/* <div className="col-sm-4 col-md-4">{this._meta()}</div> */}
            <div className="col-md-8 col-sm-8">
              <div className="md20">
                <div className="folder-content" style={{ padding: 15 }}></div>
              </div>
            </div>
          </div>
        </div>
        <Confirm
         
          confirmButton="Okay"
          onConfirm={() => this.props.history.push("/users/view")}
          
          content="User updated successfully"
        />
      </div>
    );
  }
}
