export const formatArrays = function(arr,name,value){

  if(arr.length > 0){
      arr = arr
  }else{
      arr=[{name:'', value:''}]
  }
let countryArr = [];
let countryObj = {};
if (arr.length > 0) {
    for (let i = 0; i < arr.length; i++) {
        countryObj = {
            name: arr[i][name],
            value: arr[i][value],
            // identifier: identifier
        }
        countryArr.push(countryObj)
    }
   
    return countryArr;
  }
}
