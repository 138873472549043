import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import Form from "react-jsonschema-form";
import { Card, CardText } from "material-ui";
import { PUBLIC_URL, API_PREFIX } from "../../../commons/config";
import { ToastContainer, toast } from "react-toastify";
import intl from "react-intl-universal";
import checkResponse from "../../../commons/CheckResponse";

const input_types = [
  {
    label: "Text",
    meta_label_type_id: 1
  },
  {
    label: "Dropdown",
    meta_label_type_id: 2
  },
  {
    label: "Multiple Choice",
    meta_label_type_id: 3
  }
];
const schema = {
  definitions: {
    Label: {
      title: "Label",
      type: "string",
      anyOf: [
        {
          type: "string",
          enum: ["0"],
          title: intl.get("SELECT_INPUT_TYPE") || "Select Input Type"
        },
        {
          type: "string",
          enum: ["1"],
          title: intl.get("TEXT") || "Text"
        },
        {
          type: "string",
          enum: ["2"],
          title: intl.get("DROPDOWN") || "Dropdown"
        },
        {
          type: "string",
          enum: ["3"],
          title: intl.get("MULTIPLE_CHOICE") || "Multiple Choice"
        }
      ]
    }
  },
  required: ["label", "meta_label_type_id"],
  type: "object",
  properties: {
    label: {
      title: intl.get("META_LABEL_NAME") || "Meta label name",
      type: "string",
      default: ""
    },
    meta_label_type_id: {
      default: ["0"],
      title: intl.get("TYPE") || "Type",
      $ref: "#/definitions/Label"
    }
  }
};

export default
@inject("store")
@observer
class extends Component {
  state = {
    err: "",
    meta_label_type_id:1,
    label:''

  };

  handleChange = e =>{
      this.setState({[e.target.name]:e.target.value})
  }

  submit = e => {
    e.preventDefault();
    const { Http, get_business_id } = this.props.store;
    const URL = PUBLIC_URL + API_PREFIX + "metadata/create";
    let data = {
        label: this.state.label,
        meta_label_type_id: this.state.meta_label_type_id,
        business_id: get_business_id()
      }
    //   return console.log(data)
    checkResponse(URL,"POST",data).then(result => {
        if (result.data) {
          this.props.history.push({
            pathname: "/metadata/view",
            state: { added: true }
          });
        }
        if ("err" in result.data)
          return this.setState({ err: result.data.err });
      })
      .catch(error => {
        this.setState({
          err: intl.get("META_SELECT_ERROR") || "please select a meta type"
        });
      });
  };

  render() {
    return (
     
       <header className='header'>
         <div className="text-center mt-4">
            <h2 className="primary-heading"> Create New Information</h2>
            </div>
            <div className="action-container width-60" >
              <div className="table-container padding-2">
              {this.state.err ? <h4 className="red">{this.state.err}</h4> : []}
           
           
              <form onSubmit={this.submit}>
               
                  <div className="row">
                  <div className="col-md-12">
                  <div className="form-group-container">
                  <label>{intl.get("LABEL") || "Label"}</label>
                  <input
                    className="form-control"
                    name="label"
                    onChange={this.handleChange}
                    value={this.state.label}
                    placeholder='Type your label...'
                    required
                  />
                </div>
                  </div>
                  <div className="col-md-12">
                  <div className="form-group-container">
                  <label>{intl.get("INPUT_TYPE") || "Input Type"}</label>
                  <select
                  style={{border:'none'}}
                    className="form-control"
                    name="meta_label_type_id"
                    onChange={this.handleChange}
                    value={this.state.meta_label_type_id}
                  >
                    {input_types.map(type => (
                      <option
                        key={type.meta_label_type_id}
                        value={type.meta_label_type_id}
                      >
                        {type.label}
                      </option>
                    ))}
                  </select>
                  {/* <input className='form-control' name='name' onChange={this.handleChange} value={this.state.name}/> */}
                </div>
                  </div>
                  <div className="col-md-12 my-3">
                    <div className="d-flex justify-content-center">
                    <button type="submit" className="btn-ghost">
                     {intl.get("ADD") || "Add"}
                    </button>
                    <button 
                    style={{marginLeft:'1rem'}}
                     className="btn-ghost"
                     onClick={()=>this.props.history.push('/metadata/view')}
                     >
                     {intl.get("CANCEL") || "Cancel"}
                    </button>
                    </div>
                  </div>
                  </div>

              </form>
            
              </div>
            
            </div>
       </header>
       
      
    );
  }
}
