import React, { Component } from 'react';
import {Card, CardText} from 'material-ui/Card';
import Divider from 'material-ui/Divider';
import IconButton from 'material-ui/IconButton';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';
import AppointmentCard from './commons/table/AppointmentCard';
import intl from 'react-intl-universal';
import Excel from './../commons/Excel';


const appointments = [
    {title: 'my Appointment title one', flag: '#28a745'},
    {title: 'my Appointment title two', flag: '#28a745'},
    {title: 'my Appointment title three', flag: '#fec107'},
    {title: 'my Appointment title four', flag: '#dc3545'},
    {title: 'my Appointment title five', flag: '#fec107'},
]

export default class MyAppointments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            appointments,
            IconButton: false
        }
    }



    _appointments() { 
       
        if(this.props.appointments.length) {
          
            return this.props.appointments.map((appointment,index) =>
                <AppointmentCard
                  id={appointment.id}
                  appointment={appointment}
                  biz_phone={appointment.biz_phone}
                  doctor={appointment.doctor.name}
                  patient={appointment.patient}
                  date={appointment.start_date}
                  time={appointment.time_start}
                  status={appointment.status}
                  flag={appointment.flag}
                  history={this.props.history}
                  get_data={this.props.get_data}
                  />           
            )
        }
        return (
                <h3>{intl.get('NO_APPOINTMENTS_MESSAGE') || "There are no appointments for you at the moment."}</h3>
        )
    }

    handleIconClick() {
        this.setState({
            IconButton: !this.state.IconButton
        })
    }

    _cardSettings() {
            return(
                <IconMenu
                    className="more_vert_more"
                    iconButtonElement={
                        <IconButton>
                            <i className="material-icons white">more_vert</i>
                        </IconButton>
                    }
                    targetOrigin={{horizontal: 'right', vertical: 'top'}}
                    anchorOrigin={{horizontal: 'right', vertical: 'top'}}
                >
                    <MenuItem primaryText="View" />
                    <MenuItem primaryText="Mark as Done" />
                    <MenuItem primaryText="Cancel" />
                </IconMenu>
            )
    }

    render() {
        const {appointments} = this.props;
        return(
            <div className='appointmentsList'>
               {appointments.length>=1 && <div className="d-flex justify-content-end mb-2">
                    <div >
                    <Excel 
                        data={appointments} 
                        reportType='Appointments'
                        />
                    </div>
                </div>}
                
                {this._appointments()}
            </div>
        )
    }
}