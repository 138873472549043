import React, { Component } from "react";
import { PUBLIC_URL, API_PREFIX } from "../../../commons/config";
import checkResponse from "../../../commons/CheckResponse";
import { inject, observer } from "mobx-react";
import { Confirm } from "semantic-ui-react";
import moment from "moment";
import intl from "react-intl-universal";

@inject("store")
@observer
class InvoiceDetails extends Component {
  state = {
    invoice: { payment_id: 0 },
    invoice_total: 0,
    doctors: [],
    done: false,
    open: false,
    successMessage: "",
    patients: [],
    appointments: [],
    services: [],
    patient: { name: "", email: "" },
    payment_method_id: 1,
    invoiceDiscount: 0,
    quantities: [],
    discount: 0,
    Vat: 0,
    status_id: 2,
    paid: false,
    notes: "",
    created_at: "",
    ser: [
      { discount: 0, net_amount: 0 },
      { discount: 0, net_amount: 0 },
      { discount: 0, net_amount: 0 },
      { discount: 0, net_amount: 0 },
      { discount: 0, net_amount: 0 },
    ],
    appointment: { id: "1", services: [] },
    status: [
      {
        id: 2,
        value: "unpaid",
      },
      { id: 1, value: "paid" },
    ],
    payment_method: [
      {
        id: 1,
        value: "credit card",
      },
      {
        id: 2,
        value: "cash",
      },
      {
        id: 3,
        value: "cheque",
      },
    ],
    service: {},
    rows: [],
    totalAmount: 0,
  };

  async componentDidMount() {
    this.setState({ currency: localStorage.getItem("currency") });
    const URL =
      PUBLIC_URL +
      API_PREFIX +
      "invoices/getone/" +
      this.props.match.params.invoice_id;
    await checkResponse(URL, "get").then(async (r) => {
      await this.setState({
        patient: r.data.client,
        invoice: r.data.invoice,
        notes: r.data.invoice.notes,
        created_at: r.data.invoice.created_at,
        details: r.data.details,
        discount: r.data.invoice.discount,
        Vat: r.data.invoice.Vat,
        status_id: r.data.invoice.status_id,
        payment_method_id: r.data.invoice.payment_method_id,
        invoiceDiscount: r.data.invoice.discount,
        invoice_total: r.data.invoice.invoice_total,
        appointment_id: r.data.invoice.appointment_id,
      });
    });
    this._getUsersInBusiness();
    await this.getAppointment();
    this.prepareData();
  }

  getAppointment = async () => {
    let appointment_id = this.state.appointment_id;
    const URL = PUBLIC_URL + API_PREFIX + "appointments/view/" + appointment_id;
    await checkResponse(URL, "get", 1).then((r) => {
      this.setState({ appointment: r.data });
      if (r.data && r.data.status === "Done") {
        this.setState({ done: true });
      }
    });
  };

  prepareData() {
    if (this.state.details.length > 0) {
      let services = this.state.details;
      let ser = [];
      for (var i = 0; i <= services.length - 1; i++) {
        let quantity = parseFloat(services[i].quantity);
        let amount = parseFloat(services[i].amount);
        let discount = parseFloat(services[i].discount);
        ser.push({
          service_id: services[i].service_id,
          quantity,
          amount,
          discount,
          net_amount: amount * quantity * (1 - discount / 100),
        });
      }
      let total = [];
      for (var i = 0; i <= ser.length - 1; i++) {
        total.push(parseFloat(ser[i].net_amount));
      }
      function getSum(total, num) {
        return total + num;
      }

      let invoice_total = total.reduce(getSum);
      this.setState({ invoice_total, ser });
    } else {
      this.setState({ invoice_total: 0, ser: [] });
    }
  }

  _getUsersInBusiness() {
    const URL =
      PUBLIC_URL +
      API_PREFIX +
      "appointments/" +
      this.props.store.get_business_id();
    checkResponse(URL, "get", 1).then((result) => {
      const { clients, users, appointments, services } = result.data;
      this.setState({
        patients: clients,
        doctors: users,
        appointments,
        services,
      });
    });
  }

  handlePatientChange = (e) => {
    const patients = this.state.patients;
    const option = e.target.options[e.target.options.selectedIndex];
    for (var i = 0; i <= patients.length - 1; i++) {
      if (option.id == patients[i].id) {
        this.setState({ patient: patients[i] });
      }
    }
  };

  handleAppointmentChange = (e) => {
    const appointments = this.state.appointments;
    const option = e.target.options[e.target.options.selectedIndex];
    for (var i = 0; i <= appointments.length - 1; i++) {
      if (option.value == appointments[i].id) {
        this.setState({ appointment: appointments[i] });
        this.setState({ ser: this.state.appointment.services });
      }
    }
  };

  changeService = (e) => {
    const services = this.state.services;
    const option = e.target.options[e.target.options.selectedIndex];
    for (var i = 0; i <= services.length - 1; i++) {
      if (option.value == services[i].id) {
        this.setState({ service: services[i] });
      }
    }
    this.reCalculate();
  };

  addRow = async () => {
    const item = {
      amount: 0,
      discount: 0,
      net_amount: 0,
      quantity: 0,
      service_id: "",
    };
    await this.setState({
      rows: [...this.state.rows, item],
    });
    this.reCalculate();
  };
  removeRow = (idx) => {
    let rows = [...this.state.rows];
    // let services = [...this.state.services]
    let row = rows[idx];
    let { invoice_total } = this.state;
    if (this.state.rows.length + this.state.ser.length > 1) {
      // let formatted_rows = rows.splice(idx,1)
      let formatted_rows = rows.filter((r) => r !== row);

      invoice_total = parseFloat(invoice_total - row.net_amount);
      this.setState({
        rows: formatted_rows,
        // services : formatted_service,
        invoice_total,
      });
      //  this.reCalculate();
    } else {
      alert("you should have at least one service to make an invoice");
    }
  };
  handleChange = (idx) => async (e) => {
    const { name, value } = e.target;
    const rows = [...this.state.rows];

    if (value === "") {
      // Handle the case where the value is empty
      if (name === "net_amount") {
          rows[idx] = {
              ...rows[idx],
              net_amount: 0,
              discount: 0, // Reset discount to 0 as well
          };
      } else {
          rows[idx] = {
              ...rows[idx],
              [name]: 0, // Set the value to 0
          };
      }

      await this.setState({ rows });
      this.reCalculate();
      return; // Exit early
  }

    if (name === "service_id") {
      let id = value.split("&")[1];
      let price = parseFloat(value.split("&")[0]);
      rows[idx] = {
        ...rows[idx],
        [name]: id,
        quantity: 1,
        discount: 0,
        amount: isNaN(price) ? 0 : price,
        net_amount: isNaN(price) ? 0 : price,
      };
    } else if (name === "net_amount") {
      let newNetAmount = value;
      if (isNaN(newNetAmount)) newNetAmount = 0;
      const amount = parseFloat(rows[idx].amount);
      const quantity = parseFloat(rows[idx].quantity);

      if (!isNaN(newNetAmount) && !isNaN(amount) && !isNaN(quantity) && amount * quantity !== 0) {
        const discount = 100 * (1 - newNetAmount / (amount * quantity));
        rows[idx] = {
          ...rows[idx],
          [name]: newNetAmount,
          discount: discount < 0 ? 0 : discount,
        };
      } else {
        rows[idx] = {
          ...rows[idx],
          [name]: newNetAmount,
        };
      }
    } else {
      rows[idx] = {
        ...rows[idx],
        [name]: value,
      };

      // Update net_amount based on amount, quantity, and discount
      const amount = parseFloat(rows[idx].amount);
      const quantity = parseFloat(rows[idx].quantity);
      const discount = parseFloat(rows[idx].discount);

      if (!isNaN(amount) && !isNaN(quantity) && !isNaN(discount)) {
        rows[idx].net_amount = amount * quantity * (1 - discount / 100);
      }
    }

    await this.setState({
      rows,
    });

    this.reCalculate();
  };



  removeSer = async (idx) => {
    if (this.state.rows.length + this.state.ser.length > 1) {
      let ser = [...this.state.ser];
      ser.splice(idx, 1);
      await this.setState({ ser });
      return this.reCalculate();
    }
    return alert("you should have at least one service to make an invoice");
  };

  getPayMethod = (method) => {
    if (method === "cash") {
      method = intl.get("CASH");
    }
    if (method === "credit card") {
      method = intl.get("CREDIT_CARD");
    }
    if (method === "cheque") {
      method = intl.get("CHEQUE");
    }

    return method;
  };


  handleChangeSer = (idx) => async (e) => {
    const { name, value } = e.target;
    const ser = [...this.state.ser];

    if (value === "") {
      // Handle the case where the value is empty
      if (name === "net_amount") {
          ser[idx] = {
              ...ser[idx],
              net_amount: 0,
              discount: 0, // Reset discount to 0 as well
          };
      } else {
          ser[idx] = {
              ...ser[idx],
              [name]: 0, // Set the value to 0
          };
      }

      await this.setState({ ser });
      this.reCalculate();
      return; // Exit early
  }

    if (!isNaN(value) && !isNaN(parseFloat(value))) {

      if (name === "service_id") {
        let [price, id] = value.split("&");
        ser[idx] = {
          ...ser[idx],
          [name]: id,
          quantity: 1,
          discount: 0,
          amount: parseFloat(price),
        };
      } else if (name === "net_amount") {
        let newNetAmount = value;
        if (isNaN(newNetAmount) || newNetAmount < 0 || newNetAmount === "") {
          newNetAmount = 0;
        }
        const { amount, quantity } = ser[idx];
        const newDiscount = (1 - newNetAmount / (amount * quantity)) * 100;

        ser[idx] = {
          ...ser[idx],
          net_amount: newNetAmount,
          discount: newDiscount < 0 ? 0 : newDiscount, // Ensure discount is not negative
        };
      } else {
        ser[idx] = {
          ...ser[idx],
          [name]: value,
        };

        ser[idx].net_amount =
          parseFloat(ser[idx].amount * parseFloat(ser[idx].quantity)) *
          (1 - parseFloat(ser[idx].discount) / 100);
      }

      await this.setState({ ser });
      this.reCalculate();
    }

  };

  reCalculate = () => {
    let arr = [];
    let allrows = [...this.state.rows, ...this.state.ser];
    let invoice_total = this.state.invoice_total;
    if (allrows.length == 0) {
      return;
    }
    for (var i = 0; i <= allrows.length - 1; i++) {
      arr.push(allrows[i].net_amount);
    }

    function getSum(total, num) {
      return Number(total) + num;
    }

    invoice_total = arr.reduce(getSum);
    this.setState({ invoice_total });
  };

  changeInvoice = async (e) => {
    e.preventDefault();

    let newServices = [...this.state.ser];
    let services = [];
    for (var i = 0; i <= newServices.length - 1; i++) {
      let quantity = newServices[i].quantity;
      let discount = newServices[i].discount;
      services.push({
        service_id: newServices[i].service_id,
        quantity,
        amount: newServices[i].amount,
        discount,
        net_amount: newServices[i].amount * quantity * (1 - discount / 100),
      });
    }
    let newRows = [...this.state.rows];
    let rows = [];
    for (var i = 0; i <= newRows.length - 1; i++) {
      rows.push({
        service_id: newRows[i].service_id,
        invoice_id: this.props.match.params.invoice_id,
        quantity: parseFloat(newRows[i].quantity),
        amount: parseFloat(newRows[i].amount),
        discount: parseFloat(newRows[i].discount),
        net_amount: parseFloat(
          newRows[i].amount * parseFloat(newRows[i].quantity)
        ),
      });
    }

    let allServices = [...services, ...rows];

    let data = {
      client_id: this.state.patient.id || "",
      appointment_id: this.state.appointment_id || "",
      status_id: e.target.status.value || "",
      invoice_number: 1,
      payment_method_id: parseInt(this.state.payment_method_id) || "",
      total_amount: this.state.invoice_total,
      discount: this.state.invoiceDiscount,
      Vat: this.state.Vat,
      notes: this.state.notes,
      created_at: moment(this.state.created_at).format("YYYY-MM-DD hh:mm:ss"),
      net_amount: (
        this.state.invoice_total *
        (1 - this.state.invoiceDiscount / 100) *
        (1 + this.state.Vat / 100)
      ).toFixed(2),
      // notes: e.target.notes.value,
      //this is for invoice details table
      services: allServices,
    };

    if (data.status_id == 2) {
      data.payment_method_id = 0;
    }
    const URL =
      PUBLIC_URL +
      API_PREFIX +
      "invoices/update/" +
      this.props.match.params.invoice_id;
    checkResponse(URL, "put", data).then(r => {
      this.setState({ open: true, successMessage: 'Invoice updated successfully' });
    });
  };

  getStatusValue = (status) => {
    if (status === "paid") {
      status = intl.get("PAID");
    }
    if (status === "unpaid") {
      status = intl.get("UNPAID");
    }
    return status;
  };
  payment_method() {
    if (this.state.status_id != "2") {
      return (
        <div className="col-sm-3">
          <label className="col-form-label">
            {intl.get("PAYMENT_METHOD") || "Payment Method"}{" "}
            <i className="icon-credit-card" />
          </label>
          <div className="input-group">
            <div className="input-group-prepend">
              <label className="input-group-text" />
            </div>
            <div className="input-group">
              <select
                className="form-control"
                name="payment_method"
                value={this.state.payment_method_id}
                onChange={(e) =>
                  this.setState({ payment_method_id: e.target.value })
                }
              >
                {this.state.payment_method.map((pm) => (
                  <option value={pm.id}>{this.getPayMethod(pm.value)}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
      );
    }
  }

  render() {
    const display_date = moment(this.state.created_at).format("YYYY-MM-DD");
    return (
      <header className="header">
        <div className="text-center mt-4">
          <h2 className="primary-heading"> Edit Invoice</h2>
        </div>
        <div className="action-container">
          <div className="table-container" style={{ padding: "0 3rem" }}>
            <form onSubmit={this.changeInvoice}>
              <div className="my-3 ml-4">
                <h3>{intl.get("INVOICE_DETAILS") || "Invoice Details"}</h3>
              </div>
              <input type="hidden" name="_token" defaultValue="" />
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="form-group-container">
                    <label className="col-form-label">
                      {intl.get("CLIENT_NAME") || "Client Name"}
                    </label>
                    <select
                      style={{ border: "none" }}
                      className="form-control"
                      onChange={(e) => this.handlePatientChange(e)}
                      value={this.state.patient.name}
                      disabled={this.state.generated}
                    >
                      <option id="noPatient" style={{ color: "grey" }}>
                        {intl.get("SELECT_CLIENT") || "Select Client"}
                      </option>

                      {this.state.patients.map((patient) => (
                        <option id={patient.user_id}>{patient.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="form-group-container">
                    <label className="col-form-label">
                      {intl.get("EMAIL") || "E-mail"}
                    </label>
                    <input
                      style={{ backgroundColor: "transparent" }}
                      disabled
                      type="email"
                      name="invoice[email]"
                      value={
                        this.state.patient.email.length > 30
                          ? `- ${intl.get("NO_EMAIL_PROVIDED")} -`
                          : this.state.patient.email
                      }
                      className="form-control"
                      placeholder={intl.get("EMAIL")}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="form-group-container">
                    <label className="col-form-label">
                      {intl.get("PHONE_NUMBER") || "Phone Number"}
                    </label>
                    <input
                      style={{ backgroundColor: "transparent" }}
                      disabled
                      type="text"
                      name="invoice[mobile]"
                      defaultValue={this.state.patient.mobile}
                      className="form-control"
                      placeholder="70123456"
                    />
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="form-group-container">
                    <label className="col-form-label">
                      {intl.get("STATUS") || "Status"}
                    </label>
                    <select
                      style={{ border: "none" }}
                      disabled={this.state.generated}
                      className="form-control"
                      name="status"
                      value={this.state.status_id}
                      onChange={(e) =>
                        this.setState({
                          status_id: e.target.value,
                          payment_method_id: 2,
                        })
                      }
                    >
                      {this.state.status.map((stat) => (
                        <option value={stat.id}>
                          {this.getStatusValue(stat.value)}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="form-group-container">
                    <label className="col-form-label">
                      {intl.get("DATE") || "Date"}
                    </label>
                    <input
                      disabled={this.state.generated}
                      className="form-control"
                      name="created_at"
                      type="date"
                      value={display_date}
                      onChange={(e) =>
                        this.setState({ created_at: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="form-group-container">
                    <label className="col-form-label">
                      {intl.get("PAYMENT_METHOD") || "Payment Method"}{" "}
                    </label>
                    <select
                      style={{ border: "none" }}
                      className="form-control"
                      name="payment_method"
                      value={this.state.payment_method_id}
                      onChange={(e) =>
                        this.setState({ payment_method_id: e.target.value })
                      }
                    >
                      {this.state.payment_method.map((pm) => (
                        <option value={pm.id}>
                          {this.getPayMethod(pm.value)}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="row pl-4 pr-4">
                <div className="col-12">
                  <div className="invoice-items pt-3">
                    <table className="table-input">
                      <thead>
                        <th>{intl.get("SERVICE") || "Service"}</th>
                        {/* <th>Description</th> */}
                        <th>{intl.get("QUANTITY") || "Quantity"}</th>
                        <th>{intl.get("UNIT_COST") || "Unit Cost"}</th>
                        <th>{intl.get("DISCOUNT") || "Discount %"}</th>
                        <th>{intl.get("TOTAL_PRICE") || "Total price"}</th>
                      </thead>
                      <tbody>
                        {this.state.ser.map((service, idx) => (
                          <tr className="item-row" key={idx}>
                            <td className="item-col">
                              <select
                                name="service_id"
                                className="form-control"
                                value={service.service_id}
                                onChange={(e) => this.changeService(e)}
                              >
                                {this.state.services.map((service) => (
                                  <option
                                    key={service.id}
                                    value={`${service.price}&${service.id}`}
                                  >
                                    {service.title}
                                  </option>
                                ))}
                              </select>
                              <span
                                className="invoice-circle delete-circle"
                                onClick={() => this.removeSer(idx)}
                              >
                                <i className="fa fa-minus"></i>
                              </span>
                            </td>
                            <td>
                              <textarea
                                id="text-area"
                                name="quantity"
                                className="item-quantity"
                                value={this.state.ser[idx].quantity}
                                onChange={this.handleChangeSer(idx)}
                              />
                            </td>
                            <td>
                              <textarea
                                id="text-area"
                                type="text"
                                name="amount"
                                className="item-cost"
                                value={service.amount}
                                onChange={this.handleChangeSer(idx)}
                              />
                            </td>
                            <td>
                              <textarea
                                id="text-area"
                                type="text"
                                name="discount"
                                className="item-tax-price"
                                value={this.state.ser[idx].discount}
                                onChange={this.handleChangeSer(idx)}
                              />
                            </td>
                            <td>
                              <textarea
                                id="text-area"
                                type="text"
                                name="net_amount"
                                className="item-tax-price"
                                value={this.state.ser[idx].net_amount ? this.state.ser[idx].net_amount : 0}
                                onChange={this.handleChangeSer(idx)}
                              />
                            </td>
                          </tr>
                        ))}
                        {this.state.rows.map((row, idx) => {
                          return (
                            <tr className="item-row">
                              <td className="item-col">
                                <select
                                  name="service_id"
                                  className="form-control"
                                  // value={this.state.rows[idx].service_id}
                                  onChange={this.handleChange(idx)}
                                >
                                  <option value={""}>
                                    {intl.get("SELECT_SERVICE") ||
                                      "select service"}
                                  </option>
                                  {this.state.services.map((service) => (
                                    <option
                                      value={service.price + "&" + service.id}
                                    >
                                      {service.title}
                                    </option>
                                  ))}
                                </select>
                                <span
                                  className="invoice-circle delete-circle"
                                  onClick={() => this.removeRow(idx)}
                                >
                                  <i className="fa fa-minus"></i>
                                </span>
                              </td>
                              <td className>
                                <textarea
                                  id="text-area"
                                  name="quantity"
                                  className="item-quantity"
                                  value={this.state.rows[idx].quantity}
                                  onChange={this.handleChange(idx)}
                                  placeholder="insert a number"
                                />
                              </td>
                              <td className>
                                <textarea
                                  id="text-area"
                                  type="text"
                                  name="amount"
                                  className="item-cost"
                                  value={
                                    isNaN(this.state.rows[idx].amount)
                                      ? "calculating..."
                                      : this.state.rows[idx].amount
                                  }
                                  onChange={this.handleChange(idx)}
                                />
                              </td>
                              <td>
                                <textarea
                                  id="text-area"
                                  type="text"
                                  name="discount"
                                  className="item-tax-price"
                                  value={this.state.rows[idx].discount}
                                  onChange={this.handleChange(idx)}
                                  placeholder="insert a % discount"
                                />
                              </td>
                              <td>
                                {/* to be changed */}
                                <textarea
                                  id="text-area"
                                  type="text"
                                  name="net_amount"
                                  className="item-tax-price"
                                  value={this.state.rows[idx].net_amount ? this.state.rows[idx].net_amount: 0} // Directly use net_amount from the state
                                  onChange={this.handleChange(idx)}
                                />

                              </td>
                            </tr>
                          );
                        })}

                        <tr>
                          <td colspan="1" className="p-2">
                            <div
                              className="invoice-circle ml-auto"
                              onClick={this.addRow}
                            >
                              <i
                                className="fa fa-plus"
                                style={{ marginTop: "20%" }}
                              ></i>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3} />
                          <td>{intl.get("TOTAL_AMOUNT") || "Total Amount"}</td>
                          <td>
                            <textarea
                              id="text-area"
                              type="text"
                              name="totalamount"
                              className="item-tax-price"
                              value={
                                this.state.invoice_total ? this.state.invoice_total : 0 +
                                  " " +
                                  this.state.currency
                              }
                            />
                          </td>
                        </tr>

                        <tr>
                          <td colSpan={3} />

                          <td>{intl.get("DISCOUNT") || "Discount %"}</td>
                          <td>
                            <textarea
                              id="text-area"
                              type="text"
                              name="servicediscount"
                              className="item-tax-price"
                              onChange={(e) => {
                                if (Number(e.target.value)) {
                                  this.setState({
                                    invoiceDiscount: e.target.value,
                                  })
                                } else {
                                  this.setState({
                                    invoiceDiscount: 0,
                                  })
                                }

                              }
                              }
                              value={this.state.invoiceDiscount}
                              placeholder="insert a % discount"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3} />

                          <td>{intl.get("VAT") || "Vat %"}</td>
                          <td>
                            <textarea
                              id="text-area"
                              type="text"
                              name="Vat"
                              className="item-tax-price"
                              onChange={(e) => {
                                {
                                  let number = 0;
                                  if (Number(e.target.value)) {
                                    number = Number(e.target.value)
                                  }
                                  this.setState({
                                    Vat: number
                                  })
                                }
                              }
                              }
                              value={this.state.Vat}
                              placeholder="insert a % Vat"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3} />

                          <td> {intl.get("NET_AMOUNT") || "Net Amount"}</td>
                          <td>
                            <textarea
                              id="text-area"
                              type="text"
                              name="invoice_total"
                              className="item-tax-price"
                              // defaultValue={}
                              value={
                                (
                                  this.state.invoice_total *
                                  (1 - this.state.invoiceDiscount / 100) *
                                  (1 + this.state.Vat / 100)
                                ).toFixed(2) +
                                " " +
                                this.state.currency
                              }
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {/* <div className="content-input">
                        <label> Paid : </label>
                        {this.state.status_id == 1 ? (
                          <p style={{ color: "#00c292", fontWeight: "450" }}>
                            <span style={{ color: "black", fontWeight: "400" }}>
                              Status 
                            </span>
                            this invoice is paid
                          </p>
                        ) : (
                          <p style={{ color: "red", fontWeight: "450" }}>
                            <span style={{ color: "black", fontWeight: "400" }}>
                              Status :{" "}
                            </span>
                            this invoice is not paid yet
                          </p>
                        )}
                      </div> */}

                  <div className="row">
                    <div
                      className="col-sm-6 col-md-4 content-radio-container"
                      onClick={(_) => this.setState({ done: !this.state.done })}
                      style={{ margin: "10px" }}
                    >
                      <div>
                        <input type="checkbox" checked={this.state.done} />
                        <label htmlFor="done">
                          <p
                            className="form-text text-lg"
                            style={{
                              fontSize: "14px",
                              background: "#00c292",
                              padding: "0.3rem 1rem",
                              color: "white",
                              borderRadius: "100px",
                              marginLeft: "1rem",
                            }}
                          >
                            {intl.get("MARK_AS_DONE") ||
                              "mark appointment as 'Done'"}
                          </p>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group-container">
                          <label>{intl.get("NOTES") || "Notes"}</label>
                          <textarea
                            style={{ border: "none" }}
                            className="form-control"
                            name="notes"
                            value={this.state.notes}
                            rows={3}
                            onChange={(e) =>
                              this.setState({ notes: e.target.value })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <input type="hidden" className="taxes" defaultValue="" />
              <input
                type="hidden"
                name="invoice[appointment_id]"
                defaultValue=""
              />

              {!this.state.generated ? (
                <div className="content-submit text-center">
                  <button
                    className="btn-green"
                    type="submit"
                    name="submit"
                    style={{
                      margin: "0 auto",
                      marginBottom: "2rem",
                      width: "11rem",
                    }}
                  >
                    {intl.get("SAVE_CHANGES") || "Save Changes"}
                  </button>
                  {/* <button
                 type="submit"
                 name="submit"
                 className="btn btn-primary"
                 disabled={this.state.disabled}
               >
                 {intl.get("CREATE") || "Create"}
               </button> */}
                </div>
              ) : (
                <div>
                  <div className="content-submit text-center">
                    <p>
                      {intl.get("INV_ALREADY_CREATED") ||
                        "invoice already created"}
                    </p>
                    <button
                      type="submit"
                      name="submit"
                      className="btn btn-primary"
                      onClick={(_) =>
                        this.props.history.push(
                          "/view/invoices/print/" + this.state.invoice_id
                        )
                      }
                    >
                      {intl.get("VIEW_INVOICE") || "View Invoice"}
                    </button>
                    <button
                      type="submit"
                      name="submit"
                      className="btn btn-primary"
                      onClick={(_) =>
                        this.props.history.push(
                          "/view/invoices/edit/" + this.state.invoice_id
                        )
                      }
                    >
                      {intl.get("EDIT") || "Edit"}
                    </button>
                    <button
                      type="button"
                      name="del"
                      className="btn btn-dark"
                      onClick={(_) => this.setState({ openConfirm: true })}
                    >
                      {intl.get("DELETE") || "Delete"}
                    </button>
                  </div>
                  {this.openConfirm()}
                </div>
              )}
            </form>
          </div>
        </div>
        <Confirm
          open={this.state.open}
          cancelButton={null}
          onConfirm={() => this.props.history.push("/sales/all")}
          content={this.state.successMessage}
        />
      </header>
    );
  }
}

export default InvoiceDetails;
