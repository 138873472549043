import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import "react-table/react-table.css";
import ReactTable from "react-table";
import Pagination from "./pagination";
import { Card, CardText } from "material-ui";
import { Confirm } from "semantic-ui-react";
import intl from 'react-intl-universal';

export default
@inject("store")
@observer
class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
      open: false
    };
    const style = {
      fontSize: 15,
      textAlign: "center",
      padding:'1rem 0',
      borderBottom:'1px solid #707070'
    };
    this.columns = [
      {
        Header: intl.get("LABEL") || "Label",
        accessor: "currency_label",
        style
      },
      {
        Header: intl.get("VALUE") || "Value",
        accessor: "currency_value",
        style
      },
      {
        Header: intl.get("ACTIONS") || "Actions",
        Cell: props => (
          <div className="d-flex justify-content-center">
            <span className='mr-2' style={{cursor:'pointer'}} 
            onClick={_ => this.handleClick(props)}>
              <i className="fa fa-pencil" style={{fontSize:'1.2rem'}}></i>
            </span>
            <span style={{cursor:'pointer'}}
            onClick={_ =>
              this.props.deleteCurrency(props.original.id)
            }>
              <i className="fa fa-trash" style={{fontSize:'1.2rem'}}></i>
            </span>
          </div>
         
        ),
        style
      }
    ];
  }

  handleClick = props => {
    this.props.history.push(`${this.props.to}${props.original.id}`, {
      currency: { ...props.original }
    });
  };
  handleCancel = () => {
    this.setState({ open: false });
  };

  render() {
    if (this.props.currency) {
      return (
        
              <ReactTable
              
                showPagination
                data={this.props.currency}
                columns={this.columns}
                pageSizeOptions={[10, 20, 30, 40]}
                defaultPageSize={10}
                sortable
                multiSort
                previousText={intl.get('PREVIOUS') || 'Pevious'}
                nextText={intl.get("NEXT") || "Next"}
                // loadingText: 'Loading...',
                noDataText={intl.get("NO_ROWS_FOUND") || "No rows found"}
                pageText={intl.get("PAGE") || "Page"}
                ofText= {intl.get("OF") || "of"}
                rowsText= {intl.get("ROWS") || "rows"}
                resizable
                // PaginationComponent={Pagination}
                // className="-stripped -highlight"
                // filterable
                defaultSortDesc
              />
        
      );
    }
    return <div></div>;
  }
}
