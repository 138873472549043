import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { PUBLIC_URL, API_PREFIX } from "../../../commons/config";
import CurrencyTable from "../../../components/commons/table/currency-table";
import checkResponse from "../../../commons/CheckResponse";

import intl from 'react-intl-universal';
import Swal from "sweetalert2";

export default
@inject("store")
@observer
class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currencies: [],
      loading: false,
      currency_existed: false
    };
  }

  componentDidMount() {
    this._getData();

  }

  deleteCurrency = id => {
    const URL = PUBLIC_URL + API_PREFIX + "currencies/delete/" + id;
    if (this.state.currencies.length === 1) {
      Swal.fire('warning', 'You should have at least one currency', 'Warning')
      return;
    }


    Swal.fire({
      title: "Are you sure?",
      text:
        "deleting this item will affect to delete its related services,invoices and payments, and can't be restored",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(result => {
      if (result.value) {

        let currencies = [...this.state.currencies];
        currencies = currencies.filter(cur => cur.id != id);
        this.setState({ currencies, currency_existed: false });
        try {
          checkResponse(URL, "delete").then(r => {
            if (r.data) {
              Swal.fire("Deleted!", "Currency has been deleted.", "success");
            }
          });

        } catch (ex) {
          Swal.fire("Oops...", "Something went wrong", "error");
        }
      }
    });

  };

  _getData() {
    const { Http, get_business_id } = this.props.store;
    const URL =
      PUBLIC_URL + API_PREFIX + "currencies/getall/" + get_business_id();
    Http()
      .get(URL)
      .then(this.insertData.bind(this));
  }

  insertData(data) {
    const { currencies } = data.data;
    this.setState({ currencies, loading: false });
    if (currencies.length == 1) {
      localStorage.setItem("currency", currencies[0].currency_value)
      this.setState({ currency_existed: true })
    }
  }

  render() {
    if (!this.state.loading) {
      return (
        <header className="header">
          <div className="mt-4">
            <div style={{ display: 'flex', marginBottom: 10, justifyContent: 'space-between', alignItems: 'center', paddingLeft: 30, paddingRight: 30 }}>
              <h2 className="primary-heading"> {intl.get('CURRENCIES') || 'Currencies'}</h2>
              <div className="d-flex">
                <button className="whitePrimaryBtn-design" style={{ marginLeft: 'auto', width: '11rem' }}
                  onClick={_ => this.props.history.push("/currencies/create")}>
                  {intl.get("ADD_NEW") || 'Add Currency'}
                </button>
              </div>
            </div>
          </div>
          <div className="action-container " >
            <div className="table-container">
              <CurrencyTable
                to="/currencies/view/"
                history={this.props.history}
                currency={this.state.currencies}
                deleteCurrency={this.deleteCurrency}
              />
            </div>
          </div>
        </header>


      );

    }
    return <div>loading</div>;
  }
}
