import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Rounded from "./buttons/rounded";
import * as moment from "moment";
import { inject, observer } from "mobx-react";
import { PUBLIC_URL, API_PREFIX } from "../../../../commons/config";
import checkResponse from "../../../../commons/CheckResponse";
import intl from "react-intl-universal";
import "./ShowCardView.css";

const labelStyle = {
  fontSize: 15,
  paddingLeft: 2,
  margin: 2,
  fontWeight: 400,
};

export default
@inject("store")
@observer
class ShowCardCreate extends Component {
  state = { appointment: {} };

  goToCreate = () => {
    this.props.history.push({
      pathname: "/invoices/create",
      state: {
        appointment: this.state.appointment,
        user: this.props.patient,
      },
    });
  };

  componentDidMount() {
    this._getData();
  }

  _getData = () => {
    const appointment_id = this.props.clickedEvent.id;
    const URL = PUBLIC_URL + API_PREFIX + "appointments/view/" + appointment_id;
    checkResponse(URL, "get", 1).then((result) => {
      const appointment = result.data;
      this.setState({
        appointment,
      });
    });
  };

  getFlagValue = (flag) => {
    if (flag == "normal") {
      flag = intl.get("NORMAL");
    }
    if (flag == "medium") {
      flag = intl.get("MEDIUM");
    }
    if (flag == "HIGH") {
      flag = intl.get("high");
    }
    if (flag == "VIP") {
      flag = intl.get("VIP");
    }
    if (flag == "other") {
      flag = intl.get("OTHER");
    }
    return flag;
  };

  getStatusValue = (status) => {
    if (status == "Pending") {
      status = intl.get("PENDING");
    }
    if (status == "Done") {
      status = intl.get("DONE");
    }
    if (status == "Waiting") {
      status = intl.get("WAITING");
    }
    if (status == "cancelled") {
      status = intl.get("CANCELLED");
    }
    return status;
  };

  render() {
    const { clickedEvent } = this.props;
    console.log("clickedEvent=>", clickedEvent);
    const todayDate = moment();

    let user = {};
    this.props.users.map((usr) => {
      if (usr.value == clickedEvent.user_id) {
        user = usr;
      }
    });
    let status = clickedEvent.status;
    if (clickedEvent.start.isBefore(todayDate)) {
      status = "Done";
    }
    let color = "black";
    switch (status) {
      case "Pending":
        color = "blue";
        break;
      case "Cancelled":
        color = "red";
        break;
      case "Waiting":
        color = "orange";
        break;
      case "Done":
        color = "#00c292";
        break;
    }

    let padding = "10px 7px";
    if (localStorage.getItem("lang") === "ar") {
      padding = "10px 70px";
    }
    return (
      <div
        style={{
          display: "flex",
          zIndex: 200,
          position: "absolute",
          left: 0,
          right: 0,
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Paper
          style={{ borderRadius: 8, width: 448, minHeight: 355 }}
          elevation={20}
        >
          <div style={{ marginBottom: 15, padding: 15, position: "relative" }}>
            <div className="close" onClick={(_) => this.props._cancel()}>
              <i className="fa fa-times"></i>
            </div>
            <div className="row">
              <div className="col-md-6 col-xs-12">
                <div className="form-group-container">
                  <label htmlFor="name" className="label-control">
                    {intl.get("CLIENT_NAME") || "Client Name"}
                  </label>
                  <input
                    style={{ textAlign: "center" }}
                    className="client-view-input"
                    id="name"
                    disabled
                    value={
                      clickedEvent.mobile
                        ? clickedEvent.patient_name +
                          " - " +
                          clickedEvent.mobile
                        : clickedEvent.patient_name
                    }
                  />
                </div>
              </div>
              <div className="col-md-6 col-xs-12">
                <div className="form-group-container">
                  <label htmlFor="number" className="label-control">
                    {intl.get("NUMBER") || "Number"}
                  </label>
                  <input
                    style={{ textAlign: "center" }}
                    className="client-view-input"
                    id="number"
                    disabled
                    value={clickedEvent.number}
                  />
                </div>
              </div>
              <div className="col-md-6 col-xs-12">
                <div className="form-group-container">
                  <label htmlFor="flag" className="label-control">
                    {intl.get("FLAG") || "Flag"}
                  </label>
                  <input
                    style={{ textAlign: "center" }}
                    className="client-view-input"
                    id="flag"
                    disabled
                    value={this.getFlagValue(clickedEvent.flag)}
                  />
                </div>
              </div>
              <div className="col-md-6 col-xs-12">
                <div className="form-group-container">
                  <label htmlFor="status" className="label-control">
                    {intl.get("STATUS") || "Status"}
                  </label>
                  <input
                    style={{ textAlign: "center" }}
                    className="client-view-input"
                    id="status"
                    disabled
                    value={this.getStatusValue(status)}
                  />
                </div>
              </div>
              <div className="col-md-6 col-xs-12">
                <span
                  className="fa fa-clock-o fa-2x"
                  style={{
                    position: "relative",
                    paddingRight: 10,
                    top: 7,
                    color: "rgb(95, 99, 104)",
                  }}
                />
                <input
                  value={clickedEvent.time_start}
                  className="time-picker-1"
                  type="text"
                  disabled
                />
              </div>

              <div className="col-md-6 col-xs-12">
                <input
                  style={{ marginTop: ".8rem" }}
                  value={clickedEvent.time_end}
                  className="time-picker-2"
                  type="text"
                  disabled
                />
              </div>
              <div className="col-md-12" style={{ marginTop: "1rem" }}>
                <div className="form-group-container">
                  <label htmlFor="staff" className="label-control">
                    {intl.get("STAFF_NAME") || "Staff Name"}
                  </label>
                  <input
                    style={{ textAlign: "center" }}
                    className="client-view-input"
                    id="staff"
                    disabled
                    value={user.label || this.props.selectedUser.label}
                  />
                </div>
              </div>

              <div className="col-md-12 my-3">
                <div className="d-flex justify-content-center">
                  <button
                    style={{ width: "10rem" }}
                    className="btn-ghost"
                    onClick={(_) =>
                      this.props.history.push(
                        "/appointments/" + clickedEvent.id
                      )
                    }
                  >
                    {intl.get("VIEW_MORE") || "View More"}
                  </button>
                  <button
                    style={{ width: "10rem" }}
                    className="btn-ghost ml-2"
                    onClick={(_) => this.goToCreate()}
                  >
                    {intl.get("CREATE_INVOICE") || "Create Invoice"}
                  </button>
                </div>
              </div>
            </div>

            {/* <div className="col-md-6">
              <label htmlFor="name" className="label-control">
                {intl.get("STAFF_NAME") || 'Staff Name'}
              </label>
              <input
                className="form-control"
                id="name"
                disabled
                value={user.label || this.props.selectedUser.label}
              />
            </div> */}
            {/* <div></div>
            {clickedEvent.notes.length ? (
              <div style={{ marginTop: 10 }}>
                <span
                  className="fa fa-align-left fa-2x"
                  style={{
                    position: "relative",
                    paddingRight: 10,
                    top: 39,
                    color: "rgb(95, 99, 104)"
                  }}
                />
                <TextField
                  margin="normal"
                  disabled
                  style={{ width: "370px" }}
                  label={clickedEvent.notes}
                />
              </div>
            ) : (
              ""
            )} */}
            {/* <div className="row">
              <div className="col-md-6">
             
              </div>
              <div className="col-md-6">
             
              </div>
              <div className="col-md-6">
            
              </div>
            </div> */}
          </div>
        </Paper>
      </div>
    );
  }
}
