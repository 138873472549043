import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import Swal from 'sweetalert2'
import axios from 'axios'
import "react-table/react-table.css";
import ReactTable from "react-table";
import checkResponse from "../../../commons/CheckResponse";
import { PUBLIC_URL, API_PREFIX } from "../../../commons/config";
import matchSorter from 'match-sorter'
import intl from 'react-intl-universal';



const app_phone = '15855421035'

export default
@inject("store")
@observer
class extends Component {
  state = {
    loading: true,
    data: [],
    clinic_mobile: '',
    props: { name: "" },
    credentials: {}
  };
  constructor(props) {
    super(props);

    const style = {
      textAlign: "center",
      userSelect: "none",
      padding: '1rem 0',
      borderBottom: '1px solid #707070'
    };

    this.columns = [
      {
        Header: intl.get("NAME") || "Name",
        accessor: 'name',
        id: "name",
        accessor: d => d.name,
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["name"] }),
        filterAll: true,
        style,
        Filter: ({ filter, onChange }) => (
          <input
            style={{ width: '130px' }}
            type="text"
            placeholder={intl.get("SEARCH") || "Search"}

            value={filter ? filter.value : ''}
            onChange={event => onChange(event.target.value)}
          />
        )
      },
      {
        Header: intl.get("EMAIL") || "Email",
        accessor: "email",
        style,
        Cell: props => {
          if (props.value.length > 36) {
            return <p>No Email Provided</p>;
          }
          return (
            <p style={{ fontWeight: 400 }}>
              {props.value}
            </p>
          );
        },
        Filter: ({ filter, onChange }) => (
          <input
            style={{ width: '130px' }}
            type="text"
            placeholder={intl.get("SEARCH") || "Search"}
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),

      },
      {
        Header: intl.get('COUNTRY_CODE') || "Country Code",
        id: "country_code",
        accessor: d => d.country_code,
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["country_code"] }),
        filterAll: true,
        style,
        Filter: ({ filter, onChange }) => (
          <input
            style={{ width: '130px' }}
            type="text"
            placeholder={intl.get("SEARCH") || "Search"}
            value={filter ? filter.value : ''}
            onChange={event => onChange(event.target.value)}
          />
        )
      },
      {
        Header: intl.get("MOBILE_NUMBER") || "Mobile",
        id: "mobile",
        accessor: d => d.mobile,
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["mobile"] }),
        filterAll: true,
        style,
        Filter: ({ filter, onChange }) => (
          <input
            style={{ width: '130px' }}
            type="text"
            placeholder={intl.get("SEARCH") || "Search"}
            value={filter ? filter.value : ''}
            onChange={event => onChange(event.target.value)}
          />
        )
      },
      {
        Header: intl.get("SPECIALITY") || "Role",
        show: this.props.showRole,
        accessor: "speciality",
        Cell: props => {
          let name = props.original.speciality;
          return (
            <p style={{ fontWeight: 400 }} value={name}>
              {name}
            </p>
          );
        },
        style,
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          }
          if (filter.value === "Doctors") {
            return row["speciality"] == "doctor";
          }
          if (filter.value === "Employees")
            return row["speciality"] == "employee";
        },
        Filter: ({ filter, onChange }) => (
          <select
            style={{ width: '130px' }}
            onChange={event => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="all">Show All</option>
            <option value="Doctors">{this.props.store.get_staff()}</option>
            <option value="Employees">Employees</option>
          </select>
        ),
        Filter: ({ filter, onChange }) => null,

      },
      {
        Header: "",
        Cell: props => {

          if (props.original.speciality_id === 'admin') {
            return null
          }
          return (
            <div className='d-flex'>
              <div className="material-icons action-icon ic-light-blue"
                // onClick={() => this.makeCall(app_phone, props.original.biz_phone, `${props.original.country_code}${props.original.mobile}`)}
                title={`Call ${props.original.country_code}${props.original.mobile}`}

              >
                <a href={`tel:${props.original.country_code}${props.original.mobile}`}>
                  <i className="fa fa-phone-square" style={{ color: 'black', marginRight: '.5rem', cursor: 'pointer' }}></i>
                </a>
              </div>


              <a
                onClick={_ => this.handleClick(props)}
                className="material-icons action-icon ic-light-blue"
                title='Edit'
              >
                <i className="fa fa-pencil" style={{ color: 'black', marginRight: '.5rem', cursor: 'pointer' }}></i>
              </a>
              <a
                className="material-icons action-icon ic-light-red"
                onClick={_ => this.deleteUser(props.original.id)}
                title='Delete'
              >
                <i className="fa fa-trash" style={{ color: 'black', cursor: 'pointer' }}></i>
              </a>
            </div>
          )
        },
        Filter: ({ filter, onChange }) => null,
        style
      }
    ];
  }

  componentDidMount() {
    const { business, credentials } = this.props;

    this.setState({ clinic_mobile: business.mobile, credentials })
  }


  makeCall = async (app_phone, biz_phone, client_phone) => {
    const { credentials } = this.props;
    try {
      const { data } = await axios.post('https://mnty-mbl-bookinghub.com/api/text2speech/click-2-call',
        { app_phone, biz_phone, client_phone },
        {
          auth: {
            username: credentials.sid,
            password: credentials.token
          }
        })

      Swal.fire({
        type: 'success',
        title: 'Success',
        text: `Call has been initiated successfully between ${biz_phone} and ${client_phone}`
      })
    } catch (ex) {
      console.log('Error while making a call :', ex)
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: 'Something went wrong'
      })
    }


  }
  deleteUser = u_id => {

    // const user_id = this.props.store.get_id()
    const URL = PUBLIC_URL + API_PREFIX + "allusers/delete/" + u_id;

    Swal.fire({

      icon: 'warning',
      title: "Are you sure?",
      text:
        "this would not be revert back",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(result => {
      if (result.value) {


        try {
          checkResponse(URL, "delete").then(r => {
            if (r.data) {
              window.location.reload()
            }
          });
        } catch (ex) {
          Swal.fire("Oops...", "Something went wrong", "error");
        }
      }
    });

  };

  handleClick = props => {
    this.props.history.push(`${this.props.to}${props.original.id}`, {
      user: { ...props.original }
    });
  };

  render() {
    if (this.props.users) {

      return (

        <ReactTable
          deleteUser={this.props.deleteUser}
          showPagination
          data={this.props.users}
          columns={this.columns}
          pageSizeOptions={[10, 20, 30, 40]}
          defaultPageSize={10}
          sortable

          multiSort
          resizable
          previousText={intl.get('PREVIOUS') || 'Pevious'}
          nextText={intl.get("NEXT") || "Next"}
          // loadingText: 'Loading...',
          noDataText={intl.get("NO_ROWS_FOUND") || "No rows found"}
          pageText={intl.get("PAGE") || "Page"}
          ofText={intl.get("OF") || "of"}
          rowsText={intl.get("ROWS") || "rows"}
          // PaginationComponent={Pagination}
          // className="-stripped -highlight"
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value}
          defaultSortDesc
        // getTrProps={(state, rowInfo, column) => {
        //   if (rowInfo != undefined) {
        //     return {
        //       style: {
        //         color:
        //             rowInfo.original.active == 0
        //               ? "red"
        //             : "navy"
        //       }
        //     };
        //   } else {
        //     return {};
        //   }
        // }}
        />

        // <Confirm
        //           open={this.state.open}
        //           onCancel={()=>this.setState({open:false,openerror:false})}
        //           onConfirm={()=>this.deleteUser(this.state.props)}
        //           content={this.state.props.name  + " deleted successfully"}
        //         />
        //         <Confirm
        //           open={this.state.openerror}
        //           onConfirm={()=>this.setState({open:false,openerror:false})}
        //           cancelButton={null}
        //           content={"admin user can not be deleted"}
        //         />

      );
    }
    return <div />;
  }
}
