import React from 'react';
import MenuItem from 'material-ui/MenuItem';
import IconButton from 'material-ui/IconButton';
// import FontIcon from 'material-ui/FontIcon';
import IconMenu from 'material-ui/IconMenu';
import intl from 'react-intl-universal';

const logout = ({...props}) => {
    props.store.log_out();
    props.history.push('/');
}

const Logged = (props) => (
    <IconMenu
      {...props}
      iconButtonElement={
        <IconButton>
            <i className="material-icons black" style={{color: 'black'}}>more_vert</i>
        </IconButton>
      }
      targetOrigin={{horizontal: 'right', vertical: 'top'}}
      anchorOrigin={{horizontal: 'right', vertical: 'top'}}
    > oooooooooo
      <MenuItem primaryText= {intl.get("PROFILE") || "Profile"} onClick={_=>props.history.push('/profile')} />
      <MenuItem primaryText={intl.get("REFRESH") || "Refresh"} onClick={_=>window.location.reload()} />
      <MenuItem primaryText={intl.get("CHANGE_BUSINESS") || "Change Business"} onClick={_=>props.history.push("/")} />
      {/* <MenuItem primaryText="Help" /> */}
      <MenuItem primaryText={intl.get("SIGN_OUT") || "Sign out"} onClick={_ => logout(props)} />
    </IconMenu>
);



export default Logged;