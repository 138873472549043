import React, { Component } from "react";
import { inject, observer } from "mobx-react";

import { PUBLIC_URL, API_PREFIX } from "../../../commons/config";
import checkResponse from "../../../commons/CheckResponse";
import intl from 'react-intl-universal';


const currencies = [
  {
    name: "dollars",
    sign: "$"
  },
  {
    name: "Lebanese Pounds",
    sign: "LL"
  },
  {
    name: "Pakistan Rupee",
    sign: "PKR"
  },
  {
    name: "Euro",
    sign: "€"
  },
  {
    name: "UAE",
    sign: "Dhs"
  }
];

export default
@inject("store")
@observer
class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      err: "",
      currency:{name:'USD',sign:'$'}
    };
  }

  submit = e=> {
      e.preventDefault()
    const { get_business_id } = this.props.store;
    const {name,sign} = this.state.currency
    const URL = PUBLIC_URL + API_PREFIX + "currencies/create";
    checkResponse(URL, "post", {
      currency_label: name,
      currency_value: sign,
      business_id: get_business_id()
    }).then(result => {
      if (result.response)
        return this.setState({ err: result.response.data.error_description });
      return this.props.history.push("/currencies/view");
    });
  }

  render() {
    return (

<header className="header">
<div className="text-center mt-4">
        <h2 className="primary-heading"> {intl.get('ADD_CURRENCY') || 'Add Currency'}</h2>
       </div>
       <div className="action-container width-60" >
        <div className="table-container padding-2" >
        {this.state.err ? <h3 className='text-center text-danger'>{this.state.err}</h3> : []}
        <div class="form-group-container">
              <label for="exampleInputEmail1">{intl.get('LABEL') || 'Label'}</label>
              <select
              style={{border:'none'}}
                class="form-control"
                onChange={e => this.setState({ currency: {name:e.target.value.split('|')[0],sign:e.target.value.split('|')[1]} })}
                >
                {currencies.map(currency => (
                    <option key={currency.sign} value={currency.name+'|'+currency.sign}>{currency.name}</option>
                    ))}
              </select>
            </div>
            <div class="form-group-container mt-3">
              <label for="exampleInputPassword1">{intl.get('CURRENCY') || 'Currency'}</label>
              <input
                style={{backgroundColor:'transparent', textAlign:'center'}}
                type="text"
                class="form-control"
                id="exampleInputPassword1"
                value={this.state.currency.sign}
                disabled
                />
            </div>
        <div className="my-3 d-flex justify-content-center">
        <button type="button" onClick={this.submit} class="btn-green mr-2">
              {intl.get('SUBMIT') || 'Submit'}
            </button>
        <button type="button" onClick={()=>this.props.history.push('/currencies/view')} class="btn-ghost mr-2">
              {intl.get('CANCEL') || 'Cancel'}
            </button>
        </div>
        </div>
       </div>
</header>

     
    );
  }
}
