import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { RaisedButton } from "material-ui";
import { inject, observer } from "mobx-react";
import { PUBLIC_URL, API_PREFIX } from "../../commons/config";
import { addBusiness } from "../../services/BusinessService";
import checkResponse from "../../commons/CheckResponse";
import CountryCodes from "../CountryCodes";
import intl from "react-intl-universal";
import Modal from "react-modal";
import group from "../../assets/images/Group.png";
import SelectLanguage from "../../commons/SelectLanguage";
import RightMenu from "../../components/rightMenu"
import Navigation from '../../components/Navigation'

let customStyles;
if (window.screen.width < 400) {
  customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "300px",
      height: "460px",
      borderRadius: "20px",
      boxShadow: "5px 5px 6px 0 rgba(0, 0, 0, 0.16)",
      border: "solid 1px #707070",
      backgroundColor: "#ffffff",
    },
  };
} else {
  customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "446px",
      height: "680px",
      borderRadius: "20px",
      boxShadow: "5px 5px 6px 0 rgba(0, 0, 0, 0.16)",
      border: "solid 1px #707070",
      backgroundColor: "#ffffff",
    },
  };
}

Modal.setAppElement("#root");

@inject("store")
@observer
class Dashboard extends Component {
  state = {
    modalIsOpen: false,
    available_business_types: [],
    disabled: false,
    businesses: [],
    loading: true,
    form: { name: "", short_name: "", fn_number: "" },
    type_id: "",
    err: "",
    disabled: false,
  };

  getAllTypes() {
    const URL = PUBLIC_URL + API_PREFIX + `businessTypes`;
    checkResponse(URL, "get").then((r) =>
      this.setState({ available_business_types: r.data, type_id: r.data[0].id })
    );
  }

  componentDidMount() {
    const lang = localStorage.getItem("lang");
    if (!lang) {
      localStorage.setItem("lang", "en");
    }
    this.getAllTypes();
    this._getData();
  }
  _getData() {
    const { Http } = this.props.store;
    const URL = PUBLIC_URL + API_PREFIX + "business/user-businesses";
    Http()
      .get(URL)
      .then(this.insertData.bind(this))
      .catch((e) => {
        if (!e.response) {
        }
        if (
          (e.response && e.response.status === 409) ||
          (e.response && e.response.status === 400)
        ) {
          this.props.store.log_out();
          this.props.store.reload();
        }
      });
  }

  insertData(data) {
    this.setState({ businesses: data.data.businesses, loading: false });
  }

  // async componentDidMount() {
  //     const lang = localStorage.getItem('lang')
  //     document.getElementsByTagName( 'html' )[0].className = ''
  //     document.getElementsByTagName( 'html' )[0].classList.add('dashboardHtml')
  //     if(!lang){
  //       localStorage.setItem('lang','en')
  //     }

  //     try{
  //       const {data: biz} = await getUserBusinesses()
  //       const {data: biz_types} = await getBusinessTypes()
  //       this.setState({ businesses: biz.businesses, loading: false,
  //         available_business_types:biz_types, type_id: biz_types[0].id });
  //      }catch(e){
  //        console.log(e.response)
  //        if(e.response && e.response.status === 409){
  //          this.props.store.log_out();
  //          this.props.store.reload();
  //        }

  //     // this.getAllTypes();
  //     // this._getData();
  //   }
  //    }
  // getAllTypes=async()=> {
  //   const {data: biz} = await getBusinessTypes()
  //   this.setState({available_business_types:biz, type_id: biz[0].id})
  // }

  //   _getData=async()=> {

  // try{
  //  const {data: biz} = await getUserBusinesses()

  //  this.setState({ businesses: biz.businesses, loading: false });
  // }catch(e){
  //   if(e.response && e.response.status === 409){
  //     this.props.store.log_out();
  //     this.props.store.reload();
  //   }
  // }

  //   }

  showMenu = (event) => {
    event.preventDefault();

    this.setState({
      showMenu: true,
    });
  };
  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  _handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this._createBusiness();
    }
  };

  _validate = () => {
    let valid = true;
    const { name, short_name, fn_number } = this.state.form;
    if (name === "") {
      this.setState({ err: `Name is required` });
      valid = false;
    } else if (name.length > 25) {
      this.setState({ err: `Business Name should be less than 25 chars` });
      valid = false;
    } else if (short_name === "") {
      this.setState({ err: `Short Name is required` });
      valid = false;
    } else if (short_name.length > 10) {
      this.setState({ err: `Short Name should be less than 10 characters` });
      valid = false;
    }

    let biz_name = this.state.businesses.map((n) => n.name);
    if (biz_name.includes(this.state.form.name)) {
      this.setState({ err: "Business name already esists" });
      valid = false;
    }

    return valid;
  };

  _createBusiness = (e) => {
    e.preventDefault();
    this.setState({ disabled: true });

    if (this._validate()) {
      const { get_id } = this.props.store;
      const { name, short_name, address, fn_number } = this.state.form;
      const business_phone =
        (this.state.country_code || "") +
        (this.state.form.business_phone || "").toString();
      if (name.length > 25) {
        return alert("business name should be less than 25");
      }
      const type_id = this.state.type_id;

      setTimeout(async function() {
        let data = {
          name,
          short_name,
          user_id: get_id(),
          address,
          fn_number,
          business_phone,
          type_id,
        };

        try {
          await addBusiness(data);
          window.location = "/";
        } catch (e) {
          console.log(e.response);
        }
      }, 1000);
    }
  };

  cards() {
    const { businesses } = this.state;
    if (businesses.length) {
      return businesses.map((business) => {
        return (
          <div
            className="businessCard"
            onClick={(_) => this._goToBusiness(business)}
          >
            <div className="redBlock " style={{backgroundColor: 'white'}}></div>
            <div className="businessLayer"></div>
            <div className="businessName">
              {business.name} &nbsp;
              <span>({business.short_name})</span>
            </div>
          </div>
        );
      });
    }
  }

  haveBusiness = () => {
    return (
      <div className="header-wrapper" style={{ height: "78vh" }}>
        <div className="myBusinessesHeader">
          <div className="myBusinessesSubtitle" style={{color: 'white', fontWeight: 400}}>
            {intl.get("CHOOSE_A_BUISNESS_TO_CONTINUE_OR") ||
              " Choose a business to continue or "}
            <span>
              <a
                href="#"
                className="redText addNewTitle"
                style={{color: 'white'}}
                onClick={this.openModal}
              >
                &nbsp;{intl.get("ADD_NEW") || "ADD NEW"}
              </a>
            </span>
          </div>
          <div className="businessesList">{this.cards()}</div>
        </div>
      </div>
    );
  };

  _goToBusiness = (business) => {
    const { business_id, name, logo } = business;
    const {
      set_business_id,
      set_business_name,
      set_business_logo,
    } = this.props.store;
    set_business_name(name);
    set_business_logo(logo);
    localStorage.setItem("BUSINESS_NAME", name);
    set_business_id(business_id).then((_) => {
      this.props.history.push("/dashboard", {
        business_id,
      });
    });
  };

  _noBusiness() {
    return (
      <div className="text-center mt10">
        <h2>
          You don't have any business, yet!{" "}
          <span
            style={{
              color: "blue",
              cursor: "pointer",
              textTransform: "upperCase",
              fontSize: 18,
              fontWeight: 450,
            }}
            onClick={this.openModal}
          >
            create one
          </span>
        </h2>
      </div>
    );
  }

  _setInput = (event) => {
    const element = event.target.id;
    let form = Object.assign({}, this.state.form);
    form[element] = event.target.value;
    this.setState({ form });
  };

  _createNew = () => {
    if (this.props.store.get_roles === "admin") {
      return (
        <div className="row">
          <div className="col-md-12" style={{ marginTop: 5, paddingRight: 20 }}>
            <RaisedButton
              label={intl.get("CREATE_NEW") || "Create New"}
              onClick={this.openModal}
              className="pull-right"
              primary
              variant="contained"
            />
          </div>
        </div>
      );
    }
  };

  logout = ({ ...props }) => {
    props.store.log_out();
    props.history.push("/");
  };

  welcome_message() {
    return (
      <div
        className="header-wrapper primary-bg-app"

      >
        <div className="info-message">
          <span style={{ fontWeight: 400 }}>
            You have not created any business yet.
          </span>{" "}
          <span
            onClick={this.openModal}
            style={{ fontSize: 25, cursor: "pointer" }}
          >
            Add new
          </span>
        </div>
        {/* <div class="welcome-header grid-container">
          <div className="dashboard-subtitle grid-item">
            {intl.get("WELCOME_TO") || "Welcome to"}{" "}
            <span className="highlight">ONPOINT</span>
            <span className="by-montymobile">
              ( {intl.get("BY") || "By "} Softwarezz )
            </span>
          </div>
          <div className="dashboard-actions grid-item two-equal-columns">
            <a
              className="getStarted button red"
              target="_blank"
              onClick={this.openModal}
              role="button"
            >
              {intl.get("GET_STARTED") || "Get started"}
            </a>
            <a
              className="learnMore button red"
              target="_blank"
              href="https://softwarezz.com/"
              role="button"
            >
              {intl.get("LEARN_MORE") || "Learn more"}
            </a>
          </div>
        </div> */}
      </div>
    );
  }

  render() {
    const { loading, available_business_types } = this.state;
    if (loading) {
      return <h3 style={{ color: "white" }}>loading...</h3>;
    }

    return (
      <>
        <div style={{ height: "100vh", backgroundColor: "white" }}>
        <Navigation/>
          <div style={{ display: "flex", height: "100%"}}>
            <div
              style={{ width: "70vw",  paddingLeft: 30 }}
              className="primary-bg-app mobileWidth100vw"
            >
              <div className="text-left ">
                <div>
                  <div className="dashboard-title" style={{ color: "white" }}>
                    {intl.get("MY_BUSINESSES") || "My Businesses"}{" "}
                  </div>
                </div>

                {this.state.businesses.length
                  ? this.haveBusiness()
                  : this.welcome_message()}
              </div>
            </div>
            <div style={{ width: "30vw", position: "relative" }} className="displayNoneMobile">
              {/* <div style={{ position: "absolute", right: 50, top: 50 }}>
                <div style={{display: 'flex'}}>
                <SelectLanguage />
                <div className='rightMenuWrapper' style={{paddingRight: 30}}>
                <RightMenu />
                </div>
              </div>
              </div> */}
              <div style={{ position: "absolute", bottom: 100, left: -100 }}>
                <img src={group} style={{ width: "70%" }} alt="Booking Hub" />
              </div>
            </div>
          </div>
        </div>

        {/* Add Business Modal */}
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Create Business"
        >
          <div className="row">
            <div className="col-md-12">
              <div className="my-3">
                <div className="modal-heading" style={{ margin: "0 auto" }}>
                  {intl.get("CREATE_NEW_BUSINESS") || "Create new business"}
                </div>
              </div>
            </div>
          </div>
          {this.state.err && (
            <div className="my-3 text-center text-danger">{this.state.err}</div>
          )}
          <form autoComplete="off">
            <div className="row">
              <div className="col-md-12 mb-3">
                <div className="form-group-container">
                  <label htmlFor="name" className="label-control">
                    {intl.get("BUSINESS_NAME") || "Business Name"}
                  </label>
                  <input
                    type="text"
                    name="name"
                    onChange={(ev) => this._setInput(ev)}
                    onKeyPress={this._handleKeyPress.bind(this)}
                    ref={(name) => (this.name = name)}
                    placeholder={intl.get("BUSINESS_NAME") || "Business Name"}
                    id="name"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-md-12 mb-3">
                <div className="form-group-container">
                  <label htmlFor="short_name" className="label-control">
                    {intl.get("SHORT_NAME") || "Sms Sender"}
                  </label>
                  <input
                    type="text"
                    name="name"
                    onChange={(ev) => this._setInput(ev)}
                    onKeyPress={this._handleKeyPress.bind(this)}
                    ref={(short_name) => (this.short_name = short_name)}
                    placeholder={intl.get("SMS_SENDER") || "SMS SENDER"}
                    id="short_name"
                    className="form-control"
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="col-md-12 mb-3">
                <div className="form-group-container">
                  <label htmlFor="fn_number" className="label-control">
                    {intl.get("FN_NUMBER") || "Financial  Number"}
                  </label>
                  <input
                    type="text"
                    name="fn_number"
                    onChange={(ev) => this._setInput(ev)}
                    onKeyPress={this._handleKeyPress.bind(this)}
                    ref={(fn_number) => (this.fn_number = fn_number)}
                    placeholder={intl.get("FN_NUMBER") || "FN Number"}
                    id="fn_number"
                    className="form-control"
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="col-md-12 mb-3">
                <div className="form-group-container">
                  <label htmlFor="businessType" className="label-control">
                    {intl.get("BUSINESS_TYPE") || "Business Type"}
                  </label>
                  <select
                    onChange={(e) => this.setState({ type_id: e.target.value })}
                    className="form-control"
                    id="businessType"
                    placeholder="select type"
                    required
                    value={this.state.type_id}
                    name="business_type"
                    style={{ border: "none" }}
                  >
                    {available_business_types.map((type) => {
                      return (
                        <option key={type.id} value={type.id}>
                          {type.value}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-12 mb-3">
                <div className="form-group-container">
                  <label htmlFor="businessAddress" className="label-control">
                    {intl.get("ADDRESS") || "Address"}
                  </label>
                  <input
                    type="text"
                    onChange={(ev) => this._setInput(ev)}
                    onKeyPress={this._handleKeyPress.bind(this)}
                    ref={(address) => (this.address = address)}
                    placeholder={intl.get("ADDRESS") || "Address"}
                    id="businessAddress"
                    className="form-control"
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="col-md-12 mb-3">
                <div
                  className="modal-small-heading"
                  style={{ margin: "0 auto" }}
                >
                  {intl.get("MOBILE_NUMBER") || "Mobile Number"}
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <CountryCodes
                      cssClass="country-code-1"
                      countryCode={(country_code) =>
                        this.setState({ country_code })
                      }
                    />
                  </div>
                  <div className="col-md-6 mt-2">
                    <input
                      onChange={(ev) => this._setInput(ev)}
                      onKeyPress={this._handleKeyPress.bind(this)}
                      ref={(business_phone) =>
                        (this.business_phone = business_phone)
                      }
                      placeholder={
                        intl.get("BUSINESS_PHONE") || "Business phone"
                      }
                      id="businessPhone"
                      maxLength={15}
                      className="form-control custom-form-control"
                      autoComplete="off"
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-md-12 d-flex justify-content-center my-3">
                    <button
                      className="btn-ghost mr-2"
                      onClick={this._createBusiness}
                    >
                      {intl.get("SAVE") || "Save"}
                    </button>
                    <button className="btn-ghost " onClick={this.closeModal}>
                      {intl.get("CANCEL") || "Cancel"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal>
      </>
    );
  }
}

export default withRouter(Dashboard);
