import React, { Component } from "react";
import { Card, CardActions, CardText } from "material-ui/Card";
import RaisedButton from "material-ui/RaisedButton";

import Divider from "material-ui/Divider";
import { PUBLIC_URL, API_PREFIX } from "../../commons/config";
import checkResponse from "../../commons/CheckResponse";

class ActivateAccount extends Component {
  state = {
    message: "account was not activated ...",
  };
  componentDidMount() {
    this.activate();
  }

  activate() {
    const resetToken = this.props.match.params.resetToken;
    const URL = PUBLIC_URL + API_PREFIX + "activateAccount/" + resetToken;
    checkResponse(URL, "post", 1)
      .then((r) => {
        if (r.data) {
          return this.setState({
            message: "your account was successfully activated",
          });
        }
        return this.setState({ message: "account was not activated ..." });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  _inputActions() {
    return (
      <div className="tbl no-pad" style={{ marginTop: 40 }}>
        {/* <div className="tbl-cell no-pad">
          <RaisedButton
            type="button"
            variant="contained"
            onClick={_ => this.props.history.push("/signin")}
            primary
            label="Sign in"
          />
        </div> */}
        <div className="tbl-cell no-pad text-right">
          <RaisedButton
            type="button"
            variant="contained"
            onClick={(_) => this.props.history.push("/signin")}
            primary
            label="Sign in"
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div style={{ margin: 150 }}>
        <Card style={{ display: "block", margin: "0 auto", width: 500 }}>
          <div>
            <div
              style={{ marginBottom: "-80px", marginTop: -70, padding: -10 }}
            >
              <img
                src={require("../../assets/images/logo.png")}
                style={{ width: 250, margin: "0 auto", display: "block" }}
                alt=""
              />
              <Divider
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.0)",
                  marginTop: 20,
                  marginBottom: 20,
                }}
              />
              <div style={{ textAlign: "center" }}>
                <a className="forgot">{this.state.message}</a>
              </div>
              <div style={{ padding: 20 }}>{this._inputActions()}</div>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

export default ActivateAccount;
