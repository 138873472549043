import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import Swal from 'sweetalert2'
// import 'sweetalert2/src/sweetalert2.scss'
import { PUBLIC_URL, API_PREFIX } from "../../../commons/config";
import checkResponse from "../../../commons/CheckResponse";
import intl from 'react-intl-universal';

export default
@inject("store")
@observer
class extends Component {
  state = {
    units: [],
    loading: true,
    schema: [],
    service:{},
    open:false,
    currencies:[]
  };

  componentDidMount() {
    this._getData()
    const { service_id } = this.props.match.params;
    const URL = PUBLIC_URL + API_PREFIX + "services/getone/" + service_id;
    checkResponse(URL, "get", 1).then(result => {
      if (result.data) return this.insertData(result);
      if (result.response)
        return this.setState({ err: result.response.data.error_description });
    });
  }

  _getData() {
    const { Http, get_business_id } = this.props.store;
    const URL = PUBLIC_URL + API_PREFIX + "services/units_currencies/" + get_business_id();
    Http().get(URL)
    .then(this.insertCurrenciesUnits.bind(this));
}


insertCurrenciesUnits(data) {
  const { currencies, units } = data.data;
  this.setState({ currencies, units, loading: false});
  // this.renderForm();
}


  insertData(data) {
    const {service} = data.data.service;
    this.setState({ service, loading: false });
  }

  _folderHeader(title) {
    return (
      <div className="header-folder">
        <h4>{title}</h4>
      </div>
    );
  }

  handleChange = e =>{
    let service = {...this.state.service}
    service[e.target.name] = e.target.value
    this.setState({service})
  }



  _basicInputs() {
    if (!this.state.loading) {
      return (
      
        <form onSubmit={this._basicSubmit}>
<div className="row">
  <div className="col-md-6 col-sm-12">
  <div className='form-group-container'>
                    <label>{intl.get('TITLE') || 'Title'}</label>
                    <input 
                    style={{textAlign:'center'}}
                    className='form-control' 
                    name='title' 
                    onChange={this.handleChange} 
                    value={this.state.service.title}/>
          </div>
  </div>
  <div className="col-md-6 col-sm-12">
  <div className='form-group-container'>
                    <label>{intl.get('PRICE') || 'Price'}</label>
                    <input 
                    style={{textAlign:'center'}}
                    className='form-control' 
                    name='price' 
                    onChange={this.handleChange} 
                    value={this.state.service.price}/>
          </div>
  </div>
  <div className="col-md-6 col-sm-12">
  <div className='form-group-container'>
          <label>{intl.get('CURRENCY') || 'Currency'}</label>
          <select 
          style={{border:'none'}}
          className='form-control' 
          name='currency_id' 
          onChange={this.handleChange} 
          value={this.state.service.currency_id}>
            {this.state.currencies.map(curr=> <option key={curr.id} value={curr.id}>{curr.currency_label}</option>)}
          </select>
          </div>
  </div>
  <div className="col-md-6 col-sm-12">
  <div className='form-group-container'>
          <label>{intl.get('UNIT') || 'Unit'}</label>
          <select 
          style={{border:'none'}}
          className='form-control' 
          name='unit_id' 
          onChange={this.handleChange} 
          value={this.state.service.unit_id}>
            {this.state.units.map(unit=> <option key={unit.id} value={unit.id}>{unit.per}</option>)}
          </select>
          </div>
  </div>
</div>

          <div className="my-3">
            <div className="d-flex justify-content-center">
            <button type='submit' className='btn-ghost mr-2'>
          {intl.get("SUBMIT") || 'Submit'}
            </button>
            <button type='button' onClick={e=>this.props.history.goBack()} className='btn-ghost'>
          {intl.get("BACK") || 'Back'}
            </button>
            </div>
          </div>
         
        </form>
      
    
      );
    }
  }

  _folderHeader(title) {
    return (
      <div className="header-folder">
        <h4>{title}</h4>
      </div>
    );
  }

  _basicSubmit =  e => {
    e.preventDefault()
    const { Http, get_business_id } = this.props.store;
    const service = this.state.service;
    const { service_id } = this.props.match.params;
    const URL = PUBLIC_URL + API_PREFIX + "services/update/" + service_id;
      Http().put(URL, service)
        .then(result => {
            if(result.data) return this.props.history.push('/services/view');
        }).catch(err=> Swal.fire("Oops...", err.response.data.error|| "Unable to update service", "error"));
  }

  _basic() {
    return (
      <div className="md20">
       
        <div className="folder-content">{this._basicInputs()}</div>
      </div>
    );
  }

  render() {
    return (

      <header className="header">
        <div className="text-center mt-4">
            <h2 className="primary-heading"> {intl.get('EDIT_SERVICE') || 'Edit Service'}</h2>
        </div>
        <div className="action-container width-60">
         <div className="table-container padding-2">
         {this._basic()}
         </div>
        </div>
      </header>
     
    );
  }
}
