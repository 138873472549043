import React, { Component } from 'react';
import {CrossStorageClient} from 'cross-storage' 

let storage = new CrossStorageClient('https://developer.montymobile.com/connect'); // https://developer.montymobile.com/connect
class CrossStorage extends Component {
 componentDidMount() {
  let token = localStorage.getItem('API_TOKEN')
  storage.onConnect().then(function() {
      console.log('Connected')
      return storage.get('API_TOKEN', 'x-auth-token', 'name', 'lang', 'userRoles', 'REFRESH_TOKEN', 'ID');
    
    }).then(function(res){
       
      if(!token){
        localStorage.setItem('API_TOKEN', res[0])
        localStorage.setItem('x-auth-token', res[1])
        localStorage.setItem('name', res[2])
        localStorage.setItem('lang', res[3])
        localStorage.setItem('userRoles', res[4])
        localStorage.setItem('REFRESH_TOKEN', res[5])
        localStorage.setItem('ID', res[6])
        window.location = '/'
      }else{
        localStorage.removeItem('API_TOKEN')
        localStorage.removeItem('x-auth-token')
        localStorage.removeItem('name')
        localStorage.removeItem('lang')
        localStorage.removeItem('userRoles')
        localStorage.removeItem('REFRESH_TOKEN')
        localStorage.removeItem('id')
        localStorage.setItem('API_TOKEN', res[0])
        localStorage.setItem('x-auth-token', res[1])
        localStorage.setItem('name', res[2])
        localStorage.setItem('lang', res[3])
        localStorage.setItem('userRoles', res[4])
        localStorage.setItem('REFRESH_TOKEN', res[5])
        localStorage.setItem('ID', res[6])
        window.location='/'
      }
    
    })
    .catch(function(err) {
      // Handle error
      console.log("Error is :",err)
    });
 }
  render() { 
    return ( null );
  }
}
 
export default CrossStorage;