import React, { Component } from "react";
import { Card, CardText, RaisedButton, Chip, Snackbar } from "material-ui";
import { PUBLIC_URL, API_PREFIX } from "../../../commons/config";
import { inject, observer } from "mobx-react";
import checkResponse from "../../../commons/CheckResponse";
import DateTimePicker from 'react-datetime-picker';
import moment from 'moment';
import intl from 'react-intl-universal'
export default
@inject("store")
@observer
class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      clients: [],
      services: [],
      form: {
        user_id: "",
        title:'',
        description:'',
        user_id: this.props.store.get_id(),
        notes: "",
        amount: ''
    },
      created_at:new Date(),
      open: false,
      date_time: new Date()
    };
  }

  componentWillMount() {
    this.get_data()
  }

  get_data = () => {
    const { get_business_id } = this.props.store;
    const URL =
      PUBLIC_URL + API_PREFIX + "appointments/" + get_business_id() + "/" + 1;
    checkResponse(URL, "get").then(r => {
      const clients = r.data.clients;
      const users = r.data.users;
      const services = r.data.services;
      this.setState({ clients, users, services });
    });
  };

  

  _form() {
    const created_at  = moment(this.state.date_time).format("YYYY-MM-DD hh:mm:ss")
    let { users } = this.state;
    return (
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <div className="form-group-container">
          <label htmlFor="name" className="label-control">
            {intl.get("TITLE") || "Title"}
          </label>
          <input
            onChange={ev => this._setInput(ev)}
            className="form-control"
            id="client_id"
            name="title"
            style={{textAlign:'center'}}
            placeholder={intl.get('TYPE_TITLE') || "Type your title"}
            required
          />
          </div>
         
        </div>
        <div className="col-md-6 col-sm-12">
          <div className="form-group-container">
          <label htmlFor="email" className="label-control">
            Description
          </label>
          <input
            onChange={ev => this._setInput(ev)}
            className="form-control"
            id="client_id"
            name="description"
            style={{textAlign:'center'}}
            placeholder={intl.get('DESCRIPTION') || "Type your Description"}
            required
          />
          </div>
         

        </div>
        <div className="col-md-6 col-sm-12">
          <div className="form-group-container">
          <label htmlFor="" className="label-control">
            created by
          </label>
          <select
            style={{border:'none'}}
            onKeyPress={this._handleKeyPress.bind(this)}
            onChange={ev => this._setInput(ev)}
            className="form-control"
            value={this.state.form.user_id}
            name="user_id"
            type="text"
            
          >
            <option value={null}>-select-</option>

            {users.map(ser => (
              <option value={ser.value}>{ser.label}</option>
            ))}
          </select>
          </div>
         
        </div>
        <div className="col-md-6 col-sm-12">
          <div className="form-group-container">
          <label htmlFor="notes" className="label-control">
            Notes
          </label>
          <textarea
            style={{border:'none',textAlign:'center', overflow:'hidden'}}
            onKeyPress={this._handleKeyPress.bind(this)}
            onChange={ev => this._setInput(ev)}
            className="form-control"
            id="notes"
            value={this.state.form.notes}
            name='notes'
            placeholder={intl.get('NOTES') || "Type your notes"}
            
          />
          </div>
          
        </div>
        <div className="col-md-6 col-sm-12">
          <div className="form-group-container">
          <label htmlFor="active" className="label-control">
            Amount
          </label>
          <input
            onChange={ev =>
              this._setInput(ev)
            }
            id="amount"
            name="amount"
            className="form-control"
            value={this.state.form.amount}
            style={{textAlign:'center'}}
            placeholder='Amount'
          />
          </div>
         
        </div>
    <div className='col-md-6 col-sm-12'>
      <div className="form-group-container">
      <label htmlFor='date' className='label-control'>Date</label>
      <DatePicker 
      date_time={(date_time)=>this.setState({created_at:date_time})} 
      
      />
      </div>
     
    </div>
    <div className="col-md-12 mt-3 d-flex justify-content-center">
      <button className="btn-ghost" onClick={this._doCreate.bind(this)}>
      {intl.get("ADD") || "Add"}
      </button>
      <button className="btn-ghost ml-2" onClick={()=>this.props.history.push('/expenses/view')}>
      {intl.get("CANCEL") || "Cancel"}
      </button>
    </div>
      </div>
    );
  }

  _folderHeader(title) {
    return (
      <div className="header-folder">
        <h4>{title}</h4>
      </div>
    );
  }

  _setInput(event) {
    const element = event.target.name;
    let form = Object.assign({}, this.state.form);
    form[element] = event.target.value;
    this.setState({ form });
  }

  _handleKeyPress(e) {
    if (e.key === "Enter") {
      this._doCreate();
    }
  }

  _err() {
    if (this.state.err) {
      return (
        <div className="text-center pad10">
          <h4 className="red">{this.state.err}</h4>
        </div>
      );
    }
  }
  validate = () => {
    let valid = true
    this.setState({ err: "" });
    const form = { ...this.state.form };
    Object.keys(form).map(key => {
      var value = form[key];
      if (!value && key !== "notes" && key !== 'description') {
        this.setState({ err: `${key} is missing` });
        valid = false
      }
    });
    return valid
  };

  _doCreate() {
      let created_at = moment(this.state.created_at).format("YYYY-MM-DD hh:mm:ss")
    if (this.validate()) {
      const data = {
        ...this.state.form,
        business_id: this.props.store.get_business_id(),
        created_at 
      };
  
      const URL = PUBLIC_URL + API_PREFIX + "expenses/add";
      checkResponse(URL,"POST",data).then(r=>{
          if(r.data){
              return this.props.history.goBack()
          }
      })
    }
  }

  _card() {
    return (
      <header className='header'>
      <div className="text-center mt-4">
         <h2 className="primary-heading"> {intl.get('ADD_PAYMENT') || 'Add Payment'}</h2>
       </div>
       <div className="action-container width-60" >
         <div className="table-container padding-2">
         {this.state.err ? (
                <h4  style={{ textAlign: "center", color:'red' }}>
                  {this.state.err}
                </h4>
              ) : (
                []
              )}

         {this._form()}
         </div>
       </div>
      </header>

     
    );
  }

  _snackbar() {
    if (this.state.open) {
      return (
        <Snackbar
          open={this.state.open}
          message="User successfully added."
          autoHideDuration={4000}
          onRequestClose={this.handleRequestClose}
        />
      );
    }
  }

  handleRequestClose = () => {
    this.setState({
      open: false
    });
  };

  render() {
    return (
    <>
    {this._card()}
    </>
       
       
    );
  }
}


class DatePicker extends Component {
  state = {
    date: new Date(),
  }
 
  onChange = date =>{
    this.setState({ date })
    this.props.date_time(date)
  } 
  
  render() {
    return (
      <div>
        <DateTimePicker
          onChange={this.onChange}
          value={this.state.date}
        />
      </div>
    );
  }
}