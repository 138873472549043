import React, { Component } from "react";
import Swal from "sweetalert2";
import { PUBLIC_URL, API_PREFIX } from "../../../commons/config";
import checkResponse, { CheckResponse } from "../../../commons/CheckResponse";
import { inject, observer } from "mobx-react";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

import { ToastContainer, toast } from "react-toastify";
import intl from "react-intl-universal";
import "./AppointmentEdit.css";

registerPlugin(FilePondPluginImagePreview);

@inject("store")
@observer
class AppointmentEdit extends Component {
  state = {
    loading: true,
    appointment: {},
    doctor: {},
    patient: { email: "" },
    allAppointmentServices: [],
    loggedInUser: {},
    businessData: {},
    files: [],
    attachments: [],
    notes: "",
    title: "",
    allBusinessServices: [],
  };

  componentDidMount() {
    this._getData();
    this._getLoggedInUser();
    this._getUsersInBusiness();
    this.getAppointmentAttachment();
    this.getAllServicesInBusiness();
  }

  getAllServicesInBusiness() {
    const business_id = this.props.store.get_business_id();
    const URL = PUBLIC_URL + API_PREFIX + "services/getall/" + business_id;
    checkResponse(URL, "get", 1).then((r) => {
      this.setState({ allBusinessServices: r.data.services });
    });
  }

  getAppointmentAttachment() {
    const appointment_id = this.props.match.params.appointment_id;
    const URL =
      PUBLIC_URL + API_PREFIX + "attachments/getall/" + appointment_id;
    checkResponse(URL, "get", 1).then((r) => {
      this.setState({ attachments: r.data.attachments });
    });
  }
  _getUsersInBusiness() {
    const URL =
      PUBLIC_URL +
      API_PREFIX +
      "appointments/" +
      this.props.store.get_business_id();
    checkResponse(URL, "get", 1).then((result) => {
      const businessData = result.data;
      this.setState({ businessData });
    });
  }

  _getLoggedInUser() {
    const URL =
      PUBLIC_URL + API_PREFIX + "users/view/" + this.props.store.get_id();
    checkResponse(URL, "get", 1).then((result) => {
      console.log("current user=>", result);
      this.setState({ loggedInUser: result.data.user });
    });
  }

  _getData = () => {
    const appointment_id = this.props.match.params.appointment_id;
    const URL = PUBLIC_URL + API_PREFIX + "appointments/view/" + appointment_id;
    checkResponse(URL, "get", 1).then((result) => {
      const appointment = result.data;
      this.setState({
        appointment,
        doctor: appointment.doctor,
        patient: appointment.patient,
        allAppointmentServices: appointment.services,
      });
    });
  };

  saveChanges = (e) => {
    e.preventDefault();
    let start_date = e.target.start_date.value;
    let time_start = e.target.time_start.value;
    let time_end = e.target.time_end.value;
    let number = e.target.number.value;
    let timeformat = new RegExp(
      "((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))"
    );
    if (
      !timeformat.test(time_start) ||
      !timeformat.test(time_end) ||
      time_end.length !== 8 ||
      time_start.length !== 8
    ) {
      return alert("invalid time format");
    }
    if (
      !(
        time_end >= time_start ||
        (time_start.split(":")[0] >= "10" && time_end.charAt(0) == "0")
      )
    ) {
      return alert("time start should be less than time end");
    }
    const status = this.state.appointment.status;
    const flag = this.state.appointment.flag;
    const notes = e.target.notes.value;
    const user_id = this.state.doctor.id;
    const client_id = this.state.patient.id;
    const services = [
      ...new Set(this.state.allAppointmentServices.map((item) => item.id)),
    ];

    const data = {
      start_date,
      time_start,
      time_end,
      status,
      flag,
      notes,
      number,
      user_id,
      client_id,
      services,
    };

    this.upload(e);
    const appointment_id = this.props.match.params.appointment_id;
    const URL = PUBLIC_URL + API_PREFIX + "appointments/edit/" + appointment_id;
    if (data == this.state.mydata) {
      return;
    }
    checkResponse(URL, "put", data).then((result) => {
      this.setState({ mydata: data });
      Swal.fire({
        type: "success",
        icon: "success",
        text: "Appointment saved successfully!",
      });
      return;
    });
  };

  handleChange = (e, name) => {
    const patients = this.state.businessData.clients;
    const doctors = this.state.businessData.users;
    const option = e.target.options[e.target.options.selectedIndex];
    if (name == "patient") {
      for (var i = 0; i <= patients.length - 1; i++) {
        if (option.id == patients[i].user_id) {
          this.setState({ patient: patients[i] });
        }
      }
    }
    if (name == "doctor") {
      for (var i = 0; i <= doctors.length - 1; i++) {
        if (option.id == doctors[i].id) {
          this.setState({ doctor: doctors[i] });
        }
      }
    }
  };

  upload = (e) => {
    if (true) {
      e.preventDefault();
      const formData = new FormData();
      for (var i = 0; i <= this.state.files.length - 1; i++) {
        formData.append("files", this.state.files[i]);
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
      }

      const URL =
        PUBLIC_URL +
        API_PREFIX +
        "attachments/create/" +
        this.props.match.params.appointment_id;
      const title = this.state.title;
      const notes = this.state.notes;

      formData.append("title", title);
      formData.append("notes", notes);

      checkResponse(URL, "post", formData).then((r) => {
        if (this.state.files.length) {
          toast("uploading... !", {
            position: toast.POSITION.TOP_CENTER,
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      });
    }
  };

  removeAttachment = (e, att) => {
    if (window.confirm("are you sure ?!")) {
      const URL = PUBLIC_URL + API_PREFIX + "attachments/delete/" + att.id;
      checkResponse(URL, "delete").then((r) => {
        if (r.data) {
          let attachments = [...this.state.attachments];
          attachments = attachments.filter((doc) => doc.id != att.id);
          this.setState({ attachments });
        }
      });
    } else {
      return;
    }
  };

  handleServiceChange = (e) => {
    const checkbox = e.target;
    let allAppointmentServices = [...this.state.allAppointmentServices];
    if (checkbox.checked) {
      allAppointmentServices.push({ id: e.target.value });
      this.setState({ allAppointmentServices });
    } else {
      allAppointmentServices = allAppointmentServices.filter(
        (app) => app.id !== e.target.value
      );
      this.setState({ allAppointmentServices });
    }
  };

  selectOption = (e) => {
    const appointment = { ...this.state.appointment };
    appointment[e.target.name] = e.target.value;
    this.setState({ appointment });
  };

  render() {
    if (this.state.appointment.notes === "fakeClientAppointment") {
      return (
        <div style={{ margin: "0 auto", textAlign: "center" }}>
          <h1 style={{ textAlign: "center" }}>
            {" "}
            there's no appointment for the selected invoice
          </h1>
          <button
            onClick={(_) => this.props.history.goBack()}
            style={{ border: "none" }}
            className="btn btn-primary"
          >
            Go Back
          </button>
        </div>
      );
    }
    const appointmentServicesIds = [
      ...new Set(this.state.allAppointmentServices.map((item) => item.id)),
    ];
    const doctors = this.state.businessData.users;
    const patients = this.state.businessData.clients;
    let disabled = false;
    return (
      <>
        <header className="header">
          <div className="text-center mt-4">
            <h2 className="primary-heading">
              {" "}
              {intl.get("APPOINTMENT_INFO") || "Appointment Info"}
            </h2>
          </div>

          <div className="action-container">
            <div className="table-container">
              <form name="myform" onSubmit={this.saveChanges}>
                <input type="hidden" name="_token" defaultValue="" />
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group-container">
                      <label>{intl.get("CREATED_BY") || "Created by"} : </label>

                      <input
                        style={{ backgroundColor: "transparent" }}
                        name="loggedInUser"
                        type="text"
                        defaultValue={this.state.loggedInUser.name}
                        className="form-control text-center"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group-container">
                      <label>{intl.get("DATE") || "Date"} : </label>
                      <input
                        type="text"
                        id="appointment-date"
                        name="start_date"
                        className="form-control text-center"
                        defaultValue={this.state.appointment.start_date}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group-container">
                      <label>{intl.get("START_TIME") || "Start time"} : </label>
                      <input
                        className="form-control text-center"
                        name="time_start"
                        type="text"
                        defaultValue={this.state.appointment.time_start}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group-container">
                      <label>{intl.get("END_TIME") || "End time"} : </label>
                      <input
                        className="form-control text-center"
                        type="text"
                        name="time_end"
                        defaultValue={this.state.appointment.time_end}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group-container">
                      <label>{intl.get("NUMBER") || "Number"} : </label>
                      <input
                        className="form-control text-center"
                        type="text"
                        name="number"
                        defaultValue={this.state.appointment.number}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group-container">
                      <label>{intl.get("STATUS") || "Status"} : </label>
                      <select
                        style={{ border: "none" }}
                        name="status"
                        className="form-control text-cengter"
                        value={this.state.appointment.status}
                        onChange={this.selectOption}
                      >
                        {/* <option value={this.state.appointment.status}>
                            {this.state.appointment.status == "Cancelled" ? 'Waiting' : this.state.appointment.status}
                          </option> */}
                        <option value="Pending">
                          {intl.get("PENDING") || "Pending"}
                        </option>
                        <option value="Done">
                          {intl.get("DONE") || "Done"}
                        </option>
                        <option value="Cancelled">
                          {intl.get("CANCELLED") || "Cancelled"}
                        </option>
                        <option value="Waiting">
                          {intl.get("WAITING") || "Waiting"}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group-container">
                      <label>{intl.get("FLAG") || "Flag"} : </label>
                      <select
                        style={{ border: "none" }}
                        name="flag"
                        id
                        className="form-control"
                        onChange={this.selectOption}
                        value={this.state.appointment.flag}
                      >
                        <option value="high">
                          {intl.get("HIGH") || "Hight"}
                        </option>
                        <option value="medium">
                          {intl.get("MEDIUM") || "Medium"}
                        </option>
                        <option value="normal">
                          {intl.get("NORMAL") || "Normal"}
                        </option>
                        <option value="vip">{intl.get("VIP") || "VIP"}</option>
                        <option value="other">
                          {intl.get("OTHER") || "Other"}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group-container">
                      <label>{intl.get("SERVICES") || "Services"} </label>
                      <div className="content-radio">
                        {this.state.allBusinessServices.map((service) => {
                          const checked = appointmentServicesIds.includes(
                            service.id
                          );
                          return (
                            <div>
                              <div className="row">
                                <div className="col-sm-6 col-md-4 content-radio-container">
                                  <div>
                                    <input
                                      type="checkbox"
                                      checked={checked}
                                      name="Services"
                                      value={service.id}
                                      onClick={this.handleServiceChange}
                                      id={service.id}
                                    />
                                    <label htmlFor={service.id}>
                                      <p
                                        style={{
                                          color: "black",
                                          marginLeft: ".5rem",
                                        }}
                                      >
                                        {service.title}
                                      </p>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group-container">
                      <label htmlFor="notes">
                        {intl.get("NOTES") || "Notes"}
                      </label>
                      <input
                        id="notes"
                        name="notes"
                        className="form-control"
                        defaultValue={this.state.appointment.notes}
                      />
                    </div>
                  </div>
                  <input type="hidden" name="id" defaultValue="" />
                </div>

                {/* -------------------- Doctor ------------------------ */}
                <div className="row">
                  <div className="col-md-12">
                    <div className="my-3 text-center">
                      <h3>{intl.get("EMPLOYEE") || "EMPLOYEE"}</h3>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group-container">
                      <label>{intl.get("NAME") || "Name"} :</label>
                      <select
                        style={{ border: "none", width: "100%" }}
                        className="form-control"
                        onChange={(e) => this.handleChange(e, "doctor")}
                        value={this.state.doctor.name}
                      >
                        {doctors
                          ? doctors.map((doctor) => (
                              <option id={doctor.id}>{doctor.name}</option>
                            ))
                          : ""}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group-container">
                      <label>
                        {intl.get("PHONE_NUMBER") || "Phone Number"} :
                      </label>
                      <input
                        style={{ backgroundColor: "transparent" }}
                        disabled
                        className="form-control"
                        value={this.state.doctor.mobile}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group-container">
                      <label>{intl.get("EMAIL") || "Email"} :</label>
                      <input
                        style={{ backgroundColor: "transparent" }}
                        disabled
                        className="form-control"
                        value={this.state.doctor.email}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <button
                      className="btn-ghost"
                      style={{ margin: "1rem" }}
                      onClick={(_) =>
                        this.props.history.push(
                          "/users/view/" + this.state.doctor.id
                        )
                      }
                    >
                      {intl.get("VIEW_MORE") || "View More"}
                    </button>
                  </div>
                </div>

                {/* ----------------- Patient --------------------------- */}
                <div className="row">
                  <div className="col-md-12">
                    <div className="my-3 text-center">
                      <h3>{intl.get("CLIENT") || "Client"}</h3>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group-container">
                      <label>{intl.get("NAME") || "Name"} :</label>
                      <select
                        style={{ border: "none" }}
                        className="form-control"
                        onChange={(e) => this.handleChange(e, "patient")}
                        value={this.state.patient.name}
                      >
                        {patients
                          ? patients.map((patient) => (
                              <option id={patient.id}>{patient.name}</option>
                            ))
                          : ""}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group-container">
                      <label>
                        {intl.get("PHONE_NUMBER") || "Phone Number"} :
                      </label>
                      <input
                        style={{ backgroundColor: "transparent" }}
                        disabled
                        className="form-control"
                        value={this.state.patient.mobile}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group-container">
                      <label>{intl.get("EMAIL") || "Email"} :</label>
                      <input
                        style={{ backgroundColor: "transparent" }}
                        disabled
                        className="form-control"
                        value={
                          this.state.patient.email.length > 36
                            ? "no email provided"
                            : this.state.patient.email
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <button
                      className="btn-ghost"
                      style={{ margin: "1rem" }}
                      onClick={(_) =>
                        this.props.history.push(
                          "/clients/view/" + this.state.patient.id
                        )
                      }
                    >
                      {intl.get("VIEW_MORE") || "View More"}
                    </button>
                  </div>
                </div>

                {/* ----------------------- Reports ------------------------- */}
                <div className="row">
                  <div className="col-md-12">
                    <div className="my-3 text-center">
                      <h3>{intl.get("ATTACHMENTS") || "Attachments"}</h3>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="content-block-main text-center">
                      <div
                        className="content-input"
                        className="gallery-pictures"
                        hidden={this.state.attachments.length == 0}
                      >
                        {this.state.attachments.map((att) => (
                          <div
                            className="appattachments"
                            style={{ display: "inline" }}
                          >
                            <p
                              style={{
                                paddingBottom: "0px",
                                paddingTop: "10px",
                                fontWeight: "450",
                                borderBottom: "1px dashed black",
                              }}
                            >
                              {att.title ? att.title : "untitled"}{" "}
                            </p>
                            {att.file.includes("pdf") ? (
                              <div>
                                <a
                                  href={`${PUBLIC_URL}/` + att.file}
                                  target="_blank"
                                >
                                  <img
                                    style={{
                                      width: "250px",
                                      margin: "5px",
                                      padding: "0px 20px 0px 20px",
                                      height: "150px",
                                      border: "1px solid black",
                                    }}
                                    src={
                                      "https://www.computerhope.com/jargon/p/acrobatpdf.jpg"
                                    }
                                    className="imghvr-push-up"
                                    frameborder="0"
                                  />
                                </a>
                                <div>
                                  {/* <a
                                    className="btn btn-success btn-sm"
                                    target="_blank"
                                    href={
                                      `${PUBLIC_URL}/` +
                                      att.file
                                    } download
                                  >
                                    <i class="fa fa-download" /> download
                                  </a> */}
                                  <a
                                    className="btn btn-danger btn-sm"
                                    onClick={(e) =>
                                      this.removeAttachment(e, att)
                                    }
                                  >
                                    {" "}
                                    <i class="fa fa-trash" />{" "}
                                    {intl.get("REMOVE") || "Remove"}
                                  </a>
                                </div>
                              </div>
                            ) : (
                              <div>
                                <a
                                  href={`${PUBLIC_URL}/` + att.file}
                                  target="_blank"
                                >
                                  <img
                                    style={{
                                      width: "250px",
                                      margin: "5px",
                                      padding: "5px",
                                      height: "150px",
                                    }}
                                    src={`${PUBLIC_URL}/` + att.file}
                                    className="imghvr-push-up"
                                  />
                                  <div>
                                    {/* <a
                                    className="btn btn-success btn-sm"
                                    href={
                                        `${PUBLIC_URL}/` +
                                        att.file
                                      } download
                                  >
                                    <i class="fa fa-download" /> download
                                  </a> */}
                                    <a
                                      className="btn btn-danger btn-sm"
                                      onClick={(e) =>
                                        this.removeAttachment(e, att)
                                      }
                                    >
                                      <i class="fa fa-trash" />{" "}
                                      {intl.get("REMOVE") || "Remove"}
                                    </a>
                                  </div>
                                </a>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                      <hr />
                      <div className="report-btn">
                        <a
                          className="btn btn-default btn-sm uploadDocument"
                          data-toggle="modal"
                          data-target="#reports-modal"
                        >
                          {intl.get("UPLOAD") || "Upload"}
                        </a>

                        {/* <input type="file" name="file" id="file" onChange={this.handleFileChange} /> */}
                        <FilePond
                          allowMultiple={true}
                          onupdatefiles={(fileItems) => {
                            // Set currently active file objects to this.state
                            this.setState({
                              files: fileItems.map((fileItem) => fileItem.file),
                            });
                          }}
                        />
                        {this.state.files.length > 0 ? (
                          <div className="row">
                            <div className="col-md-6 col-sm-12">
                              <div className="form-group-container">
                                <label>{intl.get("TITLE") || "Title"} : </label>
                                <input
                                  type="title"
                                  className="form-control"
                                  name="title"
                                  defaultValue=""
                                  placeholder="title"
                                  onChange={(e) =>
                                    this.setState({ title: e.target.value })
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                              <div className="form-group-container">
                                <label>
                                  {intl.get("DESCRIPTION") || "Description"} :{" "}
                                </label>
                                <input
                                  className="form-control"
                                  name="notes"
                                  defaultValue=""
                                  onChange={(e) =>
                                    this.setState({ notes: e.target.value })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="report-container" />
                    </div>
                  </div>
                  <input type="hidden" name="id" defaultValue="" />

                  <div className="col-md-6 col-sm-12 text-center mb-2" style={{display:'flex', justifyContent:'right', alignItems: 'right'}}>
                    <button
                      type="submit"
                      style={{ width: "12rem" }}
                      name="save_changes"
                      className="btn-ghost"
                    >
                      {intl.get("SAVE") || "Save"}
                    </button>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <button
                      style={{ width: "12rem" }}
                      name="create_invoice"
                      className="btn-ghost"
                      onClick={(_) =>
                        this.props.history.push({
                          pathname: "/invoices/create",
                          state: {
                            appointment: this.state.appointment,
                            user: this.state.patient,
                          },
                        })
                      }
                    >
                      {intl.get("CREATE_INVOICE") || "Create Invoice"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </header>
      </>

      //         {/* ---------------------------------------REPORTS---------------------------------- */}
      //     <div className="content-block">
      //       <div className="content-block-ttl">{intl.get('ATTACHMENTS') || 'Attachments'}</div>

      //       <div className="content-block-main text-center">
      //         <div className="content-input" className="gallery-pictures" hidden={this.state.attachments.length == 0}>
      //           {this.state.attachments.map(att => (
      //             <div
      //               className="appattachments"
      //               style={{ display: "inline" }}
      //             >
      //               <p
      //                 style={{
      //                   paddingBottom: "0px",
      //                   paddingTop: "10px",
      //                   fontWeight: "450",
      //                   borderBottom: "1px dashed black"
      //                 }}
      //               >
      //                 {att.title ? att.title : "untitled"}{" "}
      //               </p>
      //               {att.file.includes("pdf") ? (
      //                 <div>
      //                   <a
      //                     href={`${PUBLIC_URL}/` + att.file}
      //                     target="_blank"
      //                   >
      //                     <img
      //                       style={{
      //                         width: "250px",
      //                         margin: "5px",
      //                         padding: "0px 20px 0px 20px",
      //                         height: "150px",
      //                         border: "1px solid black"
      //                       }}
      //                       src={
      //                         "https://www.computerhope.com/jargon/p/acrobatpdf.jpg"
      //                       }
      //                       className="imghvr-push-up"
      //                       frameborder="0"
      //                     />
      //                   </a>
      //                   <div>
      //                     {/* <a
      //                           className="btn btn-success btn-sm"
      //                           target="_blank"
      //                           href={
      //                             `${PUBLIC_URL}/` +
      //                             att.file
      //                           } download
      //                         >
      //                           <i class="fa fa-download" /> download
      //                         </a> */}
      //                     <a
      //                       className="btn btn-danger btn-sm"
      //                       onClick={e => this.removeAttachment(e, att)}
      //                     >
      //                       {" "}
      //                       <i class="fa fa-trash" /> {intl.get('REMOVE') || 'Remove'}
      //                     </a>
      //                   </div>
      //                 </div>
      //               ) : (
      //                 <div>
      //                   <a
      //                     href={`${PUBLIC_URL}/` + att.file}
      //                     target="_blank"
      //                   >
      //                     <img
      //                       style={{
      //                         width: "250px",
      //                         margin: "5px",
      //                         padding: "5px",
      //                         height: "150px"
      //                       }}
      //                       src={`${PUBLIC_URL}/` + att.file}
      //                       className="imghvr-push-up"
      //                     />
      //                     <div>
      //                       {/* <a
      //                           className="btn btn-success btn-sm"
      //                           href={
      //                               `${PUBLIC_URL}/` +
      //                               att.file
      //                             } download
      //                         >
      //                           <i class="fa fa-download" /> download
      //                         </a> */}
      //                       <a
      //                         className="btn btn-danger btn-sm"
      //                         onClick={e => this.removeAttachment(e, att)}
      //                       >
      //                         <i class="fa fa-trash" /> {intl.get('REMOVE') || 'Remove'}
      //                       </a>
      //                     </div>
      //                   </a>
      //                 </div>
      //               )}
      //             </div>
      //           ))}
      //         </div>
      //         <hr />
      //         <div className="report-btn">
      //           <a
      //             className="btn btn-default btn-sm uploadDocument"
      //             data-toggle="modal"
      //             data-target="#reports-modal"
      //           >
      //             {intl.get('UPLOAD') || 'Upload'}
      //           </a>

      //           {/* <input type="file" name="file" id="file" onChange={this.handleFileChange} /> */}
      //           <FilePond
      //             allowMultiple={true}
      //             onupdatefiles={fileItems => {
      //               // Set currently active file objects to this.state
      //               this.setState({
      //                 files: fileItems.map(fileItem => fileItem.file)
      //               });
      //             }}
      //           />
      //           {this.state.files.length > 0 ? (
      //             <div>
      //               <div className="content-input">
      //                 <label>{intl.get('TITLE') || 'Title'} : </label>
      //                 <input
      //                   type="title"
      //                   className="input-email"
      //                   name="title"
      //                   defaultValue=""
      //                   placeholder="title"
      //                   onChange={e =>
      //                     this.setState({ title: e.target.value })
      //                   }
      //                 />
      //               </div>
      //               <div className="content-input">
      //                 <label>{intl.get('DESCRIPTION') || 'Description'} : </label>
      //                 <textarea
      //                   className="input-email"
      //                   name="notes"
      //                   defaultValue=""
      //                   onChange={e =>
      //                     this.setState({ notes: e.target.value })
      //                   }
      //                 />
      //               </div>
      //             </div>
      //           ) : (
      //             ""
      //           )}
      //         </div>
      //         {/* <div className="content-input">
      //             <label>description : </label>
      //             <textarea
      //               className="input-email"
      //               name="notes"
      //               defaultValue=""
      //               onChange={e=>this.setState({notes:e.target.value})}
      //             />
      //           </div> */}
      //         <div className="report-container" />
      //       </div>
      //     </div>
      //   </div>
      // </div>
      // <input type="hidden" name="id" defaultValue="" />
      // <div className="content-submit text-center">
      //   <button type="submit" name="save_changes" className="btn btn-primary">
      //   {intl.get('SAVE') || 'Save'}
      //   </button>
      //   <button
      //     type="submit"
      //     name="create_invoice"
      //     className="btn btn-danger"
      //     onClick={_ =>
      //       this.props.history.push({
      //         pathname: "/invoices/create",
      //         state: {
      //           appointment: this.state.appointment,
      //           user: this.state.patient
      //         }
      //       })
      //     }
      //   >
      //     {intl.get('CREATE_INVOICE') || 'Create Invoice'}
      //   </button>
      // </div>
    );
  }
}

export default AppointmentEdit;
