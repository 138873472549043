import ReactTable from "react-table";
import React, { Component } from "react";
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
// import 'sweetalert2/src/sweetalert2.scss'
import { PUBLIC_URL, API_PREFIX } from "../../../commons/config";
import checkResponse from "../../../commons/CheckResponse";
import { inject, observer } from "mobx-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import formatMoney from "../../../commons/formatMoney";

// import DailySalesPrint from "./DailySalesPrint";
import intl from 'react-intl-universal';



@inject("store")
@observer
class DailySales extends Component {
  state = {
    currentData: [],
    startDate: new Date(),
    endDate: new Date()
  };

  constructor(props) {
    super(props);
    this.reactTable = React.createRef();
    this.columns = [

      {
        Header: intl.get('CLIENT') || 'Client',
        accessor: 'client_name', // String-based value accessors!
        style,
        Filter: ({ filter, onChange }) => (
          <input
            type="text"
            placeholder={intl.get('SEARCH') || 'Search'}
            value={filter ? filter.value : ''}
            onChange={event => onChange(event.target.value)}
            style={{ width: '100px' }}
          />
        ),
        filterMethod: (filter, row, column) => {
          const id = filter.pivotId || filter.id;
          return row[id] !== undefined
            ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
            : true;
        },
      },
      {
        Header: intl.get('SERVICES') || 'Services',
        accessor: 'services',
        style,
        Filter: ({ filter, onChange }) => (
          <input
            type="text"
            placeholder={intl.get('SEARCH') || 'Search'}
            value={filter ? filter.value : ''}
            onChange={event => onChange(event.target.value)}
            style={{ width: '100px' }}
          />
        ),
        filterMethod: (filter, row, column) => {
          const id = filter.pivotId || filter.id;
          return row[id] !== undefined
            ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
            : true;
        },
      },
      {
        Header: intl.get('CREATED_BY') || 'Created By',
        accessor: 'created_by', // String-based value accessors!
        style,
        Filter: ({ filter, onChange }) => (
          <input
            type="text"
            placeholder={intl.get('SEARCH') || 'Search'}
            value={filter ? filter.value : ''}
            onChange={event => onChange(event.target.value)}
            style={{ width: '100px' }}
          />
        ),
        filterMethod: (filter, row, column) => {
          const id = filter.pivotId || filter.id;
          return row[id] !== undefined
            ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
            : true;
        },
      },


      {
        Header: intl.get('STATUS') || 'Status',
        accessor: acc => intl.get(acc.status.toUpperCase()),
        id: 'status',
        style,
        filterMethod: (filter, row) => {
          if (filter.value === 'all') {
            return true;
          }
          if (filter.value === 'paid') {
            console.log(row['status']);
            return row['status'] == 'Paid';
          }
          if (filter.value === 'unpaid') {
            return row['status'] == 'Unpaid';
          }
        },
        Filter: ({ filter, onChange }) => (
          <select
            style={{ width: '130px' }}
            onChange={event => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'all'}
          >
            {' '}
            <option value="all">{intl.get('SHOW_ALL') || 'Show All'}</option>
            <option value="paid">{intl.get('PAID') || 'paid'}</option>
            <option value="unpaid">{intl.get('UNPAID') || 'unpaid'}</option>
          </select>
        ),
      },

      {
        Header: intl.get('PAYMENT_METHOD') || 'Method method',
        accessor: acc => intl.get(acc.method.toUpperCase().replace(' ', '_')),
        id: 'method',
        style,
        filterMethod: (filter, row) => {
          if (filter.value === 'all') {
            return true;
          }
          if (filter.value === 'cash') {
            return row['method'] == 'Cash';
          }
          if (filter.value === 'credit card') {
            return row['method'] == 'Credit Card';
          }
          if (filter.value === 'cheque') {
            return row['method'] == 'Cheque';
          }
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'all'}
          >
            <option value="all">{intl.get('SHOW_ALL') || 'Show All'}</option>
            <option value="credit card">{intl.get('CREDIT_CARD') || 'credit card'}</option>
            <option value="cash">{intl.get('CASH') || 'cash'}</option>
            <option value="cheque">{intl.get('CHEQUE') || 'cheque'}</option>
          </select>
        ),
      },
      {
        Header: intl.get('AMOUNT') || 'Amount',
        accessor: 'net_amount',
        style,
        Filter: () => {
          return null;
        },
        Cell: props => {

          return (
            <p style={style}>
              {props.value.toFixed(2)}
              {' ' + props.original.currency}
            </p>
          );
        },
        sortMethod: (a, b) => {
          if (a.length === b.length) {
            return a > b ? 1 : -1;
          }
          return a.length > b.length ? 1 : -1;
        },
        style,
        Footer: ({ data }) => {
          let arr = [];
          data.map(d => {
            arr.push(d.net_amount);
          });
          let sum = 0;
          if (arr.length) {
            sum = arr.reduce((acc, val) => {
              return acc + val;
            });
          }
          return (
            <span>
              <strong>{intl.get('TOTAL') || 'Total'} : </strong>
              {sum.toFixed(2)} {localStorage.getItem('currency')}
            </span>
          );
        },
      },
      {
        Header: intl.get('DATE') || 'date',
        accessor: 'created_at',
        style,
        filterMethod: (filter, row) => {
          return row['created_at'] == filter.value;
        },
        Filter: ({ filter, onChange }) => (
          <input type="date" onChange={event => onChange(event.target.value)} style={{ width: '100%' }} />
        ),
      },

      {
        Header: intl.get('NOTES') || 'Notes',
        accessor: 'notes',
        style,
        Filter: ({ filter, onChange }) => (
          <input
            type="text"
            placeholder={intl.get('SEARCH') || 'Search'}
            value={filter ? filter.value : ''}
            onChange={event => onChange(event.target.value)}
            style={{ width: '100px' }}
          />
        ),
        filterMethod: (filter, row, column) => {
          const id = filter.pivotId || filter.id;
          return row[id] !== undefined
            ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
            : true;
        },
      },
      {
        Header: '',
        Cell: props => (
          <>
            <Link to={`/view/invoices/print/${props.original.id}`}>
              <i className='fa fa-eye' style={{ color: 'black' }}></i>
            </Link>
            <span onClick={_ => this.handleClick(props)} style={{ cursor: 'pointer' }}>
              <i className="fa fa-pencil mx-2"></i>
            </span>
            {/* <a onClick={_ => this.handleClick(props)} className="material-icons action-icon ic-light-blue">
							edit
						</a> */}
            <span onClick={_ => this.deleteSale(props.original.id)} style={{ cursor: 'pointer' }}>
              <i className="fa fa-trash"></i>
            </span>
            {/* <a
							className="material-icons action-icon ic-light-red"
							onClick={_ => this.setState({ open: true, props })}
						>
							delete
						</a> */}
          </>
        ),
        Filter: ({ filter, onChange }) => null,
        style,
      },
    ];
  }



  deleteSale = id => {
    // const s_id = props.original.id;
    const URL = PUBLIC_URL + API_PREFIX + 'invoices/delete/' + id;

    Swal.fire({
      title: "Are you sure?",
      text:
        "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(result => {
      if (result.value) {

        const currentData = this.state.currentData.filter(d => d.id !== id);
        this.setState({ currentData, DailySales: currentData });
        try {
          checkResponse(URL, "delete").then(r => {
            if (r.data) {
              // this.setState({ open: false });
              // window.location.reload();
            }
          });
          Swal.fire("Deleted!", "Operator has been deleted.", "success");
        } catch (ex) {
          Swal.fire("Oops...", "Something went wrong", "error");
        }
      }
    });

  };

  handleClick = props => {
    this.props.history.push(`/view/invoices/edit/${props.original.id}`, {
      item: { ...props.original }
    });
  };

  componentDidMount() {
    this.setState({ currency: localStorage.getItem('currency') })
    this.getData();
  }


  handleStartDateChange = (date) => {
    this.setState({ startDate: date })
  }
  handleEndDateChange = (date) => {
    this.setState({ endDate: date })
  }

  handleSearchByDate = (e) => {
    e.preventDefault()
    let data = {
      startDate: this.state.startDate,
      endDate: this.state.endDate
    }
    const URL = PUBLIC_URL + API_PREFIX + 'invoices/getByDates/' + this.props.store.get_business_id();
    checkResponse(URL, 'post', data).then(r => {
      if (r.data.invoices) {
        let DailySales = r.data.invoices;
        for (var i = 0; i <= DailySales.length - 1; i++) {
          DailySales[i].amount += ' $';
          DailySales[i].created_at = DailySales[i].created_at.split('T')[0];
        }
        return this.setState({ DailySales, currentData: DailySales });
      }
    });
  }

  handleReset = (e) => {
    e.preventDefault()
    this.getData()
    this.setState({ startDate: new Date(), endDate: new Date() })
  }

  getData = () => {
    const URL =
      PUBLIC_URL +
      API_PREFIX +
      "invoices/business/" +
      this.props.store.get_business_id();
    checkResponse(URL, "get").then(r => {
      if (r && r.data && r.data.invoices) {
        let DailySales = r.data.invoices;

        for (var i = 0; i <= DailySales.length - 1; i++) {
          DailySales[i].amount += localStorage.getItem('currency');
          DailySales[i].created_at = DailySales[i].created_at.split("T")[0];
        }
        return this.setState({ DailySales, currentData: DailySales });
      }
    });
  };



  onTableViewChange = () => {
    const current = this.reactTable.current;
    if (current) {
      const page = current.state.page;
      const pageSize = current.state.pageSize;
      const allData = current.getResolvedState().sortedData;
      const startIdx = page * pageSize;
      const currentData = allData
        .slice(startIdx, startIdx + pageSize)
        .map(item => item._original);
      this.setState({ currentData });
    }
  };

  export = (tableID, filename) => {


    let downloadLink;
    tableID = "data_table";
    filename = "invoices";
    let dataType = "application/vnd.ms-excel";
    let tableSelect = document.getElementById(tableID);
    let tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");

    // Specify file name
    filename = filename ? filename + ".xls" : "excel_data.xls";

    // Create download link element
    downloadLink = document.createElement("a");

    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
      let blob = new Blob(["\ufeff", tableHTML], {
        type: dataType
      });
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      // Create a link to the file
      downloadLink.href = "data:" + dataType + ", " + tableHTML;

      // Setting the file name
      downloadLink.download = filename;

      //triggering the function
      downloadLink.click();
    }
  };

  tableData = () => {
    const currentData = this.state.currentData || [];
    const currency = this.state.currency;
    let arr = [];
    currentData.map(cd => {
      arr.push(parseInt(cd.amount.split(" ")[0]));
    });
    let sum = 0;
    if (arr.length) {
      sum = arr.reduce((acc, val) => {
        return acc + val;
      });
    }
    sum = formatMoney(sum);

    return (
      <div id="docx" hidden={true} >
        <div className="container">
          <table
            className="table table-bordered"
            id="data_table"
          >



            <tr>
              <td style={styles.tblheader}>Client</td>
              <td style={styles.tblheader}>Services</td>
              <td style={styles.tblheader}>Created By</td>
              <td style={styles.tblheader}>Status</td>
              <td style={styles.tblheader}>Payment Method</td>
              <td style={styles.tblheader}>Amount</td>
              <td style={styles.tblheader}>Date</td>
              <td style={styles.tblheader}>Notes</td>
              <td></td>
            </tr>
            {currentData.map(cd => (
              <tr>
                <td styles={styles.cells}>{cd.client_name}</td>
                <td styles={styles.cells}>{cd.services}</td>
                <td styles={styles.cells}>{cd.created_by}</td>

                <td styles={styles.cells}>{cd.status}</td>
                <td styles={styles.cells}>{cd.method}</td>
                <td styles={styles.cells}>{formatMoney(cd.net_amount) + ' ' + currency}</td>
                <td styles={styles.cells}>{cd.created_at}</td>
                <td styles={styles.cells}>{cd.notes}</td>
                {/* <td styles={styles.cell}>
                <Link to={`/view/invoices/print/${cd.id}`}><i className='fa fa-eye'></i></Link>
                  <a onClick={_ => this.handleClick(cd)} className="material-icons action-icon ic-light-blue">
                      edit
                    </a>
                    
                    <a
                      className="material-icons action-icon ic-light-red"
                      onClick={_ => this.setState({ open: true, cd })}
                    >
                      delete
                    </a>
                </td> */}
              </tr>
            ))}
            <tr>
              <td styles={styles.cells}></td>
              <td styles={styles.cells}></td>

              <td styles={styles.cells}></td>
              <td styles={styles.cells}></td>

              <td style={{ fontWeight: "bold", textAlign: 'center' }}>{intl.get('TOTAL') || 'Total'}</td>
              <td style={{ fontWeight: "bold", color: "#00c292", textAlign: 'left' }}>
                {sum} {currency}
              </td>
              <td styles={styles.cells}></td>
              <td styles={styles.cells}></td>
              <td styles={styles.cells}></td>


            </tr>
          </table>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div id="dailysales-print">

        <header className='header'>
          <div className="text-center mt-4">
            <h2 className="primary-heading"> Invoices</h2></div>
          <div className="action-container" >
            <div className="row mb-2">
              <div className="col-md-12 ">
                <div className="d-flex justify-content-between flex-wrap">
                  <div className="left">
                    <DatePicker
                      selected={this.state.startDate}
                      onChange={this.handleStartDateChange}
                      className='date-picker border-white margin-top-1'
                    />
                    <span style={{ marginLeft: '1rem', marginRight: '1rem',  }}>To</span>
                    <DatePicker
                      selected={this.state.endDate}
                      onChange={this.handleEndDateChange}
                      className='date-picker border-white margin-top-1'
                    />
                    <button className='btn-green mx-lg-2'
                      onClick={this.handleSearchByDate}>
                      Filter By Date
                    </button>
                    <button className='btn-default-large'
                      onClick={this.handleReset}>
                      Reset
                    </button>
                  </div>
                  <div className="right d-flex flex-nowwrap">
                    <button className="whitePrimaryBtn-design btn-resp" onClick={() => this.export()}> {intl.get('EXPORT_EXCEL_SHEET') || 'Export excel sheet'}</button>
                    <button className="whitePrimaryBtn-design  ml-lg-3" onClick={_ => this.props.history.push("/invoices/create")}>{intl.get('ADD') || "Add"}</button>
                  </div>

                </div>
              </div>
            </div>



            <div className="table-container">

              <ReactTable
                ref={this.reactTable}
                data={this.state.DailySales}
                columns={this.columns}
                style={{ padding: 2 }}
                showPagination
                pageSizeOptions={[10, 20, 30, 40]}
                defaultPageSize={10}
                sortable
                previousText={intl.get('PREVIOUS') || 'Pevious'}
                nextText={intl.get("NEXT") || "Next"}
                // loadingText: 'Loading...',
                noDataText={intl.get("NO_ROWS_FOUND") || "No rows found"}
                pageText={intl.get("PAGE") || "Page"}
                ofText={intl.get("OF") || "of"}
                rowsText={intl.get("ROWS") || "rows"}
                multiSort
                resizable
                // PaginationComponent={Pagination}
                className="-stripped -highlight"
                filterable
                defaultSortDesc
                onPageChange={this.onTableViewChange}
                onPageSizeChange={this.onTableViewChange}
                onSortedChange={this.onTableViewChange}
                onExpandedChange={this.onTableViewChange}
                onFilteredChange={this.onTableViewChange}
                onResizedChange={this.onTableViewChange}

              />
            </div>
          </div>

        </header>


        {this.tableData()}
      </div>
    );
  }
}

export default DailySales;

const styles = {
  tblheader: {
    // color: "black",
    fontWeight: 600,
    fontSize: '16px',
    // textAlign:'left'
  },
  cells: {
    border: "1px solid #dddddd",
    textAlign: "left",
    padding: "8px",
    fontSize: '14px'

  }
};

const style = {
  marginLeft: 25,
  userSelect: "none",
  // color: "navy",
  fontWeight: 400,
  currency: '$'
};
