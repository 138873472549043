import React, { Component } from "react";

import { inject, observer } from "mobx-react";
import { PUBLIC_URL, API_PREFIX } from "../../../commons/config";
import {
  CardText,
  Dialog,
  Card,
  RaisedButton,
  RadioButton,
  FlatButton
} from "material-ui";
import Form from "react-jsonschema-form";
import checkResponse from "../../../commons/CheckResponse";
import { Confirm } from "semantic-ui-react";
import { toast, ToastContainer } from "react-toastify";
import intl from "react-intl-universal";

const uiSchema = {
  value: { "ui:help": "Seprated by commas" }
};

let i = 0;
export default
@inject("store")
@observer
class extends Component {
  state = {
    metadata: [],
    openConfirm: false,
    loading: true,
    label: "",
    meta_label_id: "",
    open: false,
    edit: false,
    schema: {
      type: "object",
      // required: ['value'],
      properties: {
        // value: { title: "Values", type: "string", default: "" }
      }
    },
    input: [""],
    metavalues: []
  };

  dialogStyles = {
    borderRadius: '20px',
    width: '10rem'

  }
  componentDidMount() {
    this._getData();
  }

  getvalues(id) {
    return;
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false, edit: false });
  };

  _getData() {
    const { Http, get_business_id } = this.props.store;
    const URL = PUBLIC_URL + API_PREFIX + "metadata/" + get_business_id();
    checkResponse(URL, "get", 1).then(result => {
      this.insertData(result);
    });
  }
  handleCancel = () => {
    this.setState({ openConfirm: false });
  };

  insertData(data) {
    const { metadata } = data.data;
    this.setState({ metadata, loading: false });
  }

  open = meta => {
    this.setState({ meta, openConfirm: true });
  };

  _table() {



    if (!this.state.loading && this.state.metadata.length) {
      return (
        <header className="header">
          <div className="mt-4">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: 30, paddingRight: 30 }}>
              <h2 className="primary-heading"> Clients Information</h2>
              <div>
                  <button
                    onClick={() => this.props.history.push("/metadata/create")}
                    className='whitePrimaryBtn-design mb-3'
                  >
                    {intl.get("ADD_NEW") || "add new"}
                  </button>
              </div>
            </div>

          </div>
          <div className="action-container">
            <div className="table-container" >
              <table className="table table-bordered">
                <thead>
                  <th style={{ width: '33.333%' }} className='text-center'>{intl.get("LABEL") || "Label"}</th>
                  <th style={{ width: '33.333%' }} className='text-center'>{intl.get("TYPE") || "Type"}</th>
                  <th style={{ width: '33.333%' }} className='text-center'>{intl.get("ACTIONS") || "Actions"}</th>
                </thead>
                <tbody>
                  {this.state.metadata.map(meta => {
                    meta.type = meta.meta_label_type_id;
                    return (
                      <tr key={meta.id}>
                        <td className='text-center'>{meta.label}</td>
                        <td className='text-center'>
                          {meta.type_name}
                          {/* {meta.type_name == "mutiple choice" ? (
                      //  <b>MULTIPLE CHOICES</b>
                      <img src="https://img.icons8.com/ios/35/000000/check-all.png" />
                    ) : meta.type_name == "text" ? (
                      // <b>TEXT</b>
                      <img src="https://img.icons8.com/ios/45/000000/type-filled.png" />
                    ) : (
                      //  <b>DROPDOWN</b>
                      <img src="https://img.icons8.com/ios/45/000000/dropdown-field.png" />
                    )} */}
                        </td>
                        <td className='text-center'>
                          {meta.type * 1 === 2 || meta.type * 1 === 3 ? (
                            <button className="primary-link btn-flat mr-3"
                              onClick={_ =>
                                this.setState({
                                  open: true,
                                  label: meta.label,
                                  meta_label_id: meta.id
                                })
                              }>
                              {intl.get("ADD_CHOICES") || "Add Choices"}
                            </button>

                          ) : (
                            // []
                            <button className="btn-flat mr-3" style={{ visibility: 'hidden' }}>

                              {intl.get("ADD_CHOICES") || "Add Choices"}
                            </button>
                          )}
                          <button className="btn-flat" onClick={_ => this.handleEdit(meta)}>
                            <i className='fa fa-pencil' style={{ fontSize: '1.2rem', color: '#212529' }}></i>
                          </button>

                          <button className="btn-flat" onClick={(e) => this.deleteMeta(e, meta)}>
                            <i className='fa fa-trash' style={{ fontSize: '1.2rem', color: '#212529' }}></i>
                          </button>



                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                <ToastContainer />
              </table>
            </div>
          </div>
        </header>

      );
    }
    return (
      <header className='header'>
        <div className="text-center mt-4">
          <h2 className="primary-heading"> Clients Information</h2>
        </div>
        <div style={{ width: '70%', margin: '0 auto' }}>
          <div className="text-right">
            <button
              style={{ marginLeft: '90%', marginBottom: '1rem' }}
              onClick={() => this.props.history.push("/metadata/create")}
              className='btn-ghost'
            >
              {intl.get("ADD_NEW") || "add new"}
            </button>
          </div>
          <div className="info-message-container">

            {intl.get("NO_META_DATA") ||
              "there is no meta data in the current business"}
          </div>


        </div>


      </header>

    );
  }

  handleEdit = async meta => {
    this.setState({ meta_label_type_id: meta.meta_label_type_id });
    const URL = PUBLIC_URL + API_PREFIX + `metadata/values/${meta.id}`;
    await checkResponse(URL, "get", 1).then(result => {
      console.log(result)
      this.setState({
        metavalues: result,
        open: true,
        label: meta.label,
        meta_label_id: meta.id,
        edit: true
      });
    });
  };

  deleteMeta = (e, meta) => {
    e.preventDefault()

    const URL = PUBLIC_URL + API_PREFIX + `metadata/${meta.id}`;
    checkResponse(URL, "delete").then(result => {
      this._getData();
      if (result.data)
        return toast("meta has been deleted successfully", {
          position: toast.POSITION.TOP_CENTER
        });

      if (result.response) return;
    });
  };

  addField = () => {
    const item = {};
    this.setState({
      input: [...this.state.input, item]
    });
  };
  removeField = inp => {

    let input = [...this.state.input];
    let updated_input = input.filter(v => v.meta_value !== inp.meta_value)
    // input.splice(idx, 1);
    this.setState({
      input: updated_input
    });
  };

  _addValues(e) {
    let value = [];
    this.state.input.map(inp => {
      value.push(inp.meta_value);
    });
    const { meta_label_id } = this.state;
    const { Http } = this.props.store;
    const URL = PUBLIC_URL + API_PREFIX + "meta-values/create";
    Http()
      .post(URL, { value, meta_label_id })
      .then(data => {
        if (data.data) return window.location.reload();
        if ("err" in data.data) return this.setState({ err: data.data.err });
      });
  }

  handleChange = idx => async e => {
    const { name, value } = e.target;
    const input = [...this.state.input];
    input[idx] = {
      ...input[idx],
      [name]: value
    };

    await this.setState({
      input
    });
  };

  _dialog(edit) {
    if (!edit) {
      let input = this.state.input;
      let arr = [...input];
      return (

        <Dialog
          title={`${intl.get("ADD_VALUES_TO") || "Add values to "} ${this.state.label
            }`}
          autoScrollBodyContent
          modal={false}
          open={this.state.open}
          onRequestClose={this.handleClose}
          paperProps={{ style: { borderRadius: '20px', width: '30rem', marginLeft: '14rem' } }}
        >
          {this.state.err ? <h4>{this.state.err}</h4> : []}
          <Form
            onSubmit={this._addValues.bind(this)}
            // uiSchema={uiSchema}
            schema={this.state.schema}
            style={{ overflow: 'auto' }}
          >
            {this.state.input.map((inp, idx) => {
              return (
                <div className="row no-gutters" key={idx} >
                  <div className="col-md-1">
                    <div className="action-circle circle-default"
                      style={{ marginTop: '0.75rem' }}
                      onClick={_ => this.addField(idx)}>
                      <i className='fa fa-plus' style={{ marginTop: '20%' }}></i>
                    </div>

                  </div>
                  <div className="col-md-9">
                    <input
                      name="meta_value"
                      type="text"
                      placeholder="Type"
                      value={this.state.input[idx].meta_value}
                      style={{
                        width: "92%",
                        height: "30px",
                        margin: "10px",
                        padding: "10px;",
                        border: "1px solid black",
                        borderRadius: '100px',
                        textAlign: 'center'
                      }}
                      onChange={this.handleChange(idx)}
                    />
                  </div>
                  <div className="col-md-2">
                    {this.state.input.length > 1 ? (
                      <div className="action-circle circle-danger color-danger"
                        onClick={() => this.removeField(inp)} style={{ marginTop: '0.75rem' }}>
                        <i className='fa fa-minus' style={{ marginTop: '20%' }}></i>
                      </div>

                    ) : (
                      ""
                    )}
                  </div>
                </div>

              );
            })}
            <div className="row">
              <div className="col-md-4 m-auto">
                <button className="btn-red">
                  {intl.get("SUBMIT") || "Submit"}
                </button>
              </div>
            </div>

          </Form>
        </Dialog>

      );
    } else {
      const data = this.state.metavalues.data.values;
      return (
        <Dialog
          autoScrollBodyContent
          title={`${intl.get("EDIT") || "Edit"} ${this.state.label}`}
          modal={false}
          open={this.state.open}
          onRequestClose={this.handleClose}
          paperProps={{ style: { borderRadius: '20px', width: '30rem', marginLeft: '14rem', scroll: 'paper' } }}
        >
          <form>

            <div className="form-group-container" style={{ marginBottom: '1rem' }}>
              <label>{intl.get("LABEL") || "Label"} </label>
              <input
                className="form-control"
                style={{ height: "auto", fontSize: "17px", textAlign: 'center' }}
                type="text"
                defaultValue={this.state.label}
                onChange={e => this.setState({ editedLabel: e.target.value })}
              />
            </div>
            <div className="form-group-container">
              <label>{intl.get("TYPE") || "Type"}</label>
              <select

                className="form-control"
                style={{ height: "auto", fontSize: "17px", border: 'none' }}
                defaultValue={this.state.meta_label_type_id}
                onChange={e => this.setState({ editedType: e.target.value })}
              >
                <option value="1">{intl.get("TEXT") || "text"}</option>
                <option value="2">
                  {intl.get("DROP_DOWN") || "drop down"}
                </option>
                <option value="3">
                  {intl.get("MULTIPLE_CHOICE") || "multiple choice"}
                </option>
              </select>
            </div>
            <div className="my-3">
              <b>Action Option:</b>
            </div>


            {data.map(value => (

              <div className="d-flex">
                <div className="form-group-container" >
                  <input
                    className="form-control"
                    style={{ height: "auto", fontSize: "17px", width: '20rem', textAlign: 'center' }}
                    type="text"
                    value={value.value}
                    onChange={e =>
                      this.setState({ editedValue: e.target.value })
                    }
                  />
                </div>
                <div className="action-circle circle-success color-success"
                  style={{ marginTop: '1.4rem' }}
                  onClick={() =>
                    this.editValue(value.id, this.state.editedValue)
                  }>
                  <i className='fa fa-floppy-o' style={{ marginTop: '20%' }}></i>
                </div>

                <div className="action-circle circle-danger color-danger ml-2"
                  style={{ marginTop: '1.4rem' }}
                  onClick={() => this.deleteValue(value)}>
                  <i className='fa fa-trash' style={{ marginTop: '20%' }}></i>
                </div>

              </div>

            ))}
            <div className="row mt-3">
              <div className="col-md-8 m-auto">
                <div className="d-flex">
                  <button className="btn-red" onClick={e => this.submitEdit(e)}>
                    {intl.get("SAVE") || "Save"}
                  </button>
                  <button className="btn-ghost ml-2" onClick={() => this.setState({ open: false, edit: false })}>
                    {intl.get("CANCEL") || "Cancel"}
                  </button>
                </div>
              </div>
            </div>


          </form>
        </Dialog>
      );
    }
  }

  editValue = (id, value) => {
    const URL = PUBLIC_URL + API_PREFIX + `metadata/values/${id}`;
    checkResponse(URL, "put", { value }).then(result => {
      return;
    });
  };

  deleteValue = value => {

    const data = [...this.state.metavalues.data.values]
    const index = data.map(d => d.id).indexOf(value.id)
    const newData = data.filter(v => v.id !== value.id)
    // const newData = data.splice(index,1)
    this.setState({ metavalues: { data: { values: newData } } })
    // this.setState({[this.state.metavalues.data.values]: newData})
    const URL = PUBLIC_URL + API_PREFIX + `metadata/deletevalues/${value.id}`;
    checkResponse(URL, "delete").then(result => {

      const meta = {
        id: value.meta_label_id,
        label: this.state.label
      };
      this.handleEdit(meta, value.id);
    });
  };

  remove = inputIndex => {
    let input = this.state.input;
    if (input.includes(inputIndex)) {
      var index = input.indexOf(inputIndex);
      if (index > -1) {
        input.splice(index, 1);
      }
      this.setState({ input });
    }
    for (var i = 0; i <= input.length - 1; i++) {
      input[i] = i;
    }
    this.setState({ input });
  };

  submitEdit = e => {
    e.preventDefault();
    const URL =
      PUBLIC_URL + API_PREFIX + `metadata/${this.state.meta_label_id}`;
    const label = this.state.editedLabel;
    const meta_label_type_id = this.state.editedType;
    const data = {
      label,
      meta_label_type_id
    };
    checkResponse(URL, "put", data).then(result => {
      this._getData();
      if (result.data) {
        this.props.history.push(`/metadata/view`);
        this.setState({ open: false, edit: false });
      }
    });
  };

  handleClick = e => {
    e.preventDefault();
    let input = this.state.input;
    input.push(i++);
    this.setState({ input });
    for (var i = 0; i <= input.length - 1; i++) {
      input[i] = i;
    }
    this.setState({ input });
  };

  render() {
    return (
      <>
        <div className="bootrap-wrapper md20">
          <Confirm
            open={this.state.openConfirm}
            header={intl.get("ARE_YOU_SURE") || "Are you sure ?"}
            content={
              <div style={{ padding: 5, margin: 5 }}>
                <h4 style={{ color: "red" }}>
                  {intl.get("DELETE_INVOICE_WARNING") ||
                    "deleting this item will affect to delete all related clients data"}
                </h4>
              </div>
            }
            onCancel={this.handleCancel}
            cancelButton={intl.get("CANCEL") || "Cancel"}
            confirmButton={intl.get("YES_DELETE") || "Yes delete"}
            onConfirm={() => {
              this.deleteMeta(this.state.meta);
              this.setState({ openConfirm: false });
            }}
          />

        </div>
        {/* <Card> */}
        {/* <CardText> */}
        {this._table()}
        {/* </CardText> */}
        {/* </Card> */}
        {this._dialog(this.state.edit)}
      </>
    );
  }
}
