import React, { Component } from "react";

import checkResponse from "../../../commons/CheckResponse";
import {PUBLIC_URL,API_PREFIX} from '../../../commons/config'
import { inject, observer } from "mobx-react";
import BusinessDetails from "./BusinessDetails";
import Account from './Account'
import intl from 'react-intl-universal';
import Axios from "axios";
import { toast  } from 'react-toastify';
import Navigation from "../../../components/Navigation";
const url = 'https://mnty-mbl-bookinghub.com/api/messageAnalysis/client';
// const url = 'http://localhost:4000/api/messageAnalysis/client';



export default
@inject("store")
@observer
class Profile extends Component {

    state = {
        personalDetails:{name:'',email:'',mobile:''},
        businesses:[],
        personalDetailsChanged:false,
        data:{
            accountBalance:0,
            quantity:0,
            price:0
        },
        role:'admin'
    }

    componentWillMount() {
      this.setState({client:localStorage.getItem('email')})
      this.setState({role:localStorage.getItem('userRoles')})
    }
    
    componentDidMount(){
        this.getPersonalDetails()
        this.getSMSDetails()
        this.getBusinessesDetails()
   
      }

    
    getBusinessesDetails = () =>{
      const URL = PUBLIC_URL + API_PREFIX + 'business/user-businesses'
     checkResponse(URL,'get').then(r=>{
       this.setState({businesses:r.data.businesses})
     })
    }
    getSMSDetails = () =>{
      // Axios.post(url,
      // {client:this.state.client ? this.state.client : this.state.personalDetails.email}).then(data =>{
       
      //   this.setState({data:data.data[0]})
      // })
    }
    getPersonalDetails = () =>{
        const URL = PUBLIC_URL + API_PREFIX + "users/view/" + this.props.store.get_id();
        checkResponse(URL,'get').then(r=>{
           const {name,email,mobile} = r.data.user
           const personalDetails = {
               name,email,mobile
           }
            this.setState({personalDetails})
        })
    }

    handlePersonalChange = (e) =>{
        this.setState({personalDetailsChanged:true})
        let personalDetails = {...this.state.personalDetails}
        if(e.target.name !== 'email'){
          personalDetails[e.target.name] = e.target.value
          this.setState({personalDetails})
        }
    }


  submitPersonalChange = e =>{
      e.preventDefault()
      if(this.state.personalDetailsChanged){

        const URL = PUBLIC_URL + API_PREFIX + "users/edit/" + this.props.store.get_id();
        checkResponse(URL,'put',this.state.personalDetails).then(r=>{
          toast("your details has been updated successfully !", {
            position: toast.POSITION.TOP_CENTER,
          });
          this.setState({personalDetailsChanged:false})
        })
      }
    }

  personalDetails = () =>{
      const {name,email,mobile} = this.state.personalDetails
      return(

<>
<h4>{intl.get('PERSONAL_DETAILS') || 'Personal details'}</h4>
<div className="row">
  <div className="col-md-6 col-sm-12">
  <div className="form-group-container">
                <label className="label-control" style={{ fontSize: "13px" }}>
                  {intl.get('NAME') || "Name"}
                </label>
                <input 
                className="form-control text-center" 
                onChange={this.handlePersonalChange} 
                value={name} name='name'/>
              </div>
  </div>
  <div className="col-md-6 col-sm-12">

  <div className="form-group-container">
                <label className="label-control"  style={{ fontSize: "13px" }}>
                  {intl.get("EMAIL") || 'Email'}
                </label>
                <input 
                style={{backgroundColor:'transparent'}}
                className="form-control text-center" 
                disabled 
                onChange={this.handlePersonalChange} 
                value={email} 
                name='email' />
              </div>
  </div>
  <div className="col-md-6 col-sm-12">
  <div className="form-group-container">
                <label className="label-control"  style={{ fontSize: "13px" }}>
                  {intl.get("PHONE_NUMBER") || 'Phone Number'}
                </label>
                <input 
                className="form-control text-center"  
                onChange={this.handlePersonalChange} 
                value={mobile} 
                name='mobile' />
              </div>
  </div>
  <div className="col-md-6 col-sm-12">
    <div className="d-flex">
    <button className="btn-red mr-2" onClick={this.submitPersonalChange} style={{marginTop:'1rem'}}>
      {intl.get("SAVE") || 'Save'}
      </button>
    <button className="btn-ghost mr-2" onClick={()=>this.props.history.push('/')} style={{marginTop:'1rem'}}>
      {intl.get("CANCEL") || 'Cancel'}
      </button>
    </div>
   
  </div>
</div>

           
            
</>

      )
  }

  render() {
   
    return (
<>
<Navigation/>
<header className="header">
<div className="text-center mt-4">
    <h2 className="primary-heading"> Profile</h2>
    </div>
    <div className="action-container">
      <div className="table-container padding-2">
      {this.personalDetails()}
      {this.state.role === 'admin'? 
        <>
        <Account 
        businesses={this.state.businesses} 
        data={this.state.data} 
        name={this.state.personalDetails.name} /> 
        <BusinessDetails history={this.props.history} />
        </>
      : ''}
      </div>
    </div>
</header>
</>

    );
  }
}

