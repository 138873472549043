import React, { Component } from "react";

class PrivacyAndPolicy extends Component {
  render() {
    return (
      <div class="container">
        <div class="row">
          <div class="col text-left" style={{ background: "white",padding:'1.8rem' }}>
          <div>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: 'Calibri'}}><strong><span style={{fontSize: '24px', fontFamily: 'Helvetica', color: 'black'}}>Privacy Policy</span></strong></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: 'Calibri'}}><span style={{fontSize: '18px', fontFamily: 'Helvetica', color: 'black'}}>ONPOINT has built and developed the booking hub app as a free to use application. The booking hub app is provided at no cost and is intended for use as is.&nbsp;</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: 'Calibri'}}><span style={{fontSize: '18px', fontFamily: 'Helvetica', color: 'black'}}>This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decides to use the booking hub App</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: 'Calibri'}}><span style={{fontSize: '18px', fontFamily: 'Helvetica', color: 'black'}}>If you choose to use our booking hub app, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used solely for providing and improving our application. We will not use or share your information with anyone except as described in this Privacy Policy.</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: 'Calibri'}}><span style={{fontSize: '18px', fontFamily: 'Helvetica', color: 'black'}}>The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at booking hub unless otherwise defined in this Privacy Policy.</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: 'Calibri'}}><strong><span style={{fontSize: '18px', fontFamily: 'Helvetica', color: 'black'}}>Information Collection and Use</span></strong></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: 'Calibri'}}><span style={{fontSize: '18px', fontFamily: 'Helvetica', color: 'black'}}>For a better experience, while using the booking hub app, we may require you to provide us with certain personally identifiable information. The information that we request will be retained by us and used as described in this privacy policy.</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: 'Calibri'}}><span style={{fontSize: '18px', fontFamily: 'Helvetica', color: 'black'}}>The app uses third party services that may collect information used to identify you.</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: 'Calibri'}}><span style={{fontSize: '18px', fontFamily: 'Helvetica', color: 'black'}}>Link to the privacy policy of third party service providers used by the app</span></p>
  <ul style={{marginBottom: '0in'}} type="disc">
    <li style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: 'Calibri', color: 'black'}}><span style={{color: 'windowtext'}}><u><span style={{fontSize: '18px', fontFamily: 'Helvetica', color: 'blue'}}>Google Play Services</span></u></span></li>
  </ul>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: 'Calibri'}}><strong><span style={{fontSize: '18px', fontFamily: 'Helvetica', color: 'black'}}>Log Data</span></strong></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: 'Calibri'}}><span style={{fontSize: '18px', fontFamily: 'Helvetica', color: 'black'}}>We want to inform you that whenever you use our booking hub app, in case of an error in the app we collect data and information (through third party products) on your phone called Log Data. The Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when you are using it, the time and date of your use, and other statistics.</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: 'Calibri'}}><strong><span style={{fontSize: '18px', fontFamily: 'Helvetica', color: 'black'}}>&nbsp;</span></strong></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: 'Calibri'}}><strong><span style={{fontSize: '18px', fontFamily: 'Helvetica', color: 'black'}}>Cookies</span></strong></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: 'Calibri'}}><span style={{fontSize: '18px', fontFamily: 'Helvetica', color: 'black'}}>Cookies are files with small amounts of data that are commonly used as anonymous unique identifiers. They are sent to your browser from the websites that you visit and are stored on your device's internal memory.</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: 'Calibri'}}><span style={{fontSize: '18px', fontFamily: 'Helvetica', color: 'black'}}>booking hub app does not use these “cookies” explicitly. However, the app may use third party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of our app.</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: 'Calibri'}}><strong><span style={{fontSize: '18px', fontFamily: 'Helvetica', color: 'black'}}>Service Providers</span></strong></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: 'Calibri'}}><span style={{fontSize: '18px', fontFamily: 'Helvetica', color: 'black'}}>We may employ third-party companies and individuals due to the following reasons:</span></p>
  <ul style={{marginBottom: '0in'}} type="disc">
    <li style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: 'Calibri', color: 'black'}}><span style={{fontSize: '18px', fontFamily: 'Helvetica'}}>To facilitate our app;</span></li>
    <li style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: 'Calibri', color: 'black'}}><span style={{fontSize: '18px', fontFamily: 'Helvetica'}}>To provide the app on our behalf;</span></li>
    <li style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: 'Calibri', color: 'black'}}><span style={{fontSize: '18px', fontFamily: 'Helvetica'}}>To perform app-related services; or</span></li>
    <li style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: 'Calibri', color: 'black'}}><span style={{fontSize: '18px', fontFamily: 'Helvetica'}}>To assist us in analyzing how our app is used.</span></li>
  </ul>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: 'Calibri'}}><span style={{fontSize: '18px', fontFamily: 'Helvetica', color: 'black'}}>We want to inform our users that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: 'Calibri'}}><strong><span style={{fontSize: '18px', fontFamily: 'Helvetica', color: 'black'}}>Security&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></strong></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: 'Calibri'}}><span style={{fontSize: '18px', fontFamily: 'Helvetica', color: 'black'}}>We value your trust in providing us with your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: 'Calibri'}}><strong><span style={{fontSize: '18px', fontFamily: 'Helvetica', color: 'black'}}>&nbsp;</span></strong></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: 'Calibri'}}><strong><span style={{fontSize: '18px', fontFamily: 'Helvetica', color: 'black'}}>&nbsp;</span></strong></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: 'Calibri'}}><strong><span style={{fontSize: '18px', fontFamily: 'Helvetica', color: 'black'}}>&nbsp;</span></strong></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: 'Calibri'}}><strong><span style={{fontSize: '18px', fontFamily: 'Helvetica', color: 'black'}}>&nbsp;</span></strong></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: 'Calibri'}}><strong><span style={{fontSize: '18px', fontFamily: 'Helvetica', color: 'black'}}>Links to Other Sites</span></strong></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: 'Calibri'}}><span style={{fontSize: '18px', fontFamily: 'Helvetica', color: 'black'}}>Our booking hub app may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: 'Calibri'}}><strong><span style={{fontSize: '18px', fontFamily: 'Helvetica', color: 'black'}}>Children’s Privacy</span></strong></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: 'Calibri'}}><span style={{fontSize: '18px', fontFamily: 'Helvetica', color: 'black'}}>booking hub app does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. In the case we discover that a child under 13 has provided us with their personal information, we immediately delete them from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: 'Calibri'}}><strong><span style={{fontSize: '18px', fontFamily: 'Helvetica', color: 'black'}}>Changes to This Privacy Policy</span></strong></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: 'Calibri'}}><span style={{fontSize: '18px', fontFamily: 'Helvetica', color: 'black'}}>We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: 'Calibri'}}><strong><span style={{fontSize: '18px', fontFamily: 'Helvetica', color: 'black'}}>Contact Us</span></strong></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: 'Calibri'}}><span style={{fontSize: '18px', fontFamily: 'Helvetica', color: 'black'}}>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at .</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: 'Calibri'}}><span style={{fontSize: '18px', fontFamily: 'Helvetica', color: 'black'}}>This privacy policy page was created at&nbsp;</span><u><span style={{fontSize: '18px', fontFamily: 'Helvetica', color: 'blue'}}>privacypolicytemplate.net</span></u><span style={{fontSize: '18px', fontFamily: 'Helvetica', color: 'black'}}>&nbsp;and modified/generated by&nbsp;</span><u><span style={{fontSize: '18px', fontFamily: 'Helvetica', color: 'blue'}}>App Privacy Policy Generator</span></u></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '107%', fontSize: '15px', fontFamily: 'Calibri'}}>&nbsp;</p>
</div>
          </div>
        </div>
      </div>
    );
  }
}

export default PrivacyAndPolicy;
