import axios from "axios";
import { PUBLIC_URL, API_PREFIX } from "../commons/config"
axios.defaults.baseURL = PUBLIC_URL + API_PREFIX;

const API_TOKEN = localStorage.getItem("API_TOKEN");
axios.defaults.headers.common["Authorization"] = API_TOKEN;
// axios.defaults.headers.common["Content-Type"] = 'application/json'
// axios.defaults.headers.common["Accept"] = 'application/json'
// axios.defaults.headers.common["Access-Control-Allow-Credentials"] = true
// axios.defaults.headers.common["Access-Control-Allow-Origin"] = true

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
 
};
