import React, { Component } from "react";
import checkResponse from "../../commons/CheckResponse";
import { PUBLIC_URL, API_PREFIX } from "../../commons/config";
import SingleUser from "./SingleUser";
import Axios from "axios";
import { Icon, Pagination } from "semantic-ui-react";
import DataTable from './dataTable'

class Users extends Component {
  state = {
    users: [],
    total: 0,
    current: 0,
    pages: 0
  };

  componentDidMount() {
    this.getUsers(1);
  }

  getUsers(current) {
    console.log(current);
    const URL = PUBLIC_URL + API_PREFIX + "admin/users/" + current;
    checkResponse(URL, "get").then(r => {
      let { results, total, pages } = r.data.users;
      this.setState({ users: results, total, pages });
    });
  }

  handleRowClick = (e, data) => {
    console.log(e, data);
  };
  pagination = () => {
    return (
      <Pagination
        defaultActivePage={1}
        ellipsisItem={{
          content: <Icon name="ellipsis horizontal" />,
          icon: true
        }}
        firstItem={{ content: <Icon name="angle double left" />, icon: true }}
        lastItem={{ content: <Icon name="angle double right" />, icon: true }}
        prevItem={{ content: <Icon name="angle left" />, icon: true }}
        nextItem={{ content: <Icon name="angle right" />, icon: true }}
        totalPages={this.state.pages}
        onPageChange={this.onPageChange}
      />
    );
  };
  render() {
    return (
      <div style={styles.pageContainer}>
        {/* {this.state.users.map(user => (
          <SingleUser user={user} />
		))} */}
		<div style={styles.tableContainer}>

		<DataTable users={this.state.users}/>
		</div>
        {/* {this.pagination()} */}
      </div>
    );
  }
}

export default Users;

const styles = {
  adminPaging: {
    position: "absolute",
    bottom: 0
  },
  tableContainer:{
	  margin:'20px'
  }
};
