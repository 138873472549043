import React, { Component } from 'react'


export default class SelectLanguage extends Component {

    state = {
        language:'Eng',
        flag: 'Flag_of_England',
        open: false
    }

    componentDidMount() {
      document.addEventListener('mousedown', this.handleClickOutside);
      let language,flag;
      const lang = localStorage.getItem('lang');
      if(lang === 'en' ){
        language = 'Eng';
        flag = 'Flag_of_England'
      }else if(lang === 'ar'){
        language = "العربية";
        flag = 'Flag_of_Lebanon'
      } else if(lang === 'fr'){
        language = 'Fr';
        flag = 'Flag_of_France'
      } else if(lang === 'pr'){
        language = 'Prt';
        flag = 'Flag_of_Portugal';
      }else{
        language = 'Eng';
        flag = 'Flag_of_England'
      }
       this.setState({
         language,
         flag
       }) 
    }
    
    componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleClickOutside);
    }

    changeLanguage = (name, flag, val) => {
      localStorage.setItem("lang", val);
      this.setState({
        language: name,
        flag,
        open: false
      })
      window.location.search = `?lang=${val}`;
      window.location.reload();
    };

     toggleOpen = () =>{
       this.setState({open: !this.state.open})
     }

     setWrapperRef = (node) =>{
      this.wrapperRef = node;
    }

    handleClickOutside= (event)=> {
      if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
        this.setState({open: false})
      }
    }

    render() {
    
            const languages = [
              {
                id: 1,
                name: "Eng",
                value: "en",
                img: 'Flag_of_England'
                // img: lebanonFlag
              },
              {
                id: 2,
                name: "Fr",
                value: "fr",
                img: 'Flag_of_France'
               
              },
              {
                id: 3,
                name: "العربية",
                value: "ar",
                 img:'Flag_of_Lebanon'
               
              },
              {
                id: 3,
                name: "Prt",
                value: "pr",
                img: 'Flag_of_Portugal'
                
              }
            ];
        
          
            
            const {language, flag, open} = this.state;
           
            return (
              <div className="languageBar" ref={this.setWrapperRef}>
                 <div className="d-flex" onClick={this.toggleOpen}>
                   <div className="flag">
                     <img src={
                       require(`../assets/images/${flag}.png`)
                     } alt=""/>
                   </div>
                    <div className="name ml-2">{language}</div>
                    <i className="fa fa-angle-down ml-2"
                    style={{fontSize:'1rem', marginTop:'0.4rem'}}></i>
                 </div>
                {open &&  <ul className='languageList'>
                   {languages.map(i => 
                    <li key={i.id}>
                      <div className="d-flex" onClick={()=> this.changeLanguage(i.name, i.img, i.value)}>
                      <div className="flag">
                     <img src={
                       require(`../assets/images/${i.img}.png`)
                     } alt="" style={{width:'2rem'}}/>
                   </div>
                    <div className="name1 ml-2 text-white">{i.name}</div>
                      </div>
                    </li>  
                    )}
                 </ul>}
              </div>
               
            );
    }
}