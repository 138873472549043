import React, { Component } from "react";
import { PUBLIC_URL, API_PREFIX } from "../../commons/config";
import checkResponse from "../../commons/CheckResponse";
import intl from "react-intl-universal";
import SelectLanguage from "../../commons/SelectLanguage";
import logo from "../../assets/images/bookingwhitebg.png";
import Background from "../../assets/images/calendar.jpg";

var backgroundStyle = {
  backgroundImage: `url(${Background})`,
  height: "100vh",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
};

class ForgotPassword extends Component {
  state = {
    email: "",
  };

  requestReset = (e) => {
    e.preventDefault();
    const URL = PUBLIC_URL + API_PREFIX + "requestReset";

    checkResponse(URL, "post", { email: this.state.email }).then((result) => {
      if (result.data) {
        return this.setState({
          err: "",
          message: "please check a password reset link sent to your email",
        });
      }
      if (result.response) {
        return this.setState({ err: result.response.data.error_description });
      }
    });
  };

  displayMessage = () => {
    if (this.state.err) {
      return (
        <div className="errorMsgWrapper">
          <h4 className="redText">{this.state.err}</h4>
        </div>
      );
    } else {
      return (
        <div className="errorMsgWrapper">
          <h4 className="blueText">{this.state.message}</h4>
        </div>
      );
    }
  };

  render() {
    return (
      <div
        className="blackTheme grid-container two-columns"
        style={backgroundStyle}
      >
        <section
          className="createNewAccount grid-item"
          style={{ order: 2, position: "relative" }}
        >
          <div style={{ position: "absolute", right: 50, top: 50 }}>
            <SelectLanguage />
          </div>
          <div className="logoWrapper grid-item">
            <img className="logo" src={logo} alt="Booking Hub" />
          </div>
          <div className="description grid-item">
            <span>
              {intl.get("ONPOINT_WELCOME_MESSAGE") ||
                "BookingHub is an online appointment scheduling software provided for free by Softwarezz which efficiently enables you to manage all appointments and data for your clients & business."}
            </span>
            <div className="separator"></div>
            <span>
              {intl.get("BOOST_YOUR_PRODUCTIVITY") ||
                "Boost your productivity now by using Booking Hub, the easiest way to control your schedule by managing their appointments and save their data upon each visit."}
            </span>
          </div>
          <div className="createNewAccountButtonHolder grid-item">
            <a
              className="createAccount"
              onClick={(_) => this.props.history.push("/register")}
            >
              <input
                className="red-button"
                type="button"
                value={intl.get("CREATE_NEW_ACCOUNT") || "Create New Account"}
              />
            </a>
          </div>
          <div className="footer privacyAndPolicy grid-item">
            <a
              className="forgot"
              onClick={(_) => this.props.history.push("/privacy_and_policy")}
            >
              {intl.get("PRIVACY_AND_POLICY") || "Privacy and Policy"}
            </a>
          </div>
        </section>
        <section className="forgetPasswordSection section grid-item">
          <div className="forgetPasswordFormHolder formHolder grid-item">
            <form id="forgetPasswordForm" onSubmit={this.requestReset}>
              <div className="title forgetPasswordTitle grid-item">
                <img
                  className="forgetPasswordIcon"
                  src={require("../../assets/svgs/lock_forget_password.svg")}
                  alt="password"
                />
                {intl.get("FORGOT_MY_PASSWORD") || "Forgot Password?"}
                <small
                  className="subtitle forgetPasswordSubtitle"
                  style={{ textAlign: "center" }}
                >
                  {intl.get("RESET_PASSWORD") ||
                    "You can reset your password here"}
                  .
                </small>
                {this.displayMessage()}
              </div>
              <div className="input-container grid-item emailWrapper">
                {/* <img
                  className="innerFormIcon"
                  src={require("../../assets/svgs/envelope.svg")}
                  alt="email"
                /> */}
                 <div
                    style={{ color: "white", fontSize: 16, marginLeft: "1em", paddingBottom:4 }}
                  >
                    Email
                  </div>
                <input
                  id="emailInput"
                  placeholder={intl.get("EMAIL_ADDRESS") || "Email Address"}
                  className="text-input"
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
              </div>
              <div className="input-container grid-item" style={{marginTop: 10}}>
                
                <input
                  className={
                    this.state.message
                      ? "btn btn-lg btn-success btn-block resetPassword green"
                      : "btn btn-lg btn-primary btn-block resetPassword  primary-Btn-white"
                  }
                  defaultValue="Send My Password"
                  type={this.state.message ? "button" : "submit"}
                  value={
                    this.state.message
                      ? intl.get("BACK_TO_LOGIN") || "back to login"
                      : intl.get("SEND_RESET_LINK") || "send reset link"
                  }
                  onClick={
                    this.state.message
                      ? (_) => this.props.history.push("/signin")
                      : ""
                  }
                />
              </div>
            </form>
          </div>

          {/* <div className='footer grid-item signinFooter'>
           <a className="forgot" href="/forgot">
            {intl.get("FORGOT_MY_PASSWORD") || "Forgot my password"}
           </a>  
          </div> */}
        </section>
      </div>
    );
  }
}

export default ForgotPassword;
