import React, { Component } from 'react'
import intl from 'react-intl-universal'
import { inject, observer } from 'mobx-react'
import { Redirect, withRouter } from 'react-router-dom'
import OutsideAlerter from './OutsideAlerter'
import threeDots from '../assets/svgs/threeDots.svg'
import threeDotsWhite from '../assets/svgs/threeDotsWhite.svg'

import styled from "styled-components"

const Nav = styled.header`
   height: 100%;

   .ar .inanimated{
      left: -131px;
      right: auto;
      width: 0;
      -webkit-animation: slideRight 0.2s forwards;
      -webkit-animation-delay: 0.2s;
      animation: slideRight 0.2s forwards;
      animation-delay: 0.2s;
   }

   @-webkit-keyframes slideRight {
    100% { left: -35px; height: 177px; width: 131px; }
}

@keyframes slideRight {
    100% { left: -35px; height: 177px; width: 131px; }
}
   
   .threeDotsMenuWrapper{
      position: relative;
      height: 100%;
   }

   .rightMenuWrapper{
     height: 100%;
   }

   .threeDotsMenu {
      height: 100%;
      display: grid;
      align-items: center;
   }

  .threeDotsMenu img{
      max-width: 8px;
      cursor: pointer;
  }

  .inanimated {
      display: grid;
      position: absolute;
      top: 0;
      right: -131px;
      width: 0;
      height: 0;
      border: solid 0.5px #707070;
      background-color: #ffffff;
      -webkit-animation: slide 0.2s forwards;
      -webkit-animation-delay: 0.2s;
      animation: slide 0.2s forwards;
      animation-delay: 0.2s;
      z-index: 1;
  }

 .animated{
    // -webkit-animation: dashboardSlide 0.2s forwards;
    // -webkit-animation-delay: 0.2s;
    // animation: dashboardSlide 0.2s forwards;
    // animation-delay: 0.2s;
}

@-webkit-keyframes slide {
    100% { right: -25px; height: 177px; width: 131px; }
}

@keyframes slide {
    100% { right: -25px; height: 177px; width: 131px; }
}

@-webkit-keyframes dashboardSlide {
    100% { right: -131px; height: 0;}
}

@keyframes dashboardSlide {
    100% { right: -131px; height: 0;}
}

  #slide > button {
      font-family: Poppins;
      font-size: 11px;
      font-weight: normal;
      line-height: 1.55;
      text-align: left;
      padding-left: 12px;
      color: #707070;
      background: #ffffff;
      // border: 0.5px solid #707070;
      cursor: pointer;
      outline: 0;
  }
`;


export default
@inject("store")
@observer

class RightMenu extends Component {
  constructor() {
    super();
    this.state = {
      showMenu: false,
      animationStatus: 'inanimated',
      arClass: ''
    }  
    this.showMenu = this.showMenu.bind(this);
    this.logout = this.logout.bind(this);
  }

  componentWillMount() {
    // if(localStorage.getItem('lang') === 'ar'){
    //   if(document.getElementById('slide')){
    //   document.getElementById('slide').classList.add('inArabic')
    //   document.getElementsByTagName( 'threeDotsMenuWrapper' )[0].classList.add('inArabic')
    //  }
    // }

    let lang = localStorage.getItem('lang');
    console.log('lang', lang)
    if(lang === 'ar'){
       this.setState({arClass: "ar"}, () => console.log('arClass', this.state.arClass));
       
    }

    // document.getElementsByTagName( 'threeDotsMenuWrapper' )[0].classList.remove('dashboardHtml')
    // document.getElementsByTagName( 'html' )[0].classList.add('rootHtml')
  }
  
  showMenu(event) {
    event.preventDefault();
    
    this.setState({
      showMenu: true,
      animationStatus: 'inanimated'
    });
  }

  logout = (props) => {  //alert("logout")
    // console.log("this.props.store logout", this.props.store)
    this.props.store.log_out();
    // this.props.history.push('/signin');
    // return <Redirect to='/' />
    window.location.assign('/signin')
}

collapse = () => {
  // alert('blurred')
  // this.setState({showMenu: false})
}

callbackFunction = (childData) => {
      this.setState({showMenu: childData, animationStatus: 'animated'})
      console.log('childData', childData)
}

    render() {  // console.log("store", this.props.store)
        return(
          <OutsideAlerter showMenu={this.state.showMenu} parentCallback = {this.callbackFunction}>
            <Nav>
              <div className={`threeDotsMenuWrapper ${this.state.arClass}`} onBlur={ this.collapse }>
                <div class="threeDotsMenu" onClick={this.showMenu}>
                { this.props.iconColor === 'white' ?
                  <img src={threeDotsWhite} alt='three dots menu' />:
                  <img src={threeDots} alt='three dots menu' />
                }
                </div>              
                { 
                  this.state.showMenu
                    ? (
                      <div className="menu" id="slide" className={this.state.animationStatus}>
                        <button onClick={_=>window.location.assign('/profile')}>{intl.get("PROFILE") || "Profile"}</button>
                        <button onClick={_=>window.location.reload()}>{intl.get("REFRESH") || "Refresh"}</button>
                        <button onClick={_=>window.location.assign('/')}>{intl.get("CHANGE_BUSINESS") || "Change Business"}</button>
                        <button onClick={this.logout}>{intl.get("SIGN_OUT") || "Sign out"}</button>
                      </div>
                    )
                    : (
                      null
                    )
                }
              </div>
            </Nav>
          </OutsideAlerter>
        )
    }
}