import React, { Component } from "react";
import Fab from "./buttons/fab";
import RoundedBtn from "./buttons/rounded";
import DayPicker from "./pickers/simple-date-picker";
import $ from "jquery";
import moment from "moment";
import intl from "react-intl-universal";
import { inject, observer } from "mobx-react";
// import { PUBLIC_URL, API_PREFIX } from "../../../../commons/config";
import checkResponse from "../../../../commons/CheckResponse";
import leftArrow from "../../../../assets/svgs/leftArrow.svg";
import rightArrow from "../../../../assets/svgs/rightArrow.svg";

let ar_margin = "7px 11%";
if (localStorage.getItem("lang") === "ar") {
  ar_margin = "7px 14%";
}

export default
@inject("store")
@observer
class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      month: moment().toDate(),
      selectedDays: [moment().toDate()],
      ok: false,
      dayClicked: true,
      weekClicked: false,
      yearClicked: false,
      monthClicked: false,
      appointments: [],
      refreshPicker: false,
    };
    this.view = "agendaDay";
    this.type = "days";
    this.sideCalCounter = 0;
  }

  componentWillReceiveProps(nextProps, nextState) {
    const day = nextProps.props.selectedDay;
    if (day && this.state.yearClicked) {
      return this.setState({
        month: new Date(moment(day).format("YYYY MM DD")),
        selectedDays: [new Date(day)],
      });
    }
  }

  componentDidMount() {
    // this._getToDaysAppointments()
  }

  // go to previous date
  _prevDate = () => {
    this.sideCalCounter--;
    this.setState({
      month: moment()
        .add(this.sideCalCounter, "days")
        .toDate(),
    });
    this.setState({
      selectedDays: moment()
        .add(this.sideCalCounter, "days")
        .toDate(),
    });
    $("#calendar").fullCalendar("prev");
    this.props.onDateChange(
      moment(this.props.today_date).subtract(1, this._changeArrowDateType())
    );
    if (this.view === "year") {
      this.props.updateYearView(
        moment(this.props.today_date)
          .subtract(1, "years")
          .format("YYYY")
      );
    }
  };

  // go to next date
  _nextDate = () => {
    this.sideCalCounter++;
    this.setState({
      month: moment()
        .add(this.sideCalCounter, "days")
        .toDate(),
    });
    this.setState({
      selectedDays: moment()
        .add(this.sideCalCounter, "days")
        .toDate(),
    });
    this.props.onDateChange(
      moment(this.props.today_date).add(1, this._changeArrowDateType())
    );
    $("#calendar").fullCalendar("next");
    if (this.view === "year") {
      this.props.updateYearView(
        moment(this.props.today_date)
          .add(1, "years")
          .format("YYYY")
      );
    }
  };

  // go to today
  _today = () => {
    this.sideCalCounter = 0;

    this.setState({
      month: moment().toDate(),
      selectedDays: [moment().toDate()],
      yearClicked: false,
      dayClicked: true,
      weekClicked: false,
      monthClicked: false,
    });

    this._switchToCustomDay(moment().format("YYYY MM DD"));
    $("#calendar").fullCalendar("today");
    this.props.switchToYear(false);
    this.props.onDateChange(moment().format("MMMM DD YYYY"));
    $("#calendar").fullCalendar("changeView", "agendaDay");
    this.props.switchToYear(false);
    this.view = $("#calendar").fullCalendar("getView").name;
  };

  _switchToDay = () => {
    this.setState({
      yearClicked: false,
      dayClicked: true,
      weekClicked: false,
      monthClicked: false,
    });

    $("#calendar").fullCalendar("changeView", "agendaDay");
    this.props.switchToYear(false);
    this.view = $("#calendar").fullCalendar("getView").name;
  };

  _switchToCustomDay = (clickedDay) => {
    clickedDay = moment(clickedDay).format("YYYY MM DD");
    this.setState({
      yearClicked: false,
      dayClicked: true,
      weekClicked: false,
      monthClicked: false,
      month: new Date(clickedDay),
      selectedDays: [new Date(clickedDay)]
    }, () => {
      this.props.onDateChange(moment(clickedDay));
      $("#calendar").fullCalendar("changeView", "agendaDay", clickedDay);
      this.view = $("#calendar").fullCalendar("getView").name;
    });
  };

  _switchToWeek = () => {
    $("#calendar").fullCalendar("changeView", "agendaWeek");
    this.props.switchToYear(false);

    this.view = $("#calendar").fullCalendar("getView").name;
    this.setState({
      yearClicked: false,
      dayClicked: false,
      weekClicked: true,
      monthClicked: false,
    });
  };

  _switchToMonth = () => {
    $("#calendar").fullCalendar("changeView", "month");
    this.props.switchToYear(false);
    this.view = $("#calendar").fullCalendar("getView").name;
    this.setState({
      monthClicked: true,
      yearClicked: false,
      dayClicked: false,
      weekClicked: false,
    });
  };

  _switchToYear = () => {
    this.setState({
      dayClicked: false,
      yearClicked: true,
      weekClicked: false,
      monthClicked: false,
    });
    $("#calendar").fullCalendar(
      "changeView",
      $("#calendar").fullCalendar("getView").name
    );
    this.view = $("#calendar").fullCalendar("getView").name;
    this.props.switchToYear(true);
  };

  _changeArrowDateType() {
    let type = "days";
    switch (this.view) {
      case "agendaDay":
        type = "days";
        break;
      case "agendaWeek":
        type = "weeks";
        break;
      case "month":
        type = "months";
        break;
      case "year":
        type = "years";
        break;
      default:
        type = "days";
    }
    return type;
  }
  // render sidebar view

  resolveDateLabel = (label) => {
    if (label.includes("January")) {
      label = label.replace("January", intl.get("January" || "January"));
    }
    if (label.includes("February")) {
      label = label.replace("February", intl.get("February" || "February"));
    }
    if (label.includes("March")) {
      label = label.replace("March", intl.get("March" || "March"));
    }
    if (label.includes("April")) {
      label = label.replace("April", intl.get("April" || "April"));
    }
    if (label.includes("May")) {
      label = label.replace("May", intl.get("May" || "May"));
    }
    if (label.includes("June")) {
      label = label.replace("June", intl.get("June" || "June"));
    }
    if (label.includes("July")) {
      label = label.replace("July", intl.get("July" || "July"));
    }
    if (label.includes("August")) {
      label = label.replace("August", intl.get("August" || "August"));
    }
    if (label.includes("September")) {
      label = label.replace("September", intl.get("September" || "September"));
    }
    if (label.includes("October")) {
      label = label.replace("October", intl.get("October" || "October"));
    }
    if (label.includes("November")) {
      label = label.replace("November", intl.get("November" || "November"));
    }
    if (label.includes("December")) {
      label = label.replace("December", intl.get("December" || "December"));
    }

    return label;
  };

  monthChange = (m) => {
    this.setState({ month: moment().toDate(m), selectedDays: [new Date(m)] });
    this._switchToCustomDay(moment(m).format("YYYY MM DD"));
  };

  _data() {
    const app_status = this.props.stats;
    let date = moment(this.props.today_date).format("YYYY-MM-DD");
    let now = moment().format("YYYY-MM-DD");
    let currentDate = date == now;
    return (
      <div className="appointmentCalendarWrapper">
        <h3>
          {/* {this.resolveDateLabel(this.props.today_date)} */}
          Appointment
        </h3>

        <div className="calendarNavigations">
          <a
            href="#"
            className="previousDate goToDate"
            onClick={(_) => this._prevDate()}
          >
            <img
              src={require("../../../../assets/svgs/leftArrow.svg")}
              alt="Left Arrow"
            />
          </a>
          <a
            href="#"
            className="nextDate goToDate"
            onClick={this._nextDate.bind(this)}
          >
            <img
              src={require("../../../../assets/svgs/rightArrow.svg")}
              alt="Right Arrow"
            />
          </a>
          <input
            type="button"
            style={{ marginLeft: 5 }}
            className="today"
            value={intl.get("TODAY") || "Today"}
            onClick={this._today.bind(this)}
          />
        </div>

        <div style={{}}>
          <DayPicker
            clickedDay={(clickedDay) => this._switchToCustomDay(clickedDay)}
            month={this.state.month}
            selectedDays={this.state.selectedDays}
            monthChanged={(monthChanged) => this.monthChange(monthChanged)}
          />
        </div>
        <div
          className="calendarNavigations bottomNavigations"
          style={{ marginLeft: "5px" }}
        >
          <input
            type="button"
            className={
              "dayButton " + (this.state.dayClicked ? "active" : "inactive")
            }
            value={intl.get("DAY") || "Day"}
            onClick={this._switchToDay.bind(this)}
          />
          <input
            type="button"
            className={
              "weekButton " + (this.state.weekClicked ? "active" : "inactive")
            }
            value={intl.get("WEEK") || "Week"}
            onClick={this._switchToWeek.bind(this)}
          />
          <input
            type="button"
            className={
              "monthButton " + (this.state.monthClicked ? "active" : "inactive")
            }
            value={intl.get("MONTH") || "Month"}
            onClick={this._switchToMonth.bind(this)}
          />
          <input
            type="button"
            className={
              "yearButton " + (this.state.yearClicked ? "active" : "inactive")
            }
            value={intl.get("YEAR") || "Year"}
            onClick={this._switchToYear.bind(this)}
          />
        </div>
        <select className="todaysAppointmentslist">
          <option value="" disabled selected>
            {intl.get("TODAYS_APPOINTMENTS") || "TODAYS APPOINTMENTS"}
          </option>
          <option value="" disabled>
            {intl.get("DONE") || "Done"}&nbsp; {app_status.Done}
          </option>
          <option value="" disabled>
            {intl.get("PENDING") || "Pending"}&nbsp; {app_status.Pending}
          </option>
          <option value="" disabled>
            {intl.get("CANCELLED") || "Cancelled"} &nbsp; {app_status.Cancelled}
          </option>
          <option value="" disabled>
            {intl.get("WAITING") || "Waiting"}&nbsp; {app_status.Waiting}
          </option>
          <option value="" disabled>
            {intl.get("TOTAL") || "Total"}&nbsp; {app_status.Total}
          </option>
        </select>
        {
          // <div style={{ margin:ar_margin,fontSize:'0.9rem',width:'75%'}}>
          //   <table class="table ar-table">
          //     <tr
          //       style={{
          //         background: "rgb(3, 169, 243)",
          //         color: "white",
          //         fontWeight: "bold"
          //       }}
          //     >
          //       <td colSpan={2}>
          //         {intl.get("TODAYS_APPOINTMENTS") || "TODAYS APPOINTMENTS"}
          //       </td>
          //     </tr>
          //     <tr style={{ color: "#00c292", fontWeight: "bold" }}>
          //       <td>{intl.get("DONE") || "Done"}</td>
          //       <td>{app_status.Done}</td>
          //     </tr>
          //     <tr style={{ color: "#4183c4", fontWeight: "bold" }}>
          //       <td>{intl.get("PENDING") || "Pending"}</td>
          //       <td>{app_status.Pending}</td>
          //     </tr>
          //     <tr style={{ color: "red", fontWeight: "bold" }}>
          //       <td>{intl.get("CANCELLED") || "Cancelled"}</td>
          //       <td>{app_status.Cancelled}</td>
          //     </tr>
          //     <tr style={{ color: "orange", fontWeight: "bold" }}>
          //       <td>{intl.get("WAITING") || "Waiting"}</td>
          //       <td>{app_status.Waiting}</td>
          //     </tr>
          //     <tr style={{ fontWeight: "bold" }}>
          //       <td>{intl.get("TOTAL") || "Total"}</td>
          //       <td>{app_status.Total}</td>
          //     </tr>
          //   </table>
          // </div>
        }

        {/* <div className="appColorsKeys">
                <div>
                    <label></label>
                </div>
                    <div>
                    <div style={{width:20,height:20,margin:20,padding:20,background:'blue'}}>

                    </div>
                    <label>
                        Normal
                    </label>
                    </div>
                    <div style={{width:20,height:20,margin:20,padding:20,background:'#00c292'}}>

                    </div>
                    <div style={{width:20,height:20,margin:20,padding:20,background:'silver'}}>
                    </div>
                </div> */}
      </div>
    );
  }

  render() {
    return (
      <div style={{ flex: 1, background: "white", height: "100vh" }}>
        {this._data()}
      </div>
    );
  }
}
