import React, { Component } from "react";
import { PUBLIC_URL, API_PREFIX } from "../../../commons/config";
import checkResponse from "../../../commons/CheckResponse";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import { inject, observer } from "mobx-react";
import Swal from 'sweetalert2'
registerPlugin(FilePondPluginImagePreview);

class Card extends Component {
  state = {
    title:"No title",
    notes:"No description",
    attachments:[]
  }


  render() {
   let contents;
   if(this.props.attachments.length > 0 ){
contents = <div className="row">

{this.props.attachments.map(att => (
  <div className='col-md-2 col-sm-12 mb-4'>
   <div className="doc-container">
   <a
        href={
          `${PUBLIC_URL}/` + att.file
        }
        download
        target="_blank"
      >
         <div>
                        {att.file.includes("pdf") ? (
                          <img
                            style={{
                              width: "100%",
                              // margin: "5px",
                              // padding: "25px 40px 40px 25px",
                              height: "8rem"
                            }}
                            className="gimg"
                            src={
                              "https://www.computerhope.com/jargon/p/acrobatpdf.jpg"
                            }
                            alt=""
                          />
                        ) : 
                         att.file.includes('.xls') ?
                        (
                          <img
                            style={{
                              width: "100%",
                              // margin: "4px",
                              // padding: "25px 3px 24px 3px",
                              height: "8rem"
                            }}
                            className="gimg"
                            src={
                              "http://icons.iconarchive.com/icons/dakirby309/simply-styled/256/Microsoft-Excel-2010-icon.png"
                            }
                            alt=""
                          />
                        ): 
                        att.file.includes('.xlxs') ?
                        (
                          <img
                            style={{
                              width: "100%",
                              // margin: "4px",
                              // padding: "25px 3px 24px 3px",
                              height: "8rem"
                            }}
                            className="gimg"
                            src={
                              "http://icons.iconarchive.com/icons/dakirby309/simply-styled/256/Microsoft-Excel-2010-icon.png"
                            }
                            alt=""
                          />
                        ):
                        att.file.includes('.docx') ?
                        (
                          <img
                            style={{
                              width: "100%",
                              // margin: "4px",
                              // padding: "25px 3px 24px 3px",
                              height: "8rem"
                            }}
                            className="gimg"
                            src={
                              "http://icons.iconarchive.com/icons/dakirby309/simply-styled/256/Microsoft-Word-2010-icon.png"
                            }
                            alt=""
                          />
                        ):

                        (
                          
                            <img
                              style={{
                                width: "100%",
                                // margin: "4px",
                                // padding: "25px 3px 24px 3px",
                                height: "8rem"
                              }}
                              className="gimg"
                              src={
                                `${PUBLIC_URL}/` +
                                att.file
                              }
                              alt=""
                            />
                        
                        )
                        }
                      </div>
      </a>
      <input
        type="hidden"
        name="doctor-picture"
        defaultValue="heyy"
      />
  
    <div className="doc-content mt-2" >
      <div className='text-center'>
        {att.title === 'undefined' 
        ?
        'No Title'
        :
        att.title}
       
      </div>
      {/* <h4 >DATE: {att.created_at.split("T")[0]}</h4> */}
      {/* <p>
        {att.notes ||
          "there is no any description for this document to show " +
            att.notes}
      </p>
      <br /> */}
      <div className="text-center">
      <a
        className="btn btn-danger btn-sm"
        style={{ marginBottom: "10px" }}
        onClick={e => this.props.removeAttachment(e, att)}
        style={{marginTop:'2rem'}}
      >
        {" "}
        <i class="fa fa-trash" /> remove
      </a>
      </div>
    </div>
   </div>
     
  </div>
))}

</div>

   }else{
    contents = <div style={{padding:'2rem', textAlign:'center'}}>
      No document uploaded so far
    </div>
   }
    return (

          <div className="form-group-container">
                <label htmlFor="">Client Documents</label>
              <div className="content-block-main" style={{padding: '2rem'}}>
                      {contents}
                    </div>
          </div>
   
    );
  }
}

@inject("store")
@observer
class ClientDocuments extends Component {
  state = {
    documents: [],
    files: [],
    fakeAppointmentId: ""
  };

  getFakeAppointmentId = () => {
    const URL =
      PUBLIC_URL +
      API_PREFIX +
      "appointments/get/" +
      this.props.match.params.user_id +
      "/" +
      this.props.store.get_business_id();
    checkResponse(URL, "get").then(result => {
      const appointments = result.data.appointments;
      for (var i = 0; i <= appointments.length - 1; i++) {
        if (appointments[i].notes == "fakeClientAppointment") {
          this.setState({ fakeAppointmentId: appointments[i].id });
        }
      }
    });
  };

  upload = () => {
    const formData = new FormData();
    for (var i = 0; i <= this.state.files.length - 1; i++) {
      formData.append("files", this.state.files[i]);
      const config = {
        headers: {
          "content-type": "multipart/form-data"
        }
      };
    }
    const URL =
      PUBLIC_URL +
      API_PREFIX +
      "attachments/create/" +
      this.state.fakeAppointmentId;

      const title = this.state.title;
      const notes = this.state.notes;
      formData.append("title", title);
      formData.append("notes", notes);
    checkResponse(URL, "post", formData).then(r => 
      {
        if(r.data){
         window.location.reload()

        }
      }
      ).catch(e=>{console.log(e)})
  };

  removeAttachment = (e, att) => {
    e.preventDefault();
    const URL = PUBLIC_URL + API_PREFIX + "attachments/delete/" + att.id;
    Swal.fire({
      title: "Are you sure?",
      text:
        "This would not be revert back",
      type: "warning",
      icon:'warning',
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then( result => {
      if (result.value) {
       
        let documents = [...this.state.documents]
           documents =  documents.filter(doc =>
              doc.id != att.id  
            )
            this.setState({documents})
        try {
          checkResponse(URL, "delete").then(r => {
            if (r.data) {
              Swal.fire("Deleted!", "Documented deleted successfully!.", "success");
            }
          });
          
        } catch (ex) {
          Swal.fire("Oops...", "Something went wrong", "error");
        }
      }
    });


  };

  componentDidMount() {
    this.get_data();
    this.getFakeAppointmentId();
  }

  get_data = () => {
    const URL =
      PUBLIC_URL +
      API_PREFIX +
      "attachments/client/" +
      this.props.match.params.user_id;
    checkResponse(URL, "get").then(r => {
      this.setState({ documents: r.data.documents });
    });
  };

  renderTitleAndDescription() {
    if(this.state.files.length > 0){

      return (
        <div style={{background:'white'}}>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="form-group-container">
              <label>title : </label>
          <input
            type="title"
            className="form-control"
            name="title"
            defaultValue=""
            placeholder="title"
            onChange={e => this.setState({ title: e.target.value })}
            />
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="form-group-container">
              <label>description : </label>
          <input
            className="form-control"
            name="notes"
            defaultValue=""
            onChange={e => this.setState({ notes: e.target.value })}
            />
              </div>
            </div>
          </div>
       
      </div>
    );
  }
  }
  
  render() {
    return (
     <header className="header">
      <div className="text-center mt-4">
          <h2 className="primary-heading"> Client Documents</h2>
          </div>
          <div className="action-container">
            <div className="table-container" style={{padding: '3rem'}}>
              <div className="form-group-container">
                <label htmlFor="">Upload here</label>
                <FilePond
                  allowMultiple={true}
                  onupdatefiles={fileItems => {
                    // Set currently active file objects to this.state
                    this.setState({
                      files: fileItems.map(fileItem => fileItem.file)
                    });
                  }}
                />
               <div className="row">
                 <div className="col-md-12">
                 <div className="d-flex justify-content-center">
                 {this.state.files.length > 0 ? (
                <button className="btn-green" onClick={this.upload}>
                  Upload
                </button>
              ) : (
                ""
              )}
                 </div>
                 </div>

               </div>
               <div className="my-3">
               {this.renderTitleAndDescription()}
               </div>
              </div>

              <div style={{marginTop:'3rem'}}>
               <Card
                      attachments={this.state.documents}
                      removeAttachment={this.removeAttachment}
                    />
              </div>
            </div>
          </div>
      </header> 
     
    );
  }
}

export default ClientDocuments;
