import React, { Component } from "react";
import axios from "axios";
import { inject, observer } from "mobx-react";
import { orderBy } from "lodash";
import clonedeep from "lodash.clonedeep";
import Swal from "sweetalert2";
// import 'sweetalert2/src/sweetalert2.scss'
import { Card, CardText, RaisedButton, Chip } from "material-ui";
import { PUBLIC_URL, API_PREFIX } from "../../../commons/config";
import Form from "react-jsonschema-form";
import checkResponse from "../../../commons/CheckResponse";
import { css } from "@emotion/core";
import { ClipLoader } from "react-spinners";
import OtherInformation from "./other";
import { Confirm } from "semantic-ui-react";
import moment from "moment";
import CountryCodes from "../../CountryCodes";
import intl from "react-intl-universal";

const url = "https://mnty-mbl-bookinghub.com/api/contacts/edit/contact";
// const url = 'http://localhost:4000/api/contacts/edit/contact';
const override = css`
  display: beditMode;
  margin: 0 auto;
  border-color: red;
`;

function addOrReplace(array, item) {
  const i = array.findIndex(
    (_item) => _item.meta_label_id === item.meta_label_id
  );
  if (i > -1) array[i] = item;
  else array.push(item);
}

export default
@inject("store")
@observer
class extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
  }
  state = {
    user: {},
    loading: true,
    schema: [],
    user: [{ createdat: "" }],
    data: [],

    formdata: [],
    labels: [],
    open: false,
    selected_metas: [],
    selected_metas_ids: [],
  };

  componentDidMount() {
    console.log("asdfasdfdsa")
    this.getData();
  }

  getData() {
    const { user_id } = this.props.match.params;
    const URL = PUBLIC_URL + API_PREFIX + "clients/view/" + user_id;
    checkResponse(URL, "get").then((result) => {
      if (result.data) {
        if (!result.data.user) return this.props.history.push("/clients/view");
        this.setState({
          user: result.data.user,
          country_code: result.data.user.country_code,
          loading: false,
        });
        this.getOtherData(result.data.user);
      }
      if (result.response)
        return this.setState({ err: result.response.data.error_description });
    });
  }

  _setInput = (ev) => {
    const user = { ...this.state.user };
    user[ev.target.name] = ev.target.value;
    this.setState({ user });
  };

  removeUserMeta = (id) => {
    if (window.confirm("delete permanently ?!")) {
      const URL = PUBLIC_URL + API_PREFIX + "user-meta/" + id;
      checkResponse(URL, "delete").then((r) => {
        this.getData();
      });
    }
  };

  _basicInputs() {
    const createdat = moment(this.state.user.created_at).format("LL");
    if (!this.state.loading) {
      return <>{this._form()}</>;
    }
  }

  // OtherInformation components handler
  getOtherData = (user) => {
    const { user_id } = this.props.match.params;
    let formdata = [];
    const { Http, get_business_id } = this.props.store;
    const URL = PUBLIC_URL + API_PREFIX + "metadata/all/" + get_business_id();
    checkResponse(URL, "get", 1).then((result) => {
      this.insertData(result);
      this.setState({ meta_label_id: result.data.labels[0] });
    });
    user.users_meta.map((data) => {
      formdata.push({
        business_id: this.props.store.get_business_id(),
        key: data.key,
        meta_label_id: data.meta_label_id,
        meta_value: [{ id: data.meta_value_id, value: data.value }],
        user_id: user_id,
        value: data.value,
      });
    });
    this.setState({ formdata });
  };

  insertData = (data) => {
    const { labels } = data.data;
    const orderedData = orderBy(labels, ["created_at"], ["desc"]);
    this.setState({ loading: false, data: orderedData });
  };

  handleServiceChange = (e) => {
    const checkbox = e.target;
    let allAppointmentServices = [...this.state.allAppointmentServices];
    if (checkbox.checked) {
      allAppointmentServices.push({ id: e.target.value });
      this.setState({ allAppointmentServices });
    } else {
      allAppointmentServices = allAppointmentServices.filter(
        (app) => app.id !== e.target.value
      );
      this.setState({ allAppointmentServices });
    }
  };

  _setMetaInput = (element) => {
    const { user_id } = this.props.match.params;
    let formdata = [...this.state.formdata];
    console.log(formdata);
    element = element.target;

    if (element.type === "checkbox") {
      var value = element.value.split("&")[0];

      const meta_value_id = element.value.split("&")[1];
      if (!element.checked) {
        formdata = formdata.filter(
          (app) => app.meta_value[0].id != meta_value_id
        );
        return this.setState({ formdata });
      }
      if (element.checked) {
        const meta_label_id = element.id;
        const key = element.getAttribute("data-type");
        const { get_business_id } = this.props.store;
        const meta_value = [
          {
            id: meta_value_id,
            value,
          },
        ];
        formdata.push({
          meta_label_id,
          meta_value,
          key,
          user_id,
          business_id: get_business_id(),
        });
        return this.setState({ formdata });
      }
    }
    if (element.type === "select-one") {
      var options = element.options;
      var optionId = options[options.selectedIndex].id;
      const { get_business_id } = this.props.store;
      const node = element.nodeName.toLowerCase();
      let old = clonedeep(this.state.formdata);
      const key = element.getAttribute("data-type");
      const meta_label_id = element.id;
      const meta_value_id = optionId;
      const value =
        node === "select"
          ? this._getOptions(element).toString()
          : element.value;
      if (old.length === 0) {
        const meta_value = [
          {
            id: meta_value_id,
            value,
          },
        ];
        old.push({
          meta_label_id,
          meta_value,
          key,
          user_id,
          business_id: get_business_id(),
        });
      } else {
        const meta_value = [
          {
            id: meta_value_id,
            value: value,
          },
        ];
        addOrReplace(old, {
          meta_label_id,
          meta_value,
          key,
          user_id,
          business_id: get_business_id(),
        });
      }
      this.setState({ formdata: old });
    } else {
      const { get_business_id } = this.props.store;
      const node = element.nodeName.toLowerCase();
      let old = clonedeep(this.state.formdata);
      const key = element.getAttribute("data-type");
      const meta_label_id = element.id;
      const meta_value_id = "40b7df7e-5064-11e9-8647-d663bd873d93";
      const value =
        node === "select"
          ? this._getOptions(element).toString()
          : element.value;
      if (old.length === 0) {
        const meta_value = [
          {
            id: meta_value_id,
            value: value,
          },
        ];
        old.push({
          meta_label_id,
          meta_value,
          key,
          value,
          user_id,
          business_id: get_business_id(),
        });
      } else {
        const meta_value = [
          {
            id: meta_value_id,
            value: value,
          },
        ];
        addOrReplace(old, {
          meta_label_id,
          meta_value,
          key,
          value,
          user_id,
          business_id: get_business_id(),
        });
      }
      this.setState({ formdata: old });
    }
  };

  _getOptions(element) {
    return [].filter
      .call(element.options, function(o) {
        return o.selected;
      })
      .map(function(o) {
        return o.value;
      });
  }
  // End of OtherInformation handler

  _basicSubmit = (e) => {
    e.preventDefault();
    const { name, email, mobile, active } = this.state.user;
    const { country_code } = this.state;
    const { user_id } = this.props.match.params;

    // For editing contact in Twoway
    const twoway_data = {
      user_email: localStorage.getItem("email"),
      contact_email: email,
      firstName: name,
      isActive: active === 1 ? true : false,
      mobile: country_code + mobile,
      country: "",
      onpoint_id: user_id,
    };
    const URL = PUBLIC_URL + API_PREFIX + "users/edit/" + user_id;
    // return console.log({ name, email, mobile, active,country_code })
    checkResponse(URL, "put", { name, email, mobile, active, country_code })
      .then(async (r) => {
        this._submitData();
        // await axios.put(url, twoway_data)
        // this.componentDidMount();
        Swal.fire(
          "Success",
          "Basic details updated successfully!",
          "success"
        ).then((result) => {
          if (result.value) {
            this.props.history.push("/clients/view");
          }
        });
      })
      .catch(console.error);
  };

  _submitData() {
    const { user_id } = this.props.match.params;
    // this.props._basicSubmit()
    const { formdata, user } = this.state;

    const URL = PUBLIC_URL + API_PREFIX + "user-meta/create";
    const URL2 = PUBLIC_URL + API_PREFIX + "user-meta/deleteall/" + user_id;
    checkResponse(URL2, "delete").then();
    checkResponse(URL, "post", formdata).then(async (result) => {
      if (result.response)
        return this.setState({ err: result.response.data.error_description });
    });
  }

  _form() {
    return (
      <div>
        <div className="my-3">
          <h3 className="secondary-heading">
            {intl.get("BASIC_DETAILS") || "Basic Details"}
          </h3>
        </div>
        <form>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="form-group-container">
                <label htmlFor="name" className="label-control">
                  {intl.get("FULL_NAME")}
                </label>
                <input
                  onChange={(ev) => this._setInput(ev)}
                  className="form-control"
                  name="name"
                  value={this.state.user.name}
                  placeholder="Full Name"
                  style={{ textAlign: "center" }}
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="form-group-container">
                <label htmlFor="email" className="label-control">
                  {intl.get("EMAIL")}
                </label>
                <input
                  onChange={(ev) => this._setInput(ev)}
                  className="form-control"
                  name="email"
                  value={this.state.user.email}
                  placeholder="E-mail address"
                  style={{ textAlign: "center" }}
                />
              </div>
            </div>

            <div className="col-md-6 col-sm-12">
              <label htmlFor="mobile" className="custom-label-control">
                {intl.get("CODE_NUMBER") || "Code"}
              </label>
              <CountryCodes
                cssClass="country-code-1"
                value={this.state.country_code}
                onChange={(country_code) => this.setState({ country_code })}
                // countryCode={country_code=>this.setState({country_code})}
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="form-group-container">
                <label htmlFor="">
                  {intl.get("PHONE_NUMBER" || "Phone Number")}
                </label>
                <input
                  onChange={(ev) => this._setInput(ev)}
                  className="form-control"
                  name="mobile"
                  style={{ width: "65%" }}
                  value={this.state.user.mobile}
                  placeholder="Mobile number"
                  style={{ textAlign: "center" }}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    );
    // }
  }

  _basic() {
    return (
      <div className="md20">
        {/* {this._folderHeader("BASIC DETAILS")} */}
        <div className="folder-content md20">{this._basicInputs()}</div>
      </div>
    );
  }

  _meta_values(value) {
    return (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {value.map((val, index) => (
          <Chip style={{ margin: 4 }}>{val}</Chip>
        ))}
      </div>
    );
  }

  _basicHistory() {
    return (
      <>
        <h3 className="secondary-heading">
          {intl.get("CLIENT_HISTORY") || "Client History"}
        </h3>

        {this._appointments()}
      </>
    );
  }

  _appointments() {
    return (
      <>
        <div className="row">
          <div className="col-md-4 col-sm-12 mb-3">
            <button
              className="btn-default-ghost"
              onClick={this._onAppClick}
              style={{ width: "100%" }}
            >
              {intl.get("APPOINTMENTS") || "Appointments"}
            </button>
          </div>
          <div className="col-md-4 col-sm-12 mb-3">
            <button
              className="btn-default-ghost"
              onClick={this._onInvClick}
              style={{ width: "100%" }}
            >
              {intl.get("INVOICES") || "INVOICES"}
            </button>
          </div>
          <div className="col-md-4 col-sm-12">
            <button
              className="btn-default-ghost"
              onClick={this._onDocClick}
              style={{ width: "100%" }}
            >
              {intl.get("ATTACHMENTS") || "ATTACHMENTS"}
            </button>
          </div>
        </div>
        {/* <div className="row my-3">
 
  <div className="col-md-4 col-sm-12">
  <button className="btn-default-ghost"  onClick={this._onCreClick} style={{width:'100%'}}>
  {intl.get('CREATE_APPOINTMENT') || 'CREATE APPOINTMENT'}
    </button>
  </div>
</div> */}
      </>
    );
  }

  _onAppClick = (_) =>
    this.props.history.push(
      "/clients/view/appointments/" + this.props.match.params.user_id
    );
  _onDocClick = (_) =>
    this.props.history.push(
      "/clients/view/documents/" + this.props.match.params.user_id
    );
  _onInvClick = (_) =>
    this.props.history.push(
      "/clients/view/invoices/" + this.props.match.params.user_id
    );
  _onCreClick = (_) => this.props.history.push("/appointments");

  render() {
    return (
      <>
        <div className="bootstrap-wrapper">
          {this.state.loading ? (
            <div className="sweet-loading" style={{ textAlign: "center" }}>
              <div
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "25px",
                }}
              >
                LOADING...
              </div>
              <ClipLoader
                css={override}
                sizeUnit={"px"}
                size={150}
                color={"#123abc"}
                loading={this.state.loading}
              />
            </div>
          ) : (
            <header className="header">
              <div className="text-center mt-4">
                <h2 className="primary-heading">
                  {" "}
                  {intl.get("EDIT_CLIENT") || "Edit Client"}
                </h2>
              </div>
              <div className="action-container" style={{ width: "70%" }}>
                <div
                  className="table-container"
                  style={{ padding: "1rem 4rem" }}
                >
                  {this._basic()}
                  {this._basicHistory()}
                  <div style={{ marginTop: "3rem", marginBottom: ".5rem" }}>
                    <h3 className="secondary-heading">
                      {intl.get("CLIENT_INFORMATION") || "Client Information"}
                    </h3>
                  </div>

                  <OtherInformation
                    history={this.props.history}
                    changeOtherInformation={this._setMetaInput}
                    loading={this.state.loading}
                    data={this.state.data}
                    formdata={this.state.formdata}
                    allAppointmentServices={this.state.allAppointmentServices}
                    test={this.state.test}
                    _folderHeader={this._folderHeader}
                    user_id={this.props.match.params.user_id}
                    user_selected_metas={this.state.user.users_meta}
                  />
                  <div className="row my-3">
                    <div className="col-md-3 m-auto">
                      <div className="d-flex">
                        <button
                          className="btn-green"
                          type="submit"
                          onClick={this._basicSubmit}
                        >
                          {intl.get("SAVE") || "Save"}
                        </button>
                        <button
                          className="btn-ghost ml-2"
                          onClick={(_) => this.props.history.goBack()}
                        >
                          {intl.get("CANCEL") || "Cancel"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </header>
          )}
        </div>
        <Confirm
          open={this.state.open}
          cancelButton="go back"
          confirmButton="stay on the same page"
          onCancel={() => this.props.history.push("/clients/view")}
          onConfirm={() => this.setState({ open: false })}
          content="basic details updated successfully"
        />
      </>
    );
  }
}
