import React, { Component } from "react";
import MyAppointment from "../../components/myAppointments";
import { inject, observer } from "mobx-react";
import { PUBLIC_URL, API_PREFIX } from "../../commons/config";
import checkResponse from "../../commons/CheckResponse";
import intl from "react-intl-universal";
import Background from '../../assets/images/calendar.jpg'
import Navigation from '../../components/Navigation'
import Excel from './../../commons/Excel';
import SideMenu from '../../components/sideMenu'

var backgroundStyle = {
  // backgroundImage: `url(${Background})`,
  height: '100%',
  // backgroundPosition: 'center',
  // backgroundRepeat: 'no-repeat',
  // backgroundSize: 'cover'
  backgroundColor: '#3e87c6'
};

export default
@inject("store")
@observer
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      general: [],
      patients: 0,
      biz_phone: '',
      staff: 0,
      loading: true,
      appointments: [],
      due_appointments: [],
      messages: [],
      windowWidth: window.innerWidth,

    };
    this.handleResize = this.handleResize.bind(this);

  }

  handleResize() {
    this.setState({ windowWidth: window.innerWidth });
  }


  componentDidMount() {
    document.getElementsByTagName('html')[0].className = ''
    // document.getElementsByTagName( 'html' )[0].classList.remove('dashboardHtml')
    document.getElementsByTagName('html')[0].classList.add('rootHtml')
    this.getSettingsAndValues();
    this._getData();
    this._getToDaysAppointments();
    this._getBusiness();
    this._getCurrency();
    this.filter_appointments();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  filter_appointments() {
    const appointments = [...this.state.due_appointments]
  }

  _getCurrency() {
    const URL =
      PUBLIC_URL +
      API_PREFIX +
      "currencies/getall/" +
      this.props.store.get_business_id();
    checkResponse(URL, "get").then(r => {
      const currency = r.data.currencies[0];
      if (currency) {
        return localStorage.setItem("currency", currency.currency_value);
      } else {
        localStorage.setItem("currency", "$");
      }
    });
  }
  _getBusiness() {

    const { get_business_id, set_business_type } = this.props.store;
    const URL = PUBLIC_URL + API_PREFIX + "business/" + get_business_id();
    checkResponse(URL, "get")
      .then(r => {
        const biz_phone = r.data.business.business_phone + r.data.business.mobile;
        const business_type = r.data.business.type_name;
        set_business_type(business_type);
        this.setState({ biz_phone })
      })
      .catch(e => {
        console.log(e.message);
        localStorage.removeItem('API_TOKEN')
        localStorage.removeItem('BUSINESS_ID')
        localStorage.removeItem('name')
        localStorage.removeItem('BUSINESS_LOGO')
        localStorage.removeItem('BUSINESS_TYPE')
        localStorage.removeItem('lang')
        localStorage.removeItem('x-auth-token')
        localStorage.removeItem('BUSINESS_NAME')
        localStorage.removeItem('userRoles')
        localStorage.removeItem('REFRESH_TOKEN')
        localStorage.removeItem('ID')
        this.props.history.push('/signin')
      });
  }
  _getData() {
    const { Http, get_business_id } = this.props.store;
    const URL = PUBLIC_URL + API_PREFIX + "general/" + get_business_id();
    Http()
      .get(URL)
      .then(this.insertData.bind(this));
  }
  _getToDaysAppointments() {
    const { get_business_id } = this.props.store;
    const URL =
      PUBLIC_URL + API_PREFIX + "appointments/getToday/" + get_business_id();
    checkResponse(URL, "get")
      .then(result => {
        let all = result.data.appointments;
        let amApps = [];
        let pmApps = [];
        all.map(app => {
          if (
            app.time_start.includes("AM") ||
            app.time_start.split(":")[0] == "12"
          ) {
            amApps.push(app);
          }
          if (
            app.time_start.includes("PM") &&
            app.time_start.split(":")[0] != "12"
          ) {
            pmApps.push(app);
          }
        });
        const appointments = [...amApps, ...pmApps];
        this.setState({ appointments });
      })
      .catch(e => {
        return e.message;
      });
  }

  getSettingsAndValues = () => {
    const URL =
      PUBLIC_URL +
      API_PREFIX +
      "settings/getSettingsAndValues/" +
      this.props.store.get_business_id();
    checkResponse(URL, "get").then(r => {
      //   const settingsr = r.data.settings;
      if (r.data) {

        this.setState({ messages: r.data.settings });
      }
    });
  };

  insertData(data) {
    const { appointments, patients, staff, due_appointments } = data.data;

    this.setState({
      general: appointments,
      loading: false,
      patients,
      staff,
      due_appointments
    });
  }

  cards() {
    let done = []
    this.state.appointments.map(app => {
      if (app.status === 'Done') {
        done.push(app.id)
      }
    })

    return (
      <div className="cardsWrapper">
        <div className="oneCard clients" onClick={e => this.props.history.push("/clients/view")}>
          <div className='cardTitle'>{intl.get("TOTAL_CLIENTS") || "Total Clients"}</div>
          <div className='cardIcon'><img src={require('../../assets/svgs/clients.svg')} /></div>
          <div className='cardNumber'>{this.state.patients}</div>
        </div>
        <div className="oneCard appointments" onClick={e => this.props.history.push("/appointments")}>
          <div className='cardTitle'>{intl.get("TODAYS_APPOINTMENTS") || "Today's Appointments"}</div>
          <div className='cardIcon'><img src={require('../../assets/svgs/todaysAppointment.svg')} /></div>
          <div className='cardNumber'>{done.length + "/" + this.state.appointments.length}</div>
        </div>
        <div className="oneCard staff" onClick={e => this.props.history.push("/users/view")}>
          <div className='cardTitle'>{intl.get("TOTAL_STAFF") || "Total Staff"}</div>
          <div className='cardIcon'><img src={require('../../assets/svgs/staff.svg')} /></div>
          <div className='cardNumber'>{this.state.staff}</div>
        </div>
      </div>
    );
  }

  render() {
    const { biz_phone } = this.state;

    var sectionStyle = {
      // backgroundImage: `url(${Background})`,
      // backgroundPosition: 'center',
      // backgroundRepeat: 'no-repeat',
      // backgroundSize: 'cover',
      backgroundColor: 'white',
      color: 'black',
      height: 'max-content',
      minHeight: '100vh'
    };

    let appointments = [...this.state.appointments]
    appointments.forEach(function (item) {
      Object.assign(item, { biz_phone })
    })
    return (

      <div className="blackTheme relative " style={sectionStyle}>
        <Navigation />
        <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center'}}>
          {
            this.state.windowWidth > 854 ? (
              <div style={{ width: '13vw', height:'100vh', background: '#3e87c6' }}>
                <SideMenu  ></SideMenu>
              </div>
            ) : null
          }
          <div className="row" style={{ flex: 1 }}>
            <div className="col-lg-10 col-md-12 col-sm-12">
              <div className="d-flex justify-content-end mb-2">

              </div>
              <MyAppointment
                general={this.state.general}
                appointments={appointments}
                history={this.props.history}
                get_data={this._getAppointments}
              />
            </div>
            <div className="col-md-2 d-none d-lg-block">
              <div className='appointmentsDashboard'>
                {this.cards()}
              </div>
            </div>
          </div>

        </div>


      </div>
    );
  }
}
