import Fuse from 'fuse.js';

export default function fuzzySearch(options) {
    const fuse = new Fuse(options, {
        keys: ['name'],
        threshold: 0.3,
    });

    return (value) => {
        if (!value.length) {
            return options;
        }
        return formateArray(fuse.search(value))
     
    };
}

function formateArray(arr){
 
  let formatted = []
  if(!arr.length) return []
  arr.forEach(function(item){
    formatted.push(item.item)
  })
  return formatted;
}