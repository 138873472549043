import React, { useState, useEffect } from "react"
// import {browserHistory} from 'react-router'
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom"
import history from './history'
import intl from 'react-intl-universal'

// NAVIGATION
// import Navigation from './components/Navigation'

// Cross-storage connection Route
import CrossStorage from '../pages/CrossStorage'

import Store from '../stores/store';
import { Provider } from 'mobx-react';

// import Nav from '../pages/layouts/nav';
import Navigation from '../components/Navigation'
import SideMenu from '../components/sideMenu'

import Basic from '../pages/layouts/basic';

import Root from '../pages/views/root';
import Dashboard from '../pages/views/dashboard';

// auth routes
import Signin from '../pages/auth/signin';
import Signup from '../pages/auth/signup';
import ForgotPassword from '../pages/auth/ForgotPassword';
import ResetPassword from '../pages/auth/ResetPassword';


// user views
import UsersIndex from '../pages/views/users/index';
import UsersView from '../pages/views/users/view';
import UsersCreate from '../pages/views/users/create';
import UsersOther from '../pages/views/users/other';

// Client view
import ClientsIndex from '../pages/views/clients/index';
import ClientsCreate from '../pages/views/clients/create';
import ClientsView from '../pages/views/clients/view';
import ClientsOther from '../pages/views/clients/other';
import ClientsAppointments from '../pages/views/clients/ClientAppointments';
import ClientDocuments from '../pages/views/clients/ClientDocuments';


// metadata view
import MetaIndex from '../pages/views/metadata/index';
import MetaCreate from '../pages/views/metadata/create';

// Services view
import ServicesIndex from '../pages/views/services/index';
import ServicesCreate from '../pages/views/services/create';
import ServicesView from '../pages/views/services/view';


// Currency view
import CurrencyIndex from '../pages/views/currencies/index';
import CurrencyCreate from '../pages/views/currencies/create';
import CurrencyView from '../pages/views/currencies/view';


// Units view
import UnitIndex from '../pages/views/units/index';
import UnitCreate from '../pages/views/units/create';
import UnitView from '../pages/views/units/view';


//specialities view
import SpecialityIndex from '../pages/views/specialities/index';
import SpecialityCreate from '../pages/views/specialities/create';
import SpecialityView from '../pages/views/specialities/view';

// Appointment view
import AppIndex from '../pages/views/appointments/index';
import AppointmentEdit from "../pages/views/appointments/AppointmentEdit";
import PendingAppointments from "../pages/views/appointments/PendingAppointments";


// Invoices view
import InvoiceList from '../pages/views/Invoices/InvoiceList';
import InvoiceCreate from '../pages/views/Invoices/CreateInvoice'
import InvoiceDetails from '../pages/views/Invoices/InvoiceDetails'
import InvoicePrint from "../pages/views/Invoices/InvoicePrint";
import AllInvoices from "../pages/views/Invoices/AllInvoices";
import DailySales from "../pages/views/Invoices/DailySales";

// Invoices view
import QuotationList from '../pages/views/Quotations/QuotationList';
import QuotationCreate from '../pages/views/Quotations/CreateQuotation'
import QuotationDetails from '../pages/views/Quotations/QuotationDetails'
import QuotationPrint from "../pages/views/Quotations/QuotationPrint";
import AllQuotations from "../pages/views/Quotations/AllQuotations";
import Quotations from "../pages/views/Quotations/Quotations";



//settings

import GeneralSettings from "../pages/views/settings/GeneralSettings"
import ActivateAccount from "../pages/auth/ActivateAccount";


//Profile
import Profile from "../pages/views/profile/Profile"
import PrivacyAndPolicy from "../pages/views/PrivacyAndPolicy";
import MyMessages from "../pages/views/profile/MyMessages";

//Expenses
import Expenses from "../pages/views/expenses/Expenses";
import AddExpense from "../pages/views/expenses/AddExpense";
import EditExpense from "../pages/views/expenses/EditExpense";

//report
import Report from "../pages/views/Report";
import Users from "../pages/admin/users";
import RightMenu from "../components/rightMenu"

// import RightMenu from '../components/rightMenu' 


// import DailySalesPrint from "../pages/views/finance/DailySalesPrint";


const NotAllowed = () => {
    return <h1>not authorized</h1>
}

const addStyling = (rest) => {
    if (rest.path.includes("appointments")) {
        if (document.getElementById('newStyles')) {
            document.getElementById('newStyles').remove()
        }
        // if (!document.getElementById('stylingForCalendar')){
        //     const styleLink1 = document.createElement("link");
        //     styleLink1.rel = "stylesheet";
        //     styleLink1.id = 'stylingForCalendar';
        //     styleLink1.href = window.location.origin + '/css/fullcalendar.css';
        //     document.head.appendChild(styleLink1);
        // }
        // const styleLink2 = document.createElement("link");
        // styleLink2.rel = "stylesheet";
        // styleLink2.id = 'style';
        // styleLink2.href = window.location.origin + '/css/style.css';
        // document.head.appendChild(styleLink2);
    } else {
        // alert("add");
        if (!document.getElementById('newStyles')) {
            const styleLink1 = document.createElement("link");
            styleLink1.rel = "stylesheet";
            styleLink1.id = 'newStyles';
            styleLink1.href = window.location.origin + '/css/newStyles.css';
            document.head.appendChild(styleLink1);
        }
        // if (document.getElementById('stylingForCalendar')){
        //     document.getElementById('stylingForCalendar').remove()
        // }
        // if(rest.path === '/'){

        // } 
    }
}

const PrivateRoute = ({ component: Component, title, basic, roles, ...rest }) => {
    addStyling(rest)
    if (basic) {
        return (
            <Route {...rest} render={(props) => (
                Store.is_logged_in() === true
                    ? <Basic title={title} store={Store} {...props}><Component {...props} /></Basic>
                    : <Redirect to='/signin' />
            )} />
        )
    }
    const userRoles = Store.userRoles

    let pageTitle = " | " + title
    if (pageTitle.includes(Store.get_business_name())) {
        pageTitle = ""
    }
    let title_prefix = Store.get_business_name() || ''
    document.title = title_prefix + pageTitle;

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup function to remove the event listener
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Route {...rest} render={(props) => (
            Store.is_logged_in() === true
                ? (<div>
                    <Navigation title={title} store={Store} {...props} />
                    <div style={{ display: "flex", backgroundColor: '#3e87c6' }} >
                        {
                            windowWidth > 854 ? (
                                <div style={{ width: '13vw',  background: '#3e87c6' }}>
                                    <SideMenu {...props} ></SideMenu>
                                </div>
                            ) : null
                        }
                        <div style={{ flex: 1 }}>
                            {
                                (userRoles.includes(roles) || roles.includes(userRoles)) ?
                                    <Component {...props} />
                                    :
                                    <NotAllowed />
                            }
                        </div>
                    </div>
                </div>
                )
                : <Redirect to='/signin' />
        )} />
    )
}

const PublicRoute = ({ component: Component, ...rest }) => {
    addStyling(rest)
    const is_logged_in = Store.is_logged_in();
    const path = rest.path;
    if ((path === "/signin" || path === "/register") && is_logged_in) {
        return <Redirect to='/' />
    }
    return (
        <Route {...rest} render={(props) => (
            <Component {...props} />
        )} />
    )
}

function NoMatch({ location }) {
    return (
        <div>
            <h3>
                No match for <code>{location.pathname}</code>
            </h3>
        </div>
    );
}

const AppRouter = () => (

    <Provider store={Store}>
        <Router history={history}>
            <Switch>
                <PrivateRoute exact path='/' component={Dashboard} title={intl.get('MY_BUSINESSES') || 'My Businesses'} basic roles="admin,staff" />
                <PrivateRoute exact path='/dashboard' component={Root} title={Store.get_business_name()} basic roles="admin,staff" />
                <PrivateRoute exact path='/users/view' component={UsersIndex} title={intl.get("STAFF")} roles="admin,staff" />
                <PrivateRoute exact path='/users/view/:user_id' component={UsersView} title={intl.get('EDIT_STAFF') || 'Edit STAFF'} roles="admin" />
                <PrivateRoute exact path='/users/view/other/:user_id' component={UsersOther} title={intl.get('EDIT_STAFF') || 'Edit STAFF'} roles="admin" />
                <PrivateRoute exact path='/users/create' component={UsersCreate} title={intl.get('ADD_STAFF') || 'Add STAFF'} roles="admin,staff" />


                <PrivateRoute exact path='/clients/view' component={ClientsIndex} title={intl.get('CLIENTS') || 'Clients'} roles="admin,staff" />
                <PrivateRoute exact path='/clients/create' component={ClientsCreate} title={intl.get('ADD_CLIENT') || 'Add Client'} roles="admin,staff" />
                <PrivateRoute exact path='/clients/view/appointments/:client_id' component={ClientsAppointments} title={intl.get('VIEW_APPOINTMENT') || 'View Appointment'} roles="admin,staff" />
                <PrivateRoute exact path='/clients/view/:user_id' component={ClientsView} title={intl.get('EDIT CLIENT') || "Edit Client"} roles="admin,staff" />
                <PrivateRoute exact path='/clients/view/other/:user_id' component={ClientsOther} title={intl.get('EDIT CLIENT') || "Edit Client"} roles="admin,staff" />
                <PrivateRoute exact path='/clients/view/documents/:user_id' component={ClientDocuments} title={intl.get('CLIENT_DOCUMENTS') || 'Client Documents'} roles="admin,staff" />


                <PrivateRoute exact path='/metadata/view' component={MetaIndex} title={intl.get("META_DATA") || "Metadata"} roles="admin,staff" />
                <PrivateRoute exact path='/metadata/create' component={MetaCreate} title={intl.get("CREATE_METADATA") || "Create new metadata"} roles="admin,staff" />


                <PrivateRoute exact path='/services/view' component={ServicesIndex} title={intl.get("SERVICES") || 'Services'} roles="admin" />
                <PrivateRoute exact path='/services/create' component={ServicesCreate} title={intl.get('ADD_SERVICE') || "Add Service"} roles="admin" />
                <PrivateRoute exact path='/services/view/:service_id' component={ServicesView} title={intl.get('View Service') || "View Service"} roles="admin" />


                <PrivateRoute exact path='/currencies/view' component={CurrencyIndex} title={intl.get("VIEW_CURRENCY") || "View Currency"} roles="admin" />
                <PrivateRoute exact path='/currencies/create' component={CurrencyCreate} title={intl.get('ADD_CURRENCY') || "Add Currency"} roles="admin" />
                <PrivateRoute exact path='/currencies/view/:currency_id' component={CurrencyView} title="View Currency" roles="admin" />


                <PrivateRoute exact path='/units/view' component={UnitIndex} title={intl.get('VIEW_UNITS') || "View Units"} roles="admin" />
                <PrivateRoute exact path='/units/create' component={UnitCreate} title={intl.get('ADD_UNITS') || "Add Units"} roles="admin" />
                <PrivateRoute exact path='/units/view/:unit_id' component={UnitView} title={intl.get('ADD_UNITS') || "Add Units"} roles="admin" />


                <PrivateRoute exact path='/appointments' component={AppIndex} title={intl.get('APPOINTMENTS') || 'Appointments'} roles="admin,staff" />
                <PrivateRoute exact path='/appointments/:appointment_id' component={AppointmentEdit} title={intl.get("VIEW_APPOINTMENT") || "View Appointment"} roles="admin,staff" />
                <PrivateRoute exact path='/pendingAppointments' component={PendingAppointments} title={intl.get('WAITING_LIST') || "Waiting list"} roles="admin,staff" />
                <PrivateRoute exact path='/clients/view/invoices/:user_id' component={InvoiceList} title={intl.get("CLIENT_INVOICES") || "Client Invoices"} roles="admin,staff" />


                <PrivateRoute exact path='/invoices/create' component={InvoiceCreate} title={intl.get('CREATE_NEW_INVOICE') || "Create New Invoice"} roles="admin,staff" />
                <PrivateRoute exact path='/view/invoices/print/:invoice_id' component={InvoicePrint} title={intl.get('VIEW_INVOICE') || 'View Invoice'} roles="admin,staff" />
                <PrivateRoute exact path='/view/invoices/edit/:invoice_id' component={InvoiceDetails} title={intl.get('VIEW_INVOICE') || 'View Invoice'} roles="admin,staff" />
                <PrivateRoute exact path='/finance/invoices' component={AllInvoices} title={intl.get('ALL_INVOICES') || "All Invoices"} roles="admin,staff" />

                <PrivateRoute exact path='/quotations/create' component={QuotationCreate} title={intl.get('CREATE_NEW_INVOICE') || "Create New Invoice"} roles="admin,staff" />
                <PrivateRoute exact path='/view/quotations/print/:quotation_id' component={QuotationPrint} title={intl.get('VIEW_INVOICE') || 'View Invoice'} roles="admin,staff" />
                <PrivateRoute exact path='/view/quotations/edit/:quotation_id' component={QuotationDetails} title={intl.get('VIEW_INVOICE') || 'View Invoice'} roles="admin,staff" />
                <PrivateRoute exact path='/finance/quotations' component={AllQuotations} title={intl.get('ALL_INVOICES') || "All Invoices"} roles="admin,staff" />
                <PrivateRoute exact path='/quotations/all' component={Quotations} title={intl.get('DAILY_SALES') || "Daily Sales"} roles="admin,staff" />

                <PrivateRoute exact path='/finance/report' component={Report} title={'report'} roles='' />
                <PrivateRoute exact path='/settings/general' component={GeneralSettings} title={intl.get('GENERAL_SETTINGS') || "General Settings"} roles="admin,staff" />
                <PrivateRoute exact path='/sales/all' component={DailySales} title={intl.get('DAILY_SALES') || "Daily Sales"} roles="admin,staff" />



                <PrivateRoute exact path='/expenses/view' component={Expenses} title={intl.get("EXPENSES") || 'Expenses'} roles="" />
                <PrivateRoute exact path='/expenses/add' component={AddExpense} title={intl.get("ADD") || 'Add'} roles="" />
                <PrivateRoute exact path='/expenses/edit/:expense_id' component={EditExpense} title={intl.get("ADD") || 'Add'} roles="" />



                <PrivateRoute exact path='/specialities/view' component={SpecialityIndex} title={intl.get('VIEW_SPECIALITY') || "View Specialities"} roles="admin" />                <PrivateRoute exact path='/specialities/create' component={SpecialityCreate} title={intl.get("ADD_SPECIALITY") || "Add Speciality"} roles="admin" />
                <PrivateRoute exact path='/specialities/view/:speciality_id' component={SpecialityView} title={intl.get("ADD_SPECIALITY") || "Add Speciality"} roles="admin" />
                <PublicRoute exact path="/signin" component={Signin} />
                <PublicRoute exact path="/register" component={Signup} />
                <PublicRoute exact path="/forgot" component={ForgotPassword} />
                <PublicRoute exact path="/resetPassword/:resetToken" component={ResetPassword} />
                <PublicRoute exact path="/activateAccount/:resetToken" component={ActivateAccount} />
                <PublicRoute exact path="/privacy_and_policy" component={PrivacyAndPolicy} />
                <PrivateRoute basic exact path="/profile" component={Profile} title={intl.get("MY_ACCOUNT") || 'My Account'} roles='admin' />
                <PrivateRoute basic exact path="/mymessages" component={MyMessages} title={intl.get("MY_MESSAGES") || 'My messages'} roles='admin' />

                {/* admimn routes */}
                <PublicRoute basic exact path='/admin/users' component={Users} title={'admin'} roles='' />
                <Route path="/connect" component={CrossStorage} />


                <Route exact path="*" component={NoMatch} />
            </Switch>
        </Router>
    </Provider>
);

export default AppRouter;