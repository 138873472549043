import React, { Component } from "react";
import Swal from 'sweetalert2'
import checkResponse from "../../../commons/CheckResponse";
import { PUBLIC_URL, API_PREFIX } from "../../../commons/config";
import { inject, observer } from "mobx-react";
import clonedeep from "lodash.clonedeep";

import intl from "react-intl-universal";


let timeArray = [];
for (var i = 1; i <= 24; i++) {
  timeArray.push(i);
}


export default
@inject("store")
@observer
class GeneralSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      settings: [],
      form: {},
      data: [],
      settingValues: [],
      open: false
    };
    this.settingIDs = [];
  }

  componentDidMount() {
    this.getSettingsAndValues();
  }

  getSettingsAndValues = () => {
    const URL =
      PUBLIC_URL +
      API_PREFIX +
      "settings/getSettingsAndValues/" +
      this.props.store.get_business_id();
    checkResponse(URL, "get", 1).then(r => {
      const settings = r.data.settings;
      this.setState({ settings });
    });
  };

  resolveLabelLanguage = label => {
    if (label === "24 hours") {
      label = intl.get("24HOURS");
    }
    if (label === "2 hours") {
      label = intl.get("2HOURS") || '2 hours';
    }
    if (label === "calendar_start") {
      label = intl.get("CALENDAR_START");
    }
    if (label === "calendar_end") {
      label = intl.get("CALENDAR_END");
    }
    if (label === "time frame") {
      label = intl.get("TIME_FRAME");
    }
    if (label === "create appointment") {
      label = intl.get("CREATE_APPOINTMENT");
    }
    if (label === "cancel appointment") {
      label = intl.get("CANCEL_APPOINTMENT");
    }
    if (label === "slot duration") {
      label = intl.get("SLOT_DURATION");
    }
    return label;
  };

  saveChanges = e => {
    const { calendar_start, calendar_end } = this.state.form;
    if (calendar_end && calendar_start) {
      if (calendar_end.value * 1 <= calendar_start.value * 1) {
        return alert("calendar start should be greater than calendar end");
      }
    }
    const URL =
      PUBLIC_URL +
      API_PREFIX +
      "settings/update/" +
      this.props.store.get_business_id();

    checkResponse(URL, "put", { ...this.state.form }).then(r => {
      if (r.data) {
        Swal.fire({
          type: 'success',
          icon: 'success',
          title: 'Success',
          text: 'Settings saved successfully!'
        })
      }
      if (r.response) {
        console.log(r.response.data);
      }
    });
  };



  _inputsettings = () => (
    <div className="row">
      {this.state.settings.map((setting, index) => {
        if (setting.label === "country code") {
          return;

        }

        if (
          setting.label === "calendar_start" ||
          setting.label === "calendar_end"
        ) {
          const start =
            setting.value === null && setting.label === "calendar_start";
          const end = setting.value === null && setting.label === "calendar_end";
          let defaultValue = setting.value;
          if (start) {
            defaultValue = 6;
          }
          if (end) {
            defaultValue = 24;
          }
          return (

            <div className="col-md-6 col-sm-12">
              <div className="form-group-container" >
                <label>{this.resolveLabelLanguage(setting.label)}</label>
                <select
                  style={{ border: 'none' }}
                  className="form-control"
                  onChange={e =>
                    this.updateChanges(e, setting.label, setting.settingsId)
                  }
                  defaultValue={defaultValue}
                >
                  {timeArray.map(i => {
                    var value = i < 10 ? "0" + i + ":00" : i + ":00";
                    return <option value={i}>{value}</option>;
                  })}
                </select>
              </div>
            </div>

          );
        }

        return (

          <>
            {setting.type !== "textarea" ? (
              <div className="col-md-6 col-sm-12">
                <div className="form-group-container">
                  <label >{this.resolveLabelLanguage(setting.label)}</label>
                  <input
                    // ref={_ => (this.settingIDs[index] = setting.id)}
                    type={setting.type}
                    defaultValue={setting.value == null ? "30" : setting.value}
                    className="form-control text-center"
                    onChange={e =>
                      this.updateChanges(e, setting.label, setting.settingsId)
                    }
                  />
                </div>

              </div>

            ) : (
              <>
                <div className="col-md-12">
                  <div className="form-group-container">
                    <label >{
                      setting.label === '24 hours' ?
                        this.resolveLabelLanguage('Reminder Message before 24 hours')
                        :
                        setting.label === '2 hours' ?
                          this.resolveLabelLanguage("Reminder Message before 2 hours")
                          :
                          this.resolveLabelLanguage(setting.label)
                    }</label>
                    <textarea
                      // ref={_ => (this.settingIDs[index] = setting.id)}
                      style={{ border: 'none', overflow: 'hidden', textAlign: 'left' }}
                      type={setting.type}
                      defaultValue={setting.value}
                      className="custom-text-area"

                      placeholder={
                        setting.label === '24 hours' ?
                          intl.get("24_HOURS") || `Type your message as per this example (Dear (client_name your appointment has been set at (time_start) to (time_end) with the doctor (staff_name) on the day (start_date))`
                          :
                          setting.label === '2 hours' ?
                            intl.get("2_HOURS") || `Type your message as per this example (Dear (client_name) Please be reminded that your appointment time is after two hours from now.`
                            :
                            intl.get("TYPE_YOUR_MESSAGE") || "type your message here..."
                      }
                      onChange={e =>
                        this.updateChanges(e, setting.label, setting.settingsId)
                      }
                    />
                  </div>

                </div>

              </>
            )}
          </>


        );
      })
      }
    </div>


  )

  updateChanges = (ev, ref, id) => {
    let value = ev.target.value;
    let old = clonedeep(this.state.form);
    old[ref] = { settings_id: id, value };
    this.setState({ form: old });
  };

  dateTimeParams() {
    return (
      <div>
        <hr />
        {intl.get("DATE_TIME_PARAMS") || "date and time parameters"}
        <ul style={{ fontWeight: "400" }}>
          <li>
            <span className="keyParams">(time_start)</span>
            {intl.get("STARTING_TIME") || "starting time"}
          </li>
          <li>
            <span className="keyParams">(time_end)</span>
            {intl.get("ENDING_TIME") || "ending time"}
          </li>
          <li>
            <span className="keyParams">(start_date)</span>
            {intl.get("STARTING_DAY") || "starting day"}
          </li>
          <li>
            <span className="keyParams">(client_name)</span>{" "}
            {intl.get("CLIENT_NAME")}
          </li>
        </ul>
      </div>
    );
  }



  render() {

    return (

      <header className="header">
        <div className="mt-4 " style={{paddingLeft:30}}>
          <h2 className="primary-heading" style={{paddingBottom: 10}}> Advanced</h2>
        </div>
        <div className="action-container">
          <div className="table-container" style={{ padding: '2rem' }}>
            {this._inputsettings()}
            <div className="my-3 d-flex justify-content-center">
              <button className="whitePrimaryBtn-design" onClick={this.saveChanges}>
                {intl.get("SAVE") || "Save"}
              </button>
            </div>
          </div>
        </div>
      </header>


    );
  }
}
