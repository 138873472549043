import React, { Component } from 'react'
var Recaptcha = require('react-recaptcha')

export default class RecaptchaComponent extends Component {

    state = {
      verified: false
    }

    callback = () => {}

    verifyCallback = () => {
      this.setState({verified:true}, () => this.props.onVerify())
    }

    componentWillReceiveProps(nextprops){
      if(nextprops.onSubmit){
        this.recaptcha.reset();
      }
    }

    render() {
        return (
              <Recaptcha
                ref={e => this.recaptcha = e}
                sitekey="6LemqKkUAAAAAIY2QWbXiolgVRUYi1WqY0r9-Hgq"
                render="explicit"
                verifyCallback={this.verifyCallback}
                onloadCallback={this.callback}
                elementID='recaptchaValidation'
              />
        );
    }
}