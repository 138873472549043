import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Form from 'react-jsonschema-form';
import { Card, CardText } from 'material-ui';
import { PUBLIC_URL, API_PREFIX } from '../../../commons/config';
import checkResponse from '../../../commons/CheckResponse';
import intl from 'react-intl-universal';
import Swal from 'sweetalert2'



export default @inject('store') @observer class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [], loading: false,
            name:''
        }
    }

    submit = (e) => {
        e.preventDefault()
        const { name } = this.state;
        const { Http, get_business_id } = this.props.store;
        const URL = PUBLIC_URL + API_PREFIX + "Specialities/create";

        Http().post(URL, { name, business_id: get_business_id()})
        .then(result => {
            if("error" in result.data) return this.setState({ err: result.data.error_description });
            if(result.data) return this.props.history.push('/Specialities/view');
        }).catch(err=> Swal.fire("Oops...", err.response.data.error|| "Unable to create speciality", "error"));
    }

    handleChange = e =>{
        this.setState({[e.target.name]:e.target.value})
    }
    form  = () => {
        // console.log(this.state.units.map(c=>console.log(c)))
        return (
            <div>
                <form onSubmit={this.submit}> 
                    <div className='form-group-container'>
                    <label>{intl.get('SPECIALITY') || 'Speciality'}</label>
                    <input 
                    type='text' 
                    required 
                    className='form-control text-center' 
                    name='name' 
                    onChange={this.handleChange} 
                    value={this.state.name}
                    placeholder="Speciality"
                    />
                    </div>
                    <div className="my-3">
                        <div className="d-flex justify-content-center mt-3">
                            <button type='submit' className="btn-ghost mr-2">{intl.get('SUBMIT') || "Submit"}</button>
                            <button className="btn-ghost" onClick={()=>this.props.history.push('/specialities/view')}>{intl.get('CANCEL') || "Cancel"}</button>
                        </div>
                    </div>
                    {/* <div>
                        <button type='submit' className='btn btn-primary'>{intl.get('SUBMIT') || "Submit"}</button>
                    </div> */}
                    </form>
                    </div>
        )}
    render() {
        return(
            <header className="header">
                  <div className="text-center mt-4">
          <h2 className="primary-heading"> {intl.get('ADD_SPECIALITY') || 'Add Speciality'}</h2>
          </div>
          <div className="action-container width-40" >
              <div className="table-container" style={{padding:'3rem'}}>
              {this.form()}
              </div>
          </div>
            </header>
           
        )
    }
}