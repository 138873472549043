import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import Form from "react-jsonschema-form";
import { Card, CardText } from "material-ui";
import { PUBLIC_URL, API_PREFIX } from "../../../commons/config";
import checkResponse from "../../../commons/CheckResponse";
import intl from 'react-intl-universal';

const schema = {
  title: "Add new unit",
  type: "object",
  required: ["per"],
  properties: {
    per: { title: "Per", type: "string", defaultValue: "" }
  }
};

export default
@inject("store")
@observer
class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false
    };
  }

  handleChange = e=>{
      this.setState({[e.target.name]:e.target.value})
  }

  form = () => {
    
    return (
    
        <form onSubmit={this.submit}>
          <div className="form-group-container">
            <label>{intl.get("TITLE") || "Title"}</label>
            <input
              placeholder='Type your title'
              className="form-control text-center"
              name="per"
              onChange={this.handleChange}
              value={this.state.per}
              required
            />
          </div>
          <div className='d-flex justify-content-center my-3'>
              <button className='btn-green mr-2' type='submit'>{intl.get("SUBMIT") || 'Submit'}</button>
              <button className='btn-ghost'
              onClick={()=>this.props.history.push('/units/view')}>{intl.get("CANCEL") || 'Cancel'}</button>
          </div>
        </form>
    
    );
  };

  submit =e=> {
      e.preventDefault()
    const { per } = this.state;
    if(per === '') return;
    const { Http, get_business_id } = this.props.store;
    const URL = PUBLIC_URL + API_PREFIX + "units/create";
    checkResponse(URL, "post", { per, business_id: get_business_id() }).then(
      result => {
        if (result.data) return this.props.history.push("/units/view");
      }
    );
  }

  render() {
    return (
      <header className="header">
        <div className="text-center mt-4">
        <h2 className="primary-heading"> {intl.get('ADD_UNIT') || 'Add Unit'}</h2>
       </div>
       <div className="action-container width-60" >
         <div className="table-container padding-2" >
         {this.form()}
         </div>
       </div>
      </header>
   
    );
  }
}
