import React, { Component } from "react";
import Swal from 'sweetalert2'
// import 'sweetalert2/src/sweetalert2.scss'
import { PUBLIC_URL, API_PREFIX } from "../../../commons/config";
import checkResponse from "../../../commons/CheckResponse";
import { inject, observer } from "mobx-react";
import { Confirm } from "semantic-ui-react";
import moment from "moment";
import intl from "react-intl-universal";
import { stat } from "fs";

@inject("store")
@observer
class CreateInvoice extends Component {
  state = {
    currency: 'USD',
    invoice_total: 0,
    disabled: false,
    openConfirm: false,
    open: false,
    done: true,
    doctors: [],
    patients: [],
    appointments: [],
    services: [],
    servicesClone: [],
    patient: { email: "", name: "" },
    invoiceDiscount: 0,
    quantities: [],
    paid: false,
    status_id: 1,
    Vat: 0,
    created_at: moment().format("YYYY-MM-DD hh:mm:ss"),
    ser: [

      { discount: 0, net_amount: 0, amount: 0, quantity: 0, service_id: '' },
      { discount: 0, net_amount: 0, amount: 0, quantity: 0, service_id: '' },
      { discount: 0, net_amount: 0, amount: 0, quantity: 0, service_id: '' },
      { discount: 0, net_amount: 0, amount: 0, quantity: 0, service_id: '' },
      { discount: 0, net_amount: 0, amount: 0, quantity: 0, service_id: '' }
    ],
    appointment: { id: "1", services: [] },
    status: [
      { id: 1, value: "paid" },
      {
        id: 2,
        value: "unpaid"
      }
    ],
    payment_method: [
      {
        id: 1,
        value: "credit card"
      },
      {
        id: 2,
        value: "cash"
      },
      {
        id: 3,
        value: "cheque"
      }
    ],
    service: {},
    rows: [],
    payment_method_id: 2
  };

  async componentDidMount() {
    let currency;
    if (localStorage.getItem('currency') === null) {
      currency = 'USD'
    } else {
      currency = localStorage.getItem('currency')
    }
    this.setState({ currency });
    await this._getUsersInBusiness();
    if (typeof this.props.location.state !== "undefined") {
      let appointment = this.props.location.state.appointment;
      let patient = this.props.location.state.user;
      if (appointment) {
        await this.setState({ appointment });
        const URL =
          PUBLIC_URL +
          API_PREFIX +
          "invoices/appointment/" +
          this.state.appointment.id;
        checkResponse(URL, "get", 1).then(r => {
          if (!r.data.invoice) {
            this.setState({ generated: false });
          } else {
            this.setState({ generated: true, invoice_id: r.data.invoice.id });
          }
        });
      }
      if (patient) {
        patient.user_id = patient.id;
        setTimeout(() => {
          this.state.patients.map(p => {
            if (p.user_id === patient.user_id) {
              this.setState({ patient: p });
            }
          });
          if (this.state.appointment.id == 1) {
            this.state.appointments.map(app => {
              if (
                app.notes == "fakeClientAppointment" &&
                patient.user_id == app.client_id
              ) {
                this.setState({ appointment: app });
              }
            });
          }
        }, 500);
      }
    }

    await this.prepareData();
  }

  _getUsersInBusiness() {
    const URL =
      PUBLIC_URL +
      API_PREFIX +
      "appointments/" +
      this.props.store.get_business_id();
    checkResponse(URL, "get", 1).then(result => {
      const { clients, users, appointments, services } = result.data;
      localStorage.setItem('currency', services[0].currency_value)
      this.setState({
        patients: clients,
        doctors: users,
        appointments,
        services,
        servicesClone: services,
        currency: services && services[0] && services[0].currency_value
      });
    });
  }

  openConfirm = () => {
    return (
      <Confirm
        open={this.state.openConfirm}
        header={intl.get('ARE_YOU_SURE') || 'Are you sure ?'}
        content={
          <div style={{ padding: 5, margin: 5 }}>
            <h4 style={{ color: "red" }}>
              {intl.get("DELETE_INVOICE_WARNING") ||
                "deleting this item will affect to delete its related data."}
            </h4>
          </div>
        }
        onCancel={() => this.setState({ openConfirm: false })}
        cancelButton={intl.get("CANCEL") || "Cancel"}
        confirmButton={intl.get("YES_DELTE") || "Yes delete"}
        onConfirm={() => {
          this.deleteInvoice();
          this.setState({ openConfirm: false });
        }}
      />
    );
  };

  deleteInvoice = () => {
    const URL =
      PUBLIC_URL + API_PREFIX + "invoices/delete/" + this.state.invoice_id;
    checkResponse(URL, "delete")
      .then(r => {
        if (r.data) {
          this.setState({ generated: false });
        }
      })
      .catch(e => { });
  };

  handlePatientChange = e => {
    const patients = this.state.patients;
    const option = e.target.options[e.target.options.selectedIndex];
    for (var i = 0; i <= patients.length - 1; i++) {
      if (option.id == patients[i].user_id) {
        this.setState({ patient: patients[i] });
      } else {
        if (option.id == "noPatient") this.setState({ patient: { email: "" } });
      }
    }
    this.selectFakeApp(option.id);
  };

  selectFakeApp = id => {
    this.state.appointments.map(app => {
      if (app.notes == "fakeClientAppointment" && app.client_id == id) {
        this.setState({ appointment: app });
      }
    });
  };

  handleAppointmentChange = e => {
    const appointments = this.state.appointments;
    const option = e.target.options[e.target.options.selectedIndex];
    for (var i = 0; i <= appointments.length - 1; i++) {
      if (option.value == appointments[i].id) {
        this.setState({ appointment: appointments[i] });
        this.setState({ ser: this.state.appointment.services });
        this.prepareData();
      }
      if (e.target.value == 1) {
        this.setState({ appointment: { id: "1", services: [] } });
        this.setState({ ser: [] });
        this.prepareData();
      }
    }
  };

  changeService = e => {
    console.log(e)
    const services = this.state.services;
    const option = e.target.options[e.target.options.selectedIndex];
    for (var i = 0; i <= services.length - 1; i++) {
      if (option.value == services[i].id) {
        this.setState({ service: services[i] });
      }
    }
  };

  async prepareData() {
    if (this.state.appointment.services.length > 0) {

      let services = this.state.appointment.services;
      let ser = [];
      for (var i = 0; i <= services.length - 1; i++) {
        let quantity = 1;
        let amount = parseFloat(services[i].price);
        let discount = 0;
        ser.push({
          service_id: services[i].id,
          quantity,
          amount,
          discount,
          net_amount: amount * quantity * (1 - discount / 100)
        });

      }
      let total = [];
      for (var i = 0; i <= ser.length - 1; i++) {
        total.push(parseFloat(ser[i].net_amount));
      }
      function getSum(total, num) {
        return Number(total) + Number(num);
      }

      let invoice_total = total.reduce(getSum);
      await this.setState({ invoice_total, ser });
    } else {
      await this.setState({ invoice_total: 0, ser: [] });
    }
  }

  submitInvoice = async e => {
    this.setState({ disabled: true })
    //this is for invoice table
    let created_at = moment(this.state.created_at).format(
      "YYYY-MM-DD hh:mm:ss"
    );
    e.preventDefault();
    if (!this.state.patient.id) {
      this.setState({ disabled: false })
      return alert("there is no patient selected");
    }

    let newServices = [...this.state.ser];

    let services = [];
    for (var i = 0; i <= newServices.length - 1; i++) {
      let quantity = newServices[i].quantity;
      let discount = newServices[i].discount;
      services.push({
        service_id: newServices[i].service_id,
        quantity,
        amount: newServices[i].amount,
        discount,
        net_amount: newServices[i].amount * quantity * (1 - discount / 100)
      });
    }

    let newRows = [...this.state.rows];
    let rows = [];
    for (var i = 0; i <= newRows.length - 1; i++) {
      rows.push({
        service_id: newRows[i].service_id,
        quantity: parseFloat(newRows[i].quantity),
        amount: parseFloat(newRows[i].amount),
        discount: parseFloat(newRows[i].discount),
        net_amount: parseFloat(newRows[i].net_amount)
      });
    }

    let allServices = [...services, ...rows];

    let data = {
      client_id: this.state.patient.user_id,
      appointment_id: this.state.appointment.id,
      business_id: this.props.store.get_business_id(),
      status_id: this.state.status_id,
      invoice_number: 5967,
      total_amount: this.state.invoice_total,
      discount: this.state.invoiceDiscount,
      payment_method_id: this.state.payment_method_id,
      created_by: this.props.store.get_id(),
      Vat: this.state.Vat,
      created_at,
      currency: this.state.currency,
      net_amount: (
        this.state.invoice_total *
        (1 - this.state.invoiceDiscount / 100) *
        (1 + this.state.Vat / 100)
      ).toFixed(2),
      notes: this.state.notes,
      //this is for invoice details table
      services: allServices
    };

    if (data.total_amount === 0) {
      this.setState({ disabled: false })
      return alert('Empty invoice can not be saved')
    }
    if (data.status_id == 2) {
      data.payment_method_id = 0;
    }
    const URL = PUBLIC_URL + API_PREFIX + "invoices/create";

    await checkResponse(URL, "post", data)
      .then(r => {
        this.setState({ disabled: false })
        if (!r.data) {
          return alert("error check invoice details");
          this.setState({ disabled: false })
        }
        if (this.state.done) {
          const URL =
            PUBLIC_URL +
            API_PREFIX +
            "appointments/edit/" +
            this.state.appointment.id;
          // const data = {
          //   status:'done'
          // }
          // checkResponse(URL, "put", { status: "Done" }).then(result => {
          //   return result;
          // });
        }
        Swal.fire({
          title: "Success",
          titleText: "Invoice has been added successfully!",
          type: "success",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(result => {
          if (result.value) return this.props.history.push('/sales/all');
        });


      })

      .catch(e => console.log(e));
    this.setState({ disabled: false })
  };

  addRow = async () => {

    const item = {
      amount: 0,
      discount: 0,
      net_amount: 0,
      quantity: 0,
      service_id: ''
    };
    await this.setState({
      rows: [...this.state.rows, item],

    });
    this.reCalculate();
  };

  removeRow = (idx) => {
    let rows = [...this.state.rows]
    // let services = [...this.state.services]
    let row = rows[idx]
    let { invoice_total } = this.state;
    if (this.state.rows.length + this.state.ser.length > 1) {

      // let formatted_rows = rows.splice(idx,1)
      let formatted_rows = rows.filter(r => r !== row)


      invoice_total = parseFloat(invoice_total - row.net_amount)
      this.setState({
        rows: formatted_rows,
        // services : formatted_service,
        invoice_total
      });
      //  this.reCalculate();
    } else {

      alert("you should have at least one service to make an invoice");
    }
  };

  handleChange = (idx, options) => async (e) => {
    let { name, value } = e.target;

    if (name !== 'service_id') {
      if (options && options.numberOnly) {
        if (Number(value)) {
          value = Number(value);
        } else {
          value = 0;
        }
      }
    }
    const rows = [...this.state.rows];
    
    if (name === "service_id") {
    const currentService = this.state.services.find((item) => item.id=== value)

      let id = currentService.id;
      let price = Number(currentService.price);
      rows[idx] = {
        ...rows[idx],
        [name]: id,
        quantity: 1,
        discount: 0,
        amount: isNaN(price) ? 0 : price,
        net_amount: isNaN(price) ? 0 : price,
      };
    } else if (name === "net_amount") {
      let newNetAmount = parseFloat(value);
      if (isNaN(newNetAmount)) newNetAmount = 0;
      const amount = parseFloat(rows[idx].amount);
      const quantity = parseFloat(rows[idx].quantity);

      if (!isNaN(newNetAmount) && !isNaN(amount) && !isNaN(quantity) && amount * quantity !== 0) {
        const discount = 100 * (1 - newNetAmount / (amount * quantity));
        rows[idx] = {
          ...rows[idx],
          [name]: newNetAmount,
          discount: discount < 0 ? 0 : discount.toFixed(2), // Set discount to 0 if it's smaller than 0
        };
      } else {
        rows[idx] = {
          ...rows[idx],
          [name]: newNetAmount,
        };
      }
    } else {
      rows[idx] = {
        ...rows[idx],
        [name]: value,
      };

      // If discount is smaller than 0, set it to 0
      if (name === "discount") {
        const discount = parseFloat(value);
        if (isNaN(discount) || discount < 0) {
          rows[idx].discount = 0;
        }
      }

      // Update net_amount based on amount, quantity, and discount
      const amount = parseFloat(rows[idx].amount);
      const quantity = parseFloat(rows[idx].quantity);
      const discount = parseFloat(rows[idx].discount);

      if (!isNaN(amount) && !isNaN(quantity) && !isNaN(discount)) {
        rows[idx].net_amount = amount * quantity * (1 - discount / 100);
      }
    }

    await this.setState({
      rows,
    });

    this.reCalculate();
  };

  // handleChange = (idx, options) => async (e) => {
  //   const { name, value } = e.target;
  //   console.log("name", name, value);
  //   const rows = [...this.state.rows];

  //   if (value === "") {
  //     // Handle the case where the value is empty
  //     if (name === "net_amount") {
  //         rows[idx] = {
  //             ...rows[idx],
  //             net_amount: 0,
  //             discount: 0, // Reset discount to 0 as well
  //         };
  //     } else {
  //         rows[idx] = {
  //             ...rows[idx],
  //             [name]: 0, // Set the value to 0
  //         };
  //     }

  //     await this.setState({ rows });
  //     this.reCalculate();
  //     return; // Exit early
  // }

  //   if (name === "service_id") {
  //     let id = value.split("&")[1];
  //     let price = parseFloat(value.split("&")[0]);
  //     rows[idx] = {
  //       ...rows[idx],
  //       [name]: id,
  //       quantity: 1,
  //       discount: 0,
  //       amount: isNaN(price) ? 0 : price,
  //       net_amount: isNaN(price) ? 0 : price,
  //     };
  //   } else if (name === "net_amount") {
  //     let newNetAmount = value;
  //     if (isNaN(newNetAmount)) newNetAmount = 0;
  //     const amount = parseFloat(rows[idx].amount);
  //     const quantity = parseFloat(rows[idx].quantity);

  //     if (!isNaN(newNetAmount) && !isNaN(amount) && !isNaN(quantity) && amount * quantity !== 0) {
  //       const discount = 100 * (1 - newNetAmount / (amount * quantity));
  //       rows[idx] = {
  //         ...rows[idx],
  //         [name]: newNetAmount,
  //         discount: discount < 0 ? 0 : discount,
  //       };
  //     } else {
  //       rows[idx] = {
  //         ...rows[idx],
  //         [name]: newNetAmount,
  //       };
  //     }
  //   } else {
  //     rows[idx] = {
  //       ...rows[idx],
  //       [name]: value,
  //     };

  //     // Update net_amount based on amount, quantity, and discount
  //     const amount = parseFloat(rows[idx].amount);
  //     const quantity = parseFloat(rows[idx].quantity);
  //     const discount = parseFloat(rows[idx].discount);

  //     if (!isNaN(amount) && !isNaN(quantity) && !isNaN(discount)) {
  //       rows[idx].net_amount = amount * quantity * (1 - discount / 100);
  //     }
  //   }

  //   await this.setState({
  //     rows,
  //   });

  //   this.reCalculate();
  // };



  reCalculate = () => {
    let arr = [];
    let allrows = [...this.state.rows, ...this.state.ser];
    let invoice_total = this.state.invoice_total;
    if (allrows.length == 0) {
      return;
    }

    for (var i = 0; i <= allrows.length - 1; i++) {

      arr.push(allrows[i].net_amount);
    }

    function getSum(total, num) {
      return Number(total) + Number(num);
    }

    invoice_total = arr.reduce(getSum);
    this.setState({ invoice_total });
  };

  handleChangeSer = (idx) => async (e) => {
    const { name, value } = e.target;
    const ser = [...this.state.ser];

    if (value === "") {
      // Handle the case where the value is empty
      if (name === "net_amount") {
          ser[idx] = {
              ...ser[idx],
              net_amount: 0,
              discount: 0, // Reset discount to 0 as well
          };
      } else {
          ser[idx] = {
              ...ser[idx],
              [name]: 0, // Set the value to 0
          };
      }

      await this.setState({ ser });
      this.reCalculate();
      return; // Exit early
  }

    if (!isNaN(value) && !isNaN(parseFloat(value))) {

      if (name === "service_id") {
        let [price, id] = value.split("&");
        ser[idx] = {
          ...ser[idx],
          [name]: id,
          quantity: 1,
          discount: 0,
          amount: parseFloat(price),
        };
      } else if (name === "net_amount") {
        let newNetAmount = value;
        if (isNaN(newNetAmount) || newNetAmount < 0 || newNetAmount === "") {
          newNetAmount = 0;
        }
        const { amount, quantity } = ser[idx];
        const newDiscount = (1 - newNetAmount / (amount * quantity)) * 100;

        ser[idx] = {
          ...ser[idx],
          net_amount: newNetAmount,
          discount: newDiscount < 0 ? 0 : newDiscount, // Ensure discount is not negative
        };
      } else {
        ser[idx] = {
          ...ser[idx],
          [name]: value,
        };

        ser[idx].net_amount =
          parseFloat(ser[idx].amount * parseFloat(ser[idx].quantity)) *
          (1 - parseFloat(ser[idx].discount) / 100);
      }

      await this.setState({ ser });
      this.reCalculate();
    }

  };

  getPayMethod = method => {
    if (method === "cash") {
      method = intl.get("CASH");
    }
    if (method === "credit card") {
      method = intl.get("CREDIT_CARD");
    }
    if (method === "cheque") {
      method = intl.get("CHEQUE");
    }

    return method;
  };
  removeSer = async idx => {
    if (this.state.rows.length + this.state.ser.length > 1) {
      let ser = [...this.state.ser];

      ser.splice(idx, 1);

      this.setState({ ser });
      this.reCalculate();
    }
    return alert("you should have at least one service to make an invoice");
  };

  payment_method() {
    if (this.state.status_id != 2) {
      return (
        <div className="col-sm-3">
          <label className="col-form-label">
            {intl.get("PAYMENT_METHOD") || "Payment Method"}{" "}
            <i className="icon-credit-card" />
          </label>
          <div className="input-group">
            <div className="input-group-prepend">
              <label className="input-group-text" />
            </div>
            <div className="input-group">
              <select
                className="form-control"
                name="payment_method"
                value={this.state.payment_method_id}
                onChange={e =>
                  this.setState({ payment_method_id: e.target.value })
                }
              >
                {this.state.payment_method.map(pm => (
                  <option value={pm.id}>{this.getPayMethod(pm.value)}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
      );
    }
  }

  getStatusValue = status => {
    if (status === "paid") {
      status = intl.get("PAID");
    }
    if (status === "unpaid") {
      status = intl.get("UNPAID");
    }
    return status;
  };

  render() {
    const display_date = moment(this.state.created_at).format("YYYY-MM-DD");
    return (

      <header className="header">
        <div className="text-center mt-4">
          <h2 className="primary-heading"> Create New Invoice</h2></div>
        <div className="action-container">
          <div className="table-container" style={{ padding: '0 3rem' }}>
            <form onSubmit={this.submitInvoice}>

              <div className="my-3 ml-4">
                <h3>{intl.get("INVOICE_DETAILS") || "Invoice Details"}</h3>
              </div>
              <input type="hidden" name="_token" defaultValue="" />
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="form-group-container">
                    <label className="col-form-label">
                      {intl.get("CLIENT_NAME") || "Client Name"}

                    </label>
                    <select
                      style={{ border: 'none' }}
                      className="form-control"
                      onChange={e => this.handlePatientChange(e)}
                      value={this.state.patient.name}
                      disabled={this.state.generated}
                    >
                      <option id="noPatient" style={{ color: "grey" }}>
                        {intl.get("SELECT_CLIENT") || "Select Client"}
                      </option>

                      {this.state.patients.map(patient => (
                        <option id={patient.user_id}>{patient.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="form-group-container">
                    <label className="col-form-label">
                      {intl.get("EMAIL") || "E-mail"}

                    </label>
                    <input
                      style={{ backgroundColor: 'transparent' }}
                      disabled
                      type="email"
                      name="invoice[email]"
                      value={
                        this.state.patient.email.length > 30
                          ? `- ${intl.get("NO_EMAIL_PROVIDED")} -`
                          : this.state.patient.email
                      }
                      className="form-control"
                      placeholder={intl.get('EMAIL')}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="form-group-container">
                    <label className="col-form-label">
                      {intl.get("PHONE_NUMBER") || "Phone Number"}

                    </label>
                    <input
                      style={{ backgroundColor: 'transparent' }}
                      disabled
                      type="text"
                      name="invoice[mobile]"
                      defaultValue={this.state.patient.mobile}
                      className="form-control"
                      placeholder="70123456"
                    />
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="form-group-container">
                    <label className="col-form-label">
                      {intl.get("STATUS") || "Status"}
                    </label>
                    <select
                      style={{ border: 'none' }}
                      disabled={this.state.generated}
                      className="form-control"
                      name="status"
                      value={this.state.status_id}
                      onChange={e =>
                        this.setState({
                          status_id: e.target.value,
                          payment_method_id: 2
                        })
                      }
                    >
                      {this.state.status.map(stat => (
                        <option value={stat.id}>
                          {this.getStatusValue(stat.value)}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="form-group-container">
                    <label className="col-form-label">
                      {intl.get("DATE") || "Date"}
                    </label>
                    <input
                      disabled={this.state.generated}
                      className="form-control"
                      name="created_at"
                      type="date"
                      value={display_date}
                      onChange={e =>
                        this.setState({ created_at: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="form-group-container">
                    <label className="col-form-label">
                      {intl.get("PAYMENT_METHOD") || "Payment Method"}{" "}

                    </label>
                    <select
                      style={{ border: 'none' }}
                      className="form-control"
                      name="payment_method"
                      value={this.state.payment_method_id}
                      onChange={e =>
                        this.setState({ payment_method_id: e.target.value })
                      }
                    >
                      {this.state.payment_method.map(pm => (
                        <option value={pm.id}>{this.getPayMethod(pm.value)}</option>
                      ))}
                    </select>

                  </div>
                </div>
              </div>




              <div className="row pl-4 pr-4">


                <div className="col-12">


                  {!this.state.generated ? (
                    <div className="row">
                      <div className="d-none d-md-block d-lg-block col-12">
                        <table className="table-input table col-sm-12 table-bordered table-striped table-condensed cf">
                          <thead>
                            <tr>
                              <th>{intl.get("SERVICE") || "Service"}</th>
                              {/* <th>Description</th> */}
                              <th>{intl.get("QUANTITY") || "Quantity"}</th>
                              <th>{intl.get("UNIT_COST") || "Unit Cost"}</th>
                              <th>{intl.get("DISCOUNT") || "Discount %"}</th>
                              <th>
                                {intl.get("TOTAL_PRICE") || "Total price"}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* {this.state.ser.map((service, idx) => (
                                <tr className="item-row">
                                  <div className="invoice-circle delete-circle" onClick={_ => this.removeSer(idx)}>
                                    <i className='fa fa-minus'></i>
                                  </div>
                                
                                  <td className>
                                    <select
                                      name="service_id"
                                      className="form-control"
                                      value={service.service_id}
                                      onChange={e => this.changeService(e)}
                                    >
                                      {this.state.services.map(service => (
                                        <option value={service.id}>
                                          {service.title}
                                        </option>
                                      ))}
                                    </select>
                                   
                                   
                                  </td>
                                  
                                  <td className>
                                    <textarea
                                      name="quantity"
                                      className="item-quantity"
                                      id='text-area'
                                      value={this.state.ser[idx].quantity}
                                      onChange={this.handleChangeSer(idx)}
                                    />
                                  </td>
                                  <td className>
                                    <textarea
                                      type="text"
                                      id='text-area'
                                      name="amount"
                                      className="item-cost"
                                      value={service.amount}
                                      onChange={this.handleChangeSer(idx)}
                                    />
                                  </td>
                                  <td>
                                    <textarea
                                      type="text"
                                      id='text-area'
                                      name="discount"
                                      className="item-tax-price"
                                      onChange={this.handleChangeSer(idx)}
                                      value={
                                        this.state.ser[idx].discount?
                                        parseFloat(
                                        this.state.ser[idx].discount
                                      ):""}
                                    />
                                  </td>
                                  <td>
                                    <textarea
                                      type="text"
                                      id='text-area'
                                      name="net_amount"
                                      className="item-tax-price"
                                      defaultValue={service.amount}
                                      value={
                                        this.state.ser[idx].net_amount +
                                        " " +
                                        this.state.currency
                                      }
                                    />
                                  </td>
                                </tr>
                              ))} */}



                            {this.state.rows.map((row, idx) => {
                              return (
                                <tr className="item-row">

                                  <td className='item-col'>
                                    <select
                                      name="service_id"
                                      className="form-control"
                                      value={this.state.rows[idx].service_id}
                                      onChange={this.handleChange(idx, { numberOnly: true })}
                                    >
                                      <option value={""}>
                                        {intl.get("SELECT_SERVICE") ||
                                          "select service"}
                                      </option>
                                      {this.state.services.map(service => (
                                        <option


                                          value={
                                            service.id
                                          }
                                        >
                                          {service.title}
                                        </option>
                                      ))}
                                    </select>
                                    <span className="invoice-circle delete-circle" onClick={() => this.removeRow(idx)}>
                                      <i className='fa fa-minus'></i>

                                    </span>
                                  </td>
                                  <td className>
                                    <textarea
                                      name="quantity"
                                      id='text-area'
                                      className="item-quantity"
                                      value={this.state.rows[idx].quantity}
                                      onChange={this.handleChange(idx, { numberOnly: true })}
                                      placeholder="insert an integer"
                                    />
                                  </td>
                                  <td className>
                                    <textarea
                                      type="text"
                                      id='text-area'
                                      name="amount"
                                      className="item-cost"
                                      value={

                                        this.state.rows[idx].amount
                                      }

                                      onChange={this.handleChange(idx, { numberOnly: true })}
                                    />
                                  </td>
                                  <td>
                                    <textarea
                                      type="number"
                                      id='text-area'
                                      name="discount"
                                      className="item-tax-price"
                                      value={this.state.rows[idx].discount}
                                      onChange={this.handleChange(idx, { numberOnly: true })}
                                      placeholder="insert a % discount"
                                    />
                                  </td>
                                  <td>
                                    <textarea
                                      type="text"
                                      id='text-area'
                                      name="net_amount"
                                      className="item-tax-price"
                                      value={this.state.rows[idx].net_amount ? this.state.rows[idx].net_amount: 0}
                                      onChange={this.handleChange(idx, { numberOnly: true })}
                                    />
                                  </td>
                                </tr>
                              );
                            })}


                          </tbody>
                        </table>
                      </div>

                      <div className="d-block d-md-none d-lg-none d-xl-none col-12">
                        <table className='table col-sm-12'>
                          <tbody>
                            {this.state.rows.map((row, idx) => {
                              return (
                                <>
                                  <tr>
                                    <th scope="row" style={{ width: '50%' }}>{intl.get("SERVICE") || "Service"}</th>
                                    <td style={{ width: '50%' }} className='delete-container'>
                                      <select
                                        name="service_id"
                                        className="form-control"
                                        value={this.state.rows[idx].service_id}
                                        onChange={this.handleChange(idx)}
                                      >
                                        <option value={""}>
                                          {intl.get("SELECT_SERVICE") ||
                                            "select service"}
                                        </option>
                                        {this.state.services.map(service => (
                                          <option
                                            value={
                                              service.id
                                            }
                                          >
                                            {service.title}
                                          </option>
                                        ))}
                                      </select>
                                      <span className="invoice-circle delete-circle" onClick={() => this.removeRow(idx)}>
                                        <i className='fa fa-minus'></i>
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row" style={{ width: '50%' }}>{intl.get("QUANTITY") || "Quantity"}</th>
                                    <td style={{ width: '50%' }}>
                                      <textarea
                                        name="quantity"
                                        id='text-area'
                                        className="item-quantity"
                                        value={this.state.rows[idx].quantity}
                                        onChange={this.handleChange(idx)}
                                        placeholder="insert an integer"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row" style={{ width: '50%' }}>{intl.get("UNIT_COST") || "Unit Cost"}</th>
                                    <td style={{ width: '50%' }}>
                                      <textarea
                                        type="text"
                                        id='text-area'
                                        name="amount"
                                        className="item-cost"
                                        value={

                                          this.state.rows[idx].amount
                                        }

                                        onChange={this.handleChange(idx)}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row" style={{ width: '50%' }}>{intl.get("DISCOUNT") || "Discount %"}</th>
                                    <td style={{ width: '50%' }}>
                                      <textarea
                                        type="number"
                                        id='text-area'
                                        name="discount"
                                        className="item-tax-price"
                                        value={this.state.rows[idx].discount}
                                        onChange={this.handleChange(idx)}
                                        placeholder="insert a % discount"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row" style={{ width: '50%' }}>{intl.get("TOTAL_PRICE") || "Total price"}</th>
                                    <td style={{ width: '50%' }}>

                                      <textarea
                                        type="text"
                                        id='text-area'
                                        name="net_amount"
                                        className="item-tax-price"
                                        value={
                                          isNaN(
                                            this.state.rows[idx].amount *
                                            this.state.rows[idx].quantity *
                                            (1 -
                                              this.state.rows[idx].discount /
                                              100)
                                          )
                                            ? "0"
                                            : this.state.rows[idx].amount *
                                            this.state.rows[idx].quantity *
                                            (1 -
                                              this.state.rows[idx]
                                                .discount /
                                              100) +
                                            " " +
                                            this.state.currency
                                        }
                                      />
                                    </td>
                                  </tr>

                                </>);
                            })}
                          </tbody>
                        </table>
                      </div>

                    </div>

                  ) : ""}






                  <tr>
                    <td colspan="1" className="p-2 d-none d-md-block d-lg-block">
                      <div className="invoice-circle ml-auto" onClick={this.addRow}>
                        <i className='fa fa-plus mt-1' ></i>
                      </div>
                    </td>

                    <td className="d-block d-md-none d-lg-none d-xl-none">
                      <div className="add-items" onClick={this.addRow}>
                        Add Items
                      </div>
                    </td>
                  </tr>
                  <div className="pull-right">
                    <table>
                      <tbody>
                        <tr>
                          <td colSpan={3} />
                          <td>
                            {intl.get("TOTAL_AMOUNT") || "Total Amount"}
                          </td>
                          <td>
                            <textarea
                              type="text"
                              id='text-area'
                              name="totalamount"
                              className="item-tax-price"
                              value={

                                this.state.invoice_total ? this.state.invoice_total : 0 +
                                  " " +
                                  this.state.currency
                              }

                            />
                          </td>
                        </tr>

                        <tr>
                          <td colSpan={3} />

                          <td>{intl.get("VAT") || "Vat %"}</td>
                          <td>
                            <textarea
                              type="text"
                              id='text-area'
                              name="Vat"
                              className="item-tax-price"
                              onChange={e => {
                                let number = 0;
                                if (Number(e.target.value)) {
                                  number = Number(e.target.value)
                                }
                                this.setState({
                                  Vat: number
                                })
                              }
                              }
                              value={this.state.Vat}
                              placeholder="insert a % Vat"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3} />

                          <td>
                            {intl.get("NET_AMOUNT") || "Net Amount"}
                          </td>
                          <td>
                            <textarea
                              type="text"
                              id='text-area'
                              name="netamount"
                              className="item-tax-price"
                              value={
                                parseInt(
                                  this.state.invoice_total *
                                  (1 - this.state.invoiceDiscount / 100) *
                                  (1 + this.state.Vat / 100)
                                ).toFixed(2) +
                                " " +
                                this.state.currency
                              }
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {/* </tbody>
                          </table>
                        </div>
                      ) : (
                        ""
                      )} */}
                  {!this.state.generated ? (
                    <div>
                      <div className="row">
                        <div
                          className="col-sm-6 col-md-4 content-radio-container"
                          onClick={_ =>
                            this.setState({ done: !this.state.done })
                          }
                          style={{ margin: "10px" }}
                        >
                          <div>
                            <input
                              type="checkbox"
                              checked={this.state.done}
                            />
                            <label htmlFor="done">

                              <p
                                className="form-text text-lg"
                                style={{
                                  fontSize: "14px",
                                  background: "#00c292",
                                  padding: "0.3rem 1rem",
                                  color: "white",
                                  borderRadius: '100px',
                                  marginLeft: '1rem'
                                }}
                              >
                                {intl.get("MARK_AS_DONE") ||
                                  "mark appointment as 'Done'"}
                              </p>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="mt-3">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group-container">
                              <label>{intl.get("NOTES") || 'Notes'}</label>
                              <textarea
                                style={{ border: 'none' }}
                                className="form-control"
                                name="notes"
                                rows={3}
                                onChange={e =>
                                  this.setState({ notes: e.target.value })
                                }
                                defaultValue={""}
                              />

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>


              <input type="hidden" className="taxes" defaultValue="" />
              <input
                type="hidden"
                name="invoice[appointment_id]"
                defaultValue=""
              />

              {!this.state.generated ? (
                <div className="content-submit text-center">
                  <button
                    className="btn-green"
                    type='submit'
                    name='submit'
                    style={{ margin: '0 auto', marginBottom: '2rem' }}
                  >
                    {intl.get("CREATE") || "Create"}
                  </button>
                  {/* <button
                    type="submit"
                    name="submit"
                    className="btn btn-primary"
                    disabled={this.state.disabled}
                  >
                    {intl.get("CREATE") || "Create"}
                  </button> */}
                </div>
              ) : (
                <div>
                  <div className="content-submit text-center">
                    <p>
                      {intl.get("INV_ALREADY_CREATED") ||
                        "invoice already created"}
                    </p>
                    <button
                      type="submit"
                      name="submit"
                      className="btn btn-primary"
                      onClick={_ =>
                        this.props.history.push(
                          "/view/invoices/print/" + this.state.invoice_id
                        )
                      }
                    >
                      {intl.get("VIEW_INVOICE") || "View Invoice"}
                    </button>
                    <button
                      type="submit"
                      name="submit"
                      className="btn btn-primary"
                      onClick={_ =>
                        this.props.history.push(
                          "/view/invoices/edit/" + this.state.invoice_id
                        )
                      }
                    >
                      {intl.get("EDIT") || "Edit"}
                    </button>
                    <button
                      type="button"
                      name="del"
                      className="btn btn-dark"
                      onClick={_ => this.setState({ openConfirm: true })}
                    >
                      {intl.get("DELETE") || "Delete"}
                    </button>
                  </div>
                  {/* {this.openConfirm()} */}
                  <br />
                  {/* <div className="content-submit text-center">
            <button className="btn btn-primary"
            onClick={this.changeInvoice}
            >
              Save Changes
            </button>
          </div> */}
                </div>
              )}
              {/* <button type="submit" name="submit" className="btn btn-primary" onClick={this.pay}>
                  pay
                </button> */}

            </form>
          </div>
        </div>

      </header>

    );
  }
}

export default CreateInvoice;
