import React, { Component } from "react";
import { Link } from 'react-router-dom'
import axios from 'axios'
import { inject, observer } from "mobx-react";
import { PUBLIC_URL, API_PREFIX } from "../../../commons/config";
import UserTable from "../../../components/commons/table/user-table";
import checkResponse from "../../../commons/CheckResponse";
import { RaisedButton } from "material-ui";
import intl from 'react-intl-universal';


export default
@inject("store")
@observer
class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      business: {},
      credentials: { sid: '', token: '' },
      loading: true
    };
  }

  componentDidMount() {
    this._getData();
    this.getSmsTokens()
  }

  _getData() {
    const { get_business_id } = this.props.store;
    const URL = PUBLIC_URL + API_PREFIX + "users/" + get_business_id();
    const BIZ_URL = PUBLIC_URL + API_PREFIX + "business/" + get_business_id();
    checkResponse(URL, "get").then(result => {
      checkResponse(BIZ_URL, "get").then((biz_result) => {

        this.insertData(result, biz_result);
      })
    });
  }

  getSmsTokens = async () => {
    const token = localStorage.getItem('API_TOKEN')
    try {
      const { data: credentials } = await axios.get('https://mnty-mbl-bookinghub.com/api/clinic/user/sms-credentials', {
        headers: {
          'x-auth-token': token
        }
      })
      console.log('Here are credentials :', credentials)
      this.setState({ credentials })
    } catch (ex) {
      console.log(ex)
    }
  }

  insertData(data, biz_data) {

    if (data) {
      let { users } = data.data;
      const { business } = biz_data.data;
      const biz_phone = business.business_phone + business.mobile;
      users.forEach(function (item) {
        Object.assign(item, { biz_phone })
      })
      this.setState({ users, business, loading: false });
    }
  }

  render() {
    const { users, business, credentials } = this.state;
    if (users) {
      let activedoctors = users.filter(
        user => user.active == 1
      );
      let inactivedoctors = users.filter(
        user => user.active == 0
      );
      activedoctors = activedoctors.length;
      inactivedoctors = inactivedoctors.length;

      return (
        // <div style={{ width: '100%' }}>
        //   <div className="bootrap-wrapper md20">
        //     <div className="text-right" style={{ marginRight: "15px" }}>
        //       <RaisedButton
        //         onClick={_ => this.props.history.push("/users/create")}
        //         primary
        //         label={intl.get('ADD_NEW') || 'Add New'}
        //       />
        //     </div>
        //   </div>
        //   <UserTable
        //     to="/users/view/"
        //     history={this.props.history}
        //     users={this.state.users}
        //     _getData={this._getData}
        //     showRole={true}
        //   />
        //   <ul>
        //     <li
        //       style={{ color: "#0000ff", fontSize: "15px", fontWeight: "450" }}
        //     >
        //      {intl.get('ACTIVE') || 'Active'} : {activedoctors}
        //     </li>
        //     <li style={{ color: "red", fontSize: "15px", fontWeight: "450" }}>
        //     {intl.get('INACTIVE') || 'Inactive'} : {inactivedoctors}
        //     </li>
        //   </ul>
        // </div>
        <>
          <header className='header'>
            <div className="mt-4">
              <div style={{ display: 'flex', marginBottom: 10, justifyContent: 'space-between', alignItems: 'center', paddingLeft: 30, paddingRight: 30 }}>
                <h2 className="primary-heading"> Staff</h2>
                <Link className="whitePrimaryBtn-design" to='/users/create'>Add new</Link>
              </div>
            </div>

            <div className="action-container">
              <div className="table-container">
                <UserTable
                  to="/users/view/"
                  history={this.props.history}
                  business={business}
                  credentials={credentials}
                  users={this.state.users}
                  _getData={this._getData}
                  showRole={true}
                />
              </div>
            </div>


          </header>
        </>
      );
    }

  }
}
