import Axios from 'axios'

const API_TOKEN = localStorage.getItem("API_TOKEN");
Axios.defaults.headers.common["Authorization"] = API_TOKEN;
Axios.defaults.headers.common["Content-Type"] = 'application/json'
Axios.defaults.headers.common["Accept"] = 'application/json'
Axios.defaults.headers.common["Access-Control-Allow-Credentials"] = true
Axios.defaults.headers.common["Access-Control-Allow-Origin"] = true


export default  function checkResponse (url,method,data){
    return Axios({
        url,
        method,
        data,
     })
        .then(result =>{
            
        return result
    }
    ).catch(error =>{
        if(error){
           return error;
            if(error.response){
           
                if(error.response.status == 401){
                    // localStorage.clear();
                    // Store.reload();
                    console.log(error.response)
                    return error
                }
                if(error.response.status == 400){
                    console.log(error)
                    // localStorage.clear();
                    // Store.reload();
                    console.log(error.response)
                    return error
                }
                if(error.response.status == 404){
                    console.log(error.response)

                    return error
                }
                if(error.response.status == 422){
                    console.log(error.response)
                    return error        
                }
                if(error.response.status == 422){
                    console.log(error.response)
                    return error        
                }
                if(error.response.status !== 200){
                   
                    return 'please contact administrator'
                }
            }
        }
        })
    }
    
    
    