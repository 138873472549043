import React, { Component } from "react";
import { Snackbar } from "material-ui";
import { PUBLIC_URL, API_PREFIX } from "../../../commons/config";
import { inject, observer } from "mobx-react";
import checkResponse from "../../../commons/CheckResponse";
import CountryCodes from "../../CountryCodes";
import intl from 'react-intl-universal';
// import '../../../commons/css/grid.css'

export default
@inject("store")
@observer
class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        name: "",
        email: "",
        password: "",
        mobile: "",
        speciality_id:"doctor",
        active: 1,
      },
      specialities:[],
      open: false
    };
  }

  componentDidMount() {
    const URL = PUBLIC_URL + API_PREFIX + 'specialities/all/' + this.props.store.get_business_id()
    checkResponse(URL,'get').then(r=>{
      let form = this.state.form
      form.speciality_id = r.data[0].id
      this.setState({specialities: r.data,form})
    })
  }
  

  _form() {
    return (
     
       
            <form className="new-user-form">
               <div className="row">
            <div className="col-md-6">
          <div className="form-group-container">
          <label htmlFor="name">
            {intl.get("FULL_NAME") || 'Full Name'}
          </label>
          <input
            style={{textAlign:'center'}}
            onKeyPress={this._handleKeyPress.bind(this)}
            onChange={ev => this._setInput(ev)}
            ref={name => (this.name = name)}
            className="form-control"
            id="name"
            placeholder={intl.get("FULL_NAME") || 'Full Name'}
          />
      </div>
      </div>
            <div className="col-md-6">
          <div className="form-group-container">
          <label htmlFor="email">
          {intl.get("EMAIL") || 'E-mail'}
          </label>
          <input
          style={{textAlign:'center'}}
            onKeyPress={this._handleKeyPress.bind(this)}
            onChange={ev => this._setInput(ev)}
            ref={email => (this.email = email)}
            className="form-control"
            id="email"
            placeholder={intl.get("EMAIL") || 'E-mail'}
          />
      </div>
</div>
            <div className="col-md-6">
          <div className="form-group-container">
          <label htmlFor="password" className="label-control">
          {intl.get("PASSWORD") || 'Password'}
          </label>
          <input
          style={{textAlign:'center'}}
            onKeyPress={this._handleKeyPress.bind(this)}
            onChange={ev => this._setInput(ev)}
            ref={password => (this.password = password)}
            className="form-control"
            id="password"
            type="password"
            placeholder="******"
          />
      </div>
      </div>
            <div className="col-md-6">
          <div className="form-group-container">
          <label htmlFor="mobile" className="label-control">{intl.get("SPECIALITY") || 'Speciality'}
          </label>
          <select
            style={{textAlign:'center', border:'none', width:'100%'}}
            onKeyPress={this._handleKeyPress.bind(this)}
            onChange={ev => this._setInput(ev)}

            ref={speciality => (this.speciality = speciality)}
            className="form-control"
            value={this.state.form.speciality}
            placeholder={intl.get("SPECIALITY") || 'Speciality'}
          >
            {this.state.specialities.map(speciality =>
            <option value={speciality.id+'#'+speciality.name}>
              {speciality.name}
            </option>
              )}
          </select>
      </div>
           </div>
            <div className="col-md-6">
            <label htmlFor="mobile" className="custom-label-control">{intl.get("CODE_NUMBER") || 'Code Number'}
          </label>
        
          <CountryCodes cssClass='country-code-1'  countryCode={country_code=>this.setState({country_code})} />
       
      </div>
            <div className="col-md-6">
          <div className="form-group-container">
          <label htmlFor="mobile" className="label-control">{intl.get("PHONE_NUMBER") || 'Phone Number'}
          </label>
          <input
            style={{textAlign:'center'}}
            onKeyPress={this._handleKeyPress.bind(this)}
            onChange={ev => this._setInput(ev)}
            ref={mobile => (this.mobile = mobile)}
            className="form-control"
            id="mobile"
            type="number"
            placeholder={intl.get("PHONE_NUMBER") || 'Mobile'}
            
            />
           
      </div>
      </div>
</div>     
      <div className="row">
      <div className="col-md-6">
          <div className="form-group-container">
          <label htmlFor="active" className="label-control">
          {intl.get("ACTIVITY") || 'Activity'}
          </label>
          <select
           style={{textAlign:'center', border: 'none', width:'100%'}}
            onChange={ev => this._setInput(ev)}
            ref={active => (this.active = active)}
            id="active"
            className="form-control"
          >
            <option value={1}>{intl.get("ACTIVE") || "Active"}</option>
            <option value={0}>{intl.get("INACTIVE") || "Inactive"}</option>
          </select>
      </div>
     </div>
        </div> 
  

       
</form>
          

      
    );
    
  }
  _folderHeader(title) {
    return (
      <div className="header-folder">
        <h4>{title}</h4>
      </div>
    );
  }

  _setInput(event) {
    const element = event.target.id;
    let value = event.target.value
    let form = Object.assign({}, this.state.form);
    if(value.includes('#')){
      form.speciality_id = value.split('#')[0]
      this.setState({form})
      return
    }
    form[element] = event.target.value;
    this.setState({ form });
  }

  _handleKeyPress(e) {
    if (e.key === "Enter") {
      this._doCreate();
    }
  }

  _validate() {
    const regex = new RegExp(
      /(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$)/
    );

    let valid = true;
    for (let element in this.state.form) {
      if (this.state.form[element] === "") {
        this.setState({ err: "The field " + [element] + " " + "is missing" });
         valid = false;
      } 
      else {
        // if (this.state.form.password !== this.state.form.confirmPassword) {
        //   this.setState({ err: "Passwords do not match" });
        //   valid = false;
        // }
        if (element === "password" && this.state.form[element].length <= 5) {
          this.setState({ err: "Password should at least 6 charachters" });
          if (!this[element].className.includes("err"))
            this[element].className += " err";
          valid = false;
        }
        if (element === "name" && this.state.form[element].length == 0) {
          this.setState({ err: "the name can't be empty" });
          if (!this[element].className.includes("err"))
            this[element].className += " err";
          valid = false;
        }
        if (element === "mobile" && this.state.form[element].length == 0) {
          this.setState({ err: "Phone number should be 8 digits" });
          if (!this[element].className.includes("err"))
            this[element].className += " err";
        }
        if (element === "email" && !regex.test(this.state.form[element])) {
          // if(!this[element].className.includes("err"))
          this.setState({ err: "Please enter a valid email address" });
          this[element].className += " err";
          valid = false;
        } else {
          // if (this[element].className.includes("err")) {
          //   this[element].className = this[element].className.replace(
          //     "err",
          //     ""
          //   );
          // }
        }
      }
    }
    return valid;
  }

  _err() {
    if (this.state.err) {
      return (
        <div className="text-center pad10">
          <h4 className="red">{this.state.err}</h4>
        </div>
      );
    }
  }

  _doCreate=(e)=> {
    e.preventDefault()
    const { get_business_id } = this.props.store;
    if (true) {
      const {
        name,
        email,
        password,
        mobile,
        active,
        speciality_id
      } = this.state.form;
      const URL = PUBLIC_URL + API_PREFIX + "users/create";

      const data = {
        name,
        email,
        password,
        country_code:this.state.country_code,
        mobile,
        active,
        speciality_id,
        business_id: get_business_id()
      };
      this._validate()
      checkResponse(URL, "post", data).then(result => {
        console.log(result)
        if (result.data) return this.props.history.push("/users/view");
        if (result.response)
           
          return this.setState({ err: result.response.data.error_description });
        //   this.setState({ open: !this.state.open }, this.clearall.bind(this));
      }).catch(e=>{
        console.log(e.message)
      });
    }
  }

  clearall() {
    this.email.value = "";
    this.mobile.value = "";
    this.password.value = "";
    this.active.value = "";
  }

  _card() {
    
    return (
     
        <header className='header'>
          <div className="text-center mt-4">
            <h2 className="primary-heading"> Add Staff</h2></div>
            
            <div className="action-container width-60" >
            <div className="table-container padding-2">
           
           
        <div style={{marginBottom:'1rem', 
        color:'black', 
        textAlign:'left', 
        fontWeight:'bold',
        marginRight:'.5rem',
        fontSize:'1.2rem'
        }}>Staff Details</div>
        {this.state.err && <h4 style={{ textAlign: "center", color:'red' }}>
            {this.state.err}
          </h4>} 
            {this._form()}
            <div className="row">
              <div className="col-md-12">
              <div className="d-flex justify-content-center mt-3">
            <button className="whitePrimaryBtn-design" onClick={this._doCreate}>
            {intl.get('SAVE') || 'Save'}
            </button>
            <button className="btn-ghost ml-1" onClick={()=>this.props.history.push("/users/view")}>
            {intl.get('CANCEL') || 'Cancel'}
            </button>

              </div>
              </div>
           
            </div>
           
           
           

            </div> 
            </div>
            

      </header>
    
    );
  }

  // _snackbar() {
  //   if (this.state.open) {
  //     return (
  //       <Snackbar
  //         open={this.state.open}
  //         message="User successfully added."
  //         autoHideDuration={4000}
  //         onRequestClose={this.handleRequestClose}
  //       />
  //     );
  //   }
  // }

  handleRequestClose = () => {
    this.setState({
      open: false
    });
  };

  render() {
    return (
     
        <div className="tbl">
          <div className="tbl-cell no-pad middle">{this._card()}</div>
        </div>
       
     
    );
  }
}
