import React, { Component } from 'react'
import AppBar from 'material-ui/AppBar'
import FontIcon from 'material-ui/FontIcon'
import Logged from './logged'
import { inject, observer } from "mobx-react"
import intl from 'react-intl-universal'
import Background from '../../assets/images/calendar.jpg'

var backgroundStyle = {
//   backgroundImage: `url(${Background})`,
  height: '100%', 
//   backgroundPosition: 'center',
//   backgroundRepeat: 'no-repeat',
//   backgroundSize: 'cover'
backgroundColor:'#3e87c6'
}; 

export default
@inject("store")
@observer class Basic extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sidebarOpen: false,
        };
    }


    render() {
        console.log("this.props.store", this.props.store )
        let back = false
        const { sidebarOpen, toggleSidebar } = this.props.store;
        let visibility= {visibility:"visible"}
        let ifVisible = {
            visibility:'hidden'
        }

        if (this.props.match){
            if(this.props.match.path ===  '/profile' ){
                back = true
            }
            if(this.props.match.path ===  "/"){
                visibility= {display:"none"}
            }
            if(this.props.match.path === '/appointments'){
            back = true
            }
            if(this.props.match.path === '/mymessages'){
                visibility = {display:"none"}
                }
        }   
        let arrow = 'arrow_back'
        if(localStorage.getItem('lang') === 'ar'){
          arrow = 'arrow_forward'
        }

        return(
            <div>
                {React.cloneElement(this.props.children, { loggedIn: this.state.loggedIn, sidebarOpen })}
            </div>
        )
    }
}

