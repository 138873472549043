import React, { Component } from "react";
// import { Card, CardActions, CardText } from "material-ui/Card"
// import RaisedButton from "material-ui/RaisedButton"
// import Divider from "material-ui/Divider"
import { inject, observer } from "mobx-react";
import { PUBLIC_URL, API_PREFIX } from "../../commons/config";
import checkResponse from "../../commons/CheckResponse";
import axios from "axios";
import RecaptchaComponent from "../../commons/RecaptchaComponent";
// import Cookies from "universal-cookie"
import jwt from "jsonwebtoken";
import intl from "react-intl-universal";
import SelectLanguage from "../../commons/SelectLanguage";
import LanguageBar from "../../components/languageBar";
import logo from "../../assets/images/bookingwhitebg.png";
import Background from "../../assets/images/calendar.jpg";
import group from "../../assets/images/Group.png";
import { Link } from "react-router-dom";

// import newStyles from ''

var crypto = require("crypto"),
  algorithm = "aes-256-ctr",
  password = "d6F3Efeq";

const encrypt = (text) => {
  var cipher = crypto.createCipher(algorithm, password);
  var crypted = cipher.update(text, "utf8", "hex");
  crypted += cipher.final("hex");
  return crypted;
};

const decrypt = (text) => {
  var decipher = crypto.createDecipher(algorithm, password);
  var dec = decipher.update(text, "hex", "utf8");
  dec += decipher.final("utf8");
  return dec;
};

const saveCredentials = (email, password) => {
  localStorage.setItem("email", email);
  password = jwt.sign(password, "whatever");
  password = encrypt(password);
  localStorage.setItem("variable", password);
};

const removeCredentials = () => {
  localStorage.removeItem("email");
  localStorage.removeItem("variable");
};

var backgroundStyle = {
  backgroundImage: `url(${Background})`,
  height: "100vh",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  // backgroundColor:'#3e87c6'
};

// let thiss

export default
@inject("store")
@observer
class Signin extends Component {
  constructor(props) {
    super(props);
    // thiss = this;
    this.state = {
      form: { email: "", password: "" },
      me: null,
      verified: true,
      counter: 0,
      onSubmit: false,
      info: "",
      err: "",
      loggin: false,
      rememberMe: false,
      isAuthenticated: false,
      user: null,
      token: "",
    };
    // create a ref to store the textInput DOM element
    this.loginFormStyle = React.createRef();
  }

  componentDidMount() {
    const email = localStorage.getItem("email");
    let password = localStorage.getItem("variable");
    if (email && password) {
      password = decrypt(password);
      password = jwt.decode(password);
      const { form } = { ...this.state };
      form.email = email;
      form.password = password;
      this.setState({ form, rememberMe: true });
    }
    const linkState = this.props.location.state;
    if (!linkState) {
      return;
    }
    if (linkState.info) {
      this.setState({ info: linkState.info });
    }
    // console.log('localStorage.getItem("lang")', localStorage.getItem('lang'))
  }

  _inputs() {
    return (
      <div>
        <form id="signin" onSubmit={(_) => false}>
          <input
            onKeyPress={this._handleKeyPress.bind(this)}
            onChange={(ev) => this._setInput(ev)}
            id="email"
            className="form-control"
            placeholder="email@example.com"
            style={{ width: "100%" }}
            required
            value={this.state.form.email}
            ref={(email) => (this.email = email)}
          />
          <br />
          <input
            onKeyPress={this._handleKeyPress.bind(this)}
            onChange={(ev) => this._setInput(ev)}
            className="form-control"
            id="password"
            value={this.state.form.password}
            style={{ width: "100%" }}
            type="password"
            placeholder={intl.get("PASSWORD") || "Password"}
            required
            ref={(password) => (this.password = password)}
          />
          <br />
        </form>
      </div>
    );
  }

  rememberMe = () => {
    const rememberMe = !this.state.rememberMe;
    this.setState({ rememberMe });
    if (rememberMe === false) {
      removeCredentials();
      const { form } = this.state;
      form.email = "";
      form.password = "";
      this.setState({ form });
    }
  };

  _setInput(event) {
    const element = event.target.id;
    let form = Object.assign({}, this.state.form);
    form[element] = event.target.value;
    this.setState({ form });
  }

  _inputActions() {
    return (
      <div className="tbl no-pad">
        <div className="tbl-cell top no-pad text-right">
          {
            // <RaisedButton
            //   type="submit"
            //   variant="contained"
            //   disabled={this.state.loggin}
            //   onClick={_ => this._doLogin()}
            //   primary
            //   label={
            //     this.state.loggin ? "Working..." : intl.get("SIGN_IN") || "Signin"
            //   }
            // />
          }
        </div>
      </div>
    );
  }

  _handleKeyPress(e) {
    if (e.key === "Enter") {
      this._doLogin();
      // this._validate();
    }
  }

  _validate() {
    const regex = new RegExp(
      /(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$)/
    );
    let valid = true;
    this.setState({ err: "" });
    for (let element in this.state.form) {
      if (this.state.form[element] === "") {
        if (!this[element].className.includes("err"))
          this[element].className += " err";
        valid = false;
      }
      if (element === "email" && !regex.test(this.state.form[element])) {
        // if(!this[element].className.includes("err"))

        switch (localStorage.getItem("lang")) {
          case "en":
            // code block
            this.setState({
              err: "this does not seem to be a valid email address",
            });
            break;
          case "ar":
            // code block
            this.setState({ err: "إن عنوان البريد الالكتروني لا يبدو صالحا" });
            break;
          case "fr":
            // code block
            this.setState({
              err: "cette address e-mail ne semble pas etre valide",
            });
            break;
          default:
            // code block
            this.setState({
              err: "este endereço de email não parece ser válido",
            });
        }

        // this.setState({ err: "this is not seems to be valid email address" });
        this[element].className += " err";
        valid = false;
      } else {
        if (this[element].className.includes("err")) {
          this[element].className = this[element].className.replace("err", "");
        }
      }
      if (element === "password" && this.state.form[element].length < 6) {
        // if(!this[element].className.includes("err"))

        switch (localStorage.getItem("lang")) {
          case "en":
            // code block
            this.setState({ err: "your email or password is incorrect" });
            break;
          case "ar":
            // code block
            this.setState({
              err: "إن البريد الالكتروني أو كلمة السر غير صحيحة",
            });
            break;
          case "fr":
            // code block
            this.setState({
              err: "votre e-mail ou votre mot de passe est incorrect",
            });
            break;
          default:
            // code block
            this.setState({ err: "seu e-mail ou senha está incorreto" });
        }

        // this.setState({ err: "your email or password was incorrect" });
        this[element].className += " err";
        valid = false;
      } else {
        if (this[element].className.includes("err")) {
          this[element].className = this[element].className.replace("err", "");
        }
      }
    }
    // alert(valid)
    return valid;
  }

  _doLogin() {
    //alert("_doLogin")
    this.setState({ err: "", info: "", loggin: true });
    if (this._validate()) {
      //alert("_validate")
      let counter = this.state.counter;
      if (counter >= 3) {
        // this.setState({ verified: false, onSubmit: true, loggin: false });
        this.setState({ onSubmit: true, loggin: false });
      }
      this.setState({ counter: counter + 1 });
      // if (!this.state.verified) {
      //   return this.setState({
      //     err: intl.get("HUMAN_VERIFICATION") || 'please verify you are a human',
      //     loggin: false
      //   });
      // }
      const { set_tokens, authenticate } = this.props.store;
      const { email, password } = this.state.form;
      const URL = PUBLIC_URL + API_PREFIX + "login";
      checkResponse(URL, "post", { email, password })
        .then((result) => {
          if (result.response) {
            let err = result.response.data.error_description;
            return this.setState({ err, loggin: false });
          }
          if (result.data) {
            if (result.data.message) {
              this.setState({
                info:
                  intl.get("EMAIL_VERIFICATION") ||
                  "please verify your email to activate your account",
                loggin: false,
              });
            }
            const name = result.data.name;
            const userRoles = result.data.userRoles;
            const { api_token, refresh_token, id } = result.data;
            localStorage.setItem("email", email);
            set_tokens({ api_token, refresh_token, id, name, userRoles }).then(
              (_) => {
                authenticate();
                if (this.state.rememberMe) {
                  saveCredentials(email, password);
                }

                //   axios.post('https://mnty-mbl-bookinghub.com/api/clinic',{email,password}).then(r=>{
                //     localStorage.setItem("x-auth-token",r.data.authToken);
                //   }).catch(e=>console.log(e.message))
                // })
                // .then(_ => {
                //   if (this.props.store.API_TOKEN) {

                // window.location.href = '/'

                this.props.history.push("/");
                // }
              }
            );
          }
        })
        .catch((error) => {
          return this.setState({
            err: intl.get("NETWORK_ERROR") || "Network error",
            loggin: false,
          });
        });
    }
    return this.setState({ loggin: false });
  }

  renderRecaptcha() {
    if (this.state.counter >= 3) {
      this.loginFormStyle.current.style.gridTemplateRows =
        "61px 90px 50px 83px 62px";
      return (
        <RecaptchaComponent
          onSubmit={this.state.onSubmit}
          onVerify={() =>
            this.setState({ verified: true, onSubmit: false, err: "" })
          }
        />
      );
    } else {
      // thiss.loginFormStyle.current.style.gridTemplateRows = '61px 90px 50px 56px 62px';
    }
    return;
  }

  _err() {
    if (this.state.err) {
      return (
        <div className="errorMsgWrapper">
          <h4 className="redText">{this.state.err}</h4>
        </div>
      );
    }
  }

  info() {
    if (this.state.info) {
      return (
        <div className="text-center pad10" style={{ padding: 10, margin: 20 }}>
          <h3 style={{ fontSize: 20, alignSelf: "center", color: "blue" }}>
            {this.state.info}
          </h3>
        </div>
      );
    }
  }

  render() {
    return (
      <div
        className="blackTheme grid-container two-columns"
        style={backgroundStyle}
      >
        <section
          className="createNewAccount grid-item displayNoneMobile"
          style={{ order: 2, position: "relative" }}
        >
          <div style={{ position: "absolute", right: 50, top: 50 }}>
            <SelectLanguage />
          </div>
          <div className="logoWrapper grid-item">
            <img className="logo" src={logo} alt="Booking Hub" />
          </div>
          <div style={{ position: "absolute", bottom: 100, left: -100 }}>
            <img src={group} style={{ width: "50%" }} alt="Booking Hub" />
          </div>

          <div className="description grid-item" style={{ fontSize: 14 }}>
            <span>
              {intl.get("ONPOINT_WELCOME_MESSAGE") ||
                "Booking Hub is an online appointment scheduling software provided by Booking Hub for free which elegantly enables you to utilize all appointments and data for your clients and more!"}
            </span>
            <div className="separator"></div>
            <span>
              {intl.get("BOOST_YOUR_PRODUCTIVITY") ||
                "Boost your productivity now by using Booking Hub, the easiest way to control your schedule by managing their appointments and save their data upon each visit."}
            </span>
          </div>
          {/* <div className="footer privacyAndPolicy grid-item">
            <a
              className="forgot"
              onClick={(_) => this.props.history.push("/privacy_and_policy")}
            >
              {intl.get("PRIVACY_AND_POLICY") || "Privacy and Policy"}
            </a>
          </div> */}
        </section>
        <section
          className="loginSection grid-item loginHeightStyle mobileWidth100vw"
          style={{
            backgroundColor: "white",
            padding: 30,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#3e87c6",
              height: "100%",
              borderRadius: 30,
              width: "100%",
            }}
          >
            <div className="loginFormHolder grid-item">
              <form
                id="loginForm"
                ref={this.loginFormStyle}
                onSubmit={(_) => false}
              >
                {this._err()}
                {this.info()}

                <div
                  className="title signupTitle grid-item"
                  style={{
                    fontSize: 40,
                    borderBottom: "2px solid white",
                    paddingBottom: 30,
                    paddingLeft: 90,
                    paddingRight: 90,
                  }}
                >
                  {intl.get("LOGIN") || "Login"}
                </div>
                <div className="input-container grid-item emailWrapper">
                  {/* <img
                    className="loginFormIcon"
                    src={require("../../assets/svgs/envelope.svg")}
                    alt="email"
                  /> */}
                  <div
                    style={{ color: "white", fontSize: 16, marginLeft: "1em" }}
                  >
                    Email
                  </div>
                  <input
                    type="text"
                    id="email"
                    className="text-input"
                    name="email"
                    placeholder={intl.get("EMAIL_ADDRESS") || "Email Address"}
                    onKeyPress={this._handleKeyPress.bind(this)}
                    onChange={(ev) => this._setInput(ev)}
                    required
                    value={this.state.form.email}
                    ref={(email) => (this.email = email)}
                  />
                </div>
                <div className="input-container grid-item passwordWrapper">
                  {/* <img
                    className="loginFormIcon"
                    src={require("../../assets/svgs/lock.svg")}
                    alt="password"
                  /> */}
                  <div
                    style={{ color: "white", fontSize: 16, marginLeft: "1em" }}
                  >
                    Password
                  </div>
                  <input
                    type="password"
                    id="password"
                    className="text-input"
                    name="password"
                    placeholder={intl.get("PASSWORD") || "Password"}
                    onKeyPress={this._handleKeyPress.bind(this)}
                    onChange={(ev) => this._setInput(ev)}
                    required
                    value={this.state.form.password}
                    ref={(password) => (this.password = password)}
                  />
                </div>
                {/* {this.renderRecaptcha()}  */}
                <div
                  className="grid-item button-wrapper"
                  style={{ width: "100%", paddingLeft: 20 }}
                >
                  <label
                    className="rememberMeWrapper grid-item"
                    style={{ color: "white", fontSize: 18, width: "100%" }}
                  >
                    <input
                      type="checkbox"
                      className="rememberMe"
                      onClick={this.rememberMe}
                      checked={this.state.rememberMe}
                    />
                    <span className="checkmark"></span>
                    {intl.get("REMEMBER_ME") || "Remember Me"}
                  </label>
                </div>
                <div className="grid-item button-wrapper">
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingBottom: 10,
                        marginTop: 50,
                      }}
                    >
                      <input
                        className="loginButton primary-Btn-white"
                        type="button"
                        disabled={this.state.loggin}
                        onClick={(_) => this._doLogin()}
                        style={{
                          paddingLeft: 60,
                          paddingRight: 60,
                          fontSize: 18,
                        }}
                        value={
                          this.state.loggin
                            ? "Working..."
                            : intl.get("LOGIN") || "Login"
                        }
                      />
                    </div>

                    <div
                      className="footer "
                      style={{ textAlign: "center", marginTop: 20 }}
                    >
                      <Link to="/register">
                        <span style={{ fontSize: 20, color: "white" }}>
                          Don’t have an account?{" "}
                          <span
                            style={{
                              fontSize: 20,
                              color: "white",
                              fontWeight: "bold",
                              marginLeft: 4,
                            }}
                          >
                            Sign Up
                          </span>
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </form>
              {/* <div className="footer grid-item signinFooter">
                <a className="forgot" href="/forgot">
                  {intl.get("FORGOT_MY_PASSWORD") || "Forgot my password"}
                </a>
              </div> */}
            </div>
          </div>
        </section>
      </div>
    );
  }
}
