import React, { Component } from 'react';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import { PUBLIC_URL, API_PREFIX } from '../../commons/config';
import checkResponse from '../../commons/CheckResponse';
import intl from 'react-intl-universal';

export default
@inject('store')
@observer
class Report extends Component {
	state = {
		start: moment().format('YYYY-MM-DD'),
		end: moment().format('YYYY-MM-DD'),
		all: [],
	};

	componentDidMount() {
		this.fetchData();
	}

	fetchData = () => {
		const URL = PUBLIC_URL + API_PREFIX + 'getFilteredData';

		const body = {
			start: this.state.start,
			end: this.state.end,
			business_id: this.props.store.get_business_id(),
		};
		checkResponse(URL, 'POST', body).then(r => {
			let expenses = r.data.expenses;
			let profits = r.data.profits;
			let all = [...expenses, ...profits];
			this.setState({ all });
		});
	};

	filters = () => {
		return (




			<div style={{ display: 'flex' }}>
				<div className="form-group" style={{ width: '50%' }}>
					<label>from</label>
					<input
						type="date"
						onChange={e => this.setState({ start: e.target.value })}
						className="form-control"
						value={this.state.start}
					/>
					<label>to</label>
					<input
						type="date"
						onChange={e => this.setState({ end: e.target.value })}
						className="form-control"
						value={this.state.end}
					/>
				</div>

			</div>
		);
	};

	export = (tableID, filename) => {
		var downloadLink;
		tableID = "data_table";
		filename = "filterd";
		var dataType = "application/vnd.ms-excel";
		var tableSelect = document.getElementById(tableID);
		var tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");

		// Specify file name
		filename = filename ? filename + ".xls" : "excel_data.xls";

		// Create download link element
		downloadLink = document.createElement("a");

		document.body.appendChild(downloadLink);

		if (navigator.msSaveOrOpenBlob) {
			var blob = new Blob(["\ufeff", tableHTML], {
				type: dataType
			});
			navigator.msSaveOrOpenBlob(blob, filename);
		} else {
			// Create a link to the file
			downloadLink.href = "data:" + dataType + ", " + tableHTML;

			// Setting the file name
			downloadLink.download = filename;

			//triggering the function
			downloadLink.click();
		}
	};


	render() {
		const { all } = this.state;
		let arr = [0];
		all.map(item => {
			if ('description' in item) {
				return arr.push(-item.amount);
			}
			return arr.push(item.amount);
		});

		let overall = 0;
		function getSum(total, num) {
			return total + num;
		}
		overall = arr.reduce(getSum);

		let contents;
		let cssClass;
		if (all.length < 1) {
			contents = <tr><td colSpan={3} className='text-center'> No data found</td></tr>
		} else {
			contents = all.map(item => {
				item.description ? cssClass = 'text-danger' : cssClass = 'text-success';

				return <tr key={item.id}>
					<td className='text-center' >{item.title}</td>
					<td className={`text-center ${cssClass}`} >{item.amount}</td>
					<td className='text-center' >{moment(item.created_at).format('LL')}</td>
				</tr>
			}

			)
		}

		return (

			<header className="header">
				<div className="mt-4">
					<div style={{ display: 'flex', marginBottom: 10, justifyContent: 'space-between', alignItems: 'center', paddingLeft: 30, paddingRight: 30 }}>
						<h2 className="primary-heading"> {intl.get('REPORT') || 'Report'}</h2>

						<div className="d-flex">
							<button className="btn-green btn-resp mr-2" onClick={() => this.export()}>
								{intl.get('EXPORT_EXCEL_SHEET') || 'Export excel sheet'}
							</button>
						</div>
					</div>
				</div>
				<div className="action-container">
					<div className="table-container padding-2" >
						<div className="row">
							<div className="col-md-6 col-sm-12">
								<div className="form-group-container">
									<label>from</label>
									<input
										type="date"
										onChange={e => this.setState({ start: e.target.value })}
										className="form-control text-center"
										value={this.state.start}
									/>
								</div>
							</div>
							<div className="col-md-6 col-sm-12">
								<div className="form-group-container">
									<label>to</label>
									<input
										type="date"
										onChange={e => this.setState({ end: e.target.value })}
										className="form-control text-center"
										value={this.state.end}
									/>
								</div>
							</div>
						</div>
						<div className="row mt-3">
							<div className="col-md-12">
								<div className="table-border-container">
									<table className="table table-bordered" id='data_table' >
										<thead>
											<tr>
												<th className='text-center'>Title</th>
												<th className='text-center'>Amount</th>
												<th className='text-center'>Date</th>
											</tr>
										</thead>

										{contents}
									</table>
									<div className="text-center">

										<span><strong>Total profit: <span className='ml-2'>{overall}</span></strong></span>
									</div>

								</div>

							</div>
							<div className="col-md-2 m-auto ">

								<button className="whitePrimaryBtn-design mt-3" onClick={this.fetchData}>
									fetch
								</button>

							</div>
						</div>
					</div>
				</div>
			</header>



			// <div className="container" style={{ margin: '10px', padding: '20px' }}>
			// 	<div className="row">
			// 		<div className="col-9">
			// 			{this.filters()}
			// 			<table className="table" style={{background:'white'}}>
			// 				<tr style={{ background: '#a4e3ff' }}>
			// 					<td>title</td>
			// 					<td>amount</td>
			// 					<td>date</td>
			// 				</tr>
			// 				{all.map(item => {
			// 					let style = { color: 'blue' };
			// 					item.description ? (style.color = 'red') : (style.color = 'green');

			// 					return (
			// 						<tr style={style}>
			// 							<td>{item.title}</td>
			// 							<td>{item.amount}</td>
			// 							<td>{moment(item.created_at).format('LL')}</td>
			// 						</tr>
			// 					);
			// 				})}
			// 				<tr style={{ background: '#a4e3ff' }}>
			// 					<td>Total profits</td>
			// 					<td style={{ color: overall > 0 ? 'green' : 'red', fontWeight: 'bold' }}>{overall}</td>
			// 				</tr>
			// 			</table>
			// 		</div>
			// 	</div>
			// </div>
		);
	}
}
