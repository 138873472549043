import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import Swal from 'sweetalert2'
// import 'sweetalert2/src/sweetalert2.scss'
import { RaisedButton, Card, CardText } from "material-ui";
import { PUBLIC_URL, API_PREFIX } from "../../../commons/config";
import Form from "react-jsonschema-form";
import checkResponse from "../../../commons/CheckResponse";
import { Confirm } from "semantic-ui-react";
import intl from 'react-intl-universal';


const currencies = [
  {
    name: "dollars",
    sign: "$"
  },
  {
    name: "Lebanese Pounds",
    sign: "LL"
  },
  {
    name: "Pakistan Rupee",
    sign: "PKR"
  },
  {
    name: "Euro",
    sign: "€"
  },
  {
    name: "UAE",
    sign: "Dhs"
  }
];


export default
@inject("store")
@observer
class extends Component {
  state = {
    currency: {name:'', sign:'', currency_id:''},
    loading: true,
    schema: [],
    unit: []
  };

  componentDidMount() {
    const { currency_id } = this.props.match.params;
    const URL = PUBLIC_URL + API_PREFIX + "currencies/getone/" + currency_id;
    checkResponse(URL, "get", 1).then(result => {
      if (result.data) return this.insertData(result);
      if (result.response)
        return this.setState({ err: result.response.data.error_description });
    });
  }

  insertData(data) {
    const cur = data.data.currency[0];
    let currency = {...this.state.currency}
    currency['name'] = cur.currency_label;
    currency['sign']= cur.currency_value;
    currency['currency_id']= cur.currency_id;
    this.setState({ currency, loading: false });
  }


  _folderHeader(title) {
    return (
      <div className="header-folder">
        <h4>{title}</h4>
      </div>
    );
  } 

    handleChange = e =>{
      const currency = {...this.state.currency}
      currency[e.target.name] = e.target.value
      this.setState({currency})
     
    }

  _basicInputs() {
    if (!this.state.loading) {
      return (
        <div>
         
          {this.form()}
        </div>
      );
    }
  }

  _basicSubmit =(e)=> {
    e.preventDefault()
    const currency = this.state.currency;
    const { currency_id } = this.props.match.params;

    const URL = PUBLIC_URL + API_PREFIX + "currencies/update/" + currency_id;
    checkResponse(URL, "put",
      {currency_label: currency.name,
      currency_value: currency.sign,
      currency_id : currency.currency_id
    })
      // .then(_ => window.location.reload())
      .then(r => {
        Swal.fire('Success', 'Currency updated successfully!', 'success')
        .then(result =>{
          if(result.value){
            this.props.history.push('/currencies/view')
          }
        })
      });
  }

  form = () => {
    return (
    
        <form onSubmit={this._basicSubmit}>
         
          
            <div className="form-group-container">
            <label>{intl.get("LABEL") || "Label"}</label>
            <select
              style={{border:'none'}}
                class="form-control"
                onChange={e => this.setState({ currency: {name:e.target.value.split('|')[0],sign:e.target.value.split('|')[1]} })}
              value={this.state.currency.name+'|'+this.state.currency.sign}
>
                {currencies.map(currency => (
                    <option key={currency.sign} value={currency.name+'|'+currency.sign}>{currency.name}</option>
                    ))}
              </select>
            {/* <input
              className="form-control text-center"
              name="currency_label"
              onChange={this.handleChange}
              value={this.state.currency.currency_label}
            /> */}
            
          </div>
            
            <div className="form-group-container">
            <label>{intl.get("CURRENCY") || "Currency"}</label>
            <input
                style={{backgroundColor:'transparent', textAlign:'center'}}
                type="text"
                class="form-control"
                id="exampleInputPassword1"
                value={this.state.currency.sign}
                disabled
                />
            {/* <input
              className="form-control text-center"
              name="currency_value"
              onChange={this.handleChange}
              value={this.state.currency.currency_value}
            /> */}
          </div>
           <div className="my-3 d-flex justify-content-center">
           <button type="submit" className="btn-ghost mr-2">
              {intl.get("SUBMIT") || "Submit"}
            </button>
            <button
              type="button"
              onClick={() => this.props.history.push('/currencies/view')}
              className="btn-ghost"
            >
              {intl.get("BACK") || "Back"}
            </button>
           </div>
       
        </form>
     
    );
  };

  _basic() {
    return (
      <>
       {this._basicInputs()}
      </>
    );
  }
       

  render() {
    return (

<header className="header">
<div className="text-center mt-4">
        <h2 className="primary-heading"> {intl.get('EDIT_CURRENCY') || 'Edit Currency'}</h2>
  </div>
  <div className="action-container width-60" >
    <div className="table-container padding-2" >
    {this._basic()}
    </div>
  </div>
</header>

     
    );
  }
}
