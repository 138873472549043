import ReactTable from "react-table";
import React, { Component } from "react";
import Swal from 'sweetalert2'
// import 'sweetalert2/src/sweetalert2.scss'
import { PUBLIC_URL, API_PREFIX } from "../../../commons/config";
import checkResponse from "../../../commons/CheckResponse";
import { inject, observer } from "mobx-react";
import Pagination from "../../../components/commons/table/pagination";
import formatMoney from "../../../commons/formatMoney";
import { Card, CardText, RaisedButton } from "material-ui";
import { Confirm, Button } from "semantic-ui-react";
// import expensesPrint from "./expensesPrint";
import intl from 'react-intl-universal';



@inject("store")
@observer
class Expenses extends Component {
  state = { currentData: [] };

  constructor(props) {
    super(props);
    this.reactTable = React.createRef();
    this.columns = [
      {
        Header: intl.get('TITLE') || "Title",
        accessor: "title", // String-based value accessors!
        style,
        Filter: ({ filter, onChange }) => (
          <input
            type="text"
            placeholder={intl.get("SEARCH") || "Search"}
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
            style={{ width: "100px" }}
          />
        ),
        filterMethod: (filter, row, column) => {
          const id = filter.pivotId || filter.id;
          return row[id] !== undefined
            ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
            : true;
        }
      },
      {
        Header: intl.get('DESCRIPTION') || "Description",
        accessor: "description",
        style,
        Filter: ({ filter, onChange }) => (
          <input
            type="text"
            placeholder={intl.get("SEARCH") || "Search"}
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
            style={{ width: "100px" }}
          />
        ),
        filterMethod: (filter, row, column) => {
          const id = filter.pivotId || filter.id;
          return row[id] !== undefined
            ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
            : true;
        }
      },
      {
        Header: intl.get('CREATED_BY') || "Created By",
        accessor: "created_by", // String-based value accessors!
        style,
        Filter: ({ filter, onChange }) => (
          <input
            type="text"
            placeholder={intl.get("SEARCH") || "Search"}
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
            style={{ width: "100px" }}
          />
        ),
        filterMethod: (filter, row, column) => {
          const id = filter.pivotId || filter.id;
          return row[id] !== undefined
            ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
            : true;
        }
      },

      {
        Header: intl.get('AMOUNT') || "Amount",
        accessor: "amount",
        style,
        Filter: () => {
          return null;
        },
        Cell: props => {
          console.log(props)
          return (
            <p style={style}>
              {props.value}
              {" " + this.state.currency}
            </p>
          );
        },
        sortMethod: (a, b) => {
          if (a.length === b.length) {
            return a > b ? 1 : -1;
          }
          return a.length > b.length ? 1 : -1;
        },
        style,
        Footer: ({ data }) => {
          let arr = [];
          if (data.length) {
            data.map(d => {
              arr.push(parseFloat(d.amount));
            });

          }

          let sum = 0;
          if (arr.length) {
            sum = arr.reduce((acc, val) => {
              return acc + val;
            });
          }
          return (
            <span>
              <strong>{intl.get('TOTAL') || 'Total'} : </strong>
              {sum.toFixed(2)} {localStorage.getItem("currency")}
            </span>
          );
        }
      },
      {
        Header: intl.get('DATE') || "date",
        accessor: "created_at",
        style,
        filterMethod: (filter, row) => {
          return row["created_at"] == filter.value;
        },
        Filter: ({ filter, onChange }) => (
          <input
            type="date"
            onChange={event => onChange(event.target.value)}
            style={{ width: "100%" }}
          />
        )
      },

      {
        Header: intl.get('NOTES') || "Notes",
        accessor: "notes",
        style,
        Filter: ({ filter, onChange }) => (
          <input
            type="text"
            placeholder={intl.get("SEARCH") || "Search"}
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
            style={{ width: "100px" }}
          />
        ),
        filterMethod: (filter, row, column) => {
          const id = filter.pivotId || filter.id;
          return row[id] !== undefined
            ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
            : true;
        }
      },
      {
        Header: "",
        Cell: props => {
          return (
            <div>
              <span onClick={_ => this.handleClick(props)}>
                <i className="fa fa-pencil mr-2" style={{ fontSize: '1.2rem', cursor: 'pointer' }}></i>
              </span>

              <span onClick={() => this.deleteExpense(props.original.id)}>
                <i className="fa fa-trash" style={{ fontSize: '1.2rem', cursor: 'pointer' }}></i>
              </span>

            </div>
          )
        },
        Filter: ({ filter, onChange }) => null,
        style
      }





    ];
  }

  deleteExpense = id => {
    const URL = PUBLIC_URL + API_PREFIX + "expenses/delete/" + id;
    Swal.fire({
      title: "Are you sure?",
      text:
        "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(result => {
      if (result.value) {

        const expenses = this.state.expenses.filter(d => d.id !== id);
        this.setState({ expenses });
        try {
          checkResponse(URL, "delete").then(r => {
            if (r.data) {
              // this.setState({ open: false });
              // window.location.reload();
            }
          });
          Swal.fire("Deleted!", "Operator has been deleted.", "success");
        } catch (ex) {
          Swal.fire("Oops...", "Something went wrong", "error");
        }
      }
    });

  };

  handleClick = props => {
    this.props.history.push(`/expenses/edit/${props.original.id}`, {
      item: { ...props.original }
    });
  };

  componentDidMount() {
    this.setState({ currency: localStorage.getItem('currency') })
    this.getData();
  }

  getData = () => {
    const URL =
      PUBLIC_URL +
      API_PREFIX +
      "expenses/view/" +
      this.props.store.get_business_id();
    checkResponse(URL, "get").then(r => {
      if (r.data.expenses) {
        let expenses = r.data.expenses;

        for (var i = 0; i <= expenses.length - 1; i++) {
          //   expenses[i].amount += " $";
          expenses[i].created_at = expenses[i].created_at.split("T")[0];
        }
        return this.setState({ expenses, currentData: expenses });
      }
    });
  };

  onTableViewChange = () => {
    const current = this.reactTable.current;
    if (current) {
      const page = current.state.page;
      const pageSize = current.state.pageSize;
      const allData = current.getResolvedState().sortedData;
      const startIdx = page * pageSize;
      const currentData = allData
        .slice(startIdx, startIdx + pageSize)
        .map(item => item._original);
      this.setState({ currentData });
    }
  };

  export = (tableID, filename) => {
    var downloadLink;
    tableID = "data_table";
    filename = "Expenses";
    var dataType = "application/vnd.ms-excel";
    var tableSelect = document.getElementById(tableID);
    var tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");

    // Specify file name
    filename = filename ? filename + ".xls" : "excel_data.xls";

    // Create download link element
    downloadLink = document.createElement("a");

    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
      var blob = new Blob(["\ufeff", tableHTML], {
        type: dataType
      });
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      // Create a link to the file
      downloadLink.href = "data:" + dataType + ", " + tableHTML;

      // Setting the file name
      downloadLink.download = filename;

      //triggering the function
      downloadLink.click();
    }
  };

  tableData = () => {
    const currentData = this.state.currentData || [];
    const currency = this.state.currency;
    let arr = [];
    currentData.map(cd => {
      arr.push(cd.amount);
    });

    let sum = 0;
    if (arr.length) {
      sum = arr.reduce((acc, val) => {
        return acc + val;
      });
    }
    console.log('sum is :', sum)
    // sum = formatMoney(sum);
    return (
      <div id="docx" hidden={true} >
        <div className="container">
          <table
            className="table"
            id="data_table"
            style={{ border: "1", borderCollapse: "collapse", width: "100%" }}
          >

            <tr>
              <td style={styles.tblheader}>Title</td>
              <td style={styles.tblheader}>Description</td>
              <td style={styles.tblheader}>Created By</td>
              <td style={styles.tblheader}>Amount</td>
              <td style={styles.tblheader}>Date</td>
              <td style={styles.tblheader}>Notes</td>
            </tr>
            {currentData.map(cd => (
              <tr>
                <td styles={styles.cells}>{cd.title}</td>
                <td styles={styles.cells}>{cd.description}</td>
                <td styles={styles.cells}>{cd.created_by}</td>
                <td styles={styles.cells}>{cd.amount + ' ' + currency}</td>
                <td styles={styles.cells}>{cd.created_at}</td>
                <td styles={styles.cells}>{cd.notes}</td>
              </tr>
            ))}
            <tr>
              <td styles={styles.cells}></td>
              <td styles={styles.cells}></td>

              <td style={{ fontWeight: "bold", textAlign: 'center' }}>{intl.get('TOTAL') || 'Total'}</td>
              <td style={{ fontWeight: "bold", color: "#00c292", textAlign: 'left' }}>
                {sum} {currency}
              </td>
              <td styles={styles.cells}></td>

              <td styles={styles.cells}></td>

            </tr>
          </table>
        </div>
      </div>
    );
  };

  render() {
    return (
      <header className="header">
        <div className="mt-4">

          <div style={{ display: 'flex', marginBottom: 10, justifyContent: 'space-between', alignItems: 'center', paddingLeft: 30, paddingRight: 30 }}>
            <h2 className="primary-heading"> {intl.get('EXPENSES') || 'Expenses'}</h2>
            <div className="d-flex">
                <button className="btn-green btn-resp mr-2" onClick={() => this.export()}>
                  {intl.get('EXPORT_EXCEL_SHEET') || 'Export excel sheet'}
                </button>
                <button className="whitePrimaryBtn-design" onClick={() => this.props.history.push("/expenses/add")}>
                  {intl.get('ADD_NEW') || 'Add New'}
                </button>
              </div>
          </div>
        </div>
        <div className="action-container">
          <div className="table-container">
            <ReactTable
              ref={this.reactTable}
              data={this.state.expenses}
              columns={this.columns}
              style={{ padding: 2 }}
              showPagination
              pageSizeOptions={[10, 20, 30, 40]}
              defaultPageSize={10}
              sortable
              previousText={intl.get('PREVIOUS') || 'Pevious'}
              nextText={intl.get("NEXT") || "Next"}
              // loadingText: 'Loading...',
              noDataText={intl.get("NO_ROWS_FOUND") || "No rows found"}
              pageText={intl.get("PAGE") || "Page"}
              ofText={intl.get("OF") || "of"}
              rowsText={intl.get("ROWS") || "rows"}
              multiSort
              resizable
              // PaginationComponent={Pagination}
              className="-stripped -highlight"
              filterable
              defaultSortDesc
              onPageChange={this.onTableViewChange}
              onPageSizeChange={this.onTableViewChange}
              onSortedChange={this.onTableViewChange}
              onExpandedChange={this.onTableViewChange}
              onFilteredChange={this.onTableViewChange}
              onResizedChange={this.onTableViewChange}
            />
          </div>
        </div>
        {this.tableData()}
      </header>


    );
  }
}

export default Expenses;

const styles = {
  tblheader: {
    // color: "black",
    fontWeight: 600,
    fontSize: '16px',
    // textAlign:'left'
  },
  cells: {
    border: "1px solid #dddddd",
    textAlign: "left",
    padding: "8px",
    fontSize: '14px'

  }
};

const style = {
  marginLeft: 25,
  userSelect: "none",
  // color: "navy",
  fontWeight: 400,
  currency: '$'
};
