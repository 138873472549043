import React from 'react';
import { RaisedButton, Chip } from "material-ui";

export default ({ text, btnClass, color, iconName, iconClassName, onPress, style,primary,backgroundColor }) => {
    const icon = iconClassName ? <i className={iconClassName}>{iconName}</i> : [];
    const defaultStyles = {
        borderRadius: 5, padding: 10,
        background: 'transparent',
        color: '#3c4043',
        border: '1px solid rgba(0, 0, 0, 0.2)'
    };

    return (
        // <div style={{color:'white'}}>

        <RaisedButton
            primary={primary}
            backgroundColor={backgroundColor || "#2b3136"}
            onClick={onPress}
            textColor='white'
            color='white'
            variant="contained"
            className={btnClass} 
            style={styles}
            label={text}
            />
            // </div>
    )
}


const styles = {
    color: 'white',
    border: '2px solid white',
    margin:'1px',
    marginRight: 10,

    // width: '60px',
    // height: '31px',
    // borderRadius: '20px',
    // fontSize: '12px',
    // fontWeight: '600',
    // lineHeight: '1.5',
    // color: 'white'
}