import React, { Component } from "react";
import { inject, observer } from "mobx-react";

import { RaisedButton } from "material-ui";
import moment from "moment";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { PUBLIC_URL, API_PREFIX } from "../../../commons/config";
import checkResponse from "../../../commons/CheckResponse";

export default
@inject("store")
@observer
class InvoicePrint extends Component {
  state = {
    client: { email: "" },
    details: [],
    invoice: { created_at: "", payment_method: "" },
    business_details: { address: "", phone: "", fn_number: "" },
    pos: false,
    emailMessage: "EMAIL",
  };
  componentDidMount() {
    this.getInvoice();
    this.getBusiness();
  }

  getBusiness = () => {
    const URL =
      PUBLIC_URL +
      API_PREFIX +
      "business/" +
      this.props.store.get_business_id();
    checkResponse(URL, "get").then((r) =>
      this.setState({ business_details: r.data.business })
    );
  };

  getInvoice = () => {
    const URL =
      PUBLIC_URL +
      API_PREFIX +
      "invoices/getone/" +
      this.props.match.params.invoice_id;
    checkResponse(URL, "get").then((r) => {
      if (r.data) {
        this.setState({
          invoice: r.data.invoice,
          details: r.data.details,
          client: r.data.client,
        });
      }
    });
  };

  print(pageType) {
    const head = document.getElementsByTagName("head")[0].innerHTML;
    const doc = document.getElementById(pageType).innerHTML;
    const myWindow = window.open();
    myWindow.document.write(`<html>${head}<body>${doc}</body>`);
    myWindow.document.close();
    myWindow.focus();
    setTimeout(() => {
      myWindow.print();
      myWindow.close();
    }, 500);
  }

  downloadInvoice = () => {
    const input = document.getElementById("doc");
    html2canvas(input).then((canvas) => {
      let imgData = canvas.toDataURL("image/png");

      const pdf = new jsPDF("p", "mm", "a4");

      var width = pdf.internal.pageSize.getWidth();
      var height = pdf.internal.pageSize.getHeight();

      pdf.addImage(imgData, "PNG", 0, 0, width, height);
      pdf.save("invoice.pdf");
    });
  };

  sendEmail = async () => {
    this.setState({ emailMessage: "Please Wait" });
    const { invoice, details, client, business_details } = this.state;
    const URL = PUBLIC_URL + API_PREFIX + "invoices/email";
    const data = {
      invoice,
      details,
      client,
      business_details,
    };
    await checkResponse(URL, "post", data).then((r) => {
      this.setState({ emailMessage: "DONE" });
      if (!r.data) {
        return alert("error check invoice details");
      }
    });
  };
  render() {
    return (
      <div style={{ borderTop: "2px solid black", backgroundColor: 'white' }}>
        <div className="text-center mt-4">
          <h2 className="primary-heading" style={{ color: "black" }}>
            {" "}
            View Invoice
          </h2>
        </div>
        <div className="action-container">
          <div className="row">
            <div className="col-md-7 ml-auto">
              <div className="d-flex">
                <button className="btn-red" onClick={(_) => this.print("doc")}>
                  PRINT A4
                </button>
                <button
                  className="btn-red mx-2"
                  onClick={(_) => this.print("pos")}
                >
                  PRINT POS
                </button>
                <button className="btn-red mr-2" onClick={this.downloadInvoice}>
                  DOWNLOAD
                </button>
                <button className="btn-red" onClick={this.sendEmail}>
                  {this.state.emailMessage}
                </button>
              </div>
            </div>
          </div>

          {this.normalSize()}
        </div>

        {this.posSize()}
      </div>
    );
  }

  posSize = () => {
    const currency = localStorage.getItem("currency") || "$";
    const { client, invoice, details } = this.state;
    let status = "Paid";
    if (invoice.status_id == "2") {
      status = "Not Paid";
    }

    let discountAvailable = false;
    details.map((item) => {
      if (item.discount !== 0) {
        discountAvailable = true;
      }
    });

    return (
      <div id="pos" style={{ display: "none" }}>
        <div id="invoice-POS">
          <center id="top">
            <div className="logo" />
            <div className="info">
              <h3>{moment(invoice.created_at).format("LLLL")}</h3>
            </div>
            {/*End Info*/}
          </center>
          {/*End InvoiceTop*/}
          <div id="mid">
            <div className="info">
              <h3>Contact Info</h3>
              <p>
                Name : {client.name}
                <br />
                {/* Email : JohnDoe@gmail.com
								<br /> */}
                Phone : {client.mobile}
                <br />
              </p>
            </div>
          </div>
          {/*End Invoice Mid*/}
          <div id="bot">
            <div>
              <table id="posTable" className="table table-responsive">
                <thead>
                  <tr>
                    <th>Service</th>
                    <th>Qty</th>
                    <th>Price</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {details.map((item) => (
                    <tr className="service">
                      <td className="tableitem">
                        <p className="itemtext">{item.title}</p>
                      </td>
                      <td className="tableitem">
                        <p className="itemtext">{item.quantity}</p>
                      </td>
                      <td className="tableitem">
                        <p className="itemtext">{item.amount}</p>
                      </td>
                      <td className="tableitem">
                        <p className="itemtext">
                          {item.quantity * item.amount}
                        </p>
                      </td>
                    </tr>
                  ))}

                  <tr className="tabletitle">
                    <td />
                    <td className="Rate">
                      <h3>Total</h3>
                    </td>
                    <td className="payment">
                      <h3>{invoice.total_amount}</h3>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/*End Table*/}
            <div id="legalcopy">
              <p className="legal">
                <strong>Notes : </strong>&nbsp; {invoice.notes}
              </p>
            </div>
            <div id="legalcopy">
              <p className="legal">
                <strong>Thank you for your business!</strong>
              </p>
            </div>
          </div>
          {/*End InvoiceBot*/}
        </div>
        {/*End Invoice*/}
      </div>
    );
  };

  normalSize = () => {
    const { client, invoice, details } = this.state;
    const currency = invoice.currency || "$";
    let status = "Paid";
    if (invoice.status_id == "2") {
      status = "Not Paid";
    }

    let discountAvailable = false;
    details.map((item) => {
      if (item.discount !== 0) {
        discountAvailable = true;
      }
    });
    const logo = this.state.business_details.logo;

    return (
      <div>
        <title>Invoice Print</title>

        <div
          id="doc"
          style={{
            // width: '990px',
            height: "29.7cm",
            width: "21cm",
            margin: "0 auto",
            position: "relative",
            top: "30px",
          }}
        >
          <div className="panel panel-default">
            <div className="panel-wrapper">
              <div className="inv-template">
                <div className="inv-template-bdy">
                  <table className="table table-bordered">
                    <tbody>
                      <tr style={{ width: "20px", textAlign: "left" }}>
                        {/* <div style={{background:'red',textAlign:'right'}} > */}
                        <td className="mylogo">
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: "1fr 1fr",
                            }}
                          >
                            <div style={{ textAlign: "right" }}>
                              {logo && logo !== "null" ? (
                                <img
                                  src={`${PUBLIC_URL}` + logo}
                                  style={{
                                    width: "90px",
                                    height: "90px",
                                    padding: "0px",
                                    margin: "0px",
                                  }}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                            <div>
                              <h2 style={{ paddingTop: "25px" }}>
                                {/* {this.props.store.get_business_name()} */}
                              </h2>
                            </div>
                          </div>
                        </td>

                        <td className="font-24 text-right">
                          <div style={{ fontSize: "2rem" }}>INVOICE</div>
                        </td>
                      </tr>
                      <tr className="inv-meta-container">
                        <td>
                          <div className="inv-bill-to">
                            <p>Bill To</p>
                            <span className="font-14">
                              email :{" "}
                              {client.email.length > 36
                                ? "-----"
                                : client.email}
                            </span>
                            <br />
                            <span className="font-14">
                              name : {client.name}
                            </span>
                            <br />
                            <span className="font-14">
                              phone : {client.mobile}
                            </span>
                          </div>
                        </td>
                        <td className="text-right">
                          <div className="inv-meta">
                            <p>
                              <span># : </span>
                              <span>INV-58554</span>
                            </p>
                            <p>
                              <span>Invoice Date : </span>
                              <span>
                                {moment(invoice.created_at).format("LL")}
                              </span>
                            </p>
                            <p>
                              <span>Status : </span>
                              <span>{status}</span>
                            </p>
                            <p>
                              <span>Payment Method : </span>
                              <span>
                                {invoice.payment_method_id === "1"
                                  ? "Credit Card"
                                  : invoice.payment_method_id === "2"
                                  ? "Cash"
                                  : invoice.payment_method_id === "3"
                                  ? "Cheque"
                                  : "----"}
                              </span>
                            </p>
                            {this.state.business_details.fn_number && (
                              <p>
                                <span>FN :</span>
                                <span className="ml-1">
                                  {this.state.business_details.fn_number}
                                </span>
                              </p>
                            )}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="inv-template-item mt-2">
                    <table className="table table-bordered">
                      <thead className="thead-light">
                        <tr>
                          <th>Service</th>
                          <th>Qty</th>
                          <th>Unit Cost</th>
                          {discountAvailable ? <th>Discount</th> : ""}
                          <th>Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {details.map((item) => (
                          <tr>
                            <td className="item">
                              <p>{item.title}</p>
                              <span />
                            </td>
                            <td>{item.quantity}</td>
                            <td>
                              {item.amount} {currency}
                            </td>
                            {discountAvailable ? (
                              <td>{item.discount} %</td>
                            ) : (
                              ""
                            )}

                            <td>
                              {item.net_amount} {currency}
                            </td>
                          </tr>
                        ))}

                        <tr className="total">
                          <td colSpan={2}>
                            <div className="d-flex">
                              <div>Business: </div>
                              <div className="d-flex flex-column">
                                <span className="ml-2">
                                  {this.props.store.get_business_name()}{" "}
                                </span>
                              </div>
                            </div>
                          </td>{" "}
                          {/* <td rowSpan={6} colSpan={discountAvailable ? 3 : 2}>
                              <p>sdlkja jasdk lasjd lkasjd kjas lkdjaslk jdaslkj daslkj dklasj dklajd aslkas dklj</p>
                            </td> */}
                          <td colSpan={1} style={{ backgroundColor: "#eee" }}>
                            <span>Sub Total</span>
                          </td>
                          <td colSpan={2} style={{ backgroundColor: "#eee" }}>
                            <span>
                              {invoice.total_amount} {currency}
                            </span>
                          </td>
                        </tr>
                        {invoice.discount == 0 ? (
                          ""
                        ) : (
                          <tr className="total">
                            <td colSpan={2}>
                              <span>Address : </span>
                              <span>{this.state.business_details.address}</span>
                            </td>
                            <td colSpan={1}>
                              <span>Discount</span>
                            </td>
                            <td colSpan={1}>
                              <span>{invoice.discount} %</span>
                            </td>
                          </tr>
                        )}
                        <tr className="total">
                          <td colSpan={2}>
                            <span>Address : </span>
                            <span>{this.state.business_details.address}</span>
                          </td>
                          <td colSpan={1} style={{ backgroundColor: "#eee" }}>
                            <span>Vat</span>
                          </td>
                          <td colSpan={2} style={{ backgroundColor: "#eee" }}>
                            <span>{invoice.Vat} %</span>
                          </td>
                        </tr>
                        <tr className="total">
                          <td colSpan={2}>
                            <span>Contact : </span>
                            <span>
                              {this.state.business_details.business_phone}
                            </span>
                          </td>

                          <td colSpan={1} style={{ backgroundColor: "#eee" }}>
                            <span>Total</span>
                          </td>
                          <td colSpan={2} style={{ backgroundColor: "#eee" }}>
                            <span>
                              {invoice.net_amount} {currency}
                            </span>
                          </td>
                        </tr>
                        <tr className="total">
                          <td style={{ textAlign: "left" }}>Notes:</td>
                          <td colSpan={4}>{invoice.notes}</td>
                        </tr>
                        <tr></tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="inv-template-ftr" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}
