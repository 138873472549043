import React, { Component } from "react";
import moment from 'moment'
import intl from 'react-intl-universal';


class InvoiceCard extends Component {

  state={
    currency:'$'
  }

  componentDidMount(){
    this.setState({currency:localStorage.getItem('currency')})
  }

  getInvStatus = (status)=>{
    if(status === 'paid'){
      status = intl.get('PAID') || 'paid'
    }
    if(status === 'unpaid'){
      status = intl.get('UNPAID') || 'unpaid'
    }
    return status
  }

  render() {
    let invoice = this.props.props;
    const date = moment(invoice.created_at).add(3,'hours').format('LL')
    const time = ''
 
    return (
                  <div className="custom-card" >
                    <div className="card-header">
                      <div className="text-center">
                         {/* <h5 className="tertiary-heading">{invoice.name}</h5> */}
                         <button className="btn-default-small" onClick={_=>this.props.history.push("/appointments/" + invoice.appointment_id)}>
                         {intl.get('GO_TO_APPOINTMENT') || 'Go to Appointment'}
                         </button>
                      </div>
                    </div>
                    <div className="custom-card-body-invoice">
                      <div className="row">
                        <div className="col-md-12">
                          <i className="fa fa-calendar mr-3"></i>
                          {date} {time}
                        </div>
                        <div className="col-md-12 mt-3" >
                          {invoice.status === 'unpaid' ?
                          <div className='d-flex align-items-center' style={{justifyContent:'center'}}>
                           <i className="fa fa-cross-circle"
                            style={{
                              color: 'red',
                              fontSize: '1.8rem',
                              marginRight:'2rem'
                              
                            }}></i>
                           <span style={{color:'red'}}>{this.getInvStatus(invoice.status)}</span>
                           </div>
                           :
                           <div className='d-flex align-items-center' style={{justifyContent:'center'}}>
                           <i className="fa fa-check-circle"
                           style={{
                             color: '#00c292',
                             fontSize: '1.8rem',
                             marginRight:'2rem'
                             
                           }}></i>
                           <span style={{color:'#00c292'}}>{this.getInvStatus(invoice.status)}</span>
                           </div>
                           }
                        </div>
                        <div className="col-md-12">
                          <div className="d-flex align-items-center" style={{justifyContent:'center'}}>
                            <i className="fa fa-money"  
                             style={{
                             color: '#00c292',
                             fontSize: '1.8rem',
                             marginRight:'2rem'
                             
                           }}></i>
                            <span style={{color:'#00c292'}}>{invoice.net_amount + ' ' + this.state.currency}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                     <div className="card-footer ">
                       <div className="d-flex justify-content-center">
                       <div className='circle-icon circle-icon-primary mr-2' 
                       onClick={_ =>
                        this.props.history.push(
                          "/view/invoices/print/" + invoice.id
                        )
                      }>
                         <i className="fa fa-eye"></i>
                       </div>
                       <div className='circle-icon circle-icon-warning mr-2'
                        onClick={_ =>
                          this.props.history.push({
                            pathname: "/view/invoices/edit/" + invoice.id,
                            state: {
                              patient_id: invoice.client_id
                            }
                          })
                        }>
                         <i className="fa fa-pencil"></i>
                       </div>
                       <div className='circle-icon circle-icon-danger'
                       onClick={_ => this.props.deleteInvoice(invoice.id)}>
                         <i className="fa fa-trash"></i>
                       </div>
                       </div>
                     </div>
               
               
              </div>
    );
  }
}

export default InvoiceCard;
