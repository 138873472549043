
import React from 'react';
import moment from 'moment'

import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Excel = (props) => {
  let contents, fileName ;
  if(props.reportType==='Clients'){
    fileName='Clients'
  contents = <ExcelFile element={<button className='btn-ghost'>Export</button>}
              filename={fileName }>

    <ExcelSheet data={props.data} name={fileName}>
        <ExcelColumn label="Name" value="name"/>
        <ExcelColumn label="Email" value="email"/>
        <ExcelColumn label="Country Code" value="country_code"/>
        <ExcelColumn label="Mobile" value="mobile"/>
        <ExcelColumn label="Created  Date" value= {(col) => moment(col.created_at).format('DD/MM/YYYY')}/>
       
    </ExcelSheet>
   </ExcelFile>
  }else if(props.reportType === 'Appointments'){
    fileName='Appointments'
contents = <ExcelFile element={<button className='btn-ghost'>Export</button>} filename={fileName}>

<ExcelSheet data={props.data} name={fileName}>
    <ExcelColumn label="Doctor" value={(col)=>col.doctor.name}/>
    <ExcelColumn label="Patient" value={(col)=> col.patient.name}/>
    <ExcelColumn label="Doctor Mobile" value={(col)=> col.doctor.country_code + col.doctor.mobile}/>
    <ExcelColumn label="Patient Mobile" value={(col)=> col.patient.country_code + col.patient.mobile}/>
    <ExcelColumn label="Appointment Date" value="start_date"/>
    <ExcelColumn label="Start Time" value="time_start"/>
    <ExcelColumn label="End Time" value="time_end"/>
    <ExcelColumn label="Status" value="status"/>
    <ExcelColumn label="Created Date" value= {(col) => moment(col.created_at).format('DD/MM/YYYY')}/>

   
</ExcelSheet>
</ExcelFile>
  } 
     
  return ( 
    <>
{contents}
</>
   );
}
 
export default Excel;

 