import React, { Component } from "react";
import { PUBLIC_URL, API_PREFIX } from "../../../commons/config";
import checkResponse from "../../../commons/CheckResponse";
import intl from 'react-intl-universal';
import './AppointmentCard.css'

const call_url = "https://cpaas.montymobile.com/visual-designer/services/apps/AP0c5a11e6ff6c46db81b0c38836fde83d/"
const app_phone = '15855421035'

class AppointmentCard extends Component {

  state = {
    hoverstyle: { padding: 3 }
  };

  deleteAppointment = async id => {
    if (window.confirm("are you sure ?")) {
      const URL = PUBLIC_URL + API_PREFIX + "appointments/delete/" + id;
      checkResponse(URL, "delete").then(r => {
        return window.location.reload();
      });
    }
  };

  getFlagValue = (flag) => {
    console.log("getFlagValue intl.get(normal)", intl.get("NORMAL"))
    if (flag == 'normal') {
      flag = intl.get("NORMAL") || 'Normal'
    }
    if (flag == 'medium') {
      flag = intl.get("MEDIUM") || 'Medium'
    }
    if (flag == 'HIGH') {
      flag = intl.get("high") || 'High'
    }
    if (flag == 'VIP') {
      flag = intl.get("VIP") || 'VIP'
    }
    if (flag == 'other') {
      flag = intl.get("OTHER") || 'Other'
    }
    return flag
  }


  getStatusValue = (status) => {
    if (status == 'Pending') {
      status = intl.get("PENDING")
    }
    if (status == 'Done') {
      status = intl.get("DONE")
    }
    if (status == 'Waiting') {
      status = intl.get("WAITING")
    }
    if (status == 'cancelled') {
      status = intl.get("CANCELLED")
    }
    return status
  }

  handleClick = () => {
    const URL =
      PUBLIC_URL + API_PREFIX + "invoices/appointment/" + this.props.id;
    checkResponse(URL, "get", 1).then(r => {
      if (r.data.invoice) {
        return this.props.history.push(
          "/view/invoices/print/" + r.data.invoice.id
        );
      } else {
        return window.alert(intl.get("NO_INVOICE_GENERATED") || "no invoice generated for this appointment");
      }
    });
  };

  options = id => {
    if (this.props.waiting) {
      return (
        <button
          style={{ fontSize: '0.7rem' }}
          className="btn-red"
          onClick={() => this.props.addToCalendar(id)}
        >
          {intl.get('ADD_TO_CALENDAR') || 'Add to calendar'}
        </button>


      );
    }
    return (
      <div className='d-flex flex-column'>
        <div className='invoiceStatus'>
          <span
            className="appointmentStatus"
            style={{
              background:
                this.props.status == "Done"
                  ? "#00c292"
                  : this.props.status == "Cancelled"
                    ? "red"
                    : this.props.status == "Waiting"
                      ? "orange"
                      : this.props.status == "Pending"
                        ? "#6ba5c1"
                        : "",
              padding: '4px',

              cursor: 'pointer',
              borderRadius: '20px',
              width: '100%',
              height: '100%',
              fontSize: '10px'

            }}
          >
            {this.getStatusValue(this.props.status)}
          </span>
          <span onClick={this.handleClick}
            style={{
              color: '#fff',
              backgroundColor: '#f44336',
              padding: '4px',
              cursor: 'pointer',
              borderRadius: '20px',
              // marginLeft:'5px',
              fontSize: '10px'
            }}>
            {intl.get("GENERATED_INVOICE") || 'Invoice'}
          </span>

        </div>
        <div className="card-action" style={{ marginTop: '0.5rem' }}>
          {/* <div>&nbsp; </div> */}
          <span style={{ cursor: 'pointer' }}
            onClick={_ =>
              this.props.history.push("/appointments/" + this.props.id)
            }>
            <img src={require('../../../assets/svgs/edit.svg')} alt='edit appointment' />
          </span>

          <span style={{ cursor: 'pointer', marginLeft: '1rem' }}
            onClick={_ => this.deleteAppointment(this.props.id)}>
            <img src={require('../../../assets/svgs/delete.svg')} alt='delete appointment' />
          </span>

        </div>
      </div>
    );
  };

  render() {
    let style = {
      background: "#00c292",
      color: "white"
    };
    return (
      <div className="card-block mb-2">
        <div className="card-layer"></div>
        <div className='custom-card-body'>

          <div className="row">
            <div className="col-md-1 d-none d-md-block">
              <div className='coloredleftBlock' style={{
                background:
                  this.props.status == "Done"
                    ? "#00c292"
                    : this.props.status == "Cancelled"
                      ? "red"
                      : this.props.status == "Waiting"
                        ? "orange"
                        : this.props.status == "Pending"
                          ? "#3e87c6"
                          : "",

                color: this.props.status == "con" ? "white" : "white"
              }}>
              </div>
            </div>
            <div className="col-md-1 col-6 col-sm-4">

            <div className="appointment-data clientProfile d-flex flex-column align-items-center"
                onClick={_ =>
                  this.props.history.push(
                    "/clients/view/" + this.props.patient.id
                  )
                }
              >

<i
                  className="fa fa-user-md"
                  style={{ fontSize: "1.8rem", color: '#fff' }}
                />
                <span className='clientFullName mt-2' style={{ color: '#fff' }}>
                {this.props.doctor.split(" ")[0] || this.props.doctor}
                </span>
              </div>

            </div>
            <div className="col-md-2 col-xs-6 col-sm-4 col-6">

              <div className="appointment-data clientProfile d-flex flex-column align-items-center"
                onClick={_ =>
                  this.props.history.push(
                    "/clients/view/" + this.props.patient.id
                  )
                }
              >

                <i
                  className="fa fa-user-circle app-icons"
                  style={{ fontSize: "1.8rem", color: '#fff' }}
                />
                <span className='clientFullName mt-2' style={{ color: '#fff' }}>
                  {this.props.patient.name}
                </span>
              </div>
            </div>
            <div className="col-md-1 col-xs-6 col-sm-4 col-6">
              <a className="appointment-data clientContact d-flex flex-column align-items-center " style={{ cursor: 'pointer' }}
                title={`Call ${this.props.patient.country_code}${this.props.patient.mobile}`}
                href={`tel:${this.props.patient.country_code}${this.props.patient.mobile}`}
              >
                <img src={require('../../../assets/svgs/phone.svg')} alt='phone number'
                  style={{ width: '1.8rem', margin: '0 auto' }} />
                <span className='clientPhone mt-2' style={{ color: '#fff' }}>
                  {this.props.patient.mobile}
                </span>
              </a>
            </div>
            <div className="col-md-1 d-none d-md-block">
              <div className="appointment-data appointmentPriorityWrapper d-flex flex-column align-items-center">
                <img src={require('../../../assets/svgs/white-flag.svg')} alt='white flag'
                  style={{ width: '1.8rem', margin: '0 auto' }} />
                <span className='appointmentPriority mt-2' style={{ color: '#fff' }}>
                  {this.getFlagValue(this.props.flag)}
                </span>
              </div>
            </div>
            <div className="col-md-2 col-xs-6 col-sm-4 col-6">

              <div className="appointment-data appointmentDate d-flex flex-column align-items-center">
                <img src={require('../../../assets/svgs/calendar.svg')} alt='calendar'
                  style={{ width: '1.8rem', margin: '0 auto' }} />
                <span className='mt-2' style={{ color: '#fff' }}>
                  {this.props.date.split("T")[0]}
                </span>
              </div>
            </div>
            <div className="col-md-2 col-xs-6 col-sm-4 col-6">

              <div className="appointment-data appointmentTime d-flex flex-column align-items-center">
                <img src={require('../../../assets/svgs/clock.svg')} alt='clock'
                  style={{ width: '1.8rem', margin: '0 auto' }} />
                <span className='mt-2' style={{ color: '#fff' }}>
                  {this.props.time}
                </span>
              </div>
            </div>
            <div className="col-md-2 col-xs-6 col-sm-4 col-6">
              {this.options(this.props.id)}
            </div>
          </div>



        </div>
      </div>
    );
  }
}

export default AppointmentCard;
