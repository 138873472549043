import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import 'react-table/react-table.css';
import ReactTable from "react-table";
import Pagination from './pagination';
import { Card, CardText } from 'material-ui';
import {Confirm } from 'semantic-ui-react'
import intl from 'react-intl-universal';

export default @inject('store') @observer class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: [],
            open:false
        };

        const style = {
            fontSize: 15,
            textAlign: "center",
            padding:'1rem 0',
            borderBottom:'1px solid #707070'
            
    }
        this.columns = [{
            Header: intl.get("UNITS") || 'Units',
            accessor: 'per',
            style
          },
           {
              Header: intl.get("ACTIONS") || 'Actions',
              Cell: props => {
                 return <div className="d-flex justify-content-center">
                      <span className='mr-2' style={{cursor:'pointer'}}
                       onClick={_ => this.handleClick(props)}>
                          <i className="fa fa-pencil" style={{fontSize:'1.2rem'}}></i>
                      </span>
                      <span style={{cursor:'pointer'}}
                      onClick={()=>{this.props.deleteUnit(props.original.id)}}>
                      <i className="fa fa-trash" style={{fontSize:'1.2rem'}}></i>
                      </span>
                  </div>
               
                },
                style
              
            }];
        }
        handleCancel = ()=>{
            this.setState({open:false})
        }
        
        handleClick = props => {
            this.props.history.push(`${this.props.to}${props.original.id}`
            , {
                unit: { ...props.original }
            }
            );
        }
        
        
        
        render() {
        if(this.props.units) {
            return(
                // <div style={{margin: 15}}>
                //                   <Confirm open={this.state.open} header="Are you sure ?" content={<div style={{padding:5,margin:5}}>
                //                       <h4 style={{color:"red"}}>{intl.get("DELETE_INVOICE_WARNING")}</h4>
                //                       </div>
                //                     } onCancel={this.handleCancel} cancelButton="No keep it" confirmButton="Yes delete" onConfirm={()=>{this.props.deleteUnit(this.state.unit_id)
                // this.setState({open:false})}} /> 
                //     <div>
                //         <Card>
                            <ReactTable
                                
                                showPagination
                                data={this.props.units}
                                columns={this.columns}
                                pageSizeOptions={[10, 20, 30, 40]}
                                defaultPageSize={10}
                                sortable
                                previousText={intl.get('PREVIOUS') || 'Pevious'}
                                nextText={intl.get("NEXT") || "Next"}
                                // loadingText: 'Loading...',
                                noDataText={intl.get("NO_ROWS_FOUND") || "No rows found"}
                                pageText={intl.get("PAGE") || "Page"}
                                ofText= {intl.get("OF") || "of"}
                                rowsText= {intl.get("ROWS") || "rows"}
                                multiSort
                                resizable
                                // PaginationComponent={Pagination}
                                // className="-stripped -highlight"
                                // filterable
                                defaultSortDesc
                            />
                //         </Card>
                //     </div>
                // </div>
              )
        }
        return <div></div>
    }
}