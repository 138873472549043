import React, { Component } from "react";
import { PUBLIC_URL, API_PREFIX } from "../../../commons/config";
import checkResponse from "../../../commons/CheckResponse";
import { inject, observer } from "mobx-react";
import InvoiceCard from './InvoiceCard'
@inject("store")
@observer
class InvoiceList extends Component {
  state = {
    invoices: [],
    user:{name:""}
  };

  componentDidMount() {
    this.getInvoices();
    this.getUser()
  }


  getUser = async () => {
    const URL =
      PUBLIC_URL +
      API_PREFIX +
      "clients/view/" +
      this.props.match.params.user_id;
  checkResponse(URL, "get", 1).then(r => {
      if(r){
        this.setState({ user: r.data.user });
      }
    })
  };
  getInvoices = async () => {
    const URL =
      PUBLIC_URL +
      API_PREFIX +
      "invoices/getall/" +
      this.props.match.params.user_id;
  checkResponse(URL, "get", 1).then(r => {
      if(r){
        this.setState({ invoices: r.data.invoices });
      }
    })
  };
  deleteInvoice = async id => {
    if (window.confirm("are you sure ?")) {
      const URL = PUBLIC_URL + API_PREFIX + "invoices/delete/" + id;
      checkResponse(URL, "delete").then(r => {
        if(!r.data){
          return console.log(r.response.data)
        }
        let invoices = [...this.state.invoices]
        invoices = invoices.filter(inv=>
          inv.id != id
        )
        this.setState({invoices})
      });
    }
  };

  render() {
    return (
      <header className="header">
        <div className="text-center mt-4">
    <h2 className="primary-heading"> {this.state.user.name} Invoices</h2>
    </div>
    <div className="action-container">
      <div className="table-container" style={{padding:'2rem', height: '80vh'}}>
        <div className="row">
        {(this.state.invoices.length != 0) ? 
                
                  this.state.invoices.map(invoice => {
                    if (invoice != null)
                    return (
                      <div className="col-md-3">
                        <InvoiceCard props={{...invoice}} history={this.props.history} deleteInvoice={this.deleteInvoice}/>
                      </div>
                      );
                    })
               
               : (
                <tbody>
                  <p>there is no invoices for you at the moment</p>
                </tbody>
              )}
        </div>
               
      </div>
    </div>
      </header>

      // <div>
      //   <div className="page-hdr">
      //     <div className="row">
      //       <div className="col-4 page-menu">
      //         <h1 style={{textAlign:"left",fontWeight:"450",textTransform:"upperCase"}}>{this.state.user.name} Invoices</h1>
              
      //       </div>
      //     </div>
      //   </div>

      //   <div className="content">
      //         {(this.state.invoices.length != 0) ? (
      //           <div className="mydivss">
      //             {this.state.invoices.map(invoice => {
      //               if (invoice != null)
      //               return (
      //                 <InvoiceCard props={{...invoice}} history={this.props.history} deleteInvoice={this.deleteInvoice}/>
      //                 );
      //               })}
      //           </div>
      //         ) : (
      //           <tbody>
      //             <p>there is no invoices for you at the moment</p>
      //           </tbody>
      //         )}
      // </div>
      // </div>
    );
  }
}

export default InvoiceList;
