import React, { Component } from "react";
import "react-table/react-table.css";
import ReactTable from "react-table";
import { Card } from "material-ui";
import matchSorter from 'match-sorter'
import intl from 'react-intl-universal';
import { PUBLIC_URL, API_PREFIX } from "../../commons/config";
import checkResponse from "../../commons/CheckResponse";
import {withRouter} from 'react-router-dom'
import moment from 'moment'


class DataTable extends Component {
  state = {
    loading: true,
    data: [],
    props:{name:""}
  };
  constructor(props) {
    super(props);

    const style = {
      // cursor: "pointer",
      // paddingLeft: 10,
      marginLeft: 45,
      userSelect: "none",
      // color: "navy"
    };

    this.columns = [
      {
        Header:"Name",
        accessor: 'name',
        id: "name",
        accessor: d => d.name,
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["name"] }),
        filterAll: true,
        style,
        Filter: ({ filter, onChange }) => (
          <input
          style={{width:'130px'}}
            type="text"
            placeholder={intl.get("SEARCH") || "Search"}

            value={filter ? filter.value : ''}
            onChange={event => onChange(event.target.value)}
          />
        )
      },
      {
        Header:"Email",
        accessor: "email",
        style,
        Cell: props => {
          if (props.value.length > 36) {
            return <p>No Email Provided</p>;
          }
          return (
            <p style={{ fontWeight: 400 }}>
              {props.value}
            </p>
          );
        },
        Filter: ({ filter, onChange }) => (
          <input
          style={{width:'130px'}}
            type="text"
            placeholder={intl.get("SEARCH") || "Search"}
            value={filter ? filter.value : ""}
            onChange={event => onChange(event.target.value)}
          />
        ),

      },
      {
        Header:"Phone number",
        id: "mobile",
        accessor: d => d.mobile,
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["mobile"] }),
        filterAll: true,
        style,
        Filter: ({ filter, onChange }) => (
          <input
          style={{width:'130px'}}
            type="text"
            placeholder={intl.get("SEARCH") || "Search"}
            value={filter ? filter.value : ''}
            onChange={event => onChange(event.target.value)}
          />
        )
      },
      {
        Header:'Joined on',
        // show: this.props.showRole,
        id:'created_at',
        accessor: "created_at",
        Cell: props => {
          let created_at = props.original.created_at;
          return (
            <p style={{fontWeight: 400 }}>
              {moment(created_at).format('LL')}
            </p>
          );
        },
        style,

      },
    ];
  }



  handleClick = props => {
    this.props.history.push(`${this.props.to}${props.original.id}`, {
      user: { ...props.original }
    });
  };

  render() {
    if (this.props.users) {
      return (
            <Card>
              <ReactTable
                showPagination
                data={this.props.users}
                columns={this.columns}
                pageSizeOptions={[10, 20, 30, 40]}
                defaultPageSize={10}
                sortable
                style={{ padding: 15 }}
                multiSort
                resizable
                previousText={intl.get('PREVIOUS') || 'Pevious'}
                nextText={intl.get("NEXT") || "Next"}
                // loadingText: 'Loading...',
                noDataText={intl.get("NO_ROWS_FOUND") || "No rows found"}
                pageText={intl.get("PAGE") || "Page"}
                ofText= {intl.get("OF") || "of"}
                rowsText= {intl.get("ROWS") || "rows"}
                // PaginationComponent={Pagination}
                filterable
                defaultFilterMethod={(filter, row) =>
                  String(row[filter.id]) === filter.value}
                defaultSortDesc
                getTdProps={(state, rowInfo, column, instance) => {
                  return {
                    onClick: (e, handleOriginal) => {
                      this.props.history.push('/admin/users/' + rowInfo.original.id)
                    }}}}
                  
  
              />
            </Card>
      );
    }
    return <div />;
  }
}


export default withRouter(DataTable)