import { observable, computed, action} from 'mobx';
import Axios from 'axios';


class Store  {

    constructor() {
        this.set_tokens = this.set_tokens.bind(this);
        this.Http = this.Http.bind(this);
        this.authenticate = this.authenticate.bind(this);
        this.get_id = this.get_id.bind(this);
        this.set_business_id = this.set_business_id.bind(this);
        this.get_business_id = this.get_business_id.bind(this);
        this.set_business_name = this.set_business_name.bind(this);
        this.get_business_name = this.get_business_name.bind(this)
        this.set_business_logo = this.set_business_logo.bind(this)
        this.get_business_logo = this.get_business_logo.bind(this)
        this.toggleSidebar = this.toggleSidebar.bind(this);
        this.set_business_type = this.set_business_type.bind(this)
        this.get_business_type = this.get_business_type.bind(this)

    }

    @observable API_TOKEN = localStorage.getItem('API_TOKEN');
    @observable REFRESH_TOKEN = localStorage.getItem('REFRESH_TOKEN'); 
    @observable ID = localStorage.getItem('ID'); 
    @observable BUSINESS_ID = localStorage.getItem('BUSINESS_ID'); 
    @observable BUSINESS_TYPE = localStorage.getItem('BUSINESS_TYPE'); 
    @observable BUSINESS_NAME = localStorage.getItem('BUSINESS_NAME'); 
    @observable BUSINESS_LOGO = localStorage.getItem('BUSINESS_LOGO')
    @observable USER;
    @observable name = localStorage.getItem('name')
    @observable userRoles = localStorage.getItem('userRoles') || "";
    @observable sidebarOpen = false;
    @observable showCard  = false;


    saveData(props){
        return props
    }

    @action gettest(){
        return this.TEST
    }
   
    @computed get get_user() {
       return this.USER
    }

    @computed get get_card() {
       return this.showCard
    }

    @computed get get_roles() {
       return this.userRoles
    }

    toggleSidebar(bool) {
        this.sidebarOpen = bool;
    }

    toggleCard(bool) {
        this.showCard = bool;
    }


    // : void() { }
    async set_tokens(tokens) {
        return new Promise((resolve, reject) => {
            this.API_TOKEN = tokens['api_token'];
            this.REFRESH_TOKEN = tokens['refresh_token'];
            this.ID = tokens['id'];
            this.name = tokens['name']
            this.userRoles = tokens['userRoles']
    
            this.toLocal(tokens).then(_ => resolve(true));
        })
    }
    
    // : void() { }
    async toLocal(tokens) {
        localStorage.setItem('API_TOKEN', tokens['api_token']);
        localStorage.setItem('REFRESH_TOKEN', tokens['refresh_token']);
        localStorage.setItem('ID', tokens['id']);
        localStorage.setItem("name",tokens['name'])
        localStorage.setItem("userRoles", tokens['userRoles'])

    } 

    @action is_logged_in() {
        const api_token = localStorage.getItem('API_TOKEN');
        const refresh_token = localStorage.getItem('REFRESH_TOKEN');
        const id = localStorage.getItem('ID');

        if(api_token) {
            this.API_TOKEN = api_token;
            this.REFRESH_TOKEN = refresh_token;
            this.ID = id;
            this.authenticate();
        }

        return (api_token) ? true : false;
    }
 
    @action log_out() {
        const email = localStorage.getItem("email")
        const password = localStorage.getItem("variable")
        const lang = localStorage.getItem('lang')
        localStorage.clear();
        if(!(email || password)){
            return
        }
        localStorage.setItem("email",email)
        localStorage.setItem("variable",password)
        localStorage.setItem("lang",lang)
    }

    @action reload() {
        window.location.reload()
    }

    @action authenticate() {
        const name = this.name
        const roles = (this.userRoles).toString()
        return (this.API_TOKEN) ?
             this.USER = {
                 name,
                 roles
             }
              : this.USER = {};    
    }

    @action get_id() {
        return this.ID;    
    }

    @action get_business_id() {
        return this.BUSINESS_ID;    
    }
    @action get_business_name() {
        return this.BUSINESS_NAME;    
    }

    @action get_business_logo(){
        return this.BUSINESS_LOGO
    }
    
    @action get_business_type() {
        return this.BUSINESS_TYPE;    
    }
    

    @action get_title(){
        const business_type = this.BUSINESS_TYPE
        if(business_type == "Clinic"){
            return "Patients"
        }
        if(business_type == "Restaurant" || "Hair Stylist"){
            return "Customers"
        }
        if(business_type == "Car Care" || "Cosmatics"){
            return "Clients"
        }
    }

    @action get_staff(){
        const business_type = this.BUSINESS_TYPE
        if(business_type == "Clinic"){
            return "Doctors"
        }
        if(business_type == "Restaurant" || "Hair Stylist" ){
            return "Staff"
        }
        if(business_type == "Car Care"  || "Cosmatics"){
            return "Employees"
        }
    }

    @action get_backGround(){
        const business_type = this.BUSINESS_TYPE
        if(business_type == "Clinic"){
            return "https://images.pexels.com/photos/48604/pexels-photo-48604.jpeg?auto=format%2Ccompress&cs=tinysrgb&dpr=2&h=650&w=940"
        }
        if(business_type == "Restaurant"){
            return "https://images.pexels.com/photos/2351274/pexels-photo-2351274.jpeg?auto=format%2Ccompress&cs=tinysrgb&dpr=2&h=650&w=940"
        }
        if(business_type == "Car Care"){
            return "https://images.pexels.com/photos/210881/pexels-photo-210881.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
        }
        if(business_type == "Cosmatics"){
            return "https://images.pexels.com/photos/208052/pexels-photo-208052.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
        }
        if(business_type == "Hair Stylist"){
            return "https://media-public.canva.com/MAAt9eW6Rlk/1/screen_2x.jpg"
        }
    }



    @action set_business_id(business_id) {
        const self = this;
        return new Promise(function(resolve, reject) {
            self.BUSINESS_ID = business_id;
            localStorage.setItem('BUSINESS_ID', business_id);
            resolve(true);
        });
    }
    @action set_business_name(business_name) {
        const self = this;
        return new Promise(function(resolve, reject) {
            self.BUSINESS_NAME = business_name;
            localStorage.setItem('BUSINESS_NAME', business_name);
            resolve(true);
        });
    }
    @action set_business_logo(business_logo) {
        const self = this;
        return new Promise(function(resolve, reject) {
            self.BUSINESS_NAME = business_logo;
            localStorage.setItem('BUSINESS_LOGO', business_logo);
            resolve(true);
        });
    }

    @action set_business_type(business_type) {
        
        const self = this;
        return new Promise(function(resolve, reject) {
            self.BUSINESS_TYPE = business_type;
            localStorage.setItem('BUSINESS_TYPE', business_type);
            resolve(true);
        });
    }

    @action Http() {
        if(this.API_TOKEN && this.REFRESH_TOKEN) {
            Axios.defaults.headers.common['Authorization'] = this.API_TOKEN;
            Axios.defaults.headers.common['Refresh'] = this.REFRESH_TOKEN;
        }
        return Axios;
    }
}

export default new Store()