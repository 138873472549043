import http from './HttpService'
const url = 'business'

// Get all business types
export function getBusinessTypes(){
  return http.get('/businessTypes')
}

// Get user businesses
export function getUserBusinesses(){
  return http.get(url + '/user-businesses')
}

export function addBusiness(data){
  return http.post(url + '/create', data)
}
