import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Specialitiestable from '../../../components/commons/table/specialities-table'
import { PUBLIC_URL, API_PREFIX } from '../../../commons/config';
import checkResponse from '../../../commons/CheckResponse'
import { RaisedButton } from 'material-ui';
import intl from 'react-intl-universal';



export default @inject('store') @observer class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      specialities: [], loading: true,
    };
  }

  componentDidMount() {
    this._getData();
  }

  deleteSpeciality = id => {
    const URL = PUBLIC_URL + API_PREFIX + "specialities/delete/" + id;
    checkResponse(URL, "delete").then(r => {
      if (r.data) {
        let specialities = [...this.state.specialities];
        specialities = specialities.filter(service => service.id != id);
        return this.setState({ specialities });
      }
    });
  };

  _getData() {
    const { get_business_id } = this.props.store;
    const URL = PUBLIC_URL + API_PREFIX + "specialities/all/" + get_business_id()
    checkResponse(URL, "get")
      .then(result => {
        this.setState({ specialities: result.data })
      })
  }

  render() {


    return (
      <header className="header">
        <div className="mt-4">
          <div style={{ display: 'flex', marginBottom: 10, justifyContent: 'space-between', alignItems: 'center', paddingLeft: 30, paddingRight: 30 }}>
            <h2 className="primary-heading"> {intl.get('SPECIALITIES') || 'Specialities'}</h2>
            <div className="d-flex">
              <button className="whitePrimaryBtn-design"
                onClick={_ => this.props.history.push("/specialities/create")}
                style={{ marginLeft: 'auto', marginBottom: '1rem' }}>
                {intl.get("ADD_NEW") || 'Add New'}
              </button>
            </div>
          </div>
        </div>
        <div className="action-container" >
          <div className="table-container">
            <Specialitiestable
              history={this.props.history}
              to="/specialities/view/"
              specialities={this.state.specialities}
              deleteSpeciality={this.deleteSpeciality}
            />
          </div>
        </div>
      </header>
    )
  }
}