import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { PUBLIC_URL, API_PREFIX } from "../../../commons/config";
import checkResponse from "../../../commons/CheckResponse";
import intl from 'react-intl-universal';
import Swal from 'sweetalert2'

export default
@inject("store")
@observer
class extends Component {
  state = {
    speciality: '',
    loading: true,
    schema: [],
    open:false
  };

  componentDidMount() {
    const { speciality_id } = this.props.match.params;
    const URL = PUBLIC_URL + API_PREFIX + "specialities/" + speciality_id;
    checkResponse(URL, "get").then(result => {
      
      if (result.data) return this.insertData(result);
      if (result.response)
        return this.setState({ err: result.response.data.error_description });
    });
  }

  insertData(data) {
    const specialities = data.data[0];
    this.setState({ speciality:specialities.name, loading: false });
  
  }


  _basicInputs() {
  
      return (
        <>
        <div className="form-group-container">
          <label htmlFor="">Name</label>
          <input type="text" className='form-control text-center' value={this.state.speciality}
          onChange={(e)=>this.setState({speciality: e.target.value})}/>
        </div>
         <div className="my-3">
           <div className="d-flex justify-content-center">
             <button className="btn-green mr-2"
             onClick={this._basicSubmit.bind(this)}>
               {intl.get('SAVE') || 'Save'}
             </button>
             <button className="btn-ghost"
             onClick={() => this.props.history.push("/specialities/view")}>
             {intl.get('CANCEL') || 'Cancel'}
             </button>
           </div>
         </div>
         </>
      
      );
    
  }

  _basicSubmit() {
    const { Http, get_business_id } = this.props.store;
    const speciality = this.state.speciality;
    if(speciality==='') return alert('Speciality name is required')
    const { speciality_id } = this.props.match.params;
    const URL = PUBLIC_URL + API_PREFIX + "specialities/" + speciality_id;
    
      Http().put(URL, { speciality, business_id: get_business_id()})
        .then(result => {
            if(result.data) return this.props.history.push('/specialities/view');
        }).catch(err=> Swal.fire("Oops...", err.response.data.error|| "Unable to update speciality", "error"));
  }

  _basic() {
    return (
      <div className="md20">
     
        <div className="folder-content">{this._basicInputs()}</div>
      </div>
    );
  }

  render() {
    return (
      <header className="header">
                <div className="text-center mt-4">
          <h2 className="primary-heading"> {intl.get('EDIT_SPECIALITY') || 'Edit Speciality'}</h2>
          </div>
          <div className="action-container width-60">
            <div className="table-container padding-2">
            {this._basic()}
            </div>
          </div>
      </header>
     
    );
  }
}
