import React, { Component } from "react";
import { PUBLIC_URL, API_PREFIX } from "../../../commons/config";
import checkResponse from "../../../commons/CheckResponse";
import { inject, observer } from "mobx-react";
import Swal from "sweetalert2";
import moment from "moment";
// import { Button, Modal } from "semantic-ui-react";
import Modal from "react-modal";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import AppointmentCard from "../../../components/commons/table/AppointmentCard";
import intl from "react-intl-universal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "500px",
    height: "500px",
    borderRadius: "20px",
    boxShadow: "5px 5px 6px 0 rgba(0, 0, 0, 0.16)",
    border: "solid 1px #707070",
    backgroundColor: "#ffffff",
  },
};

Modal.setAppElement("#root");

@inject("store")
@observer
class PendingAppointments extends Component {
  state = {
    appointments: [],
    currentAppointment: "",
    modalIsOpen: false,
  };

  componentWillMount() {
    this.get_appointments();
  }

  get_appointments = () => {
    const URL =
      PUBLIC_URL +
      API_PREFIX +
      "appointments/pending/" +
      this.props.store.get_business_id();
    checkResponse(URL, "get").then((r) => {
      this.setState({ appointments: r.data.appointments });
    });
  };

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };
  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  addToCalendar = (id) => {
    let appointments = [...this.state.appointments];
    appointments.map((app) => {
      if (app.id === id) {
        this.setState({ currentAppointment: app });
      }
    });
  };

  onAdd = () => {
    const app = this.state.currentAppointment;
    if (app.start_date && app.time_start) {
      // Check if start_date is a Date object and format it to "YYYY/MM/DD"
      if (app.start_date instanceof Date) {
        app.start_date = moment(app.start_date).format("YYYY/MM/DD");
      }
  
      // Parse the start_date and time_start into a Date object
      const [year, month, day] = app.start_date.split("/").map(Number);
      const [time, modifier] = app.time_start.split(" ");
      let [hours, minutes] = time.split(":").map(Number);
  
      // Convert 12-hour time to 24-hour time
      if (modifier === "PM" && hours !== 12) {
        hours += 12;
      }
      if (modifier === "AM" && hours === 12) {
        hours = 0;
      }
  
      // Create the appointment date and time object
      const appointmentDate = new Date(year, month - 1, day, hours, minutes);
  
      // Get the current date and time
      const currentDate = new Date();
  
      // Check if the appointment date and time are less than the current date and time
      if (appointmentDate < currentDate) {
        alert(
          "The start time must be later than the current time. Please update the start date and end time."
        );
        return; // Exit the function if the alert is shown
      }
  
      const URL = PUBLIC_URL + API_PREFIX + "appointments/edit/" + app.id;
      app.status = "Pending";
      app.start_date = moment(appointmentDate).format("YYYY/MM/DD");
      checkResponse(URL, "put", { ...app }).then((result) => {
        // Handle the result
      });
  
      let appointments = [...this.state.appointments];
      appointments = appointments.filter((item) => item.id !== app.id);
      this.setState({ appointments, modalIsOpen: false });
      Swal.fire("success", "Appointment is added to calendar", "Success");
    }
  };
  

  showModel = () => {
    if (this.state.showModel) {
      return (
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Appointment"
          open={this.state.showModel}
          header={intl.get("ADD_TO_PENDING") || "add to pending appointment ?"}
          content={this.modelContent()}
          actions={[
            {
              key: "Cancel",
              content: intl.get("CANCEL") || "Cancel",
              positive: false,
              onClick: (_) => this.setState({ showModel: false }),
            },
            {
              key: "done",
              content: intl.get("ADD") || "Add",
              positive: true,
              onClick: (_) => this.onAdd(),
            },
          ]}
        >
          <div>
            <div>
              <h3 style={{ padding: 5 }}>
                {intl.get("AN_SMS_WILL") ||
                  "An SMS will be sent to the client to inform him the appointment set ?"}
              </h3>

              <div className="form-group-container">
                <label
                  style={{ fontSize: "16px", color: "black", margin: "7px" }}
                >
                  {intl.get("DATE") || "Date"}
                </label>
                <DayPickerInput
                  className="form-control"
                  defaultValue={
                    new Date(this.state.currentAppointment.start_date)
                  }
                  value={new Date(this.state.currentAppointment.start_date)}
                  onDayChange={this.handleDayeChange}
                />
              </div>

              <div className="form-group-container">
                <label
                  style={{ color: "black", margin: "7px", fontSize: "16px" }}
                >
                  {intl.get("START_TIME") || "Start"}
                </label>

                <input
                  style={{
                    width: "25%",
                    fontSize: "16px",
                    border: "none",
                    border: "1px solid black",
                  }}
                  defaultValue={this.state.currentAppointment.time_start}
                  className="time-picker"
                  type="text"
                  name="time_start"
                  onChange={this.handleChange}
                />
              </div>

              <div style={{ margin: 5 }}>
                <label
                  style={{ color: "black", fontSize: "16px", margin: "7px" }}
                >
                  {intl.get("END_TIME") || "End"}
                </label>
                <input
                  style={{ width: "25%", fontSize: "16px" }}
                  defaultValue={this.state.currentAppointment.time_end}
                  className="time-picker"
                  type="text"
                  name="time_end"
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>
        </Modal>
      );
    }
  };

  handleChange = (e) => {
    const currentAppointment = { ...this.state.currentAppointment };
    currentAppointment[e.target.name] = e.target.value;
    this.setState({ currentAppointment });
  };
  handleDayeChange = (day) => {
    const currentAppointment = { ...this.state.currentAppointment };
    currentAppointment["start_date"] = day;
    this.setState({ currentAppointment });
  };
  modelContent = () => {
    return (
      <div>
        <div>
          <h3 style={{ padding: 5 }}>
            {intl.get("AN_SMS_WILL") ||
              "An SMS will be sent to the client to inform him the appointment set ?"}
          </h3>
          <div style={{ margin: 5 }}>
            <label style={{ fontSize: "16px", color: "black", margin: "7px" }}>
              {intl.get("DATE") || "Date"}
            </label>
            <DayPickerInput
              style={{ fontSize: "16px", width: "25%", color: "blue" }}
              defaultValue={new Date(this.state.currentAppointment.start_date)}
              value={new Date(this.state.currentAppointment.start_date)}
              onDayChange={this.handleDayeChange}
            />
          </div>

          <div style={{ margin: 5 }}>
            <label style={{ color: "black", margin: "7px", fontSize: "16px" }}>
              {intl.get("START_TIME") || "Start"}
            </label>

            <input
              style={{ width: "25%", fontSize: "16px" }}
              defaultValue={this.state.currentAppointment.time_start}
              className="time-picker"
              type="text"
              name="time_start"
              onChange={this.handleChange}
            />
          </div>
          <div style={{ margin: 5 }}>
            <label style={{ color: "black", fontSize: "16px", margin: "7px" }}>
              {intl.get("END_TIME") || "End"}
            </label>
            <input
              style={{ width: "25%", fontSize: "16px" }}
              defaultValue={this.state.currentAppointment.time_end}
              className="time-picker"
              type="text"
              name="time_end"
              onChange={this.handleChange}
            />
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <header className="header">
        <div className="mt-4" style={{ paddingLeft: 30 }}>
          <h2 className="primary-heading" style={{ paddingBottom: 10 }}>
            {" "}
            {intl.get("WAITING_LIST") || "Waiting List"}
          </h2>
        </div>
        {!this.state.appointments.length > 0 && (
          <div className="action-container ">
            <div className="table-container padding-2">
              <div className="text-center">
                <h3>
                  {intl.get("NO_WAITING_APPOINTMENTS") ||
                    "there are no waiting appointments at the moment"}
                </h3>
              </div>
            </div>
          </div>
        )}

        {this.state.appointments.map((appointment) => (
          <AppointmentCard
            id={appointment.id}
            appointment={appointment}
            doctor={appointment.doctor.name}
            patient={appointment.patient}
            date={appointment.start_date}
            time={appointment.time_start}
            status={appointment.status}
            flag={appointment.flag}
            history={this.props.history}
            waiting
            addToCalendar={(id) => {
              this.setState({ modalIsOpen: true });
              this.addToCalendar(id);
            }}
          />
        ))}

        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Appointment"
        >
          <div>
            <div>
              <h3 style={{ padding: 5 }}>
                {intl.get("AN_SMS_WILL") ||
                  "An SMS will be sent to the client to inform him the appointment set ?"}
              </h3>
              <div className="form-group-container mb-3">
                <label style={{ fontSize: "16px", color: "black" }}>
                  {intl.get("DATE") || "Date"}
                </label>

                <DayPickerInput
                  style={{ border: "none", width: "100%" }}
                  className="form-control text-center"
                  defaultValue={
                    new Date(this.state.currentAppointment.start_date)
                  }
                  value={new Date(this.state.currentAppointment.start_date)}
                  onDayChange={this.handleDayeChange}
                />
              </div>

              <div className="form-group-container mb-3">
                <label style={{ color: "black", fontSize: "16px" }}>
                  {intl.get("START_TIME") || "Start"}
                </label>

                <input
                  style={{
                    width: "100%",
                    fontSize: "16px",
                    textAlign: "center",
                  }}
                  defaultValue={this.state.currentAppointment.time_start}
                  className="time-picker"
                  type="text"
                  name="time_start"
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group-container mb-3">
                <label style={{ color: "black", fontSize: "16px" }}>
                  {intl.get("END_TIME") || "End"}
                </label>
                <input
                  style={{
                    width: "100%",
                    fontSize: "16px",
                    textAlign: "center",
                  }}
                  defaultValue={this.state.currentAppointment.time_end}
                  className="time-picker"
                  type="text"
                  name="time_end"
                  onChange={this.handleChange}
                />
              </div>
              <div className="my-3 d-flex justify-content-center">
                <button className="btn-ghost mr-2" onClick={this.onAdd}>
                  {intl.get("ADD") || "Add"}
                </button>
                <button className="btn-ghost" onClick={this.closeModal}>
                  {intl.get("CANCEL") || "Cancel"}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </header>

      // <div className="apps">
      //   {this.state.appointments.length ? (
      //     ""
      //   ) : (
      //     <h2 style={{ textAlign: "center" }}>
      //       {intl.get('NO_WAITING_APPOINTMENTS') || "there are no waiting appointments at the moment"}
      //     </h2>
      //   )}
      //   {this.state.appointments.map(appointment => (
      //     <div
      //       style={{
      //         margin: "10px",
      //         borderBottom: "1px solid black",
      //         textAlign: "center"
      //       }}
      //     >
      //       <AppointmentCard
      //         id={appointment.id}
      //         appointment={appointment}
      //         doctor={appointment.doctor.name}
      //         patient={appointment.patient}
      //         date={appointment.start_date}
      //         time={appointment.time_start}
      //         status={appointment.status}
      //         flag={appointment.flag}
      //         history={this.props.history}
      //         waiting
      //         addToCalendar={id => {
      //           this.setState({ showModel: true });
      //           this.addToCalendar(id);
      //         }}
      //       />
      //     </div>
      //   ))}
      //   {this.showModel()}
      // </div>
    );
  }
}
// class Waiting extends Component {
//   render() {
//     const patient = this.props.patient
//     return <div>
//       <h2 style={{textAlign:"center"}}>{patient.name} | {patient.mobile}</h2>
//       <hr/>
//       </div>
//   }
// }

export default PendingAppointments;
