import React, { Component } from "react";
import RaisedButton from "material-ui/RaisedButton";
import Swal from "sweetalert2";
import { inject, observer } from "mobx-react";
import { PUBLIC_URL, API_PREFIX } from "../../commons/config";
import checkResponse from "../../commons/CheckResponse";
import { Link } from "react-router-dom";
import axios from "axios";
import RecaptchaComponent from "../../commons/RecaptchaComponent";
import CountryCodes from "../CountryCodes";
import intl from "react-intl-universal";
import logo from "../../assets/images/bookingwhitebg.png";
import group from "../../assets/images/Group.png";
import SelectLanguage from "../../commons/SelectLanguage";
import Background from "../../assets/images/calendar.jpg";

// const twoway_url = 'http://localhost:4000/api/clinic';
const twoway_url = "https://mnty-mbl-bookinghub.com/api/clinic";

var backgroundStyle = {
  // backgroundImage: `url(${Background})`,
  height: "100vh",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  // backgroundColor:'#3e87c6'
};

export default
@inject("store")
@observer
class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        email: "",
        password: "",
        confirmPassword: "",
        mobile: "",
        cc: "+961",
      },
      err: "",
      onSubmit: false,
      registering: false,
      business_type: "",
      height: 0,
      meetsCriteria: false,
      showCaptha: true,
    };
  }

  componentDidMount() {
    // const height = document.getElementById('SignUpSection').clientHeight;
    document.getElementsByTagName("html")[0].classList.add("signupHtml");
    // this.setState({ height }, () => {alert(height)});
  }

  _inputs() {
    return (
      <div>
        <form id="signin" onSubmit={(_) => false}>
          <div>Full Name</div>
          <input
            onKeyPress={this._handleKeyPress.bind(this)}
            onChange={(ev) => this._setInput(ev)}
            id="name"
            value={this.state.form.name}
            className="form-control"
            placeholder={intl.get("FULL_NAME") || "Full Name"}
            style={{ width: "100%" }}
            required
            ref={(name) => (this.name = name)}
          />
          <br />
          <input
            onKeyPress={this._handleKeyPress.bind(this)}
            onChange={(ev) => this._setInput(ev)}
            id="email"
            className="form-control"
            placeholder="email@example.com"
            style={{ width: "100%" }}
            required
            maxLength={36}
            ref={(email) => (this.email = email)}
          />
          <br />
          <input
            onKeyPress={this._handleKeyPress.bind(this)}
            onChange={(ev) => this._setInput(ev)}
            className="form-control"
            id="password"
            style={{ width: "100%" }}
            type="password"
            placeholder={intl.get("PASSWORD") || "password"}
            required
            maxLength={36}
            ref={(password) => (this.password = password)}
          />
          <input
            onKeyPress={this._handleKeyPress.bind(this)}
            onChange={(ev) => this._setInput(ev)}
            className="form-control"
            id="confirmPassword"
            style={{ width: "100%" }}
            type="password"
            placeholder={intl.get("CONFIRM_PASSWORD") || "confirm password"}
            required
            ref={(confirmPassword) => (this.confirmPassword = confirmPassword)}
          />
          <br />
          <CountryCodes
            countryCode={(country_code) => this.setState({ country_code })}
            style={{ width: "30%", height: "38px" }}
          />
          <input
            onKeyPress={this._handleKeyPress.bind(this)}
            onChange={(ev) => this._setInput(ev)}
            className="form-control"
            id="mobile"
            style={{ width: "70%" }}
            type="text"
            value={this.state.form.mobile}
            placeholder={intl.get("MOBILE_NUMBER") || "Mobile number"}
            required
            maxLength={10}
            ref={(mobile) => (this.mobile = mobile)}
          />
          <br />

          <br />
        </form>
      </div>
    );
  }

  _setInput(event) {
    const element = event.target.id;
    if (element == "mobile") {
      const re = /^[0-9\b]+$/;
      if (event.target.value === "" || re.test(event.target.value)) {
        let form = { ...this.state.form };
        form[element] = event.target.value;
        this.setState({ form });
      }
      return;
    }
    if (element == "cc") {
      const re = /^[0-9-+()]*$/;
      if (event.target.value === "" || re.test(event.target.value)) {
        let form = { ...this.state.form };
        form[element] = event.target.value;
        this.setState({ form });
      }
      return;
    }

    let form = Object.assign({}, this.state.form);
    form[element] = event.target.value;
    this.setState({ form });
  }

  _inputActions() {
    return (
      <div className="tbl no-pad">
        <div className="tbl-cell no-pad text-right">
          <RaisedButton
            primary
            disabled={this.state.registering}
            onClick={this._doSignup.bind(this)}
            // label={this.state.registering ? "Working..." : intl.get('CREATE_ACCOUNT') || 'Create Account'}
            label="Register"
          />
        </div>
      </div>
    );
  }

  _handleKeyPress(e) {
    if (e.key === "Enter") {
      this._doSignup();
    }
  }

  _validate() {
    const regex = new RegExp(
      /(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$)/
    );

    let valid = true;
    this.setState({ err: "" });
    for (let element in this.state.form) {
      if (this.state.form[element] === "") {
        console.log("this.state.form[element]", this.state.form[element]);
        const err =
          "The field " + this.state.form[element] + " " + "is missing";
        this.setState({ err });

        if (!this[element].className.includes("err"))
          this[element].className += " err";

        valid = false;
        return;
      } else {
        if (this.state.form.password !== this.state.form.confirmPassword) {
          this.setState({ err: "Passwords do not match" });
          valid = false;
        }

        if (element === "password") {
          if (this.state.form[element].length <= 5) {
            this.setState({ err: "Password should at least 6 charachters" });
            if (!this[element].className.includes("err"))
              this[element].className += " err";
            valid = false;
          }
          let regex = /^((?=.*\d)(?=.*[A-Z])(?=.*\W).{6,16})$/;
          if (regex.test(this.state.form[element]) === false) {
            this.setState({
              err: "Password must have 1 Uppercase, 1 number, 1 special char",
            });
            if (!this[element].className.includes("err"))
              this[element].className += " err";
            valid = false;
          }
        }

        if (element === "name" && this.state.form[element].length == 0) {
          this.setState({ err: "the name can't be empty" });
          if (!this[element].className.includes("err"))
            this[element].className += " err";
          valid = false;
        }

        if (element === "mobile" && this.state.form[element].length == 0) {
          this.setState({ err: "Phone number should be 8 digits" });
          if (!this[element].className.includes("err"))
            this[element].className += " err";
        }

        if (element === "email" && !regex.test(this.state.form[element])) {
          // if(!this[element].className.includes("err"))
          this.setState({ err: "Please enter a valid email address" });
          this[element].className += " err";
          valid = false;
        }
      }
    }
    return valid;
  }

  _doSignup(e) {
    e.preventDefault();
    this.setState({ verified: false });
    // this.setState({ showCaptha: false });
    setTimeout(() => {
      // this.setState({ showCaptha: true });
    }, 100);

    if (this._validate()) {
      // if (!this.state.verified) {
      //   return this.setState({
      //     err: intl.get("HUMAN_VERIFICATION") || 'please verify you are a human',
      //     onSubmit: false,
      //     registering: false
      //   });
      // }

      let { email, password, mobile, name } = this.state.form;
      const URL = PUBLIC_URL + API_PREFIX + "register";

      checkResponse(URL, "post", {
        email,
        password,
        country_code: this.state.country_code,
        mobile,
        name,
      }).then((result) => {
        if (result.data) {
          const URL2 = PUBLIC_URL + API_PREFIX + "requestActivate";
          checkResponse(URL2, "post", { email }).then(async (r) => {
            this.setState({ registering: false });
            const response = await Swal.fire({
              icon: "success",
              title: "Success",
              titleText:
                intl.get("VERIFICATION_LINK") ||
                "A verification link was sent to your email",
              type: "success",
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
            if (response.value) return this.props.history.push("/signin");
          });
        }
        if (result.response) {
          const err = result.response.data.error_description;
          return this.setState({ err, registering: false });
        }
      });
    }
  }

  _err() {
    if (this.state.err) {
      return (
        <div className="errorMsgWrapper grid-item">
          <h4 className="redText">{this.state.err}</h4>
        </div>
      );
    }
  }

  info() {
    if (this.state.info) {
      return (
        <div className="text-center pad10" style={{ padding: 10, margin: 20 }}>
          <h3 style={{ fontSize: 20, alignSelf: "center", color: "blue" }}>
            {this.state.info}
          </h3>
        </div>
      );
    }
  }

  checkPasswordCriteria(password) {
    const result = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*]).{8,}$/.test(
      password
    );
    return result;
  }
  checkConfigmPass(password, confimPass) {
    // Check if the length of confirm password is greater than 8 characters
    if (confimPass.length <= 8) {
      return true; // Return false if confirm password length is less than 8
    }

    // Check if both password and confirm password match
    if (password === confimPass) {
      return true; // Return true if both passwords match
    } else {
      return false; // Return false if passwords do not match
    }
  }

  render() {
    return (
      <div
        className="blackTheme grid-container two-columns"
        style={backgroundStyle}
      >
        <section
          className="createNewAccount grid-item displayNoneMobile"
          style={{ order: 2, position: "relative" }}
        >
          <div style={{ position: "absolute", right: 50, top: 50 }}>
            <SelectLanguage />
          </div>
          <div className="logoWrapper grid-item">
            <img className="logo" src={logo} alt="Booking Hub" />
          </div>
          <div style={{ position: "absolute", bottom: 100, left: -100 }}>
            <img src={group} style={{ width: "50%" }} alt="Booking Hub" />
          </div>
          <div className="description grid-item" style={{fontSize: 14}}>
            <span>
              {intl.get("ONPOINT_WELCOME_MESSAGE") ||
                "Booking Hub is an online appointment scheduling software provided by Booking Hub for free which elegantly enables you to utilize all appointments and data for your clients and more!"}
            </span>
            <div className="separator"></div>
            <span>
              {intl.get("BOOST_YOUR_PRODUCTIVITY") ||
                "Boost your productivity now by using Booking Hub, the easiest way to control your schedule by managing their appointments and save their data upon each visit."}
            </span>
          </div>
          <div className="createNewAccountButtonHolder grid-item">
            <a
              className="createAccount"
              onClick={(_) => this.props.history.push("/signin")}
            >
              {/* <input className='createNewAccountButton green'
                type='button'
                value={intl.get("SIGN_IN") || 'Login Account'} /> */}
            </a>
          </div>
          <div className="footer privacyAndPolicy grid-item">
            <a
              className="forgot"
              onClick={(_) => this.props.history.push("/privacy_and_policy")}
            >
              {intl.get("PRIVACY_AND_POLICY") || "Privacy and Policy"}
            </a>
          </div>
        </section>
        <section
          id="SignUpSection"
          style={{
            backgroundColor: "white",
            padding: 30,
          }}
          className="loginHeightStyle mobileWidth100vw"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#3e87c6",
              height: "100%",
              borderRadius: 30,
            }}
          >
            <div
              className="signupFormHolder grid-item"
              style={{ paddingTop: 30 }}
            >
              <form id="signupForm" onSubmit={(_) => false}>
                {this._err()}
                {this.info()}
                <div
                  className="title signupTitle grid-item"
                  style={{
                    fontSize: 40,
                    borderBottom: "2px solid white",
                    paddingBottom: 30,
                    paddingLeft: 90,
                    paddingRight: 90,
                  }}
                >
                  {intl.get("SIGN_UP") || "Sign Up"}
                </div>
                <div
                  className="input-container grid-item fullNameWrapper"
                  style={{ paddingTop: 30 }}
                >
                  {/* <img
                    className="signupFormIcon"
                    src={require("../../assets/svgs/person.svg")}
                    alt="full name"
                  /> */}
                  <div
                    style={{ color: "white", fontSize: 16, marginLeft: "1em" }}
                  >
                    Full Name
                  </div>
                  <input
                    onKeyPress={this._handleKeyPress.bind(this)}
                    onChange={(ev) => this._setInput(ev)}
                    id="name"
                    value={this.state.form.name}
                    className="text-input"
                    placeholder={intl.get("FULL_NAME") || "Full Name"}
                    required
                    ref={(name) => (this.name = name)}
                  />
                </div>
                <div className="input-container grid-item emailWrapper">
                  {/* <img
                    className="signupFormIcon"
                    src={require("../../assets/svgs/envelope.svg")}
                    alt="email"
                  /> */}
                  <div
                    style={{ color: "white", fontSize: 16, marginLeft: "1em" }}
                  >
                    Email
                  </div>

                  <input
                    onKeyPress={this._handleKeyPress.bind(this)}
                    onChange={(ev) => this._setInput(ev)}
                    id="email"
                    className="text-input"
                    placeholder="email@example.com"
                    required
                    maxLength={36}
                    ref={(email) => (this.email = email)}
                  />
                </div>
                <div className="input-container grid-item passwordWrapper">
                  {/* <img
                    className="signupFormIcon"
                    src={require("../../assets/svgs/lock.svg")}
                    alt="password"
                  /> */}
                  <div
                    style={{ color: "white", fontSize: 16, marginLeft: "1em" }}
                  >
                    Password
                  </div>

                  <input
                    onKeyPress={this._handleKeyPress.bind(this)}
                    onChange={(ev) => {
                      this._setInput(ev);
                    }}
                    className="text-input"
                    id="password"
                    type="password"
                    placeholder={intl.get("PASSWORD") || "password"}
                    required
                    maxLength={36}
                    ref={(password) => (this.password = password)}
                  />
                  {this.state.form.password ? (
                    <p
                      style={{
                        fontSize: "14px",
                        marginTop: 10,
                        marginBottom: 10,
                        color: 'white',
                        visibility: this.checkPasswordCriteria(
                          this.state.form.password
                        )
                          ? "hidden"
                          : "visible",
                      }}
                    >
                      Password must include: 1 uppercase letter (A-Z), number
                      (0-9), 1 special character, and 8 characters long.
                    </p>
                  ) : null}
                </div>
                <div className="input-container grid-item confirmPasswordWrapper">
                  {/* <img
                    className="signupFormIcon"
                    src={require("../../assets/svgs/lock.svg")}
                    alt="confirm password"
                  /> */}
                  <div
                    style={{ color: "white", fontSize: 16, marginLeft: "1em" }}
                  >
                    Confirm Password
                  </div>
                  <input
                    onKeyPress={this._handleKeyPress.bind(this)}
                    onChange={(ev) => this._setInput(ev)}
                    className="text-input"
                    id="confirmPassword"
                    type="password"
                    placeholder={
                      intl.get("CONFIRM_PASSWORD") || "confirm password"
                    }
                    required
                    ref={(confirmPassword) =>
                      (this.confirmPassword = confirmPassword)
                    }
                  />
                  {/* <p
                    style={{
                      fontSize: "14px",
                      marginTop: 10,
                      marginBottom: 10,
                      visibility: this.checkConfigmPass(
                        this.state.form.password,
                        this.state.form.confirmPassword
                      )
                        ? "hidden"
                        : "visible",
                    }}
                  >
                    Password not match.
                  </p> */}
                </div>

                <div
                  style={{ color: "white", fontSize: 16, marginLeft: "1em", width: '100%', marginBottom: -10 }}
                >
                  Mobile Number
                </div>
                <div className="input-container grid-item mobileNumberWrapper">
                  <div className="grid-item countryCodeWrapper" style={{marginRight: 10}}>
                    <CountryCodes
                      countryCode={(country_code) =>
                        this.setState({ country_code })
                      }
                    />
                  </div>

                  <div className="grid-item mobileInputWrapper">
                    <input
                      onKeyPress={this._handleKeyPress.bind(this)}
                      onChange={(ev) => this._setInput(ev)}
                      className="text-input grid-item"
                      id="mobile"
                      type="text"
                      value={this.state.form.mobile}
                      placeholder={intl.get("MOBILE_NUMBER") || "Mobile number"}
                      required
                      maxLength={10}
                      ref={(mobile) => (this.mobile = mobile)}
                    />
                  </div>
                </div>
                {/* {
                this.state.showCaptha ? (
                  <RecaptchaComponent
                    onSubmit={this.state.onSubmit}
                    onVerify={() =>
                      this.setState({ verified: true, onSubmit: false })
                    }
                  />
                ): null
              } */}
                <div
                  className="grid-item createAccountButtonWrapper button-wrapper middle"
                  style={{ margin: "auto 0", marginTop: 20 }}
                >
                  <button
                    className="createAccountButton primary-Btn-white"
                    disabled={this.state.registering}
                    onClick={this._doSignup.bind(this)}
                    style={{
                      paddingLeft: 60,
                      paddingRight: 60,
                      paddingTop: 8,
                      paddingBottom: 8,
                    }}
                  >
                    {this.state.registering
                      ? "Working..."
                      : intl.get("CREATE_ACCOUNT") || "Create My Account"}
                  </button>
                </div>
              </form>
              <div
                className="footer "
                style={{ textAlign: "center", marginTop: 20 }}
              >
                <Link to="/signin">
                  <span style={{ fontSize: 20, color: "white" }}>
                    {intl.get("ALREADY_HAVE_ACCOUNT") ||
                      "Already have an account"}{" "}
                    <span
                      style={{
                        fontSize: 20,
                        color: "white",
                        fontWeight: "bold",
                        marginLeft: 4,
                      }}
                    >
                      Sign In
                    </span>
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
