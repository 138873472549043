import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import Swal from 'sweetalert2'
import "react-table/react-table.css";
import ReactTable from "react-table";

import intl from 'react-intl-universal';

export default
@inject("store")
@observer
class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
      open: false
    };

    const style = {
      fontSize: 15,
      textAlign: "center",
      padding:'1rem 0',
      borderBottom:'1px solid #707070'
    };
    this.columns = [
      {
        Header: intl.get('SPECIALITIES') || "Specialities",
        accessor: "name",
        style
      },
      {
        Header: intl.get('ACTIONS') || "Actions",
        Cell: props => {
          return (
            <div className="d-flex justify-content-center">
              <span className='mr-2' style={{cursor:'pointer'}} onClick={_ => this.handleClick(props)}>
                <i className="fa fa-pencil" style={{fontSize:'1.2rem', color:'black'}}></i>
              </span>
              <span style={{cursor:'pointer'}}  onClick={_ =>
              this.handleDelete(props.original.id)
                  // this.setState(
                  //   { speciality_id: props.original.id },
                  //   this.setState({ open: true })
                  // )
                }>
                <i className="fa fa-trash" style={{fontSize:'1.2rem', color:'black'}}></i>
              </span>
            </div>
           
          );
        },
        style
      }
    ];
  }

handleDelete = (id) =>{
  Swal.fire({
     
    icon:'warning',
    title: "Are you sure?",
    text:
      "this would not be revert back",
    type: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!"
  }).then( result => {
    if (result.value) {
     
    
      try {
        this.props.deleteSpeciality(id);
        Swal.fire('success', 'Speciality deleted!', 'Success')
      } catch (ex) {
        Swal.fire("Oops...", "Something went wrong", "error");
      }
    }
  });
   
};


  handleCancel = () => {
    this.setState({ open: false });
  };

  handleClick = props => {
    this.props.history.push(`${this.props.to}${props.original.id}`, {
      speciality_id: { ...props.original }
    });
  };

  render() {
    let specialities = this.props.specialities;
    let one_spec = specialities.length <= 1;
    if (specialities) {
      return (
      
          // <Confirm
          //   open={this.state.open}
          //   header={one_spec ? 'Error' : intl.get("ARE_YOU_SURE")}
          //   content={
          //     <div style={{ padding: 5, margin: 5 }}>
          //       <h4 style={{ color: "red" }}>
          //         {one_spec
          //           ? "You should have at least one speciality"
          //           : "Deleting this item will affect to delete its related services,invoices and payments, and can't be restored"}
          //       </h4>
          //     </div>
          //   }
          //   onCancel={this.handleCancel}
          //   cancelButton={one_spec ? null : true}
          //   confirmButton={one_spec ? 'close' : 'yes delete'}
          //   onConfirm={() => {
          //       if(!one_spec){
          //           this.props.deleteSpeciality(this.state.speciality_id);
          //       }
          //     this.setState({ open: false });
          //   }}
          // />
          <>
            
              <ReactTable
               
                showPagination
                data={this.props.specialities}
                columns={this.columns}
                pageSizeOptions={[10, 20, 30, 40]}
                defaultPageSize={10}
                sortable
                multiSort
                resizable
                // PaginationComponent={Pagination}
               
                // filterable
                defaultSortDesc
              />
          
          </>
       
      );
    }
    return <div></div>;
  }
}
