import React, { Component } from "react";
import AppRouter from "./router/routes";
import _ from "lodash";
import intl from "react-intl-universal";

import Axios from "axios";

import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";

const SUPPOER_LOCALES = [
  {
    name: "English",
    value: "en-US"
  },
  {
    name: "简体中文",
    value: "zh-CN"
  },
  {
    name: "繁體中文",
    value: "zh-TW"
  },
  {
    name: "français",
    value: "fr-FR"
  },
  {
    name: "日本の",
    value: "ja-JP"
  },
  {
    name: "العربية",
    value: "ar-AR"
  }
];

class App extends Component {
  componentWillMount() {
    this.loadLocales();
  }

  loadLocales() {
    let currentLocale = intl.determineLocale({
      urlLocaleKey: "lang",
      cookieLocaleKey: "lang"
    });

    // console.log("currentLocale", currentLocale)

    const lang = localStorage.getItem("lang");

    // console.log("lang", lang)

    const url = `${window.location.origin}/locales/${lang}.json`;

    // console.log("url", url)

    if (lang === "ar") {
      document.body.style.direction = "rtl";
      document.body.classList.add("arabicLanguage")
      let ar_table = document.getElementsByClassName("ar-table")[0] || {
        style: { marginRight: "0px" }
      };
      ar_table.style.marginRight = "40px";
    }
    Axios.get(url)
      .then(res => {
        // init method will load CLDR locale data according to currentLocale
        return intl.init({
          currentLocale,
          locales: {
            [currentLocale]: res.data
          }
        });
      })
      .then(() => {
        // After loading CLDR locale data, start to render
        this.setState({ initDone: true });
      });
  }

  render() {
    return (
      <MuiThemeProvider>
        <AppRouter />
      </MuiThemeProvider>
    );
  }
}

export default App;
