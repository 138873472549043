import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { PUBLIC_URL, API_PREFIX } from '../../../commons/config';
// import Form from 'react-jsonschema-form';
import { Card, CardText, RaisedButton } from 'material-ui';
import clonedeep from 'lodash.clonedeep'

function addOrReplace(array, item) {
    const i = array.findIndex(_item => _item.key === item.key);
    if (i > -1) array[i] = item; 
    else array.push(item);
}

export default @inject('store') @observer class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: true,
            formdata: []
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        const { Http, get_business_id } = this.props.store;
        const URL = PUBLIC_URL + API_PREFIX + "metadata/all/" + get_business_id();
        Http().get(URL)
            .then(this.insertData.bind(this))
    }

    insertData(data) {
        const { labels } = data.data;
        this.setState({loading: false, data: labels });
    }


    _form() {
        if(!this.state.loading) {
            const { data } = this.state;
            return data.map((item) => {
                const { type, label, id, meta_values } = item;
                if(parseInt(type) === 1) {
                    return this._normalInput(label, id);
                }
                const multiple = parseInt(type) === 3;
                return this._dropdown(label, id, meta_values, multiple);
            });
        }
    }

    _normalInput(label, id) {
        return(
            <div className="form-group">
                <label htmlFor={id}>{label}</label>
                <input 
                    onChange={this._setInput.bind(this)} 
                    data-type={label} className="form-control" id={id} />
            </div>
        )
    }

    _dropdown(label, id, meta_values, multiple) {
        meta_values = meta_values[0].value.split(",");
        return(
            <div className="form-group">
                <label htmlFor={id} className="label-control">{label}</label>
                <select 
                    onChange={this._setInput.bind(this)} 
                    data-type={label} className="form-control" multiple={multiple} id={id}>
                    {meta_values.map((meta, index) => (
                        <option key={index} value={meta}>{meta}</option>
                    ))}
                </select>
            </div>
        )
    }

    _setInput(element) {
        element = element.target;
        const { user_id } = this.props.match.params;
        const { get_business_id } = this.props.store;
        const node = element.nodeName.toLowerCase();
        let old = clonedeep(this.state.formdata);
        const key = element.getAttribute('data-type');
        const value = node === "select" ? this._getOptions(element).toString() : element.value;
        if(old.length === 0) {
            old.push({key, value, user_id, business_id: get_business_id()});
        } else {
            addOrReplace(old, {key, value, user_id, business_id: get_business_id()});
        }
        this.setState({formdata: old});
    }

    _getOptions(element) {
        return [].filter.call(element.options, function (o) {
            return o.selected;
        }).map(function (o) {
            return o.value;
        });
    }

    _submitData() {
        const { formdata } = this.state;
        const { Http } = this.props.store;
        const URL = PUBLIC_URL + API_PREFIX + "user-meta/create";
        Http().post(URL, { metadata: formdata })
        .then(console.log); 
    }

    render() {
        return(
            <div style={{margin: 15}}>
                <Card>
                    <CardText>
                        <div className="md20">
                            {this._form()}
                        </div>
                        <div className="text-right">
                            <RaisedButton
                                primary
                                onClick={this._submitData.bind(this)}
                                label="Submit"
                            />
                        </div>
                    </CardText>
                </Card>
            </div>
        )
    }
}