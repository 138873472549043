import React from "react";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";

export default ({clickedDay,month,selectedDays,monthChanged}) => {
    const modifiersStyles = {
        thursdays: {
            fontSize: 12,
            color: 'black',
            fontWeight:"bold"
        },
        birthday:{
            color:"white",
            background:"#03a9f3"
        }
    }

    const modifiers = {
        thursdays: { daysOfWeek: [0,1,2,3,4,5,6] },
        birthday: selectedDays
    };


    return <DayPicker
        modifiers={modifiers}
        modifiersStyles={modifiersStyles}
        onDayClick={day=>clickedDay(day)}
        month={month}
        selectedDays={selectedDays}
        onMonthChange={month=>monthChanged(month)}    />
}

