import React, { Component } from "react"
import { PUBLIC_URL, API_PREFIX } from "../../commons/config"
import checkResponse from "../../commons/CheckResponse"
import intl from "react-intl-universal"
import SelectLanguage from "../../commons/SelectLanguage"
import logo from '../../assets/images/logo_booking_white.png'
import Background from '../../assets/images/calendar.jpg'

var backgroundStyle = {
  // backgroundImage: `url(${Background})`,
  height: '100vh', 
  // backgroundPosition: 'center',
  // backgroundRepeat: 'no-repeat',
  // backgroundSize: 'cover'
backgroundColor:'#3e87c6'
}; 

class ForgotPassword extends Component {
  state = {
    password: "",
    confirmPassword: "",
    passwordChanged:false
  };

  displayMessage = () => {
    if (this.state.err) {
      if (this.state.err == "provided email is not found") {
        this.setState({
          message: "password reset link has been sent to your email"
        });
        this.setState({ err: "" });
        
        return (
            <div className='errorMsgWrapper'>
              <h4 className='blueText'>{this.state.message}</h4>
            </div>
          )
      }
      return (
            <div className='errorMsgWrapper'>
              <h4 className='redText'>{this.state.err}</h4>
            </div>
            )
    } else {
      return (
            <div className='errorMsgWrapper'>
              <h4 className='blueText'>{this.state.message}</h4>
            </div>
            )
    }
  }

  changePassword = e => {
      e.preventDefault()
      const {password,confirmPassword} = this.state
    if(password.length < 6){
        return this.setState({err:"password should be at least 6 charachters long"})
    }
    if(password !== confirmPassword){
        return this.setState({err:"passwords do not match"})
    }
    const resetToken = this.props.match.params.resetToken
    const URL = PUBLIC_URL + API_PREFIX + "resetPassword/" + resetToken
    checkResponse(URL,"post",{password:this.state.password})
    .then(result =>{
        if(result.response){
            return this.setState({err:result.response.data.error_description})
        }
        if(result.data){
            return this.setState({passwordChanged:true,err:"",message:result.data.success})
        }
    })
  };


   checkValidLink = () => {
     return (
      <div className="blackTheme grid-container two-columns" style={ backgroundStyle }>
        <section className='createNewAccount grid-item'>
         <div className='logoWrapper grid-item'>
            <img
                className='logo'
                src={logo}
                alt="Booking Hub"
            />
          </div>
          <div className='description grid-item'>
            <span>
              {intl.get("ONPOINT_WELCOME_MESSAGE") || "Booking Hub is an online appointment scheduling software provided by Booking Hub for free which elegantly enables you to utilize all appointments and data for your clients and more!"}
            </span>
            <div className='separator'></div> 
            <span>
              {intl.get("BOOST_YOUR_PRODUCTIVITY") || "Boost your productivity now by using Booking Hub, the easiest way to control your schedule by managing their appointments and save their data upon each visit."}
            </span>
         </div>
          <div className='createNewAccountButtonHolder grid-item'>
            <a className="createAccount"
               onClick={_ => this.props.history.push("/register")}
            >
              <input className='createNewAccountButton green'
                     type='button'
                     value='Create New Account' />
           </a>
          </div>
          <div className='footer privacyAndPolicy grid-item'>
            <a className="forgot"
               onClick={_ => this.props.history.push("/privacy_and_policy")}
              >
              {intl.get("PRIVACY_AND_POLICY") || "Privacy and Policy"}
            </a>
          </div>
        </section>
        <section className='forgetPasswordSection section grid-item'>
          <div className='languageHolder grid-item'><SelectLanguage /></div>

          <div className='forgetPasswordFormHolder formHolder grid-item'>
            <form id="forgetPasswordForm" onSubmit={this.changePassword}>
   
              <div className='title forgetPasswordTitle grid-item'>
                <img className='forgetPasswordIcon' src={require('../../assets/svgs/lock_forget_password.svg')} alt='password' />
                 {intl.get("FORGOT_MY_PASSWORD") || 'Forgot Password?'}
                <small className='subtitle forgetPasswordSubtitle'>
                  {intl.get("RESET_PASSWORD") || 'You can reset your password here'}.
                </small>
                {this.displayMessage()}
              </div>    
              <div className='input-container grid-item emailWrapper'>
                <img className='innerFormIcon' src={require('../../assets/svgs/envelope.svg')} alt='email' />
                <input placeholder="new password"
                       className="form-control"
                       type="password"
                       onChange={e =>
                          this.setState({ password: e.target.value })
                       }
                       disabled={this.state.passwordChanged}
                />
              </div>
              <div className='input-container grid-item emailWrapper'>
                <img className='innerFormIcon' src={require('../../assets/svgs/envelope.svg')} alt='email' />                            
                <input placeholder="confirm new password"
                       className="form-control"
                       type="password"
                       onChange={e =>
                          this.setState({
                              confirmPassword: e.target.value
                          })
                       }
                       disabled={this.state.passwordChanged}
                />
              </div>
              <div className='input-container grid-item'>
                <input
                className= {this.state.passwordChanged ?  "btn btn-lg btn-success btn-block" : "btn btn-lg btn-primary btn-block"}
                type={this.state.passwordChanged ? "button" : "submit"}
                value={this.state.passwordChanged ? "back to sign in" : "change my password"}
                onClick={this.state.passwordChanged ? _=>this.props.history.push("/signin") : ""}
              />
              </div>
            </form>
          </div>

          <div className='footer grid-item signinFooter'>
           <a className="forgot" href="/forgot">
            {intl.get("FORGOT_MY_PASSWORD") || "Forgot my password"}
           </a>  
          </div>
        </section>
      </div>
     )
   }

  render() {
    return (
     <div className="blackTheme grid-container two-columns" style={ backgroundStyle }>
        <section className='createNewAccount grid-item'>
         <div className='logoWrapper grid-item'>
            <img
                className='logo'
                src={logo}
                alt="Booking Hub"
            />
          </div>
          <div className='description grid-item'>
            <span>
              {intl.get("ONPOINT_WELCOME_MESSAGE") || "Booking Hub is an online appointment scheduling software provided by Booking Hub for free which elegantly enables you to utilize all appointments and data for your clients and more!"}
            </span>
            <div className='separator'></div> 
            <span>
              {intl.get("BOOST_YOUR_PRODUCTIVITY") || "Boost your productivity now by using Booking Hub, the easiest way to control your schedule by managing their appointments and save their data upon each visit."}
            </span>
          </div>
          <div className='createNewAccountButtonHolder grid-item'>
            <a className="createAccount"
               onClick={_ => this.props.history.push("/register")}
            >
              <input className='createNewAccountButton green'
                     type='button'
                     value={intl.get('CREATE_ACCOUNT') || 'Create Account'} />
           </a>
          </div>
          <div className='footer privacyAndPolicy grid-item'>
            <a className="forgot"
               onClick={_ => this.props.history.push("/privacy_and_policy")}
              >
              {intl.get("PRIVACY_AND_POLICY") || "Privacy and Policy"}
            </a>
          </div>
        </section>
        <section className='forgetPasswordSection section grid-item'>
          <div className='languageHolder grid-item'><SelectLanguage /></div>

          <div className='forgetPasswordFormHolder formHolder grid-item'>
            <form id="forgetPasswordForm" onSubmit={this.changePassword}>
   
              <div className='title forgetPasswordTitle grid-item'>
                <img className='forgetPasswordIcon' src={require('../../assets/svgs/lock_forget_password.svg')} alt='password' />
                 {intl.get("FORGOT_MY_PASSWORD") || 'Forgot Password?'}
                <small className='subtitle forgetPasswordSubtitle'>
                  {intl.get("RESET_PASSWORD") || 'You can reset your password here'}.
                </small>
                {this.displayMessage()}
              </div>    
              <div className='input-container grid-item emailWrapper'>
                <img className='innerFormIcon' src={require('../../assets/svgs/envelope.svg')} alt='email' />
                <input placeholder={intl.get("NEW_PASSWORD") || 'new password'}
                       className="text-input"
                       type="password"
                       onChange={e =>
                          this.setState({ password: e.target.value })
                       }
                       disabled={this.state.passwordChanged}
                />
              </div>
              <div className='input-container grid-item emailWrapper'>
                <img className='innerFormIcon' src={require('../../assets/svgs/envelope.svg')} alt='email' />                            
                <input placeholder={intl.get("CONFIRM_PASSWORD") || 'confirm new password'}
                       className="text-input"
                       type="password"
                       onChange={e =>
                          this.setState({
                              confirmPassword: e.target.value
                          })
                       }
                       disabled={this.state.passwordChanged}
                />
              </div>
              <div className='input-container grid-item'>
                <input
                className= {this.state.passwordChanged ?  "btn btn-lg btn-success btn-block resetPassword green" : "btn btn-lg btn-primary btn-block resetPassword red"}
                type={this.state.passwordChanged ? "button" : "submit"}
                value={this.state.passwordChanged ? intl.get("BACK_TO_LOGIN") || 'back to sign in' : intl.get("Confirm") || 'Confirm'}
                onClick={this.state.passwordChanged ? _=>this.props.history.push("/signin") : ""}
              />
              </div>
            </form>
          </div>

          <div className='footer grid-item signinFooter'>
           <a className="forgot" href="/forgot">
            {intl.get("FORGOT_MY_PASSWORD") || "Forgot my password"}
           </a>  
          </div>
        </section>
      </div>
    );
  }
}

export default ForgotPassword;