import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { PUBLIC_URL, API_PREFIX } from "../../../commons/config";
import { Card, CardText, RaisedButton } from "material-ui";
import clonedeep from "lodash.clonedeep";
import checkResponse from "../../../commons/CheckResponse";
import { Confirm } from "semantic-ui-react";
import intl from 'react-intl-universal';


function addOrReplace(array, item) {
  const i = array.findIndex(
    _item => _item.meta_label_id === item.meta_label_id
  );
  if (i > -1) array[i] = item;
  else array.push(item);
}

export default
@inject("store")
@observer
class OtherInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // data: [],
      // loading: true,
      // formdata: [],
      // labels: [],
      // open:false,
      // selected_metas: [],
      // selected_metas_ids: []
    };
  }

  componentDidMount() {
    // this.getData();
    
  }
  parentData(){
    console.log('parent data camee')
  }

  // getData() {
  //   let formdata = [];
  //   const { Http, get_business_id } = this.props.store;
  //   const URL = PUBLIC_URL + API_PREFIX + "metadata/all/" + get_business_id();
  //   checkResponse(URL, "get", 1).then(result => {
  //     this.insertData(result);
  //     this.setState({ meta_label_id: result.data.labels[0] });
  //   });
  //   this.props.user_selected_metas.map(data => {
  //     formdata.push({
  //       business_id: this.props.store.get_business_id(),
  //       key: data.key,
  //       meta_label_id: data.meta_label_id,
  //       meta_value: [{ id: data.meta_value_id, value: data.value }],
  //       user_id: this.props.user_id,
  //       value: data.value
  //     });
  //   });
  //   this.setState({ formdata });
  // }

  // insertData(data) {
  //   const { labels } = data.data;
  //   this.setState({ loading: false, data: labels });
  // }

  _form(selected_metas_ids, selected_values_ids) {
    if (!this.props.loading) {
      const { data } = this.props;
      
      return data.map(item => {
        const { meta_label_type_id, label, id, meta_values } = item;
        if (parseInt(meta_label_type_id) === 1) {
          return this._normalInput(label, id, selected_metas_ids);
        }
        if (parseInt(meta_label_type_id) === 3) {
          const { label, id, meta_values } = item;

          return this._multipleChoice(
            label,
            id,
            meta_values,
            selected_metas_ids,
            selected_values_ids
          );
        }

        return this._dropdown(label, id, meta_values, selected_metas_ids);
      });
    }
  }

  _normalInput(label, id) {
    const selected = false;
    let value = "";
    const user_selected_metas = this.props.user_selected_metas;
    user_selected_metas.map(meta => {
      if (meta.meta_label_id == id) {
        value = meta.value;
      }
    });

    return (
      <div className="row">
        <div className="col-md-6">
        <div className="form-group">
        <label
          htmlFor={id}
          className="label-control"
          style={{ fontSize: "13px" }}
        >
          {label}
        </label>
        <input
          onChange={this.props.changeOtherInformation}
          data-type={label}
          defaultValue={value}
          className="form-control"
          id={id}
        />
      
      </div>
        </div>
      </div>

    );
  }

  _dropdown(label, id, meta_values, selected_metas_ids) {
    console.log(meta_values);

    // Check if "none" already exists in meta_values
    const noneOption = meta_values.find(meta => meta.value === 'none');
    if (!noneOption) {
        meta_values.push({ key: 'none', value: 'none' });
    }

    let value = "none"; // Default value is "none"
    const user_selected_metas = this.props.user_selected_metas;

    user_selected_metas.forEach(meta => {
        if (meta.meta_label_id === id) {
            value = meta.value;
        }
    });

    return (
        <div className="row">
            <div className="col-md-6">
                <div className="form-group">
                    <label
                        htmlFor={id}
                        style={{ fontSize: "13px" }}
                        className="label-control"
                    >
                        {label}
                    </label>
                    <select
                        onChange={this.props.changeOtherInformation.bind(this)}
                        data-type={label}
                        className="form-control"
                        id={id}
                        defaultValue={value}
                    >
                        {meta_values.map((meta, index) => (
                            <option key={index} value={meta.value} id={meta.id}>
                                {meta.value}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    );
}


  _multipleChoice(label, id, meta_values) {
    const selected = false;
    let selectedValues = [];
    this.props.formdata.map(data =>
      selectedValues.push(data.meta_value[0].value + "&" + data.meta_value[0].id)
    );

    return (
      <div className="form-group">
        <label
          htmlFor={id}
          style={{ fontSize: "13px" }}
          className="label-control"
        >
          {label}{" "}
          {selected ? (
            <span style={{ color: "red", fontSize: "10px" }}>
              (already selected)
            </span>
          ) : (
            ""
          )}
        </label>
        {meta_values.map(meta => {
         
          return (
            <div>
              <input
                type="checkbox"
                name={label}
                value={meta.value + "&" + meta.id}
                onChange={this.props.changeOtherInformation}
                // onChange={this._setInput.bind(this)}
                data-type={label}
                className="meta-checkbox"
                checked={selectedValues.includes(meta.value + "&" + meta.id)}
                id={id}
                style={{ width: "14px", height: "14px", margin: "5px" }}
              />
              <label htmlFor={meta.value}>
                {meta.value}
                {/* {selectedValue ? <span style={{ color: "red" }}> *</span> : ""} */}
              </label>
            </div>
          );
        })}
        {/* <hr /> */}
        <br />
      </div>
    );
  }

  // handleServiceChange = e => {
  //   const checkbox = e.target;
  //   let allAppointmentServices = [...this.state.allAppointmentServices];
  //   if (checkbox.checked) {
  //     allAppointmentServices.push({ id: e.target.value });
  //     this.setState({ allAppointmentServices });
  //   } else {
  //     allAppointmentServices = allAppointmentServices.filter(
  //       app => app.id !== e.target.value
  //     );
  //     this.setState({ allAppointmentServices });
  //   }
  // };

  _setInput(element) {
    let formdata = [...this.props.formdata];
    element = element.target;
    const user_id = this.props.user_id;
    if (element.type === "checkbox") {
      var value = element.value.split("&")[0];
      const meta_value_id = element.value.split("&")[1];
      if (!element.checked) {
        formdata = formdata.filter(app => app.meta_value[0].value != value);
        return this.setState({ formdata });
      }
      if (element.checked) {
        const meta_label_id = element.id;
        const key = element.getAttribute("data-type");
        const { get_business_id } = this.props.store;
        const meta_value = [
          {
            id: meta_value_id,
            value
          }
        ];
        formdata.push({
          meta_label_id,
          meta_value,
          key,
          user_id,
          business_id: get_business_id()
        });
        return this.setState({ formdata });
      }
    }
    if (element.type === "select-one") {
      var options = element.options;
      var optionId = options[options.selectedIndex].id;
      const { get_business_id } = this.props.store;
      const node = element.nodeName.toLowerCase();
      let old = clonedeep(this.state.formdata);
      const key = element.getAttribute("data-type");
      const meta_label_id = element.id;
      const meta_value_id = optionId;
      const value =
        node === "select"
          ? this._getOptions(element).toString()
          : element.value;
      if (old.length === 0) {
        const meta_value = [
          {
            id: meta_value_id,
            value
          }
        ];
        old.push({
          meta_label_id,
          meta_value,
          key,
          user_id,
          business_id: get_business_id()
        });
      } else {
        const meta_value = [
          {
            id: meta_value_id,
            value: value
          }
        ];
        addOrReplace(old, {
          meta_label_id,
          meta_value,
          key,
          user_id,
          business_id: get_business_id()
        });
      }
      this.setState({ formdata: old });
    } else {
      const { get_business_id } = this.props.store;
      const node = element.nodeName.toLowerCase();
      let old = clonedeep(this.state.formdata);
      const key = element.getAttribute("data-type");
      const meta_label_id = element.id;
      const meta_value_id = "40b7df7e-5064-11e9-8647-d663bd873d93";
      const value =
        node === "select"
          ? this._getOptions(element).toString()
          : element.value;
      if (old.length === 0) {
        const meta_value = [
          {
            id: meta_value_id,
            value: value
          }
        ];
        old.push({
          meta_label_id,
          meta_value,
          key,
          value,
          user_id,
          business_id: get_business_id()
        });
      } else {
        const meta_value = [
          {
            id: meta_value_id,
            value: value
          }
        ];
        addOrReplace(old, {
          meta_label_id,
          meta_value,
          key,
          value,
          user_id,
          business_id: get_business_id()
        });
      }
      this.setState({ formdata: old });
    }
  }

  // _getOptions(element) {
  //   return [].filter
  //     .call(element.options, function(o) {
  //       return o.selected;
  //     })
  //     .map(function(o) {
  //       return o.value;
  //     });
  // }


  // _submitData() {
  
  //   const { formdata } = this.state;
  //   const URL = PUBLIC_URL + API_PREFIX + "user-meta/create";
  //   const URL2 =
  //     PUBLIC_URL + API_PREFIX + "user-meta/deleteall/" + this.props.user_id;
  //   checkResponse(URL2, "delete").then();
  //   checkResponse(URL, "post", formdata).then(result => {
  //     if (result.response)
  //       return this.setState({ err: result.response.data.error_description });
  //   });
  //   return this.setState({open:true}) ;
  // }

  render() {
   
    if (this.props.data.length) {
      return (
        <div className="md20">
                    <Confirm
                    open={this.state.open}
                    cancelButton='go back'
                    confirmButton='stay on the same page'
                    onCancel={()=>this.props.history.push('/clients/view')}
                    onConfirm={()=>this.setState({open:false})}
                    content="details updated successfully"
                  />
         
          <div style={{ margin: 0 }}>
           
                <div className="md20">{this._form()}</div>
                {/* <div className="text-right">
                  <RaisedButton
                    primary
                    onClick={this._submitData.bind(this)}
                    label={intl.get('SAVE') || 'Save'}
                  />
                </div> */}
             
          </div>
        </div>
      );
    } else {
      return (
        <div className="text-center">
          <h4>{intl.get('NO_ADDITIONAL_INFO') || 'No additional information available.'}</h4>
          <h4>
            <i>{intl.get('GO_TO_SETTINGS') || 'Go to /settings/clients information to add your fields.'} </i>
          </h4>
        </div>
      );
    }
  }
}

