import React from 'react';

const SingleUser = ({ user, onClick }) => {
	return (
		<div>
			<h4 onClick={onClick}>{user.name}</h4>
		</div>
	);
};

export default SingleUser;
