import React, { Component } from "react";
import { Link } from 'react-router-dom'
import axios from 'axios'
import Modal from "react-responsive-modal";
import XLSX from 'xlsx'
import Swal from 'sweetalert2'
import uuid from 'uuid'
// import Card from 'material-ui/Card';
import { inject, observer } from "mobx-react";
import { PUBLIC_URL, API_PREFIX } from "../../../commons/config";
import UserTable from "../../../components/commons/table/user-table";
import checkResponse from "../../../commons/CheckResponse";
import Excel from './../../../commons/Excel';
import intl from 'react-intl-universal';


export default
@inject("store")
@observer
class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      file: '',
      business: {},
      credentials: { sid: '', token: '' },
      loading: true,
      modalOpen: false
    };
  }

  componentDidMount() {
    this._getData();
    this.getSmsTokens()
  }

  _getData() {
    const { get_business_id } = this.props.store;
    const URL = PUBLIC_URL + API_PREFIX + "clients/" + get_business_id();
    const BIZ_URL = PUBLIC_URL + API_PREFIX + "business/" + get_business_id();
    checkResponse(URL, "get").then(result => {
      checkResponse(BIZ_URL, "get").then((biz_result) => {

        this.insertData(result, biz_result);
      })
    });
  }

  insertData(data, biz_data) {
    if (data && data.data && data.data.users) {
      let { users } = data.data;
      const { business } = biz_data.data;
      const biz_phone = business.business_phone + business.mobile;
      users.forEach(function (item) {
        Object.assign(item, { biz_phone })
      })
      this.setState({ users, business, loading: false });
    }
  }

  getSmsTokens = async () => {
    const token = localStorage.getItem('API_TOKEN')
    try {
      const { data: credentials } = await axios.get('https://mnty-mbl-bookinghub.com/api/clinic/user/sms-credentials', {
        headers: {
          'x-auth-token': token
        }
      })
      //  console.log('Here are credentials :',credentials)
      this.setState({ credentials })
    } catch (ex) {
      console.log(ex)
    }
  }

  openModal = () => {
    this.setState({ modalOpen: true })
  }

  // Excel file change handler
  onChangeHandler = event => {
    event.preventDefault();
    const file = event.target.files[0];
    this.setState({ file: file });
  };


  onDataImport = () => {
    this.setState({ excelLoading: true });
    let { file } = this.state;
    
    if (!file) {
      console.error("No file selected");
      this.setState({ excelLoading: false });
      return;
    }
  
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
  
    reader.onload = (e) => {
      /* Parse data */
      const arrayBuffer = e.target.result;
      const data = new Uint8Array(arrayBuffer);
      const bstr = String.fromCharCode.apply(null, data);
      const wb = XLSX.read(bstr, { type: 'binary' });
  
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
  
      /* Convert array of arrays */
      const sheetData = XLSX.utils.sheet_to_json(ws, { header: 1 });
      console.log(sheetData);
  
      const expected_cols = ['name', 'country_code', 'mobile', 'Country Code'];
      const result = sheetData[0].filter(val => expected_cols.includes(val.toLowerCase()));
  
      // if (result.length < 3) {
      //   this.setState({ excelLoading: false });
      //   return Swal.fire({
      //     icon: 'error',
      //     type: 'error',
      //     title: 'Validation Error',
      //     text: 'Your file format is incorrect. Please download the format and fill it'
      //   });
      // }
  
      const lower_case_cols = sheetData[0].map(v => v.toLowerCase());
      const index_of_name = lower_case_cols.indexOf('name');
      let index_of_country_code = lower_case_cols.indexOf('country_code');
      index_of_country_code = lower_case_cols.indexOf('country code');
      const index_of_mobile = lower_case_cols.indexOf('mobile');
      const index_of_email = lower_case_cols.indexOf('email');
      delete sheetData[0];
  
      const converted = sheetData.map(val => ({
        id: uuid(),
        password: '',
        name: val[index_of_name],
        country_code: val[index_of_country_code],
        mobile: val[index_of_mobile],
        active: 1,
        email: val[index_of_email] ? val[index_of_email] : uuid() + "@" + this.props.store.get_business_name() + ".com",
        business_id: this.props.store.get_business_id(),
      })).filter(Boolean);
  
      this.setState({ users: [...this.state.users, ...converted] });
      const URL = PUBLIC_URL + API_PREFIX + "clients/bulk";
      checkResponse(URL, "post", converted)
        .then(async result => {
          // Handle success response
        })
        .catch(e => console.log(e));
  
      Swal.fire({
        icon: 'success',
        type: 'success',
        text: 'Data imported successfully!'
      }).then((result) => {
        if (result.value) {
          this.setState({ modalOpen: false });
        }
      });
    };
  
    reader.readAsArrayBuffer(file);
  };
  

  formatDownload = async () => {
    try {

      window.location.href = PUBLIC_URL + API_PREFIX + "clients/download"
    } catch (ex) {
      Swal.fire({ type: 'error', icon: 'error', text: 'Something went wrong' })
    }
  };

  render() {
    const { users, business, credentials } = this.state;

    let patients = this.state.users.filter(
      user => user.speciality == "Patient"
    );
    patients = patients.length;
    if (this.state.users) {
      return (

        <>
          <header className='header'>
            <div className="mt-4 ">
              <div style={{ display: 'flex', marginBottom: 10, justifyContent: 'space-between', alignItems: 'center', paddingLeft: 30, paddingRight: 30 }}>
                <h2 className="primary-heading"> Clients</h2>
                <div className="d-flex align-items-center justify-content-end" >
                  <Link className="whitePrimaryBtn-design mr-2" style={{ padding: '.6rem 1rem' }} to='/clients/create'>{intl.get("ADD_NEW") || "ADD NEW"}</Link>
                  <Excel
                    data={users}
                    reportType='Clients'
                  />
                  <button className="whitePrimaryBtn-design ml-2" onClick={this.openModal}>Import</button>
                </div>
              </div>

              <div className="action-container ">
                <div className="table-container">
                  <UserTable
                    history={this.props.history}
                    to="/clients/view/"
                    users={users}
                    business={business}
                    credentials={credentials}
                    _getData={this._getData}
                    showRole={false}
                  />
                </div>
              </div>


            </div>


          </header>

          <Modal
            open={this.state.modalOpen}
            onClose={_ => this.setState({ modalOpen: false })}
            center
          >
            <p>Please download the sample format and upload the same</p>
            <div className="d-flex flex-column align-items-center justify-content-center">
              <button className="btn-green" onClick={this.formatDownload}>Download</button>

              <div className="my-2 text-center">----------------------------</div>

              <input type="file" className='form-control' onChange={this.onChangeHandler} />
              <button className="btn-ghost my-2" onClick={this.onDataImport}>Upload</button>
            </div>
          </Modal>
        </>


      );
    }
    return <div />;
  }
}
