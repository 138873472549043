import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { PUBLIC_URL, API_PREFIX } from '../../../commons/config';
import intl from 'react-intl-universal';
import Swal from 'sweetalert2'

export default @inject('store') @observer class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            currencies: [],
            schema: {},
            units: [],
            title:'',
            price:'',
            currency_id:'',
            unit_id:''
        };
    }

    componentDidMount() {
        this._getData();
    }

    _getData() {
        const { Http, get_business_id } = this.props.store;
        const URL = PUBLIC_URL + API_PREFIX + "services/units_currencies/" + get_business_id();
        Http().get(URL)
        .then(this.insertData.bind(this));
    }

    insertData(data) {
        const { currencies, units } = data.data;
        this.setState({ currencies, units, loading: false,currency_id:currencies[0].id,unit_id:units[0].id});
        // this.renderForm();
    }

    

    handleChange = e=>{
        this.setState({[e.target.name]:e.target.value})
    }

    form  = () => {
       
       
        return (
          
                <form onSubmit={this.submit}> 

               <div className="row">
                   <div className="col-md-6 col-sm-12">
                   <div className='form-group-container'>
                    <label>{intl.get('TITLE') || 'Title'}</label>
                    <input 
                    placeholder='Type your title'
                    className='form-control' 
                    name='title' 
                    onChange={this.handleChange} 
                    value={this.state.title}/>
                    </div>
                   </div>
                   <div className="col-md-6 col-sm-12">
                   <div className='form-group-container'>
                    <label>{intl.get('PRICE') || 'Price'}</label>
                    <input 
                    placeholder='Type your price'
                    className='form-control' 
                    name='price' 
                    onChange={this.handleChange} 
                    value={this.state.price} />
                    </div>
                   </div>
                   <div className="col-md-6 col-sm-12">
                   <div className='form-group-container'>
                    <label>{intl.get('CURRENCY') || 'Currency'}</label>
                    <select 
                    style={{border:'none'}}
                    className='form-control' 
                    name='currency_id' 
                    onChange={this.handleChange}  
                    value={this.state.currency_id}>
                        {this.state.currencies.map(curr=> <option key={curr.id} value={curr.id}>{curr.currency_label}</option>)}
                    </select>
                    </div>
                   </div>
                   <div className="col-md-6 col-sm-12">
                   <div className='form-group-container'>
                    <label>{intl.get('UNIT') || 'Unit'}</label>
                    <select 
                    style={{border:'none'}}
                    className='form-control' 
                    name='unit_id' 
                    onChange={this.handleChange}  
                    value={this.state.unit_id}>
                        {this.state.units.map(unit=> <option key={unit.id} value={unit.id}>{unit.per}</option>)}
                    </select>
                    </div>
                   </div>
               </div>

                
                    <div className='my-3'>
                        <div className="d-flex justify-content-center">
                        <button type='submit' className='btn-green mr-2'>{intl.get("SUBMIT") || "Submit"}</button>
                        <button type='submit' className='btn-ghost'
                        onClick={()=>this.props.history.push('/services/view')}>{intl.get("CANCEL") || "Cancel"}</button>

                        </div>
                    </div>
                    
                </form>
          
        )
    }

    submit = e=> {
        e.preventDefault()
        const { Http, get_business_id } = this.props.store;
        const URL = PUBLIC_URL + API_PREFIX + "services/create";
        const { title, price, currency_id, unit_id } = this.state;
        Http().post(URL, { title, price, price_discount:0, currency_id, unit_id, business_id: get_business_id()})
        .then(result => {
            if("error" in result.data) return this.setState({ err: result.data.error_description });
            return this.props.history.push('/services/view');
        }).catch(err=> Swal.fire("Oops...", err.response.data.error|| "Unable to create service", "error"));
    }

    render() {
        if(!this.state.loading) {
            if(this.state.currencies.length && this.state.units.length) {
                return(
                    <header className="header">
                    <div className="text-center mt-4">
                        <h2 className="primary-heading"> {intl.get('ADD_SERVICE') || 'Add Service'}</h2>
                    </div>
                    <div className="action-container width-60" >
                        <div className="table-container padding-2" >
                        {this.state.err ? <h4 style={{color:"red",textAlign:"center",padding:"5px",margin:"5px"}}>{this.state.err}</h4> : []}
                        {this.form()}
                        </div>
                    </div>
                    </header>
            
                )
            }
            return (
                <div className="text-center" style={{marginTop: 20}}>
                    <h2>You cannot add a service without setting a currency/unit rate first.</h2>
                </div>
            )
        }
    }
}