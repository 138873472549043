import React, { Component } from 'react'
import intl from 'react-intl-universal'
import { inject, observer } from 'mobx-react'
import { Redirect, withRouter } from 'react-router-dom'
import OutsideAlerter from './OutsideAlerter'

import * as Colors from 'material-ui/styles/colors'
import getMuiTheme from 'material-ui/styles/getMuiTheme'
import TextField from 'material-ui/TextField'

import AppBar from 'material-ui/AppBar'
import DropDownMenu from 'material-ui/DropDownMenu'
import MenuItem from 'material-ui/MenuItem'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'

const muiTheme = getMuiTheme({
  palette: {
    textColor: Colors.darkBlack,
    primary1Color: Colors.white,
    primary2Color: Colors.indigo700,
    accent1Color: Colors.redA200,
    pickerHeaderColor: Colors.darkBlack,
    alternateTextColor: Colors.redA200
  },
  appBar: {
    height: 128,
  },
});

export default
@inject("store")
@observer

class LanguageBar extends Component {
   constructor() {
    super();

    this.state = {
      selection : 1
    };
    this.handleChange = this.handleChange.bind(this); 
  }

  
  handleChange(event, index, value) {
    //set selection to the value selected
    this.setState({ selection : value });

  }

  pageControl(){
    if( this.state.selection == 1){
      return (
        <div>Hello</div>
      );
    } else if( this.state.selection == 2) {
      return (
        <div>Hola</div>
      );
    } else if( this.state.selection == 3) {
      return (
        <div>Bonjour</div>
      );
    }
  }

    render() {  //console.log("store", this.props.store) 

        return(
                  <MuiThemeProvider muiTheme={muiTheme}>
                    <AppBar className='languageBarTitle'
                            title="My App"
                            style={{ 
                                background:"#ffb400", //hex color values (yellow)
                                height: '126'
                            }}
                            titleStyle = {
                              {
                                color:"#FFFFF" //color of text (black)
                              }
                            }
                            showMenuIconButton={false}
                     />
                     <DropDownMenu className='languageBar'
                                   value={this.state.selection} 
                                   onChange={this.handleChange}   
                     >
                        <MenuItem className='languageName' value={1} primaryText="Eng"  />
                        <MenuItem className='languageName' value={2} primaryText="Fr" />
                        <MenuItem className='languageName' value={3} primaryText="العربية" />
                        <MenuItem vclassName='languageName' alue={3} primaryText="PRT" />
                    </DropDownMenu>
                    <br/><br/><br/>
                    <center>
                    {this.pageControl()}
                    </center>
                  </MuiThemeProvider>
        )
    }
}