import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import Swal from 'sweetalert2'
// import 'sweetalert2/src/sweetalert2.scss'
import { PUBLIC_URL, API_PREFIX } from "../../../commons/config";
import ServiceTable from "../../../components/commons/table/service-table";
import checkResponse from "../../../commons/CheckResponse";
import { RaisedButton } from "material-ui";
import intl from 'react-intl-universal';

export default
@inject("store")
@observer
class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services: [],
      loading: false
    };
  }

  componentDidMount() {
    this._getData();
  }

  deleteservice = id => {
    const URL = PUBLIC_URL + API_PREFIX + "services/delete/" + id;
    Swal.fire({
      title: "Are you sure?",
      text:
        "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(result => {
      if (result.value) {

        let services = [...this.state.services];
        services = services.filter(service => service.id != id);
        this.setState({ services });
        try {
          checkResponse(URL, "delete").then(r => {
            if (r.data) {

            }
          });
          Swal.fire("Deleted!", "Service has been deleted.", "success");
        } catch (ex) {
          Swal.fire("Oops...", "Something went wrong", "error");
        }
      }
    });

  };

  _getData() {
    const { Http, get_business_id } = this.props.store;
    const URL =
      PUBLIC_URL + API_PREFIX + "services/getall/" + get_business_id();
    Http()
      .get(URL)
      .then(this.insertData.bind(this));
  }

  insertData(data) {
    const { services } = data.data;
    this.setState({ services, loading: false });
  }

  render() {
    if (!this.state.loading) {
      return (
        <header className="header">
          <div className="mt-4">
            <div style={{ display: 'flex', marginBottom: 10, justifyContent: 'space-between', alignItems: 'center', paddingLeft: 30, paddingRight: 30 }}>
              <h2 className="primary-heading"> {intl.get('SERVICES') || 'Services'}</h2>
              <div className="d-flex">
                <button className="whitePrimaryBtn-design" style={{ marginLeft: 'auto' }}
                  onClick={_ => this.props.history.push("/services/create")}>
                  {intl.get("ADD_NEW") || "Add new"}
                </button>
              </div>
            </div>
          </div>
          <div className="action-container">
            <div className="table-container">
              <ServiceTable
                to="/services/view/"
                history={this.props.history}
                services={this.state.services}
                deleteservice={this.deleteservice}
              />
            </div>
          </div>
        </header>

      );
    }
    return <div />;
  }
}
