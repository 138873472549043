import React, { Component } from "react";
import { PUBLIC_URL, API_PREFIX } from "../../../commons/config";
import moment from "moment";
import { inject, observer } from "mobx-react";
import checkResponse from "../../../commons/CheckResponse";
import FC from "./components/fc";
import Sidebar from "./components/sidebar";
import "fullcalendar";
import Modal from "react-responsive-modal";
import "./AppointmentEdit.css";

export default
@inject("store")
@observer
class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      today_date: moment().format("MMMM DD YYYY"),
      year: new Date().getFullYear(),
      showCalendar: true,
      stats: {},
      isSidebarModalOpen: false,
    };
  }

  handleOpenSidebarModal = () => {
    this.setState({ isSidebarModalOpen: true });
  };

  handleCloseSidebarModal = () => {
    this.setState({ isSidebarModalOpen: false });
  };

  componentDidMount() {}

  _updateDate = (today_date) => {
    this.handleCloseSidebarModal();
    this.setState({ today_date: moment(today_date).format("MMMM DD YYYY") });
  };

  _updateDateFC = (today_date) => {
    this.handleCloseSidebarModal();
    this.setState({
      today_date: moment(today_date).format("MMMM DD YYYY"),
      shouldSwitchToYear: false,
    });
  };

  _updateCalendar = (bool) => {
    this.handleCloseSidebarModal();
    this.setState({ shouldSwitchToYear: bool });
  };

  _updateYearView = (year) => {
    this.handleCloseSidebarModal();
    this.setState({ year });
  };

  render() {
    const ar = localStorage.getItem("lang") === "ar";
    const isSmallDevice = window.innerWidth < 750;

    return (
      <div
        style={{
          overflow: "hidden",
          height: "calc(100%)",
          background: "white",
          position: "relative",
          zIndex: 0,
          height:'100vh'
        }}
      >
        <div className="row">
          {isSmallDevice && (
            <div className="w-100">
              <button
                className="w-100 options-button "
                onClick={this.handleOpenSidebarModal}
              >
                Open Calender Options
              </button>
            </div>
          )}
          <div className="col-md-2 d-none d-md-block">
            <Sidebar
              className="appointmentsScheduler"
              updateYearView={this._updateYearView.bind(this)}
              switchToYear={this._updateCalendar}
              onDateChange={this._updateDate.bind(this)}
              today_date={this.state.today_date}
              props={{ ...this.state }}
              stats={this.state.stats}
            />
          </div>
          <div className="col-md-10">
            <FC
              id="fullCalendar"
              className="overflow-hidden"
              _updateMiniCalendar={(selectedDay) =>
                this.setState({ selectedDay })
              }
              onDateChange={(today_date) => this._updateDateFC(today_date)}
              props={{ ...this.state }}
              history={this.props.history}
              stats={(stats) => this.setState({ stats })}
            />
          </div>
        </div>

        <Modal
          open={this.state.isSidebarModalOpen}
          onClose={this.handleCloseSidebarModal}
          center
        >
          <div style={{ position: "relative" }}>
            <Sidebar
              className="appointmentsScheduler"
              updateYearView={this._updateYearView.bind(this)}
              switchToYear={this._updateCalendar}
              onDateChange={this._updateDate.bind(this)}
              today_date={this.state.today_date}
              props={{ ...this.state }}
              stats={this.state.stats}
            />
            <div
              onClick={() => {
                this.handleCloseSidebarModal();
              }}
              style={{
                position: "absolute",
                top: 0,
                padding: 20,
                paddingTop: 10,
                right: 0,
                cursor: "pointer",
              }}
            >
              close
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const ar = localStorage.getItem("lang") === "ar";
const styles = {
  sidebar: {
    width: "240px",
    position: "absolute",
    zIndex: 1,
    top: 0,
    right: ar ? 0 : "",
    overflowX: "hidden",
  },
  fc: {
    marginLeft: ar ? 0 : 240,
    marginRight: ar ? 240 : 0,
  },
};
