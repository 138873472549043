import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Unitstable from '../../../components/commons/table/units-table'
import { PUBLIC_URL, API_PREFIX } from '../../../commons/config';
import checkResponse from '../../../commons/CheckResponse'
import Swal from 'sweetalert2'
import intl from 'react-intl-universal';

export default @inject('store') @observer class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      units: [], loading: true,
    };
  }

  componentDidMount() {
    this._getData();
  }

  deleteUnit = id => {
    const URL = PUBLIC_URL + API_PREFIX + "units/delete/" + id;


    Swal.fire({
      title: "Are you sure?",
      text:
        "this would not be revert back",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(result => {
      if (result.value) {

        let units = [...this.state.units];
        units = units.filter(u => u.id != id);
        this.setState({ units });
        try {
          checkResponse(URL, "delete").then(r => {
            if (r.data) {
              Swal.fire("Deleted!", "Unit has been deleted.", "success");
            }
          });
        } catch (ex) {
          Swal.fire("Oops...", "Something went wrong", "error");
        }
      }
    });

  };



  _getData() {
    const { get_business_id } = this.props.store;
    const URL = PUBLIC_URL + API_PREFIX + "units/getall/" + get_business_id()
    checkResponse(URL, "get", 1)
      .then(result => {
        this.setState({ units: result.data.units })
      })
  }

  render() {
    if (this.state.units) {
      return (
        <header className="header">
          <div className="text-center mt-4">
            <div style={{ display: 'flex', marginBottom: 10, justifyContent: 'space-between', alignItems: 'center', paddingLeft: 30, paddingRight: 30 }}>
              <h2 className="primary-heading"> {intl.get('UNITS') || 'Units'}</h2>
              <div className="d-flex">
                <button className="whitePrimaryBtn-design ml-auto" onClick={_ => this.props.history.push("/units/create")}>
                  {intl.get('ADD_NEW') || 'Add Unit'}
                </button>
              </div>
            </div>
          </div>
          <div className="action-container " >
            <div className="table-container">
              <Unitstable
                history={this.props.history}
                to="/units/view/"
                units={this.state.units}
                deleteUnit={this.deleteUnit}
              />
            </div>
          </div>
        </header>

      )
    }
    return <div></div>
  }
}