import React, { Component } from "react";
import { PUBLIC_URL, API_PREFIX } from "../../../commons/config";
import checkResponse from "../../../commons/CheckResponse";
import { inject, observer } from "mobx-react";
import InvoiceCard from './InvoiceCard'
import {Confirm } from 'semantic-ui-react'
import intl from 'react-intl-universal';


@inject("store")
@observer
class AllQuotations extends Component {
  state = {
    invoices: [],
    open:false
  };

  componentDidMount() {
    this.getInvoices();
  }

  getInvoices = async () => {
    const URL =
      PUBLIC_URL +
      API_PREFIX +
      "invoices/business/" +
      this.props.store.get_business_id();
    checkResponse(URL, "get").then(r => {
      console.log(r)
      if(r.data){
        let invoices = r.data.invoices
       return this.setState({invoices});
      }else{
         return this.setState({invoices:[]})
      }
    });
  };

  deleteInvoice = async id => {
    
      const URL = PUBLIC_URL + API_PREFIX + "invoices/delete/" + id;
      checkResponse(URL, "delete").then(r => {
        if(!r.data){
          return console.log(r.response.data)
        }
        let invoices = [...this.state.invoices]
        invoices = invoices.filter(inv=>
          inv.id != id
        )
        this.setState({invoices,open:false})
      });
    
  };

  render() {
    return (
      <div>
        <div className="page-hdr">
          <div className="row">
            <div className="col-4 page-menu">
              {/* <a
                id="cancel"
                onClick={_=>window.location.reload()}
                data-toggle="tooltip"
                data-placement="left"
                title="Reload"
                style={{cursor:"pointer"}}

              >
                <i className="fa fa-refresh" />
              </a> */}
              <a
                onClick={_=>this.props.history.push("/invoices/create")}
                data-toggle="tooltip"
                style={{cursor:"pointer"}}
                data-placement="left"
                title="Create Invoice"
              >
                <i className="fa fa-plus" />
              </a>
            </div>
          </div>
        </div>

        <div className="content">
              {(this.state.invoices.length != 0) ? (
                <div className="mydivss">
                  {this.state.invoices.map(invoice => {
                    if (invoice != null)
                    return (
                      <InvoiceCard props={{...invoice}} deleteInvoice={invoice_id=>this.setState({invoice_id,open:true})} history={this.props.history}/>
                      );
                    })}
                </div>
              ) : (
                <tbody>
                  <p>{intl.get('NO_INVOICE_FOUND') || 'there is no invoices for you at the moment'}</p>
                </tbody>
              )}
      </div>
            
      <Confirm open={this.state.open}  content={intl.get('ARE_YOU_SURE') || 'Are you sure ?'}  onCancel={()=>this.setState({open:false})} cancelButton={intl.get('CANCEL') || 'Cancel'} confirmButton={intl.get("OK") || 'Ok'} onConfirm={()=>this.deleteInvoice(this.state.invoice_id)}/> 

      </div>
    );
  }
}

export default AllQuotations;
