import React, { Component } from "react";
import moment from "moment";
import axios from "axios";
import { Icon, Pagination } from 'semantic-ui-react'
import intl from 'react-intl-universal';

import Navigation from './../../../components/Navigation';
const url = 'https://mnty-mbl-bookinghub.com/api/messageAnalysis/messages/user/'

const formatDate = date => {
  return (
    moment(date)
      .subtract(3, "hours")
      .format("LL") +
    " at " +
    moment(date)
      .subtract(3, "hours")
      .format("hh:mm a")
  );
};

class MyMessages extends Component {
  state = {
    messages: [],
    current:1
  };

  componentDidMount() {
    this.getMessages(1);
  }


  onPageChange= (e,data) =>{
    this.getMessages(data.activePage)
  }

  getMessages = (current) => {
    let xAuthToken = localStorage.getItem("x-auth-token") || "";
    let email = localStorage.getItem("email") || "";
    let data = {email}
    axios
      .post(`${url}${current}`, data,{
        headers: { "x-auth-token": xAuthToken }
      })
      .then(r => {
          let {recordsData,current,pages,count} = r.data
        this.setState({ messages:recordsData,current,pages,count });
      })
      .catch(e => console.log(e.message));
  };

  render() {
    let messages = this.state.messages;
    let contents;
    if(messages.length > 0){
    contents = messages.map(msg => (
      <tr>
        <td>{msg.destinationAddress}</td>
        <td>{msg.message}</td>
        <td>{formatDate(msg.DateSent)}</td>
        <td>{msg.pricing}</td>
      </tr>
    ))
    }else{
      contents = <tr><td colSpan={4}>No message found</td></tr>
    }
    return (
<>
      <Navigation/>
<header className="header">
<div className="text-center mt-4">
    <h2 className="primary-heading"> Messages</h2>
    </div>
    <div className="action-container">
      <div className="table-container padding-2">
      <div className="row">
          <div className="col-md-12">
            <table className="table table-bordered">
              <tr
                // style={{
                //   background: "black",
                //   color: "white",
                //   fontWeight: "bold",
                //   fontSize: "16",
                //   padding: "3px"
                // }}
              >
                <td>{intl.get('DESTINATION') || 'Destination'}</td>
                <td>{intl.get('MESSAGE_TEXT') ||  'Message text'}</td>
                <td>{intl.get('SENT_AT') || 'Sent at'}</td>
                <td>{intl.get('PRICE') || 'Price'}</td>
              </tr>
              {contents}
            </table>
        <Pagination
    defaultActivePage={1}
    ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
    firstItem={{ content: <Icon name='angle double left' />, icon: true }}
    lastItem={{ content: <Icon name='angle double right' />, icon: true }}
    prevItem={{ content: <Icon name='angle left' />, icon: true }}
    nextItem={{ content: <Icon name='angle right' />, icon: true }}
    totalPages={this.state.pages}
    onPageChange={this.onPageChange}
  />
          </div>
        </div>
      </div>
    </div>
</header>
</>
      // <div
      //   className="container"
      //   style={{ margin: "0.5rem auto", width: "70%" }}
      // >
       
      //   <p>pages : {this.state.pages} - current : {this.state.current} - count : {this.state.count}</p>
      // </div>
    );
  }
}

export default MyMessages;


