import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { PUBLIC_URL, API_PREFIX } from "../../../commons/config";
import checkResponse from "../../../commons/CheckResponse";
import intl from 'react-intl-universal';
import Swal from 'sweetalert2'

export default
@inject("store")
@observer
class extends Component {
  state = {
    unit: '',
    loading: true,
    schema: [],
    open:false
  };

  componentDidMount() {
    const { unit_id } = this.props.match.params;
    const URL = PUBLIC_URL + API_PREFIX + "units/getone/" + unit_id;
    checkResponse(URL, "get", 1).then(result => {
      if (result.data) return this.insertData(result);
      if (result.response)
        return this.setState({ err: result.response.data.error_description });
    });
  }

  insertData(data) {
    const unit = data.data.unit[0];
    this.setState({ unit:unit.per, loading: false });
 
  }
 
  _basicInputs() {
  
      return (
        <form onSubmit={this._basicSubmit.bind(this)}>
     <div className="form-group-container">
       <label htmlFor="">Title</label>
       <input 
       type="text" 
       className='form-control text-center'
       value={this.state.unit}
       onChange={(e)=>this.setState({unit: e.target.value})}
       name='per'
       />
     </div>
     <div className="my-3 d-flex justify-content-center">
       <button className="btn-ghost mr-2" type='submit'>
         {intl.get('SAVE') || 'Save'}
       </button>
       <button className="btn-ghost" onClick={() => this.props.history.push("/units/view")}>
         {intl.get('CANCEL') || 'Cancel'}
       </button>
     </div>
        </form>
        
      );
    }
  

  _basicSubmit(e) {
    e.preventDefault()
    const per =this.state.unit;
    if(per === ''){
      return Swal.fire('warning', 'Title is required', 'Warning')
    }
      
    const { unit_id } = this.props.match.params;
    const URL = PUBLIC_URL + API_PREFIX + "units/update/" + unit_id;
    checkResponse(URL, "put", { per })
     
      .then(r => {
        Swal.fire('Success', 'Unit updated successfully!', 'success')
        .then(result =>{
          if(result.value){
            this.props.history.push('/units/view')
          }
        })
      });
  }

  _basic() {
    return (
      <div className="md20">
    
       {this._basicInputs()}
      </div>
    );
  }

  render() {
    return (
<header className="header">
<div className="text-center mt-4">
        <h2 className="primary-heading"> {intl.get('EDIT_UNIT') || 'Edit Unit'}</h2>
       </div>
       <div className="action-container width-60" >
         <div className="table-container padding-2" >
         {this._basic()}
         </div>
       </div>
</header>

    );
  }
}
