import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import axios from 'axios'
import { isValidPhoneNumber } from 'react-phone-number-input'
import Swal from 'sweetalert2'
import { PUBLIC_URL, API_PREFIX } from "../../../commons/config";
import checkResponse from "../../../commons/CheckResponse";
import uuid from "uuid";
import CountryCodes from "../../CountryCodes";
import intl from "react-intl-universal";
// const url = 'http://localhost:4000/api/contacts/add';
const url = 'https://mnty-mbl-bookinghub.com/api/contacts/add';
export default
@inject("store")
@observer
class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      err: "",
      form: {
        name: "",
        mobile: "",
        email: "",
        active: 1,
       mobile1:'',
       mobile2:'',
       relation1:'',
       relation2:'',
       contactName1:'',
       contactName2:'',

      },
      countryCode: "+88",
      loading: false
    };
  }

  _handleKeyPress(e) {
    if (e.key === "Enter") {
      this._doCreate();
    }
  }

  _validate() {
    this.setState({loading: true})
    const regex = new RegExp(
      /(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$)/
    );
 
    let valid = true;
    for (let element in this.state.form) {
      if (element === "name" && this.state.form[element].length == 0) {
        this.setState({
          err: intl.get("REQUIRE_NAME_ERROR") || "the name can't be empty",
               loading: false

        });
        // if (!this[element].className.includes("err"))
        //   this[element].className += " err";
        return (valid = false);
      }else if(element === "mobile"){
        let mobile_number = this.state.country_code + this.state.form.mobile;

        if(!isValidPhoneNumber(mobile_number)){
          this.setState({
            err:
             intl.get("INVALID_PHONE_NUMBER_ERROR") ||
             "please enter a valid phone number",
             loading: false
          });
          // if (!this[element].className.includes("err"))
          // this[element].className += " err";
        return (valid = false);
        }
       
      }else{
        return valid=true
      }
    
      
    }
    return valid;
  }

  _doCreate() {
    this.setState({loading: true})
    const { get_business_id } = this.props.store;
  
    if (this._validate()) {
      let { name, 
        email, 
        mobile, 
        active,
        mobile1,
       mobile2,
       relation1,
       relation2,
       contactName1,
       contactName2,
       } = this.state.form;
       let client_mobile = this.state.country_code + mobile;
       let client_relative_mobile1 = this.state.country_code + mobile1;
       let client_relative_mobile2 = this.state.country_code + mobile2;
    
       if(!isValidPhoneNumber(client_mobile)) return this.setState({err:  intl.get("INVALID_PHONE_NUMBER_ERROR") ||
       "please enter a valid phone number", loading:false})
       if(mobile1 && !isValidPhoneNumber(client_relative_mobile1)) return this.setState({err:  intl.get("INVALID_RELATIVE_1_PHONE_NUMBER_ERROR") ||
       "please enter a valid phone number for first relative", loading:false})
       if(mobile2 && !isValidPhoneNumber(client_relative_mobile2)) return this.setState({err:  intl.get("INVALID_RELATIVE_2_PHONE_NUMBER_ERROR") ||
       "please enter a valid phone number for second relative", loading:false})
      const URL = PUBLIC_URL + API_PREFIX + "clients/create";
      if (!email && email.length == 0) {
        email = uuid() + "@" + this.props.store.get_business_name() + ".com";
      }

      let emergency_contacts = []
      let obj1={
        name: contactName1,
        mobile: mobile1,
        relation : relation1
      }
      let obj2={
        name: contactName2,
        mobile: mobile2,
        relation : relation2
      }
      emergency_contacts.push(obj1)
      if(contactName2){
        emergency_contacts.push(obj2)
      }
      const data = {
        name,
        email,
        country_code: this.state.country_code,
        mobile,
        active,
        emergency_contacts,
        business_id: get_business_id()
      };

      // for twoway
      // const twoway_data = {
      //   user_email: localStorage.getItem('email'),
      //   firstName: name,
      //   contact_email:  email,
      //   mobile: this.state.country_code + mobile,
      //   isActive: active,
      //   group:[],
      //   country: ''
      // };
       
        checkResponse(URL, "post", data)
        .then(async result => {
          if (result.data) {
            // await axios.post(url, Object.assign(twoway_data, {onpoint_id: result.data.id}))
        

            this.setState({loading: false})
            Swal.fire('Success', 'Basic details saved successfully!', 'success')
            .then(result =>{
              if(result.value){
                this.props.history.push('/clients/view')
              }
            })
          }
          if (result.response)
      
            return this.setState({
              err: result.response.data.error_description,
              loading: false
            });
        })
        .catch(e => console.log(e));
    }
  }
  _setInput(event) {
    const element = event.target.id;
    let form = Object.assign({}, this.state.form);
    form[element] = event.target.value;
    this.setState({ form , err:''});
  }
  _form() {
    return (
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <div className="form-group-container">
          <label htmlFor="name" className="label-control">
            {intl.get("FULL_NAME") || "Full Name"}
          </label>
          <input
            onKeyPress={this._handleKeyPress.bind(this)}
            onChange={ev => this._setInput(ev)}
            ref={name => (this.name = name)}
            className="form-control"
            id="name"
            name="name"
            placeholder="Full Name"
            style={{textAlign:'center'}}
          />
          </div>
         
        </div>
        <div className="col-md-6 col-sm-12">
          <div className="form-group-container">
          <label htmlFor="email" className="label-control">
            {intl.get("EMAIL") || "E-mail"}{" "}
            {`(${intl.get("OPTIONAL")})` || "(Optional)"}
          </label>
          <input
            name="email"
            onKeyPress={this._handleKeyPress.bind(this)}
            onChange={ev => this._setInput(ev)}
            ref={email => (this.email = email)}
            className="form-control"
            id="email"
            value={this.state.form.email}
            placeholder="example@example.com"
            style={{textAlign:'center'}}
          />
          </div>
         
        </div>
        <div className="col-md-6 col-sm-12">
        
          <label htmlFor="mobile" className="custom-label-control">
            {intl.get("COUNTRY_CODE") || "Country Code"}
          </label>
          <CountryCodes
            countryCode={country_code => this.setState({ country_code })}
           cssClass='country-code-1'
          />
         
         
         
        </div>
        <div className="col-md-6 col-sm-12">
          <div className="form-group-container">
          <label htmlFor="mobile" className="label-control">
            {intl.get("PHONE_NUMBER") || "Phone Number"}
          </label>
        <input
            name="mobile"
           
            onKeyPress={this._handleKeyPress.bind(this)}
            onChange={ev => this._setInput(ev)}
            ref={mobile => (this.mobile = mobile)}
            className="form-control"
            id="mobile"
            placeholder="70123456"
            style={{textAlign:'center'}}
          />
          </div>
       
        </div>
        <div className="col-md-6 col-sm-12">
          <div className="form-group-container">
          <label htmlFor="active" className="label-control">
            {intl.get("ACTIVITY") || "Activity"}
          </label>
          <select
          style={{border:'none'}}
            onChange={ev => this._setInput(ev)}
            ref={active => (this.active = active)}
            id="active"
            className="form-control"
          >
            <option value={1}>{intl.get("ACTIVE") || "Active"}</option>
            <option value={0}>{intl.get("Inactive") || "Inactive"}</option>
          </select>
          </div>
          
        </div>
    <div className="col-md-6"></div>
    <div  style={{marginTop:'2rem', marginBottom:'2rem'}}></div>
    <div className="col-md-12 m-auto">
      <strong>Emergency Contacts</strong>
    </div>
    
      <div className="col-md-4">
      <div className="form-group-container">
          <label htmlFor="name" className="label-control">
            {intl.get("FULL_NAME") || "Full Name"}
          </label>
          <input
            onKeyPress={this._handleKeyPress.bind(this)}
            onChange={ev => this._setInput(ev)}
            ref={name => (this.contactName1 = name)}
            className="form-control"
            id="contactName1"
            name="contactName1"
            placeholder="Full Name"
            style={{textAlign:'center'}}
          />
          </div>
      </div>
      <div className="col-md-4">
      <div className="form-group-container">
          <label htmlFor="name" className="label-control">
            {intl.get("RELATION") || "Relation"}
          </label>
          <input
            onKeyPress={this._handleKeyPress.bind(this)}
            onChange={ev => this._setInput(ev)}
            ref={relation => (this.relation1 = relation)}
            className="form-control"
            id="relation1"
            name="relation1"
            placeholder="Relation"
            style={{textAlign:'center'}}
          />
          </div>
      </div>
      <div className="col-md-4">
      <div className="form-group-container">
          <label htmlFor="mobile" className="label-control">
            {intl.get("PHONE_NUMBER") || "Phone Number"}
          </label>
        <input
            name="mobile"
           
            onKeyPress={this._handleKeyPress.bind(this)}
            onChange={ev => this._setInput(ev)}
            ref={mobile1 => (this.mobile1 = mobile1)}
            className="form-control"
            id="mobile1"
            placeholder="70123456"
            style={{textAlign:'center'}}
          />
          </div>
      </div>
      <div className="col-md-4">
      <div className="form-group-container">
          <label htmlFor="name" className="label-control">
            {intl.get("FULL_NAME") || "Full Name"}
          </label>
          <input
            onKeyPress={this._handleKeyPress.bind(this)}
            onChange={ev => this._setInput(ev)}
            ref={name => (this.contactName2 = name)}
            className="form-control"
            id="contactName2"
            name="contactName2"
            placeholder="Full Name"
            style={{textAlign:'center'}}
          />
          </div>
      </div>
      <div className="col-md-4">
      <div className="form-group-container">
          <label htmlFor="name" className="label-control">
            {intl.get("RELATION") || "Relation"}
          </label>
          <input
            onKeyPress={this._handleKeyPress.bind(this)}
            onChange={ev => this._setInput(ev)}
            ref={relation => (this.relation2 = relation)}
            className="form-control"
            id="relation2"
            name="relation2"
            placeholder="Relation"
            style={{textAlign:'center'}}
          />
          </div>
      </div>
      <div className="col-md-4">
      <div className="form-group-container">
          <label htmlFor="mobile" className="label-control">
            {intl.get("PHONE_NUMBER") || "Phone Number"}
          </label>
        <input
            name="mobile"
           
            onKeyPress={this._handleKeyPress.bind(this)}
            onChange={ev => this._setInput(ev)}
            ref={mobile1 => (this.mobile2 = mobile1)}
            className="form-control"
            id="mobile2"
            placeholder="70123456"
            style={{textAlign:'center'}}
          />
          </div>
      </div>
    
    <div className="col-md-12">
    <div className="row mt-3">
          <div className="col-md-12">
            <div className="d-flex justify-content-center">
            <button
            disabled={this.state.loading ? true : false}
            className='btn-green' onClick={this._doCreate.bind(this)}>
            {intl.get("SAVE") || "Save"}
            </button>
            <button
            style={{marginLeft:'1rem'}}
            className='btn-ghost' 
            onClick={()=>this.props.history.push('/clients/view')}>
            {intl.get("CANCEL") || "Cancel"}
            </button>
            </div>
            
          </div>
        </div>
    </div>
       
      </div>
    );
    // }
  }

  _folderHeader(title) {
    return (
      <div className="header-folder">
        <h4>{title}</h4>
      </div>
    );
  }

  _err() {
    let error_message = this.state.err
    if (error_message) {
      // if(error_message === 'mobile is not allowed to be empty'){
      //   error_message = intl.get('REQUIRED_PHONE_NUMBER') || 'mobile is not allowed to be empty' 
      // }
     return <h4  style={{ textAlign: "center", color:'red' }}>
        {error_message}
      </h4>;
    }
  }

  _card() {
    return (
      <header className="header">
          <div className="text-center mt-4">
    <h2 className="primary-heading"> {intl.get("ADD") || "ADD"}</h2></div>
    <div className="action-container width-60">
      <div className="table-container padding-2">
      {this._err()}
              <div className="bootstrap-wrapper">
                {this._form()}
                
              </div>
      </div>
    </div>
      </header>
     
    );
  }

  render() {
    return (
   
        <div className="tbl">
          <div className="tbl-cell no-pad middle">{this._card()}</div>
        </div>
    
    );
  }
}
