import React from "react";
import intl from "react-intl-universal";
import {Link} from 'react-router-dom'



//this is the for sharing local storage with developer.montymobile.com

import { CrossStorageHub } from "cross-storage";
CrossStorageHub.init([
  {
    origin: /developer.montymobile.com$/,
    allow: ["get", "set", "del", "getKeys", "clear"]
  }
]);


const Account = props => {
  let data = props.data;

  return (

<>
<div className="my-3"><h4>SMS {intl.get("DETAILS") || "details"}</h4></div>
<div className="row">
  <div className="col-md-4 col-sm-12">
    <div className="form-group-container">
      <label htmlFor="">
      {intl.get("ACCOUNT_NAME") || "Account name"}
      </label>
      <input 
      style={{backgroundColor:'transparent'}}
      type="text"
      value={props.name}
      className='form-control text-center'
      disabled
      />
    </div>
      
  </div>
   
  <div className="col-md-4 col-sm-12 ">
    <div className="form-group-container">
      <label htmlFor="">{intl.get("ACCOUNT_BALANCE") || "Account Balance"}</label>
    <input 
      style={{backgroundColor:'transparent'}}
      type="text"
      className='form-control text-center'
      disabled
      value={`${data.accountBalance} ${data.currency ? data.currency : "USD"}`}/>
    </div>
  </div>
  <div className="col-md-4 col-sm-12 ">
    <div className="form-group-container">
      <label htmlFor="">{intl.get("NUMBER_OF_SMS") || "Number of SMS's Sent"}</label>
    <input 
      style={{backgroundColor:'transparent'}}
      type="text"
      className='form-control text-center'
      disabled
      value={`${data.quantity} SMS`}/>
    </div>
  </div>
  
  <div className="col-md-4 col-sm-12 ">
    <div className="form-group-container">
     <label htmlFor="">
     {intl.get("COST_PER_SMS") || "cost per SMS"}
     </label>
     <input 
     style={{backgroundColor:'transparent'}}
     type="text"
     className='form-control text-center'
     disabled
     value={`${data.price ? data.price.toFixed(2):0}  ${data.currency ? data.currency : "USD"}`}/>
    </div>
  </div>
    
  <div className="col-md-4 col-sm-12">
    <div className="form-group-container">
      <label htmlFor="">
      {intl.get("TOTAL_COST_SPENT") || "total cost spent"}
      </label>
      <input 
      style={{backgroundColor:'transparent'}}
      type="text"
      className='form-control text-center'
      disabled
      value={`${(data.quantity * data.price).toFixed(2)}`}/>
    </div>
  
  </div>
  <div className="col-md-4 col-sm-12">
    <div className="d-flex" style={{marginTop:'1rem'}}>
 
     <Link className='btn-red mr-2' to='/mymessages'>{intl.get("VIEW_MORE") || "View More"}</Link>
    <button className="btn-ghost"  onClick={() =>
                          this.props.history.push('/dashboard')
                        }>
     {intl.get("CANCEL") || "Cancel"}
     </button>
    </div>
   
  </div>
 
</div>
</>


  );
};

export default Account;
