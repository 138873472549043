import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import 'react-table/react-table.css';
import ReactTable from "react-table";

import intl from 'react-intl-universal';

export default @inject('store') @observer class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: []
        };
        const style = {
            fontSize: 15,
            textAlign: "center",
            padding:'1rem 0',
            borderBottom:'1px solid #707070'
        
    }
        this.columns = [{
            Header: intl.get('TITLE') || 'Title',
            accessor: 'title',
            style,
            
          },
           {
            Header: intl.get('PRICE') || 'Price',
            accessor: 'price',
            style,
          },
        //   {
        //     Header: 'Price discount',
        //     accessor: 'price_discount',
        //     style
        //   },
          {
            Header: intl.get('CURRENCY') || 'currency',
            accessor: 'currency_label',
            style,
          },
          {
            Header: intl.get('UNIT') || 'unit',
            accessor: 'per',
            style
          },
           {
              Header: intl.get('ACTIONS') || 'Actions',
              Cell: props => (
                <div className="d-flex justify-content-center">
                <span className='mr-2' onClick={_ => this.handleClick(props)}
                style={{fontSize:'1.2rem', color:'black', cursor:'pointer'}}>
                    <i className="fa fa-pencil"></i>
                </span>
                <span onClick={()=>{this.props.deleteservice(props.original.id)}} style={{fontSize:'1.2rem', cursor:'pointer'}}>
                    <i className="fa fa-trash" ></i>
                </span>
            </div>
                //   <div style={{float: 'right'}}>
                //       <a onClick={_ => this.handleClick(props)} className="material-icons action-icon ic-light-blue">edit</a>
                //       <a onClick={_=>this.setState({service_id:props.original.id},this.setState({open:true}))} className="material-icons action-icon ic-light-red">delete</a>
                //   </div>
              ),
              style
          }];
    }

    handleCancel = ()=>{
        this.setState({open:false})
    }

    handleClick = props => {
        this.props.history.push(`${this.props.to}${props.original.id}`, {
            service: { ...props.original }
        })
    }

    render() {
        return(
            
                        <ReactTable
                           
                            showPagination
                            data={this.props.services}
                            columns={this.columns}
                            pageSizeOptions={[10, 20, 30, 40]}
                            defaultPageSize={10}
                            sortable
                            multiSort
                            previousText={intl.get('PREVIOUS') || 'Pevious'}
                            nextText={intl.get("NEXT") || "Next"}
                            // loadingText: 'Loading...',
                            noDataText={intl.get("NO_ROWS_FOUND") || "No rows found"}
                            pageText={intl.get("PAGE") || "Page"}
                            ofText= {intl.get("OF") || "of"}
                            rowsText= {intl.get("ROWS") || "rows"}
                            resizable
                            // PaginationComponent={Pagination}
                            // className="-stripped -highlight"
                            // filterable
                            defaultSortDesc
                        />
                   
            )
    }
}