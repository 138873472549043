import React, { Component } from "react";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import moment from "moment";
import $ from "jquery";

let nowdate = new Date();

export default class extends Component {
  state = {
    dateClicked: moment(nowdate),
    year: this.props.year || new Date().getFullYear()
  };

  
  componentWillReceiveProps(nextProps) {
    if(nextProps.year !== this.state.year) {
      this.setState({ year: parseInt(nextProps.year) }, () => {});
      return true;
    }
  }
  

  render() {
    const modifiersStyles = {
        birthday: {
          color: "white" 
        },
      thursdays: {
        fontSize: 12,
      }
    };

    const modifiers = {
      thursdays: { daysOfWeek: [0, 1, 2, 3, 4, 5, 6] }
    };
    
    return (
      <div className="yearly">
        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(ar => (
          <div>
            <DayPicker
              month={new Date(this.state.year, ar)}
              className="yearlyView"
              key={ar}
              modifiers={modifiers}
              modifiersStyles={modifiersStyles}
              onDayClick={clickedDay=>this.props.switchToCustomDay(clickedDay)}
              selectedDays={[new Date(nowdate)]}
              style={`.DayPicker-Day {
                    background-color: #00bcd4;
                    color: white;
                    }
                    .DayPicker-Day--monday {
                    color: #00bcd4;
                    }
                 `}
            />
          </div>
        ))}
      </div>
    );
  }
}
