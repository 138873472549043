import React, { Component } from 'react';
import { Card, CardText, RaisedButton, Chip, Snackbar } from 'material-ui';
import { PUBLIC_URL, API_PREFIX } from '../../../commons/config';
import { inject, observer } from 'mobx-react';
import checkResponse from '../../../commons/CheckResponse';
import DateTimePicker from 'react-datetime-picker';
import moment from 'moment';
import intl from 'react-intl-universal';
export default
@inject('store')
@observer
class extends Component {
	constructor(props) {
		super(props);
		this.state = {
			users: [],
			clients: [],
			services: [],
			form: {
				user_id: '',
				title: '',
				description: '',
				user_id: this.props.store.get_id(),
				notes: '',
				amount: '',
				created_at: moment().format('YYYY-MM-DD hh:mm:ss'),
			},
			created_at: new Date(),
			open: false,
			date_time: new Date(),
		};
	}

	componentWillMount() {
		this.get_data();
		this.getExpense();
	}
	getExpense = () => {
		const expense_id = this.props.match.params.expense_id;
		const URL = PUBLIC_URL + API_PREFIX + 'expenses/viewone/' + expense_id;
		checkResponse(URL, 'GET').then(r => {
			let expense = r.data.expense;
			let form = {
				title: expense.title,
				description: expense.description,
				user_id: expense.user_id,
				notes: expense.notes,
				amount: expense.amount,
				created_at: new Date(expense.created_at),
			};
			this.setState({
				form,
			});
		});
	};

	handleDateChange = (date) =>{
		const form = {...this.state.form}
		form['created_at'] = new Date(date)
		this.setState({form})
	}
	get_data = () => {
		const { get_business_id } = this.props.store;
		const URL = PUBLIC_URL + API_PREFIX + 'appointments/' + get_business_id() + '/' + 1;
		checkResponse(URL, 'get').then(r => {
			const clients = r.data.clients;
			const users = r.data.users;
			const services = r.data.services;
			this.setState({ clients, users, services });
		});
	};

	_form() {

		let { users } = this.state;
		return (
			<div className="row">
				<div className="col-md-6 col-sm-12">
					<div className="form-group-container">
					<label htmlFor="name" className="label-control">
						{intl.get('TITLE') || 'Title'}
					</label>
					<input
						onChange={ev => this._setInput(ev)}
						className="form-control"
						id="client_id"
						name="title"
						value={this.state.form.title}
						style={{textAlign:'center'}}
						required
					/>
					</div>
					
				</div>
				<div className="col-md-6 col-sm-12">
					<div className="form-group-container">
					<label htmlFor="client_id" className="label-control">
						Description
					</label>
					<input
						onChange={ev => this._setInput(ev)}
						className="form-control"
						id="client_id"
						value={this.state.form.description}
						name="description"
						style={{textAlign:'center'}}
						required
					/>
					</div>
				
				</div>
				<div className="col-md-6 col-sm-12">
					<div className="form-group-container">
					<label htmlFor="password" className="label-control">
						created by
					</label>
					<select
						onKeyPress={this._handleKeyPress.bind(this)}
						onChange={ev => this._setInput(ev)}
						className="form-control"
						value={this.state.form.user_id}
						name="user_id"
						type="text"
						style={{border:'none'}}
					>
						<option value={null}>-select-</option>

						{users.map(ser => (
							<option value={ser.value}>{ser.label}</option>
						))}
					</select>
					</div>
				
				</div>
				<div className="col-md-6 col-sm-12">
					<div className="form-group-container">
					<label htmlFor="notes" className="label-control">
						notes
					</label>
					<textarea
						onKeyPress={this._handleKeyPress.bind(this)}
						onChange={ev => this._setInput(ev)}
						className="form-control"
						id="notes"
						value={this.state.form.notes}
						name="notes"
						placeholder="notes"
						style={{textAlign:'center', border:'none'}}
					/>
					</div>
				
				</div>
				<div className="col-md-6 col-sm-12">
					<div className="form-group-container">
					<label htmlFor="active" className="label-control">
						Amount
					</label>
					<input
						onChange={ev => this._setInput(ev)}
						id="amount"
						name="amount"
						className="form-control"
						value={this.state.form.amount}
						style={{textAlign:'center'}}
					/>
					</div>
				
				</div>
        <br />
				<div className="col-md-6 col-sm-12">
					<div className="form-group-container">
					<label htmlFor="date" className="label-control">
						Date
					</label> 
					<DateTimePicker 
					onChange={date=>this.handleDateChange(date)} 
					value={this.state.form.created_at }
					 />
					
					</div>
				
         
				</div>
				<div className="col-md-12 d-flex justify-content-center mt-3">
      <button className="btn-red" onClick={this._doCreate.bind(this)}>
      {intl.get("ADD") || "Add"}
      </button>
      <button className="btn-ghost ml-2" onClick={()=>this.props.history.push('/expenses/view')}>
      {intl.get("CANCEL") || "Cancel"}
      </button>
    </div>
			</div>
		);
	}

	_folderHeader(title) {
		return (
			<div className="header-folder">
				<h4>{title}</h4>
			</div>
		);
	}

	_setInput(event) {
		let form = Object.assign({}, this.state.form);
		if (event.target) {
			const element = event.target.name;
			form[element] = event.target.value;
			this.setState({ form });
		} else {
      form.created_at = moment(event).format('YYYY-MM-DD hh:mm:ss')

			this.setState({ form });
		}
	}

	_handleKeyPress(e) {
		if (e.key === 'Enter') {
			this._doCreate();
		}
	}

	_err() {
		if (this.state.err) {
			return (
				<div className="text-center pad10">
					<h4 className="red">{this.state.err}</h4>
				</div>
			);
		}
	}
	validate = () => {
		let valid = true;
		this.setState({ err: '' });
		const form = { ...this.state.form };
		Object.keys(form).map(key => {
			var value = form[key];
			if (!value && key !== 'notes' && key !== 'amount') {
				this.setState({ err: `${key} is missing` });
				valid = false;
			}
		});
		return valid;
	};

	_doCreate() {
		let created_at = moment(this.state.created_at).format('YYYY-MM-DD hh:mm:ss');
		if (this.validate()) {
			const data = {
				...this.state.form,
				created_at,
			};
		
			const URL = PUBLIC_URL + API_PREFIX + 'expenses/edit/' + this.props.match.params.expense_id;
			checkResponse(URL, 'PUT', data).then(r => {
				if (r.data) {
					return this.props.history.goBack();
				}
			});
		}
	}

	_card() {
		return (
			<header className="header">
				 <div className="text-center mt-4">
         <h2 className="primary-heading"> {intl.get('EDIT_PAYMENT') || 'Edit Payment'}</h2>
       </div>
			 <div className="action-container" style={{width:'60%'}}>
				 <div className="table-container padding-2">
				 {this.state.err ? (
								<h4 className="red" style={{ textAlign: 'center' }}>
									{this.state.err}
								</h4>
							) : (
								[]
							)}

           {this._form()}
				 </div>
			 </div>
			</header>
			// <div className="md20">
			// 	{this._folderHeader('Edit ' + 'payment')}
			// 	<div style={{ margin: 0 }}>
			// 		<Card>
			// 			<CardText>
			// 				{this.state.err ? (
			// 					<h4 className="red" style={{ textAlign: 'center' }}>
			// 						{this.state.err}
			// 					</h4>
			// 				) : (
			// 					[]
			// 				)}
			// 				<div className="bootstrap-wrapper">
			// 					{this._form()}
			// 					<div className="mt10 col-md-12" style={{ textAlign: 'right' }}>
			// 						<RaisedButton
			// 							onClick={this._doCreate.bind(this)}
			// 							primary
			// 							labelColor="#FFF"
			// 							variant="contained"
			// 							label={intl.get('SAVE') || 'Save'}
			// 						/>
			// 					</div>
			// 				</div>
			// 			</CardText>
			// 		</Card>
			// 	</div>
			// </div>
		);
	}

	_snackbar() {
		if (this.state.open) {
			return (
				<Snackbar
					open={this.state.open}
					message="User successfully added."
					autoHideDuration={4000}
					onRequestClose={this.handleRequestClose}
				/>
			);
		}
	}

	handleRequestClose = () => {
		this.setState({
			open: false,
		});
	};

	render() {
		return (
			<div >
				<div className="tbl">
					<div className="tbl-cell no-pad middle">{this._card()}</div>
				</div>
				{this._snackbar()}
			</div>
		);
	}
}

class DatePicker extends Component {


	render() {
		return (
			<div>
				<DateTimePicker onChange={date=>this.props.date_time(date)} value={this.props.value} />
			</div>
		);
	}
}
