import React, { Component } from "react";

import checkResponse from "../../../commons/CheckResponse";
import { PUBLIC_URL, API_PREFIX } from "../../../commons/config";
import { inject, observer } from "mobx-react";
import formatMoney from "../../../commons/formatMoney";
import { Modal } from "semantic-ui-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import intl from "react-intl-universal";

@inject("store")
@observer
class BusinessDetails extends Component {
  state = {
    businesses: [],
    openModal: false,
    business_name: "",
    password: "",
  };

  componentDidMount() {
    this.getBusinesses();
  }

  getBusinesses() {
    const URL = PUBLIC_URL + API_PREFIX + "business/user-businesses";
    checkResponse(URL, "get").then((r) => {
      this.setState({ businesses: r.data.businesses });
    });
  }
  updateList = (deletedBusiness) => {
    window.location.reload();
    let businesses = [...this.state.businesses];
    businesses = businesses.filter((bus) => {
      return bus.id !== deletedBusiness.id;
    });
    return this.setState({ businesses });
  };

  render() {
    return (
      <>
        <div className="my-3">
          <h4>{intl.get("MY_BUSINESSES") || "My businesses"}</h4>
        </div>
        {this.state.businesses.map((business) => {
          return (
            <div className="mb-4">
              <EditBusiness
                business={business}
                updateList={(business) => this.updateList(business)}
                history={this.props.history}
                store={this.props.store}
              />
            </div>
          );
        })}
      </>
    );
  }
}

class EditBusiness extends React.Component {
  state = {
    business: { appointments: [] },
    edit: false,
    invoices: [],
    payments: [],
  };

  handleChange = (e) => {
    let business = { ...this.state.business };
    business[e.target.name] = e.target.value;
    this.setState({ business });
  };

  componentDidMount() {
    this.setState({ business: this.props.business });
    this._getInvoices();
    this._getPayments();
  }
  _getInvoices() {
    const URL =
      PUBLIC_URL + API_PREFIX + "invoices/business/" + this.props.business.id;
    checkResponse(URL, "get").then((r) => {
      const invoices = r.data.invoices;
      this.setState({ invoices });
    });
  }
  _getPayments() {
    const URL = PUBLIC_URL + API_PREFIX + "sales/all/" + this.props.business.id;
    checkResponse(URL, "get").then((r) => this.setState({ payments: r.data }));
  }

  confirmDelete = async (business) => {
    this.setState({ err: "" });

    const { value: formValues } = await Swal.fire({
      title: "Are you sure?",
      html:
        `<input id="business_name" class="swal2-input" 
        name="business_name"
        placeholder="Type business name">` +
        '<input id="password" class="swal2-input" placeholder="Type your password">',

      focusConfirm: false,
      preConfirm: () => {
        this.setState({
          business_name: document.getElementById("business_name").value,
          password: document.getElementById("password").value,
        });
      },
    });

    if (formValues) {
      const { business_name, password } = this.state;
      if (business_name === business.name) {
        const URL = PUBLIC_URL + API_PREFIX + "deleteBusiness/" + business.id;

        checkResponse(URL, "delete", { password })
          .then((r) => {
            if (r.data) {
              Swal.fire({
                type: "success",
                icon: "success",
                text: "Business deleted successfully!",
              });
              return this.props.updateList(business);
            } else {
              Swal.fire({
                type: "error",
                icon: "error",
                text: "Your password is not valid",
              });
            }
          })
          .catch((e) => {
            return;
          });
      } else {
        Swal.fire({
          type: "error",
          icon: "error",
          text: "Business name does not match with the one you want to delete",
        });
      }
    }
  };

  modal = (business) => {
    return (
      <Modal
        open={this.state.openModal}
        // trigger={<Button>Show Modal</Button>}
        //  header= {intl.get('SURE_DELETE_BUSINESS') || `are you sure you want to delete your business ?!`}
        content={() => this.content(business)}
        actions={[
          <button
            className="btn btn-default"
            onClick={() => this.setState({ openModal: false })}
          >
            {intl.get("CANCEL") || "Cancel"}
          </button>,
          <button
            className="btn btn-danger"
            onClick={() => this.confimDelete(business)}
          >
            {intl.get("DELETE") || "Delete"}
          </button>,
        ]}
      />
    );
  };

  _goToBusiness(business) {
    const { business_id, name } = business;
    const { set_business_id, set_business_name } = this.props.store;
    set_business_name(name);
    localStorage.setItem("BUSINESS_NAME", name);
    set_business_id(business_id).then((_) => {
      this.props.history.push("/dashboard", {
        business_id,
      });
    });
  }

  handleImageChange = (e) => {
    const file = e.target.files[0];
    this.setState({ file });
  };

  renderView() {
    const { business } = this.state;
    const paymentsArray = [0];
    // this.state.payments.map(payment => {
    //   paymentsArray.push(payment.amount);
    // });
    function myFunc(total, num) {
      return total + num;
    }

    let total = 0 || paymentsArray.reduce(myFunc);
    total = `${formatMoney(parseInt(total))} ${this.state.business.currency}`;

    return (
      <>
        {this.modal(business)}
        <ToastContainer />
        <div className="text-center my-3">
          <strong>{business.name}</strong>
        </div>
        <div className="form-group-container">
          <label htmlFor="">{business.name}</label>
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <div className="form-group-container">
                <label htmlFor="">
                  {intl.get("BUSINESS_NAME") || "Business name"}
                </label>
                <input
                  className="form-control text-center"
                  type="text"
                  value={business.name}
                  disabled
                  style={{ backgroundColor: "transparent" }}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="form-group-container">
                <label htmlFor="">
                  {intl.get("SHORT_NAME") || "Short name"}
                </label>
                <input
                  value={business.short_name}
                  type="text"
                  className="form-control text-center"
                  disabled
                  style={{ backgroundColor: "transparent" }}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="form-group-container">
                <label htmlFor="">
                  {intl.get("FN_NUMBER") || "Finance Number"}
                </label>
                <input
                  value={business.fn_number}
                  type="text"
                  className="form-control text-center"
                  disabled
                  style={{ backgroundColor: "transparent" }}
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="form-group-container">
                <label htmlFor="">
                  {intl.get("APPOINTMENTS") || "Appointments"}
                </label>
                <input
                  type="text"
                  className="form-control text-center"
                  disabled
                  value={business.appointments.length}
                  style={{ backgroundColor: "transparent" }}
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="form-group-container">
                <label htmlFor="">{intl.get("INVOICES") || "Invoices"}</label>
                <input
                  type="text"
                  className="form-control text-center"
                  disabled
                  style={{ backgroundColor: "transparent" }}
                  value={this.state.invoices.length}
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="form-group-container">
                <label htmlFor="">
                  {intl.get("TOTAL_PAYMENTS") || "Total Payments"}
                </label>
                <input
                  type="text"
                  className="form-control text-center"
                  disabled
                  value={total}
                  style={{ backgroundColor: "transparent" }}
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="row">
                <div className="col-md-4 col-sm-12">
                  <button
                    style={{ marginTop: "1rem", width: "100%" }}
                    className="btn-red"
                    onClick={() => this.setState({ edit: true })}
                  >
                    {intl.get("EDIT") || "edit"}
                  </button>
                </div>
                <div className="col-md-4 col-sm-12">
                  <button
                    style={{ marginTop: "1rem", width: "100%" }}
                    className="btn-ghost"
                    onClick={() => this.confirmDelete(business)}
                  >
                    {intl.get("DELETE") || "Delete"}
                  </button>
                </div>
                <div className="col-md-4 col-sm-12">
                  <button
                    style={{ marginTop: "1rem", width: "100%" }}
                    className="btn-default-ghost"
                    onClick={() => this._goToBusiness(business)}
                  >
                    {intl.get("GO_TO_BUSINESS") || "Go to business"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  err = () => {
    if (this.state.err) {
      return (
        <div className="text-center text-danger">
          <p>{this.state.err}</p>
        </div>
      );
    }
  };

  content = (business) => {
    return (
      <div className="container-fluid">
        {this.err()}
        <div className="row">
          <div className="col"></div>
        </div>
        <div className="row" style={{ margin: "5px" }}>
          <div className="col-10">
            <div className="form-group">
              <label style={{ marginLeft: "10px" }}>
                {intl.get("PLEASE_TYPE") || "please type"}{" "}
                <span style={{ color: "red" }}>{business.name}</span>
              </label>
              <br />
              <input
                type="text"
                placeholder="business name"
                tabindex="-1"
                className="form-control p-5"
                name="business_name"
                value={this.state.business_name}
                onChange={this.handleInputChange}
                style={{ width: "50%", margin: "10px" }}
                autoCapitalize="off"
              />
            </div>
            <div className="form-group">
              <label style={{ marginLeft: "10px" }}>
                {intl.get("PASSWORD") || "your password"}
              </label>
              <br />
              <input
                type="password"
                tabindex="-1"
                placeholder={intl.get("PASSWORD") || "your password"}
                required
                value={this.state.password}
                name="password"
                onChange={this.handleInputChange}
                className="form-control p-5"
                style={{ width: "50%", margin: "10px" }}
                autoComplete="off"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  submit = () => {
    const URL =
      PUBLIC_URL + API_PREFIX + "business/edit/" + this.state.business.id;
    const {
      name,
      short_name,
      business_phone,
      address,
      fn_number,
    } = this.state.business;
    const data = new FormData();
    if (this.state.file) {
      data.append("files", this.state.file);
    }
    data.append("name", name);
    data.append("short_name", short_name);
    data.append("business_phone", business_phone);
    data.append("fn_number", fn_number);
    data.append("address", address);
    checkResponse(URL, "put", data).then((r) => {
      this.setState({ edit: false });
      toast("Business updated successfully !", {
        position: toast.POSITION.TOP_CENTER,
      });
    });
  };

  renderEdit() {
    const {
      name,
      short_name,
      fn_number,
      address,
      business_phone,
      logo,
    } = this.state.business;
    return (
      <>
        <div className="row">
          <div className="col-md-4 col-sm-12">
            <div className="form-group-container">
              <label className="label-control" style={{ fontSize: "13px" }}>
                {intl.get("BUSINESS_NAME") || "Business name"}
              </label>
              <input
                className="form-control text-center"
                onChange={this.handleChange}
                value={name}
                name="name"
                autoComplete="off"
              />
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="form-group-container">
              <label className="label-control" style={{ fontSize: "13px" }}>
                {intl.get("SHORT_NAME") || "Short name"}
              </label>
              <input
                className="form-control text-center"
                onChange={this.handleChange}
                value={short_name}
                name="short_name"
                autoComplete="off"
              />
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="form-group-container">
              <label className="label-control" style={{ fontSize: "13px" }}>
                {intl.get("FN_NUMBER") || "Finance Number"}
              </label>
              <input
                className="form-control text-center"
                onChange={this.handleChange}
                value={fn_number}
                name="fn_number"
                autoComplete="off"
              />
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="form-group-container">
              <label className="label-control" style={{ fontSize: "13px" }}>
                {intl.get("BUSINESS_PHONE") || "Business phone"}
              </label>
              <input
                className="form-control text-center"
                onChange={this.handleChange}
                value={business_phone}
                name="business_phone"
                autoComplete="off"
              />
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="form-group-container">
              <label className="label-control" style={{ fontSize: "13px" }}>
                {intl.get("ADDRESS") || "Address"}
              </label>
              <input
                className="form-control text-center"
                onChange={this.handleChange}
                value={address}
                name="address"
                autoComplete="off"
              />
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="form-group-container">
              <label className="label-control" style={{ fontSize: "13px" }}>
                {intl.get("LOGO") || "Logo"}
              </label>
              <img
                src={`${PUBLIC_URL}/` + logo}
                style={{ width: "200px", height: "200px", border: "none" }}
              />
              <input
                type="file"
                id="file"
                className="form-control"
                onChange={this.handleImageChange}
                name="file"
              />
            </div>
          </div>
          <div className="my-3 d-flex flex-column justify-content-center">
            <button className="btn-red mb-2" onClick={this.submit}>
              {intl.get("SAVE") || "Save"}
            </button>
            <button
              className="btn-ghost"
              onClick={(_) => this.setState({ edit: false })}
            >
              {intl.get("CANCEL") || "Cancel"}
            </button>
          </div>
        </div>
        <ToastContainer />
      </>
    );
  }

  render() {
    if (this.state.edit) {
      return this.renderEdit();
    }
    return this.renderView();
  }
}

export default BusinessDetails;
