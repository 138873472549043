import React, { PureComponent, useState } from "react";
import $ from "jquery";
import Yearly from "./pickers/yearly";
import { inject, observer } from "mobx-react";
import { PUBLIC_URL, API_PREFIX, CREDS } from "../../../../commons/config";
import moment from "moment";
import ShowCardCreate from "./ShowCardCreate";
import ShowCardView from "./ShowCardView";
import checkResponse from "../../../../commons/CheckResponse";
import { Menu, Item, contextMenu } from "react-contexify";
import "react-contexify/dist/ReactContexify.min.css";
import "fullcalendar";
import { Confirm } from "semantic-ui-react";
import { ToastContainer, toast } from "react-toastify";
import intl from "react-intl-universal";

require("../rightclick")($);

export default
@inject("store")
@observer
class extends PureComponent {
  constructor(props) {
    super(props);
    const self = this;
    this.state = {
      open: false,
      patient: {},
      result: "show the modal to capture a result",
      countryCode: "+961",
      loading: true,
      googleEvents: [],
      elements: [],
      timeframe: 30,
      showCard: false,
      val: { label: "" },
      showCardView: false,
      options: {
        nowIndicator: true,
        slotEventOverlap: false,
        eventLimit: 5, // for all non-TimeGrid views
        agendaEventMinHeight: 20,
        droppable: true,
        aspectRatio: 2,
        editable: true,
        slotDuration: "00:30:00",
        eventResizableFromStart: true,
        eventDurationEditable: true,
        header: false,
        allDay: false,
        allDaySlot: false,
        minTime: "06:00:00",
        maxTime: "24:00:00",
        defaultView: "agendaDay",
        dayClick: (calEvent, jsEvent, view) =>
          this._dayClick(calEvent, jsEvent),

        eventResize: function(eventResizeInfo, o, revertFunc) {
          if (!eventResizeInfo.client_id) {
            alert("event unresizable");
            revertFunc();
          }
          const time_start = moment(eventResizeInfo.start).format("hh:mm A");
          const time_end = moment(eventResizeInfo.end).format("hh:mm A");

          self._updateAppointment(
            0,
            time_start,
            time_end,
            eventResizeInfo.id,
            revertFunc
          );
        },
        //remove the first N to activate mouse over
        NeventMouseover: function(calEvent, jsEvent) {
          var tooltip =
            '<div class="tooltipevent" style="width:130px;height:100px;background:rgba(51, 102, 255,0.5);position:absolute;z-index:10001;border-radius:3px 3px 3px 3px;padding-top:10px;margin:0;text-align:center;font-weight:bold;">' +
            calEvent.patient_name +
            "<br/>" +
            "start : " +
            calEvent.time_start +
            "<br/>" +
            "end : " +
            calEvent.time_end +
            "</div>";
          var $tool = $(tooltip).appendTo("body");
          $(this)
            .mouseover(function(e) {
              $(this).css("z-index", 10000);
              $tool.fadeIn("500");
              $tool.fadeTo("10", 1.9);
            })
            .mousemove(function(e) {
              $tool.css("top", e.pageY + 10);
              $tool.css("left", e.pageX + 20);
            });
        },
        eventMouseout: function(calEvent, jsEvent) {
          $(this).css("z-index", 8);
          $(".tooltipevent").remove();
        },

        eventClick: (calEvent, jsEvent, view) =>
          // alert()
          this._eventClick(calEvent, jsEvent),

        eventDrop: function(calEvent, delta, revertFunc) {
          if (!calEvent.client_id) {
            alert("undropable google event");
            revertFunc();
          }
          var drop_date = moment(calEvent.start.format("YYYY-MM-DD")).format(
              "YYYY-MM-DD"
            ),
            today_date = moment().format("YYYY-MM-DD");

          var drop_start_time = calEvent.start.format("hh:mm A");
          var end = calEvent.end || calEvent.start;
          var drop_end_time = end.format("hh:mm A");

          if (drop_date < today_date) {
            alert("Error: Selected Date is less than today's date!");
            revertFunc();
            return false;
          }

          self._updateAppointment(
            drop_date,
            drop_start_time,
            drop_end_time,
            calEvent.id,
            revertFunc
          );
        },

        eventRender: (event, element) => {
          if (event.status === "Done") {
            element
              .children(".fc-content")
              .css({ "background-color": "#00c292" });
          }
          if (event.status === "Waiting") {
            element
              .children(".fc-content")
              .css({ "background-color": "orange" });
          }
          if (event.status === "Cancelled") {
            element.children(".fc-content").css({ "background-color": "red" });
          }
          if (event.status === "Pending") {
            element
              .children(".fc-content")
              .css({ "background-color": "royalblue" });
          }
          let eventStart = moment(event.time_start, "hh:mm A"); // Corrected time format

          console.log(event.status)
          console.log(eventStart)
          if (eventStart.isBefore(moment())) {
            element.children(".fc-content").css({ "background-color": "red" });
            if (event.status == 'Pending') {
              const URL = `${PUBLIC_URL}${API_PREFIX}appointments/edit/${event.id}`;
              checkResponse(URL, "put", { status: "Done" }).then(
                (result) => {
                  this.getStats(); // Refresh stats after updating status
                }
              );
            }
            event.status = "Done";
          }
          let elements = this.state.elements;
          element.evid = event.id;
          elements.push(element);
          this.setState({ elements });
          element.bind("dblclick", () => {
            if (event.client_id) {
              return this.props.history.push("/appointments/" + event.id);
            }
            return alert("unclickable google event");
          });
        },

        eventRightclick: function(event, jsEvent, view) {
          console.log(event);
          contextMenu.show({
            id: "menu_id",
            event: jsEvent,
          });
          self.setState(
            {
              eventId: event.id,
              // eventType: event.miscProps.flag,
              mobile: event.mobile,
              rcEvent: event,
            },
            (_) => self.forceUpdate()
          );
          return false;
        },

        eventAfterRender: function(event, element) {
          // if (event.flag == "high") {
          //   element.css("background-color", "red");
          // }
          // if (event.flag == "medium") {
          //   element.css("background-color", "orange");
          // }
          // if (event.flag == "vip") {
          //   element.css("background-color", "#00c292");
          // }
          // if (event.flag == "other") {
          //   element.css("background-color", "black");
          // }
          // if (event.flag == "googleEvent") {
          //   element.css("background-color", "purple");
          // }
          if (event.status === "Done") {
            element
              .children(".fc-event-inner")
              .css({ "background-color": "#00c292" });
          }
          if (event.status === "Waiting") {
            element
              .children(".fc-event-inner")
              .css({ "background-color": "orange" });
          }
          if (event.status === "Cancelled") {
            element
              .children(".fc-event-inner")
              .css({ "background-color": "red" });
          }
        },
      },
      shouldSwitchToYear: this.props.props.shouldSwitchToYear,
      year: new Date().getFullYear(),
      show: false,
      cal: {},
    };
  }

  getTimeFrame = () => {
    const { get_business_id } = this.props.store;
    const URL =
      PUBLIC_URL +
      API_PREFIX +
      "settings/getSettingsAndValues/" +
      get_business_id();
    checkResponse(URL, "get").then((r) => {
      if (r.data.settings.length > 0) {
        var timeframe = this.state.timeframe;
        var calendar_start = this.state.options.minTime;
        var calendar_end = this.state.options.maxTime;
        var slotDuration = this.state.options.slotDuration;
        const settings = r.data.settings;
        settings.map((setting) => {
          if (setting.label == "country code") {
            // const countryCode = localStorage.getItem("country code") || "+961";
            // this.setState({ countryCode: setting.value || countryCode });
          }
          if (setting.value !== null) {
            if (setting.label === "time frame") {
              timeframe = setting.value;
            }
            if (setting.label === "calendar_start") {
              calendar_start = setting.value + ":00";
            }
            if (setting.label === "calendar_end") {
              calendar_end = setting.value + ":00";
            }
            if (setting.label === "slot duration") {
              var value =
                setting.value < 10 ? "0" + setting.value : setting.value;
              slotDuration = "00:" + value + ":00";
            }
          }
        });
        var options = { ...this.state.options };
        let end = calendar_end.split(":")[0];
        let start = calendar_start.split(":")[0];
        if (parseInt(end) < parseInt(start)) {
          end = parseInt(end) + 12;
          end = end < 10 ? "0" + end : end;
          calendar_end = end + ":00";
        }

        options.minTime = calendar_start;
        options.maxTime = calendar_end;
        options.slotDuration = slotDuration;
        return this.setState({ timeframe, options });
      }
    });
  };

  _eventClick = (calEvent, jsEvent) => {
    this.setState({ clickedEvent: calEvent });
    this._updateclients();
    this.setState({ showCardView: true }, () => {
      this.forceUpdate();
    });
  };
  _dayClick(calDate, event, view) {
    this._updateclients();
    var view = $("#calendar").fullCalendar("getView");
    const month = view.name == "month";
    var time_start = moment(month ? Date.now() : calDate).format("hh:mm A");
    var time_end = moment(month ? Date.now() : calDate)
      .add(this.state.timeframe, "minutes")
      .format("hh:mm A");
    var select_date = moment(calDate.format("YYYY-MM-DD")).format("YYYY-MM-DD");
    const today_date = moment().format("YYYY-MM-DD");

    if (select_date < today_date) {
      alert("Error: Selected Date is less than today's date!");
      return false;
    }
    this.setState({
      dialog: true,
      start_date: select_date,
      time_start,
      time_end,
    });

    const parsedCalDate = new Date(moment(calDate).format());
    const currentTime = moment().toDate();

    console.log("calDate (Date object):", parsedCalDate);
    console.log("current time (Date object):", currentTime);

    if (parsedCalDate > currentTime) {
      this.setState({ showCard: true, calDate }, () => {
        this.forceUpdate();
      });
    } else {
      console.log("calDate is not before current time");
    }
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.props.shouldSwitchToYear !== this.state.shouldSwitchToYear) {
      this.setState(
        { shouldSwitchToYear: nextProps.props.shouldSwitchToYear },
        this._initCalendar.bind(this)
      );
      return true;
    }
    if (nextProps.props.year !== this.state.year) {
      this.setState({ year: nextProps.props.year }, () => {});
      return true;
    }
    return false;
  }

  _updateAppointment = (
    drop_date,
    time_start,
    time_end,
    appointment_id,
    revertFunc
  ) => {
    if (window.confirm("Are you sure to update the the appointment?")) {
      //if user accept drag event
      const URL =
        PUBLIC_URL + API_PREFIX + "appointments/edit/" + appointment_id;
      let datee = {};
      if (drop_date != 0) {
        datee = {
          start_date: drop_date.replace(/-/g, "/"),
          time_start,
          time_end,
        };
      } else {
        datee = { time_start, time_end };
      }

      checkResponse(URL, "put", { ...datee }).then((result) => {
        return;
        //  toast("Appointment time has been changed !", {
        //   position: toast.POSITION.TOP_CENTER,
        // });
      });
    } else {
      //if user cancel drag event
      revertFunc();
    }
  };

  componentDidMount() {
    this._getGoogleEvents();
    this.setState({ loading: false });
    this.getStats();
  }
  _getGoogleEvents() {
    const token = JSON.parse(localStorage.getItem("googleTokens"));
    if (!token) {
      return this._getData();
    }
    const URL = PUBLIC_URL + API_PREFIX + `googleEvents`;
    checkResponse(URL, "post", token).then((r) => {
      if (r.data.filter) {
        let googleEvents = r.data.filter;
        this.setState({ googleEvents });
        this._getData();
      } else {
        this._getData();
      }
    });
  }
  _initCalendar(day) {
    this._getData(day);
  }

  _switchToCustomDay(day) {
    this.setState({ shouldSwitchToYear: false }, () => {
      this.forceUpdate();
      this.props._updateMiniCalendar(day);
      setTimeout(() => {
        this._initCalendar(day);
        this.props.onDateChange(day);
      }, 40);
    });
  }

  _calendar() {
    if (!this.state.shouldSwitchToYear) {
      return <div id="calendar" />;
    }
    return (
      <Yearly
        switchToCustomDay={this._switchToCustomDay.bind(this)}
        year={this.state.year}
      />
    );
  }

  _cancel = () => {
    this.setState({ showCard: false, showCardView: false }, () =>
      this.forceUpdate()
    );
  };
  _save = () => {
    this.setState({ showCard: false }, () => this.forceUpdate());
  };

  _showCreateForm() {
    if (this.state.showCard) {
      return (
        <ShowCardCreate
          // countryCode={this.state.countryCode}
          cal={this.state.cal}
          _cancel={this._cancel}
          _save={this._save}
          history={this.props.history}
          users={this.state.users}
          selectedUser={(val) => this.setState({ val })}
          clients={this.state.clients}
          store={this.props.store}
          _getData={this._getData}
          time_start={this.state.time_start}
          time_end={this.state.time_end}
          start_date={this.state.start_date}
          getStats={this.getStats}
        />
      );
    }
    if (this.state.showCardView) {
      let patient = {};
      this.state.clients.map((cli) => {
        if (cli.value === this.state.clickedEvent.client_id) {
          patient = {
            id: cli.value,
            email: "email",
            mobile: "mobile",
          };
        }
      });
      let user_label = "";
      this.state.users.map((usr) => {
        if (usr.value == this.state.val) {
          user_label = usr;
        }
      });
      return (
        <ShowCardView
          patient={patient}
          clickedEvent={this.state.clickedEvent}
          _cancel={this._cancel}
          selectedUser={user_label}
          history={this.props.history}
          users={this.state.users}
          clients={this.state.clients}
        />
      );
    }
  }

  updateEvent = (id, parm, rcEvent) => {
    let elements = this.state.elements;
    elements.map((element) => {
      if (element.evid === id) {
        switch (parm) {
          case "Cancelled":
            element.css("background-color", "red");
            break;
          case "Done":
            element.css("background-color", "#00c292");
            break;
          case "Waiting":
            element.css("background-color", "orange");
            break;
          case "Pending":
            element.css("background-color", "#4183c4");
            break;
          default:
            element.css("background-color", "#4183c4");
        }
      }
    });

    // element.css('background-color','red')
    const URL = PUBLIC_URL + API_PREFIX + "appointments/edit/" + id;
    checkResponse(URL, "put", { status: parm }).then((result) => {
      this.getStats();
    });
  };

  getStats = () => {
    const { get_business_id } = this.props.store;
    const URL =
      PUBLIC_URL + API_PREFIX + "appointments/getToday/" + get_business_id();
    checkResponse(URL, "get").then((result) => {
      const appointments = result.data.appointments;
      let done = [];
      let pending = [];
      let cancelled = [];
      let waiting = [];
      appointments.map((app) => {
        if (app.status === "Pending") {
          pending.push(app.id);
        }
        if (app.status === "Done") {
          done.push(app.id);
        }
        if (app.status === "Cancelled") {
          cancelled.push(app.id);
        }
        if (app.status === "Waiting") waiting.push(app.id);
      });
      let app_status = {
        Done: done.length,
        Pending: pending.length,
        Cancelled: cancelled.length,
        Waiting: waiting.length,
        Total: appointments.length,
      };
      this.props.stats(app_status);
    });
  };

  _showContext() {
    return (
      <Menu id="menu_id" theme="white" animation="fade">
        <Item
          onClick={() =>
            this.updateEvent(this.state.eventId, "Done", this.state.rcEvent)
          }
        >
          <p style={{ color: "#00c292" }}>{intl.get("DONE") || "Done"}</p>
        </Item>
        <Item onClick={() => this.updateEvent(this.state.eventId, "Pending")}>
          <p style={{ color: "#4183c4" }}>{intl.get("PENDING") || "Pending"}</p>
        </Item>
        <Item onClick={() => this.updateEvent(this.state.eventId, "Waiting")}>
          <p style={{ color: "orange" }}>
            {intl.get("MOVE_TO_WAITING_LIST") || "Move to waiting list"}
          </p>
        </Item>
        <Item onClick={() => this.updateEvent(this.state.eventId, "Cancelled")}>
          <p style={{ color: "red" }}>
            {intl.get("CANCEL_APPOINTMENT") || "Cancel Appointment"}
          </p>
        </Item>
        <Item onClick={() => this.setState({ open: true }, this.forceUpdate())}>
          {intl.get("DELETE_APPOINTMENT") || "Delete Appointment"}
        </Item>
        {/* <Item onClick={""}>Ipsum</Item>
    <Separator />
    <Item disabled>Dolor</Item>
    <Separator />
    <Submenu label="Foobar">
      <Item onClick={""}>Foo</Item>
      <Item onClick={""}>Bar</Item>
    </Submenu> */}
      </Menu>
    );
  }
  show = () => this.setState({ open: true });
  handleCancel = () => this.setState({ open: false }, this.forceUpdate());

  deleteEvent = (id) => {
    if (this.state.eventType == "googleEvent")
      return alert("google events can be only deleted from google calendar");
    const URL = PUBLIC_URL + API_PREFIX + `appointments/delete/${id}`;
    checkResponse(URL, "delete").then((r) => {
      $("#calendar").fullCalendar("removeEvents", id);
    });
    this.setState({ open: false }, this.forceUpdate());
    return;
    //  toast("Appointment has been deleted permanently !", {
    //   position: toast.POSITION.TOP_CENTER,
    // });
  };

  _hideCreateForm = () => {
    $(document).mouseup(function(e) {
      var container = $(".MuiPaper-root-1"); // YOUR CONTAINER SELECTOR

      if (
        !container.is(e.target) && // if the target of the click isn't the container...
        container.has(e.target).length === 0
      ) {
        // ... nor a descendant of the container
        // container.hide()
        //  this.setState({showCard:false},()=>this.forceUpdate());
      }
    });
  };

  _updateclients() {
    const { get_business_id } = this.props.store;
    const URL =
      PUBLIC_URL + API_PREFIX + "appointments/" + get_business_id() + "/" + 1;
    checkResponse(URL, "get").then((r) => {
      const clients = r.data.clients;
      const users = r.data.users;
      this.setState({ clients, users });
    });
  }

  _getData(day) {
    this.getTimeFrame();
    const { get_business_id } = this.props.store;
    const URL =
      PUBLIC_URL + API_PREFIX + "appointments/" + get_business_id() + "/" + 1;
    checkResponse(URL, "get", 1)
      .then((result) => {
        this.insertData(result, day);
      })
      .catch(console.error);
  }
  insertData(data, day) {
    if (day === undefined) {
      day = new Date();
    }
    let events = [];
    const { appointments, users, clients, services } = data.data;
    for (var i = 0; i < appointments.length; i++) {
      events.push({
        patient_name: appointments[i].name,
        id: appointments[i].id,
        mobile: appointments[i].mobile,
        start: appointments[i].start_date + " " + appointments[i].time_start,
        end: appointments[i].start_date + " " + appointments[i].time_end,
        time_start: appointments[i].time_start,
        time_end: appointments[i].time_end,
        notes: appointments[i].notes,
        title: appointments[i].title,
        user_id: appointments[i].user_id,
        client_id: appointments[i].client_id,
        flag: appointments[i].flag,
        services: appointments[i].services,
        all_day: false,
        number: appointments[i].number,
        status: appointments[i].status,
        //title for testingg
        title: appointments[i].name,
      });
    }

    let googleEvents = this.state.googleEvents;

    if (this.state.googleEvents.length) {
      events = [...events, ...googleEvents];
    }

    this.setState({ users, clients, loading: false }, () =>
      $("#calendar").fullCalendar({
        ...this.state.options,
        defaultDate: new Date(day),
        events,
        timeFormat: "hh:mm A",
        contentHeight: () => {
          if (window.screen.width < 577) {
            return "auto";
          }
        },
      })
    );
  }

  statistics = () => {};

  loading = () => {
    return (
      <div style={{ textAlign: "center" }}>
        <h1
          style={{
            color: "black",
            textAlign: "center",
            textTransform: "uppercase",
            textAlign: "center",
          }}
        >
          LOADING...
        </h1>
      </div>
    );
  };

  render() {
    const { open } = this.state;
    return (
      <div>
        {/* <ToastContainer /> */}

        <div>
          <Confirm
            open={open}
            // header={intl.get('ARE_YOU_SURE')}
            content={intl.get("ARE_YOU_SURE")}
            onCancel={this.handleCancel}
            cancelButton={intl.get("CANCEL") || "Cancel"}
            confirmButton={intl.get("OK") || "OK"}
            onConfirm={(_) => this.deleteEvent(this.state.eventId)}
          />
        </div>
        {this._showContext()}
        {this._hideCreateForm()}
        {this._showCreateForm()}
        {true ? this._calendar() : this.loading()}
      </div>
    );
  }
}
